import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {
  checkNgpReportSettings,
  DEFAULT_NG_REP_SETTINGS,
  NgpReportSettings
} from '../../../../shared-utilities/models-old/ngp-report/ngp-data-types';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {StandardAlertsService} from '../../../../shared-utilities/services-old/standard-alerts.service';

@Component({
  selector: 'app-ngp-report.ts-setting',
  templateUrl: './ngp-report-setting.component.html',
  styleUrls: ['./ngp-report-setting.component.scss'],
})
export class NgpReportSettingComponent implements OnInit {

  @Input() storeID: string;
  @Input() storeInfo: StoreInfo = {name: ''};

  ngpSettings: NgpReportSettings;
  changed: { [key in keyof NgpReportSettings]: true };

  ngpSettingsFG: UntypedFormGroup;

  constructor(
    private firebase: FirebaseService,
    private stdAlerts: StandardAlertsService,
    private alertControl: AlertController,
    private modalControl: ModalController,
    private toastControl: ToastController,
  ) { }

  ngOnInit() {
    this.stdAlerts.indeterminateProgress({}).then((finished) => {
      this.firebase.getStoreDataDoc('general_ngp_settings', this.storeID, 'operational').then((data) => {
        console.log('>', data);
        this.ngpSettings = data ? data : DEFAULT_NG_REP_SETTINGS;
        this.ngpSettingsFG = new UntypedFormGroup({});
        this.ngpSettingsFG.setControl('itemExpDays', new FormControl<number>(this.ngpSettings.itemExpDays,
          [this.validateNgpReportSetting('itemExpDays')]));
        this.ngpSettingsFG.setControl('requestExpDays', new FormControl<number>(this.ngpSettings.requestExpDays,
          [this.validateNgpReportSetting('requestExpDays')]));

        Object.keys(this.ngpSettingsFG.controls).forEach((key) => {
          this.ngpSettingsFG.controls[key].setParent(this.ngpSettingsFG);
        });
        finished();
      });
    });
  }

  close = () => {
    this.modalControl.dismiss().then();
  };

  change(event, key: keyof NgpReportSettings) {
    console.log(''+event.target.value, ''+this.ngpSettings[key], ''+event.target.value !== ''+this.ngpSettings[key]);

    if (''+event.target.value !== ''+this.ngpSettings[key] &&
        !(event.target.value === '' && [undefined, null].includes(this.ngpSettings[key]))) {
      if (!this.changed) {
        this.changed = {[key]: true} as any;
      } else {
        // @ts-ignore
        this.changed[key] = true;
      }
    } else if (this.changed) {
      // @ts-ignore
      delete this.changed[key];

      if (Object.keys(this.changed).length === 0) {
        this.changed = null;
      }
    }
  }

  checkRemoveValue(key: keyof NgpReportSettings, valueOnWhichToRemove: string | number) {
    // @ts-ignore
    const v = this.ngpSettingsFG.get(key).value;

    if (v === valueOnWhichToRemove) {
      // @ts-ignore
      this.ngpSettingsFG.get(key).setValue(null);
    }
  }

  getErrors(key: keyof NgpReportSettings) {
    const errors: string[] = [];
    // @ts-ignore
    const e = this.ngpSettingsFG.get(key).errors;
    Object.keys(e).forEach((k) => {
      errors.push(e[k]);
    });
    return errors;
  }

  async save() {
    if (this.ngpSettingsFG.invalid) { return; }

    const value = this.ngpSettingsFG.value;

    const ac = await this.alertControl.create({
      header: 'Update General NGP Settings', subHeader: 'You are about to update the NGP Report settings for ' +
        this.storeInfo.name + '.', message: 'This will affect all users with access to Report for ' +
        this.storeInfo.name + '<br><br>Are you sure you want to continue?', cssClass: ['custom-alert', 'warn'],
      buttons: ['Cancel', {text: 'Yes', role: 'y'}]
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role !== 'y') { return; }

    this.stdAlerts.indeterminateProgress({}).then((loaded) => {
      this.firebase.updateStoreDataDoc('general_ngp_settings', this.storeID, value, 'operational')
        .then(async () => {
          loaded();
          const tc = await this.toastControl.create({
            header: 'Settings Saved', cssClass: 'custom-toast', duration: 1500, position: 'top'
          });
          await tc.present();
          this.changed = null;
        })
        .catch((error) => {
          loaded();
          this.stdAlerts.errorAlert({
            message: error.message,
            type: (error.message.includes('Missing or insufficient permissions') ? 'PERMISSION' : null)});
        });
    });
  }

  private validateNgpReportSetting(key: keyof NgpReportSettings): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!control.parent) { return null; }
      const nrs = control.parent.value;
      nrs[key] = control.value;
      return checkNgpReportSettings(nrs, key);
    };
  }
}
