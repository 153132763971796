import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {ColDef, GridOptions} from 'ag-grid-community';
import {PriceCellEditorComponent} from '../price-cell-editor/price-cell-editor.component';
import {MultipleErrorPopoverComponent} from '../multiple-error-popover/multiple-error-popover.component';
import {
  keyToTitle,
  stockValCCR2Str,
  StockValChangeCheckConfig,
  StockValuesChangeCheckResult
} from '../../../../shared-utilities/models-old/datastructures';
import {formatThousands} from '../../../../shared-utilities/utils-old/formatting';

type KeysOfStockValChangeCheckConfig = keyof Partial<StockValChangeCheckConfig>;

@Component({
  selector: 'app-stock-val-change-flags',
  templateUrl: './stock-val-change-flags.component.html',
  styleUrls: ['./stock-val-change-flags.component.scss'],
})
export class StockValChangeFlagsComponent implements OnInit {

  @Input() desc?: 'Stock item name';
  @Input() itemFlags: { [code: string]: StockValuesChangeCheckResult };
  @Input() codes: string[];
  @Input() storeID: string;

  rowData: any[] = [];
  columnDefs: ColDef[] = [];
  gridOptions: GridOptions;
  changes = false;
  defaultColDef: any = {editable: false, sortable: true, filter: false};
  resolvedValues: {
    [code: string]: {
      [key in KeysOfStockValChangeCheckConfig]?: {
        [type: string | number]: string | number | Date;
      }
    };
  } = {};
  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Object = Object;

  constructor(
    private modalControl: ModalController,
    private popControl: PopoverController,
  ) {
  }

  ngOnInit() {
      // for (let i = 0; i < this.codes.length; i++) {
      //   this.itemFlags[Object.keys(this.itemFlags)[i]].desc = [];
      //     this.itemFlags[Object.keys(this.itemFlags)[i]].desc.push({
      //       desc: 'special char',
      //       new: 'ZZ BI MID SHEEN WHITE 20LT XXX',
      //       type: 'test',
      //       old: 'ZZ BI MID SHEEN WHITE 20LT XX',
      //       thresh: 20
      //     });
      //   this.itemFlags[Object.keys(this.itemFlags)[i]].desc.push({
      //     desc: 'special char',
      //     new: 'ZZ BI MID SHEEN WHITE 20LT XXX',
      //     type: 'noZero',
      //     old: 'ZZ BI MID SHEEN WHITE 20LT XX',
      //     thresh: 20
      //   });
      //   }
    this.initializeGridData();
  }

  initializeGridData(): void {
    const allKeys = new Set<string>();
    this.codes.forEach(code => {
      Object.keys(this.itemFlags[code]).forEach(key => allKeys.add(key));
    });

    const keysArray = Array.from(allKeys);
    const maxEntries = Math.max(...this.codes
      .map((code: string) =>
        Math.max(...Object
          .keys(this.itemFlags[code])
          .map((key: string) => this.itemFlags[code][key].length))
      ));
    this.columnDefs = [
      {
        headerName: 'Code',
        field: 'code',
        width: 130,
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'toolTip',
        onCellClicked: this.onShowErrorsClick,
      },
      {
        headerName: 'Type',
        field: 'key2Title',
        width: 150
      },
      {
        headerName: 'New Value',
        field: 'new',
        editable: true,
        cellEditor: PriceCellEditorComponent,
        tooltipField: 'new',
      },
    ];

    this.getRowData();
    this.gridOptions = {singleClickEdit: true, tooltipShowDelay: 500};
  }

  getRowData(): void {
    this.rowData = this.codes.reduce((acc: any[], code: string) => {
      const flaggedKeys = Object.keys(this.itemFlags[code]);
      console.log(flaggedKeys);
      const rows = flaggedKeys.reduce((innerAcc: any[], key: string) => {
        const items = this.itemFlags[code][key];
        console.log(items);
        const descriptions = [];
        let formattedNew: any;
        let original: any;
        let type: any;
        let key2Title: any;
        for (const item of items) {
          type = item.type;
          original = item.old;
          key2Title = keyToTitle[key];
          descriptions.push(stockValCCR2Str(item));
          if (!this.resolvedValues[code]) {
            this.resolvedValues[code] = {};
          }
          if (!this.resolvedValues[code][key]) {
            this.resolvedValues[code][key] = {};
          }
          this.resolvedValues[code][key][type] = item.new as any;
          if (key === 'sellPriIncl1') {
            formattedNew = formatThousands(item.new);
          } else {
            formattedNew = item.new;
          }
        }
        let isDescList ='';
        if (descriptions.length > 1){
          isDescList = 'Click to see all Errors';
        } else {
          isDescList = descriptions[0];
        }
        innerAcc.push({
          code,
          description: descriptions[0],
          descriptionList: descriptions,
          toolTip: isDescList,
          new: formattedNew,
          original,
          type,
          key2Title,
          key
        });
        return innerAcc;
      }, []);
      return acc.concat(rows);
    }, []);
  }

  onCancelClick(): void {
    this.modalControl.dismiss(null, 'ignore').then();
  }

  onChangeButtonClick(): void {
    for (const idx of Object.keys(this.rowData)) {
      this.resolvedValues[this.rowData[idx].code][this.rowData[idx].key] =
        this.rowData[idx].new;
    }
    this.modalControl.dismiss(this.resolvedValues, 'change').then();
  }

  onGridReady(params): void {
    params.api.sizeColumnsToFit();
  }

  onCellValueChanged() {
    this.changes = true;
  }

  onShowErrorsClick = async (params): Promise<void> => {
    if (params.data.descriptionList.length > 1) {
      const pc = await this.popControl.create({
        component: MultipleErrorPopoverComponent,
        backdropDismiss: true,
        componentProps: {
          description: params.data.descriptionList,
        },
      });
      await pc.present();
    }
  };
}
