import {Injectable} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {Observable, Subscriber} from 'rxjs';
import {AlertController, AlertOptions, ModalController} from '@ionic/angular';
import {arrayInsert} from '../functions-old/object-functions';
import {
  PartialProgressOptions, ProgressComponent,
  ProgressOptions
} from '../../shared-modules/shared-module/components/progress/progress.component';
import {BasicInputComponent} from '../../shared-modules/shared-module/components/basic-input/basic-input.component';
import {
  BasicMultipleInputComponent
} from '../../shared-modules/shared-module/components/basic-multiple-input/basic-multiple-input.component';

export interface BasicAlertParts {
  header?: string; subHeader?: string; message?: string;
}

// TODO: Maybe I should make this ReducedAlertOptions and omit a bunch of shit from the og.
export interface AltOpts extends BasicAlertParts{
  addCss?: 'warn' | 'error';
}

export interface ErrorAlertOptions extends Omit<AltOpts, 'addCss'>{
  reloadable?: boolean;
  buttons?: { text: string; role: string }[];
  type?: 'WARN' | 'PERMISSION' | 'URGENT' | 'GENERAL' | 'USER-NOT-FOUND';
}

export interface GetInputAlertOptions extends BasicAlertParts {
  required?: boolean;
  submitText?: string;
  cancelText?: string;
  inputLabel?: string;
  type?: 'string' | 'number';
  pattern?: RegExp;
  validators?: ValidatorFn[];
}

@Injectable({
  providedIn: 'root'
})
export class StandardAlertsService {

  constructor(
    private alertControl: AlertController,
    private modalControl: ModalController,
  ) { }

  static addDefaultIonicBackdropStyling(mc: HTMLIonModalElement | HTMLIonAlertElement | HTMLIonPopoverElement) {
    mc.style.setProperty('--backdrop-opacity', 'var(--ion-backdrop-opacity, 0.32)');
    mc.style.setProperty('--box-shadow', '0 28px 48px rgba(0, 0, 0, 0.4)');
  }

  async errorAlert(options: ErrorAlertOptions): Promise<string | null> {
    const ops: AlertOptions = {};
    ops.header = options.header;
    ops.subHeader = options.subHeader;
    ops.message = options.message;
    ops.cssClass = ['custom-alert', options.type === 'WARN' ? 'warn' : 'error'];
    ops.buttons = options.buttons ? options.buttons : ['OK'];

    if (options.reloadable) {
      options.buttons.push({text: 'Attempt Reload', role: 'reload'});
      ops.message = (ops.message ? ops.message + '<br><br>' : '') +
        'You can try reloading the page to see if it helps.';
    }

    switch (options.type) {
      case 'URGENT':
        ops.header = ops.header ? ops.header : 'Unusual Error Occurred';
        ops.message = (ops.message ? ops.message + '<br><br>' : '') + 'Please inform Techodactyl Support';
        break;
      case 'PERMISSION':
        ops.header = ops.header ? ops.header : 'Permission Denied';
        ops.subHeader = ops.subHeader ? ops.subHeader : 'You do not have permission to preform some task. ' +
          'If you think this is a mistake, please let us know.';
        break;
      case 'GENERAL':
        ops.header = ops.header ? ops.header : 'An Error Occurred';
        ops.subHeader = ops.subHeader ? ops.subHeader : 'If you are seeing this then a dev probably forgot to report ' +
          'this error properly. Please ket us know.';
        break;
      case 'WARN':
        break;
      case 'USER-NOT-FOUND':
        ops.header = 'User Not Found';
        ops.subHeader = 'There is no user account matching the provided email address, or it has been deleted.';
        ops.message = 'Please make sure you have entered the correct email address';
        break;
      default:
        ops.header = ops.header ? ops.header : 'Undefined Error Occurred';
        ops.message = (ops.message ? ops.message + '<br><br>' : '') + 'If you are seeing this then a dev probably ' +
          'forgot to report this error properly. Please ket us know.';
        break;
    }

    const ac = await this.alertControl.create(ops);
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'reload') {
      window.location.reload();
      return;
    }
    return role;
  }

  async indeterminateProgress(opts: PartialProgressOptions): Promise<() => void> {
    let observer: Subscriber<number>;
    const obs = new Observable<number>((o) => (observer = o));
    const options: ProgressOptions = {updates: obs};
    Object.keys(opts).forEach((key) => (options[key] = opts[key]));
    options.indeterminate = true;
    const mc = await this.modalControl.create({component: ProgressComponent, componentProps: options,
      backdropDismiss: false});
    await mc.present();
    return () => observer.complete();
  }

  async simpleConfirmation(
    opts?: AltOpts, btnText?: {yes?: string; no?: string}, optionC?: {text: string; role?: string}
  ): Promise<boolean | string> {
    const options: AlertOptions = {
      header: 'Please Confirm',
      subHeader: 'Are you sure?',
      cssClass: ['custom-alert']
    };
    let btnNoText = 'No';
    let btnYesText = 'Yes';

    if (opts) {
      options.header = opts.header ? opts.header : options.header;
      options.subHeader = opts.subHeader ? opts.subHeader : options.subHeader;
      options.message = opts.message ? opts.message : options.message;
      btnNoText = btnText && btnText.no ? btnText.no : 'No';
      btnYesText = btnText && btnText.yes ? btnText.yes : 'Yes';

      if (opts.addCss) { (options.cssClass as string[]).push(opts.addCss); }
    }
    options.buttons = [{text: btnNoText, role: 'n'}, {text: btnYesText, role: 'y'}];

    if (optionC) {
      optionC.role = optionC.role ? optionC.role : optionC.text;
      arrayInsert(options.buttons, 1, optionC);
    }

    const ac = await this.alertControl.create(options);
    await ac.present();
    const {role} = await ac.onDidDismiss();
    return role === 'y' ? true : (role === 'n' ? false : (optionC ? optionC.role : null));
  }

  /**
   * A very simple one button alert message. Used to convey small amounts of information or completion of a task.
   * Note `backgroundDismiss` is `true` by default.
   *
   * @member { AltOpts & { backdropDismiss?: boolean } }opts
   * @member {string | null} okText text to display on the 'ok' button
   */
  async simpleAlert(opts: AltOpts & { backdropDismiss?: boolean }, okText: string | null = 'ok') {
    const options: AlertOptions = {
      header: opts.header ? opts.header : 'Alert',
      subHeader: opts.subHeader ? opts.subHeader : 'Alert',
      message: opts.message ? opts.message : null,
      backdropDismiss: opts.hasOwnProperty('backdropDismiss') ? opts.backdropDismiss : true,
      buttons: [okText]
    };
    options.cssClass = opts.addCss ? ['custom-alert', opts.addCss] : 'custom-alert';
    const ac = await this.alertControl.create(opts);
    await ac.present();
    await ac.onDidDismiss();
  }

  async getEmail(
    opts?: Omit<GetInputAlertOptions, 'required' | 'type' | 'validators' | 'pattern'>
  ): Promise<string | null> {
    const options: GetInputAlertOptions = {
      header: 'Reset Password',
      subHeader: 'Please enter the email address bellow:',
      inputLabel: 'Email',
      cancelText: 'Cancel',
      submitText: 'Submit',
      type: 'string',
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };

    if (opts) {
      Object.keys(opts).forEach((k) => options[k] = opts[k]);
    }
    const mc = await this.modalControl.create({
      component: BasicInputComponent, componentProps: {options}
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();

    if (data) {
      return data;
    }
    return null;
  }

  async getMultipleEmail(
    opts?: Omit<GetInputAlertOptions, 'required' | 'type' | 'validators' | 'pattern'>
  ): Promise<string[] | null> {
    const options: GetInputAlertOptions = {
      header: 'Email Input',
      subHeader: 'Please enter the email address bellow:',
      inputLabel: 'Email',
      cancelText: 'Cancel',
      submitText: 'Submit',
      type: 'string',
      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    };

    if (opts) {
      Object.keys(opts).forEach((k) => options[k] = opts[k]);
    }
    const mc = await this.modalControl.create({
      component: BasicMultipleInputComponent, componentProps: {options}, cssClass: 'input-modal-small',
    });
    // When used in another modal etc, the backdrop does not show. Even with showBackdrop set to true. This forces it
    StandardAlertsService.addDefaultIonicBackdropStyling(mc);
    await mc.present();
    const {data} = await mc.onDidDismiss();

    if (data) {
      return data;
    }
    return null;
  }
}
