import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as SharedGridActions from './shared-grid.actions';
import {of, from} from 'rxjs';
import {mergeMap, map, catchError} from 'rxjs/operators';
import {TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IPriceBandPerStore} from '../../../shared/shared-components/models/price-banding/price-band';

@Injectable()
export class SharedGridEffects {

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================

  // '[Shared Grid][Table Nav] Get Table Nav Settings'
  getTableNavSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getTableNavSettings),
      mergeMap(() =>
        from(this.firebaseService.getUserDocument('ngp_table_nav')).pipe(
          map((data: TableNavSettings) => {
            if (!data.cellDebounce) {
              data.cellDebounce = 1500;
            }
            return SharedGridActions.getTableNavSettingsSuccess({settings: data});
          }),
          catchError((error: IError) => of(SharedGridActions.getTableNavSettingsFailure({error})))
        )
      )
    )
  );

  // '[Shared Grid][Table Nav] Get Table Nav Settings Success',
  setTableNavSettingsInFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getTableNavSettingsSuccess),
      mergeMap(({settings}) => {
        if (this.hasUpdatedFirebase) {
          return from(this.firebaseService.setUserDocument('ngp_table_nav', settings)).pipe(
            map(() => {
              return {type: '[Shared Grid][Table Nav] Table Nav Settings Set on Firebase'};
            }),
            catchError(error => {
              return of(SharedGridActions.getTableNavSettingsFailure({error}));
            })
          );
        } else {
          this.hasUpdatedFirebase = true;
          return of({type: 'Settings Fetched From Firebase '});
        }
      })
    )
  );

//===============================================================================================================
// Price Banding
//===============================================================================================================
  // '[Shared Grid][Price Banding] Get Price Banding for Stores'
  getPriceBandingForStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedGridActions.getPriceBandingForStores),
      mergeMap(() =>
        from(this.firebaseService.getPriceBandingForStores()).pipe(
          map((priceBandsPerStore: IPriceBandPerStore) => {
            return SharedGridActions.getPriceBandingForStoresSuccess({priceBandsPerStore});
          }),
          catchError((error: IError) => of(SharedGridActions.getPriceBandingForStoresFailure({error})))
        )
      )
    )
  );
  hasUpdatedFirebase = false;

  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) {
  }
}
