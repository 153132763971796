import {Component, OnInit} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {
  selectDepartmentsForSelectedStore
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {IDepartment, ISubDepartment} from '../../../../shared/shared-components/models/stock/departments';

@Component({
  selector: 'app-cell-renderer-departments',
  templateUrl: './cell-renderer-departments.component.html',
  styleUrls: ['./cell-renderer-departments.component.scss'],
})
export class CellRendererDepartmentsComponent implements ICellRendererAngularComp, OnInit {

  value: any;
  params: ICellRendererParams;
  width: string;
  type: string;
  departments: IDepartment[];

  departmentsAndSubDepartmentsForCurrentStore$: Observable<IDepartment[]>;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.type = params.colDef.field;
  }

  ngOnInit(): void {
    this.departmentsAndSubDepartmentsForCurrentStore$ = this.store.select(selectDepartmentsForSelectedStore);
    this.departmentsAndSubDepartmentsForCurrentStore$.subscribe((departments: IDepartment[]): void => {
      this.departments = departments;
    });
  }

  checkSubDepCode(): boolean {
    if (this.params.colDef.field === 'subDep') {
      if (this.params.data.dep !== '0') {
        const department = this.departments.find((dep: IDepartment): boolean => dep.dep === this.params.data.dep);
        const subDepartment = department?.subDeps;
        if (subDepartment) {
          const filtered = subDepartment.filter((subDep: ISubDepartment): boolean => subDep.subDep === this.value);
          return filtered.length > 0;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onUndoButtonClick(): void {
    this.value = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.value = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.data[this.params.colDef.field] = this.params.data.originalValue[this.params.colDef.field].value;
    this.checkSubDepCode();
    GridUtils.removeIsEditingFlagFromNgpItem(this.params.data, this.params.colDef.field, this.store)
    GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  getDepartmentName(depCode: string): string {
    return this.departments.find((dep: IDepartment) => dep.dep === depCode).name;
  }

  getSubDepartmentName(depCode: string): string {
    const department = this.departments.find((dep: IDepartment) => dep.dep === depCode);
    const subDepartments = department.subDeps.find((sub: ISubDepartment) => sub.subDep === this.value);
    return subDepartments.name;
  }

}
