
import { createAction, props } from '@ngrx/store';
import { IStore} from '../../../shared-utilities/models-old/store/IStore';
import {DisabledRules, LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';

//===============================================================================================================
// Get All NGP Report Options
//===============================================================================================================
export const getNGPReportOptions = createAction(
  '[Options][NGP Report] Get NGP Report Options',
);

export const getNGPReportOptionsSuccess = createAction(
  '[Options][NGP Report] Get NGP Report Options Success',
  props<{ rulesDoc: DisabledRules, store: IStore, lineColours: LineColour, navSettings: TableNavSettings, colPreview: {[key:number]: string} }>()
);

export const getNGPReportOptionsFailure = createAction(
  '[Options] Get NGP Report Options Failure',
  props<{ errors: IError[]; store: IStore }>(),
);

//===============================================================================================================
// Set NGP Preview Columns
//===============================================================================================================
export const setNgpReportOptionsPreviewColumns = createAction(
  '[Options][NGP Report] Set Ngp Report Options Preview Columns',
  props<{ columnData: {} }>(),
);

export const setNgpReportOptionsPreviewColumnsSuccess = createAction(
  '[Options][NGP Report] Set Ngp Report Options Preview Columns Success',
  props<{ columnData: {}; store: IStore }>(),
);

export const setNgpReportOptionsPreviewColumnsFailure = createAction(
  '[Options][NGP Report] Set Ngp Report Options Preview Columns Failure',
  props<{ error: IError , store: IStore}>(),
);

//===============================================================================================================
// Set Table Navigation Options
//===============================================================================================================
export const setNGPOptionsTableNavSettings = createAction(
  '[Options][Table Nav] Set Table Nav Settings',
  props<{ settings: TableNavSettings }>()
);

export const setNGPOptionsTableNavSettingsSuccess = createAction(
  '[Options][Table Nav] Set Table Nav Settings Success',
  props<{ settings: TableNavSettings, store: IStore }>()
);

export const setNGPOptionsTableNavSettingsFailure = createAction(
  '[Options][Table Nav] Set Table Nav Settings Failure',
  props<{ error: IError, store: IStore }>()
);


