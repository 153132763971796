import {ApplicationSettingsComponent} from './application-settings/application-settings.component';
import {AutomationSettingsEmailComponent} from './automation-settings-email/automation-settings-email.component';
import {BasicInputComponent} from './basic-input/basic-input.component';
import {BasicMultipleInputComponent} from './basic-multiple-input/basic-multiple-input.component';
import {DataFreshnessIndicatorComponent} from './data-freshness-indicator/data-freshness-indicator.component';
import {DateRangeSelectorComponent} from './date-range-selector/date-range-selector.component';
import {DisableSupplierSettingsComponent} from './disable-supplier-dettings/disable-supplier-settings.component';
import {EditorsConfigComponent} from './editors-config/editors-config.component';
import {FacetListComponent} from './facet-list/facet-list.component';
import {FreshnessPopupComponent} from './freshness-popup/freshness-popup.component';
import {InactivityModalComponent} from './inactivity-modal/inactivity-modal.component';
import {MenuPopupComponent} from './menu-popup/menu-popup.component';
import {ModalHeaderComponent} from './modal-header/modal-header.component';
import {MultipleErrorPopoverComponent} from './multiple-error-popover/multiple-error-popover.component';
import {NavigableModalComponent} from './navigable-modal/navigable-modal.component';
import {NgpReportSettingComponent} from './ngp-report-setting/ngp-report-setting.component';
import {PageHeaderComponent} from './page-header-old/page-header.component';
import {PriceBandingComponent} from './price-banding/price-banding.component';
import {PriceCellEditorComponent} from './price-cell-editor/price-cell-editor.component';
import {PriceThresholdComponent} from './price-threshold/price-threshold.component';
import {ProgressComponent} from './progress/progress.component';
import {SelectPopoverComponent} from './select-popover/select-popover.component';
import {SharedStoreTabsComponent} from './shared-store-tabs/shared-store-tabs.component';
import {SimplePaginationComponent} from './simple-pagination/simple-pagination.component';
import {StockFiltersComponent} from './stock-filters/stock-filters.component';
import {StockSearchComponent} from './stock-search/stock-search.component';
import {StockValChangeFlagsComponent} from './stock-val-change-flags/stock-val-change-flags.component';
import {StoreConfigComponent} from './store-config/store-config.component';
import {StoreInfoComponent} from './store-info/store-info.component';
import {SuccessfulEventFabComponent} from './successful-event-fab/successful-event-fab.component';
import {UnlinkedDepartmentsModalComponent} from './unlinked-departments-modal/unlinked-departments-modal.component';
import {UserProfileConfigComponent} from './user-profile-config/user-profile-config.component';
import {SaveUserSettingsPage} from '../modules-old/save-user-settings/save-user-settings.page';

export const APPLICATION_SETTINGS = ApplicationSettingsComponent;
export const AUTOMATION_SETTINGS_EMAIL = AutomationSettingsEmailComponent;
export const BASIC_INPUT = BasicInputComponent;
export const BASIC_MULTIPLE_INPUT = BasicMultipleInputComponent;
export const DATA_FRESHNESS_INDICATOR = DataFreshnessIndicatorComponent
export const DATE_RANGE_SELECTOR = DateRangeSelectorComponent;
export const DISABLE_SUPPLIER_SETTINGS = DisableSupplierSettingsComponent;
export const EDITORS_CONFIG = EditorsConfigComponent;
export const FACET_LIST = FacetListComponent;
export const FRESHNESS_POPUP = FreshnessPopupComponent;
export const INACTIVITY_MODAL = InactivityModalComponent;
export const MENU_POPUP_COMPONENT = MenuPopupComponent;
export const MODAL_HEADER = ModalHeaderComponent;
export const MULTIPLE_ERROR_POPOVER = MultipleErrorPopoverComponent;
export const NAVIGABLE_MODAL = NavigableModalComponent;
export const NGP_REPORT_SETTINGS = NgpReportSettingComponent;
export const PAGE_HEADER_OLD = PageHeaderComponent;
export const PRICE_BANDING = PriceBandingComponent;
export const PRICE_CELL_EDITOR = PriceCellEditorComponent;
export const PRICE_THRESH_HOLD = PriceThresholdComponent;
export const PROGRESS_COMPONENT = ProgressComponent;
export const SELECT_POPOVER = SelectPopoverComponent;
export const SHARED_STORE_TABS = SharedStoreTabsComponent;
export const SIMPLE_PAGINATION = SimplePaginationComponent;
export const STOCK_FILTERS = StockFiltersComponent;
export const STOCK_SEARCH = StockSearchComponent;
export const STOCK_VAL_CHANGE_FLAGS = StockValChangeFlagsComponent;
export const STORE_CONFIG = StoreConfigComponent;
export const STORE_INFO = StoreInfoComponent;
export const SUCCESSFUL_EVENT_FAB = SuccessfulEventFabComponent;
export const UNLINKED_DEPARTMENTS_MODAL = UnlinkedDepartmentsModalComponent;
export const USER_PROFILE_CONFIG = UserProfileConfigComponent;
export const SAVE_USER_SETTINGS = SaveUserSettingsPage;

export const SHARED_MODULE_COMPONENTS = [
  APPLICATION_SETTINGS,
  AUTOMATION_SETTINGS_EMAIL,
  BASIC_INPUT,
  BASIC_MULTIPLE_INPUT,
  DATA_FRESHNESS_INDICATOR,
  DATE_RANGE_SELECTOR,
  DISABLE_SUPPLIER_SETTINGS,
  EDITORS_CONFIG,
  FACET_LIST,
  FRESHNESS_POPUP,
  INACTIVITY_MODAL,
  MENU_POPUP_COMPONENT,
  MODAL_HEADER,
  MULTIPLE_ERROR_POPOVER,
  NAVIGABLE_MODAL,
  NGP_REPORT_SETTINGS,
  PAGE_HEADER_OLD,
  PRICE_BANDING,
  PRICE_CELL_EDITOR,
  PRICE_THRESH_HOLD,
  PROGRESS_COMPONENT,
  SAVE_USER_SETTINGS,
  SELECT_POPOVER,
  SHARED_STORE_TABS,
  SIMPLE_PAGINATION,
  STOCK_FILTERS,
  STOCK_SEARCH,
  STOCK_VAL_CHANGE_FLAGS,
  STORE_CONFIG,
  STORE_INFO,
  SUCCESSFUL_EVENT_FAB,
  UNLINKED_DEPARTMENTS_MODAL,
  USER_PROFILE_CONFIG
];
