import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';

export function getSelectNGPReportsFiltered(
  allNGPReports: { [storeID: string]: NGPReport[] },
  filtersAndTools: FiltersAndTools,
  store: IStore
): NGPReport[] {
  const storeNGPReports = allNGPReports[store.storeID] || [];
  if (!filtersAndTools) {
    return storeNGPReports || [];
  }
  const ngpReports: NGPReport[] = [...storeNGPReports];
  let copyNGPReports: NGPReport[] = [...storeNGPReports];
  /** Negative Values Only ------------------------------------------------------------------ */
  if (filtersAndTools.isGpDifferenceNegativeOn && !filtersAndTools.isGpDifferencePositiveOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP < 0);
  }
  /** Positive Values Only ------------------------------------------------------------------ */
  if (filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP >= 0);
  }
  /** Show Preview ----------------------------------------------------------------------------- */
  if (filtersAndTools.isGpDifferencePositiveOn && filtersAndTools.isGpDifferenceNegativeOn) {
    if (filtersAndTools.showSubmissionPreview) {
      const copyEditedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
      const copySelectedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isSelected && !row.isEdited);
      copyNGPReports = copyEditedNGPReports.concat(copySelectedNGPReports);
    } else if (filtersAndTools.isEditedItemsOn) {
      copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
    } else {
      copyNGPReports = ngpReports;
    }
  }
  /** No Values ----------------------------------------------------------------------------- */
  if (!filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
    copyNGPReports = [];
  }
  return copyNGPReports;
}

export function selectGetSelectNGPReportsFiltered(
  ngpReports: NGPReport[], headerMenuData: StoreHeaderMenuData
): NGPReport[] {
  let found = '';
  let sortType = '';
  Object.keys(headerMenuData).forEach((store: string): void => {
    Object.keys(headerMenuData[store]).forEach((key: string): void => {
      if (headerMenuData[store][key]?.sortType !== null) {
        found = key;
        sortType = headerMenuData[store][key]?.sortType;
      }
    });

  });
  if (found !== '' && headerMenuData) {
    switch (sortType) {
      case 'sort-asc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => a[found] > b[found] ? 1 : -1);
        break;
      case 'sort-desc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => a[found] < b[found] ? 1 : -1);
        break;
    }
  }
  return ngpReports;
}
