import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {SuperAdminGuardGuard} from './shared-utilities/auth-guards-old/super-admin-guard/super-admin-guard.guard';
import {EditUserAccessGuard} from './shared-utilities/auth-guards-old/edit-user-access-guard/edit-user-access.guard';
import {SignupGuard} from './shared-utilities/auth-guards-old/signup-guard/signup.guard';
import {UserAccessGuard} from './shared-utilities/auth-guards-old/user-access-guard/user-access.guard';
import {StockManagerModule} from './features/stock-manager/stock-manager.module';

/**
 * ManageIT Routes
 *
 * Recommended Order of Routes
 * 1. Root and Redirect Routes
 * 2. Specific Static Routes
 * 3. Parameterized Routes and Guards
 * 4. Wildcard and Catch-All Routes
 *
 * Todo: Look at using newer tech for Authentication - CanLoad is Depreciated
 */
const routes: Routes = [
  // Redirects and High-Level Routes
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }, {
    path: 'login',
    loadChildren: () => import('./features-as-modules/feature-login/login.module').then(m => m.LoginPageModule),
    canLoad: [UserAccessGuard],
  }, {
    path: 'signup',
    loadChildren: () => import('./features-as-modules/feature-sign-up/signup.module').then(m => m.SignupPageModule),
    canLoad: [SignupGuard],
  }, {
    path: 'home',
    loadChildren: () => import('./features-as-modules/feature-community-forum/home.module').then(m => m.HomePageModule),
  },
  // Main Functional Areas
  {
    path: 'dashboard',
    loadChildren: () => import('./features-as-modules/feature-dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canLoad: [UserAccessGuard],
  }, {
    path: 'user-access',
    loadChildren: () => import('./features-as-modules/feature-user-access/user-access.module').then(m => m.UserAccessPageModule),
    canLoad: [EditUserAccessGuard], canActivate: [EditUserAccessGuard], canActivateChild: [EditUserAccessGuard],
  },
  // Admin Specific Routes
  {
    path: 'admin-info',
    loadChildren: () => import('./features-as-modules/feature-admin-info/admin-info.module').then(m => m.AdminInfoPageModule),
    canActivate: [UserAccessGuard], canActivateChild: [UserAccessGuard]
  }, {
    path: 'admin-internal',
    loadChildren: () => import('./features-as-modules/feature-admin-internal/admin-internal.module').then(m => m.AdminInternalModule),
    canLoad: [SuperAdminGuardGuard], canActivate: [SuperAdminGuardGuard], canActivateChild: [SuperAdminGuardGuard],
  },
  // Operational Functional Areas
  {
    path: 'auto-ordering',
    loadChildren: () => import('./features-as-modules/feature-auto-ordering/auto-ordering.module').then(m => m.AutoOrderingPageModule),
    canLoad: [UserAccessGuard],
  }, {
    path: 'barcode-scanner',
    loadChildren: () => import('./features-as-modules/feature-barcode-scanner/barcode-scanner.module')
      .then(m => m.BarcodeScannerPageModule),
    canLoad: [UserAccessGuard],
  },
  // Reports and Related Redirection
  {
    path: 'ngp-report.ts-new',
    redirectTo: 'ngp-report.ts'
  }, {
    path: 'ngp-report.ts',
    loadChildren: () => import('./features-as-modules/feature-ngp-report-ts/ngp-report-new.module').then(m => m.NgpReportNewPageModule),
    canLoad: [UserAccessGuard],
  }, {
    path: 'ngp-report',
    loadChildren: () => import('./features-as-modules/feature-ngp-report/ngp-report.module').then(module => module.NgpReportModule),
    canLoad: [UserAccessGuard]
  },
  // Departmental and Settings
  // {
  // path: 'dss-modal',
  // loadChildren: () => import('./dep-sales-summary/dep-sales-summary.module').then(m => m.DssModalPageModule),
  // canLoad: [UserAccessGuard],
  // },
  // {
  //   path: 'manage-it/dep-sales-summary',
  //   loadChildren: () => import('./dep-sales-summary/dep-sales-summary.module').then(m => m.DepSalesSummaryPageModule),
  //   canLoad: [UserAccessGuard],
  // },
  {
    path: 'save-user-settings',
    loadChildren: () => import('./shared-modules/shared-module/modules-old/save-user-settings/save-user-settings.module')
      .then(m => m.SaveUserSettingsPageModule),
    canLoad: [UserAccessGuard],
  }, {
    path: 'shelf-talkers',
    loadChildren: () => import('./features-as-modules/feature-shelf-talkers/shelf-talkers.module')
      .then(m => m.ShelfTalkersPageModule),
    canLoad: [UserAccessGuard],
  },
  // Testing
  {
    path: 'testing',
    loadChildren: () => import('./features-as-modules/feature-testing-playground/testing.module').then(m => m.TestingPageModule),
    canLoad: [UserAccessGuard],
  },
  // Stock Management
  {
    path: 'stock-match',
    loadChildren: () => import('./features-as-modules/feature-stock-match/stock-match.module').then(m => m.StockMatchPageModule),
    canLoad: [UserAccessGuard],
  },
  {
    path: 'stock',
    loadChildren: () => import('./features-as-modules/feature-stock-updates/stock-updates.module').then(m => m.StockUpdatesPageModule),
    canLoad: [UserAccessGuard],
  },
  {
    path: 'stock-manager',
    loadChildren: () => import('./features/stock-manager/stock-manager.module').then(m => m.StockManagerModule),
    canLoad: [UserAccessGuard],
  },
  {
    path: 'stock-updates',
    loadChildren: () => import('./features-as-modules/feature-stock-updates/stock-updates.module').then(m => m.StockUpdatesPageModule),
    canLoad: [UserAccessGuard],
  },
  // Wildcard route for a 404 page or redirect
  // Todo: Implement a wildcard route for a page not found module
  // {path: '**', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)}
  {
    path: '**',
    redirectTo: 'login'
  }
];

// Todo: Remember to add saving of data to server from NGP report??

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
