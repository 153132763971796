import {IRange} from '../functions-old/ranges';
import {superSafeURLDecode, superSafeURLEncode} from '../utils-old/formatting';
import {AccessFlags} from './utils-old/rule-structure';
import {Timestamp} from '../services-old/firebase.service';

export interface UserObj {
  id: string;
  userName: string;
  pp?: string;
}

export type ValueOf<T> = T[keyof T];

export type Feature = 'shared' | 'operational' | 'observation';

export interface StoreInfo {
  name: string;
  keyValueSettings?: { linkDep: boolean };
}

export interface StoreIQInfo {
  name: string;
  adr1: string;
  adr2: string;
  adr3: string;
  adr4: string;
  province: string;
  tel1: string;
  fax: string;
  email: string;
  reg: string;
  vat: string;
  logo: string;
  tradingAs: string;
  physAdr1: string;
  physAdr2: string;
  physAdr3: string;
  post: number;
  logoOverride?: string;
}

export interface VatRates {
  [vatNum: number]: { desc: string; vatRate: number };
}

export interface SubDep {
  dep: string | string[];
  name: string;
  targetGP: number;
}

export type UserAccess = {
  [af in AccessFlags]?: boolean;
} & {
  storeList?: string[];
  stores: { [storeID: string]: number };
  features: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
};

export interface Colleague {
  name?: string;
  stores: { [storeID: string]: string };
}

export const OUT_GOING_MSG_TYPES =
  ['SERVER_TEST', 'STOCK_UPDATE', 'SERVER_CONFIG', 'AUTO_ORDERS', 'AUTO_ORDERS_U_UPDATE'] as const;
export const INCOMING_MSG_TYPES = ['API-RESPONSE', 'AUTO_ORDER_RESULT', 'SIGN_UP', 'ACCESS_CHANGE'] as const;
export const MESSAGE_TYPES = [...OUT_GOING_MSG_TYPES, ...INCOMING_MSG_TYPES] as const;

export type OutGoingMsgType = typeof OUT_GOING_MSG_TYPES[number];
export type IncomingMsgType = typeof INCOMING_MSG_TYPES[number];

export type MsgType = OutGoingMsgType | IncomingMsgType;

export const msgTypeName = (mt: MsgType): string | undefined => {
  switch (mt) {
    case 'API-RESPONSE':
      return 'Stock Updates';
    case 'AUTO_ORDER_RESULT':
      return 'Auto Orders';
    case 'SIGN_UP':
      return 'Sign Up';
    case 'AUTO_ORDERS_U_UPDATE':
      return 'Unscheduled Auto Orders';
  }
  return undefined;
};

export interface Message {
  // TODO: Decide on '-', '_', or ' ' you stupid tit.
  type: MsgType;
  id?: string;
  payload: {
    body?: string;
    event?: string;
    success?: boolean;
    data?: any;
  };
  sender: string;
  timestamp: Date;
  nb?: boolean;
}

export interface InboxMessage extends Omit<Message, 'type'> {
  type: IncomingMsgType;
}

export interface OutgoingMessage extends Omit<Message, 'type'> {
  type: OutGoingMsgType;
}

export interface ApiLogObj {
  userID: string;
  creationDate: Date;
  scheduledDate: Date;
  type: string;
  data?: any; // New version, give it more details
  payload?: any; // Shitty Legacy, eventually remove
  original?: any;
  status?: string;
  executedDate?: Date;
  errorMsg?: string;
  lastAttempt?: any;
}

export interface StockItemUpdateObj {
  o: { [k: string]: string | number | Date | boolean };
  n: { [k: string]: string | number | Date | boolean };
}

export interface ApiLogObj2 {
  id?: string;
  userID: string;
  creationDate: Date;
  scheduleDate?: Date;
  executedDate?: Date;
  lastAttempt?: Date;
  type: 'STOCK_UPDATE';
  data: { [code: string]: StockItemUpdateObj } | any;
  status?: 'SUBMITTED' | 'COMPLETE' | 'FAILED' | 'REATTEMPTED';
  errors?: any;
}

export interface DepSales {
  name: string;
  salesYTD: number;
  grossProfitYTD: number;
  salesPYTD: number;
  grossProfitPYTD: number;
  salesToday: number;
  grossProfitToday: number;
  salesMTD: number;
  grossProfitMTD: number;
  salesPMTD: number;
  grossProfitPMTD: number;
}

export interface DepSales2 {
  customerCount: number;
  netCost: number;
  netSales: number;
}

export interface DepSalesHistory {
  // byYear: year > month > day > department
  byYear: Map<number, Map<number, Map<number, { [dep: string]: DepSales2 }>>>;
  // byDep department > year > month > day
  byDep: { [dep: string]: Map<number, Map<number, Map<number, DepSales2>>> };
  // totals of all departments'
  // year > month > day
  totals?: Map<number, Map<number, Map<number, DepSales2>>>;
}

export interface LineColour {
  value: string;
  description: string;
}


export interface Supplier {
  account: string;
  name: string;
  adr1: string;
  adr2: string;
  adr3: string;
  adr4: string;
  postC: string;
  country: string;
  dlv1: string;
  dlv2: string;
  dlv3: string;
  dlv4: string;
  dlvPostC: string;
  contact: string;
  terms: number;
  crLimit: number;
  tel1: string;
  tel2: string;
  cell: string;
  email: string;
  taxNo: string;
  current: number;
  days30: number;
  days60: number;
  days90: number;
  days120: number;
  days150: number;
  days180: number;
  total: number;
  age: number;
  onHold: boolean;
  onHoldCode?: number;
  linkAcc: string;
  cCB: number;
  minOrder?: number;
  addEmails?: string[];
  emailNames?: { [email: string]: string };
}

export interface AOScheduleElement {
  rank?: number;
  userID?: string;
  sDays: number;
  growth: number;
  moHistory: number;
  period: number;
  // subtractPO: boolean;
  // excludePO: boolean;
  // onlyPO: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AOSchedulePeriodName = () => ({1: 'Seasonal', 2: 'Max', 3: 'Last X Months'});

export interface AOSettings {
  iqCode: string;
  iqTill: number;
  repName: string;
  repCell: string;
  ccEmail: string;
  cc: boolean;
  executionTime: string;
  noLogo: boolean;
  emailBody: {
    subject: string;
    body: string;
  };
  pdfClause?: {
    text?: string;
  };
  mailer: EmailSettings;
  // iqLogin: {
  //   user: number;
  //   till: number;
  //   pwd?: string;
  //   pwdLength?: number;
  // };
}

export interface EmailSettings {
  email: string;
  user?: string;
  pwd?: string;
  pwdLength?: number;
  smtp: { port: number; server: string; sslOnly?: boolean };
  imap?: { port: number; server: string; sentBox?: string };
}

export interface GenAOData {
  outOfStock?: number;
  maxSold?: number;
  netSold?: number;
  qtyDiff?: number;
  maxReturned?: number;
  negDays?: number;
  zeroDays?: number;
  new?: true | 'PERIOD-2' | 'ADDED';
}

// export interface AOReadyObj {
//   items: { [code: string]: GenOAData };
//   scheduled?: boolean;
//   executed: Date;
// }


// -------------------------------------------------- STOCK ITEMS --------------------------------------------------- //

export interface DisabledRule {
  descPrefix?: string;
  descSuffix?: string;
  onHoldCode?: number;
  lineColour?: number;
}


export type DisabledRuleSupp = Omit<DisabledRule, 'lineColour'>;

export const disableRuleInvolvedKeys = (dr: DisabledRules | DisabledRule) => {
  let keys: string[] = [];

  if (dr.hasOwnProperty('onHandZero') || dr.hasOwnProperty('onHandNotZero')) {
    dr = dr as DisabledRules;
    if (dr.onHandZero) {
      keys = disableRuleInvolvedKeys(dr.onHandZero);
    }
    if (dr.onHandNotZero) {
      keys = keys.concat(disableRuleInvolvedKeys(dr.onHandNotZero));
    }
    keys = [...new Set(keys)];
  } else {
    dr = dr as DisabledRule;

    if (dr.hasOwnProperty('descPrefix') && dr.descPrefix !== null) {
      keys.push('desc');
    } else if (dr.hasOwnProperty('descSuffix') && dr.descSuffix !== null) {
      keys.push('desc');
    }
    if (dr.hasOwnProperty('onHoldCode') && dr.onHoldCode !== null) {
      keys.push('onHoldCode');
    }
    if (dr.hasOwnProperty('lineColour') && dr.lineColour !== null) {
      keys.push('lineColourCode');
    }
  }
  return keys;
};

export interface DisabledRules {
  onHandZero?: DisabledRule;
  onHandNotZero?: DisabledRule;
}

export interface DisabledRulesSupp extends DisabledRules {
  onHandZero?: DisabledRuleSupp;
  onHandNotZero?: DisabledRuleSupp;
}

export interface PriceBand {
  range?: IRange;
  departs?: string[];
  subDeparts?: string[];
  // randSnaps?: { range: Range; digits: number; v: number }[];
  randSnap?: { digits: number; snaps: { range: IRange; v: number }[] };
  centSnaps?: { range: IRange; v: number }[];
}

// ---------------------------------------------------------------------------------------------------------------------

// TODO: Move all these check configs somewhere else

/*
  NB: Note currently config for sellPriIncl1 (a number) is the only supported check.
 */
export interface StockValChangeCheckConfig {
  sellPriIncl1?: SVCCNumber;
  desc?: SVCCNumber;
}


/**
 * @interface StockValChangeCheckResult
 * The result object of one flag being raised in Stock Value Change check flagging.
 *
 * @member {keyof SVCCNumber} type The key of a SCC<type: Number | String | Date > flag that was raised.
 * @member {boolean | number} thresh The value of the SCC flag key (type).
 * @member {string | number | Date} new The new value of the Stock Value (what it was changed to).
 * @member {string | number | Date} old The old value of the Stock Value (what it was changed from).
 * @member {string} desc A error / warning description string for the flag (human-readable).
 */
export interface StockValChangeCheckResult {
  /* @member {keyof SVCCNumber} type The key of a SCC<type: Number | String | Date > flag that was raised. */
  type: keyof SVCCNumber;
  /* @member {boolean | number} thresh The value of the SCC flag key (type). */
  thresh: boolean | number;
  /* @member {string | number | Date} new The new value of the Stock Value (what it was changed to). */
  new: string | number | Date;
  /* @member {string | number | Date} old The old value of the Stock Value (what it was changed from). */
  old?: string | number | Date;
  /* @member {string} desc A error / warning description string for the flag (human-readable). */
  desc?: string;
}

/**
 * The results of Stock Value Change flagging on a StockItem. Each changed key (k) of the StockItem may have a list of
 * flag results (StockValChangeCheckResult) as more than one flag may apply.
 */
export type StockValuesChangeCheckResult = { [k in keyof StockValChangeCheckConfig]?: StockValChangeCheckResult[] };

/**
 * @interface SVCCNumber
 * A Stock Value Change Check flagging config for values which are numbers. These configs are processed along with a
 * changed value, to determine if the change should be flagged or not.
 *
 * @member {number} pct ? Positive percentage that the value is allowed to change by.
 * @member {number} negPct ? Negative percentage that the value is allowed to change by.
 * @member {number} noZero ? If `true`, new values cannot be zero.
 * @member {number} noNeg ? If `true`, new values cannot be negative.
 */
export interface SVCCNumber {
  /* @member {number} pct ? Positive percentage that the value is allowed to change by. */
  pct?: number;
  /* @member {number} negPct ? Negative percentage that the value is allowed to change by. */
  negPct?: number;
  /* @member {number} noZero ? If `true`, new values cannot be zero. */
  noZero?: boolean;
  /* @member {number} noNeg ? If `true`, new values cannot be negative. */
  noNeg?: boolean;
  test?: string;
}

/**
 * Get a human-readable string describing the flag triggered by Stock Value Change Checks.
 *
 * @member {keyof SVCCNumber} t The flag that has been raised.
 * @member {number | string} threshold The value of the threshold or check.
 * @member {keyof StockValChangeCheckConfig} key The key (filed of a StockItem) on which the flag was raised.
 */
export const stockValCCRType2Str = (
  t: keyof SVCCNumber, threshold: number | boolean, key?: keyof StockValChangeCheckConfig
) => {
  const valStr = key && keyToTitle[key] ? keyToTitle[key] : null;

  switch (t) {
    case 'pct':
      return `${valStr}s have increased by more than ${threshold as number * 100}%`;
    case 'negPct':
      // Assuming a negative percentage change
      return `${valStr}s have decreased by more than ${threshold as number * 100}%`;
    // Add other cases as needed
    case 'noZero':
      // are zero and zero is not allowed
      return `${valStr} cannot be zero`;
    case 'noNeg':
      // are negative and negative is not allowed
      return `${valStr} cannot be negative`;
    case 'test':
      // are negative and negative is not allowed
      return ` ${valStr} test Change`;
    default:
      return null;
  }
};

/**
 * A more indepth version of stockValCCRType2Str, that should probably replace stockValCCRType2Str, and that only
 * requires one parameter.
 *
 * @member {stockValCCR2Str} svccr
 */
export const stockValCCR2Str = (svccr: StockValChangeCheckResult): string => {
  const value = svccr.new as number;
  const original = svccr.old as number;

  const numberChange = (): number => (value - original) / original;

  switch (svccr.type) {
    case 'pct':
      return `Value ${value} is ${(numberChange() * 100).toFixed(2)} larger then current value of ` +
        `${original}. Threshold is ${((svccr.thresh as number) * 100).toFixed(2)}`;
    case 'negPct':
      return `Value ${value} is ${Math.abs(numberChange() * 100).toFixed(2)} smaller then current value of ` +
        `${original}. Threshold is ${((svccr.thresh as number) * 100).toFixed(2)}`;
    case 'noZero':
      return 'Value should not be zero.';
    case 'noNeg':
      return 'Value should not be negative';
    case 'test':
      return 'the testing test test should test the testing test';
    default:
      return null;
  }
};

// TODO: MOVE THIS!! Should not be here
/**
 * Takes a value v and returns the true if it is not undefined or null. Else it returns false
 *
 * @param v - value to be tested.
 */
export const hasValue = (v: any) => v !== null && v !== true ? v : false;

// ---------------------------------------------------------------------------------------------------------------------

export interface FilterGroup {
  type: 'AND' | 'OR' | 'NOT';
  filters: (Filter | FilterGroup)[];
  filtersType?: 'string' | 'number';
}

export interface TableNavSettings {
  enterEnd: 'restart-page' | 'restart-column' | 'next-column' | 'next-page' | 'next-page-same-column' | 'restart_page' |
    'restart_column' | 'next_column' | 'next_page' | 'next_page_same_column';
  tabEnd: 'restart-page' | 'restart-column' | 'next-column' | 'next-page' | 'next-page-same-column' | 'restart_page' |
    'restart_column' | 'next_column' | 'next_page' | 'next_page_same_column';
  cellDebounce?: number;
  // shortcuts:
}

export interface Filter {
  itemIntKey: string;
  condition: '=' | '<' | '>' | '<=' | '>=' | '!=' | 'in' | 'TO';
  type?: 'search' | 'query';
  value: string | number | Date | string[] | number[];
}

export const filterConditions = (type: 'search' | 'query' = 'search') => {
  if (type === 'search') {
    return ['=', '<', '>', '<=', '>=', '!=', 'TO'];
  } else if (type === 'query') {
    return ['=', '<', '>', '<=', '>=', '!=', 'in'];
  }
  return null;
};

export interface StockItem {
  stockID?: string; // Actual document id. 99.9% of stockID === code
  code: string;
  desc: string;
  suppCode: string;
  dep: string;
  subDep: string;
  category: number;
  lastMoved: Date;
  lastSold: Date;
  lastPurchase: Date;
  prvSellingPri: number;
  prvSellingPriDate: Date;
  sellPriExcl1: number;
  sellPriIncl1: number;
  onHand: number;
  avCost: number;
  latestCost: number;
  gp1: number;
  recommendedGP: number;
  suppUsedLast5: string;
  suppUsedLast4: string;
  suppUsedLast3: string;
  suppUsedLast2: string;
  suppUsedLast1: string;
  suppUsedLastPrice5: string;
  suppUsedLastPrice4: string;
  suppUsedLastPrice3: string;
  suppUsedLastPrice2: string;
  suppUsedLastPrice1: string;
  suppUsedLastDate5: Date;
  suppUsedLastDate4: Date;
  suppUsedLastDate3: Date;
  suppUsedLastDate2: Date;
  suppUsedLastDate1: Date;
  salesOrder: number;
  purchaseOrder: number;
  dlvColl: number;
  vatR: number;
  reportItemCode: string;
  reportItemFactor: number;
  onHoldCode: number;
  regularSuppCode: string;
  created: Date;
  user: number;
  discExempt: boolean;
  maxDisc: number;
  noDecimal: boolean;
  lineColourCode: number;
  lastStockTake: Date;
  web: boolean;
  sellUnderCost: boolean;
  sellIntoNegative: boolean;
  packSize: number;
  unitsYear: number;
  barcode: string;
  binL: string;
  ordLvl: number;
  genCode: string;
  excludeSellingValue: number;
  _tags?: any;
  newlyAdded?: boolean;
  suppName?: string;
  storeID?: string;
  store?: string;
}

export const stockCodeEncode = superSafeURLEncode;
export const stockCodeDecode = superSafeURLDecode;

export interface StockItemLocallyExtended extends StockItem {
  storeID?: string;
  nominalGP?: number; // This is currently for gp editing. As gp1 is on average cost, not nominal. So no good
}

export interface StockItemUnsafeAdditions extends StockItem {
  link?: string;
  _tags?: string[];
}

export type RawStockItem = {
  [k in ValueOf<typeof sItemKeyToInt>]: string | number | boolean | Timestamp;
};

// [k in keyof typeof ST]: (typeof ST)[k]['type']

export const sItemKeyToInt: { [k in keyof StockItemUnsafeAdditions] } = {
  stockID: 'objectID',
  code: '0',
  desc: '1',
  suppCode: '2',
  dep: '3',
  subDep: '4',
  category: '5',
  lastMoved: '6',
  lastSold: '7',
  lastPurchase: '8',
  prvSellingPri: '9',
  prvSellingPriDate: '10',
  sellPriExcl1: '11',
  sellPriIncl1: '12',
  onHand: '13',
  avCost: '14',
  latestCost: '15',
  gp1: '16',
  recommendedGP: '17',
  suppUsedLast5: '18',
  suppUsedLast4: '19',
  suppUsedLast3: '20',
  suppUsedLast2: '21',
  suppUsedLast1: '22',
  suppUsedLastPrice5: '23',
  suppUsedLastPrice4: '24',
  suppUsedLastPrice3: '25',
  suppUsedLastPrice2: '26',
  suppUsedLastPrice1: '27',
  suppUsedLastDate5: '28',
  suppUsedLastDate4: '29',
  suppUsedLastDate3: '30',
  suppUsedLastDate2: '31',
  suppUsedLastDate1: '32',
  salesOrder: '33',
  purchaseOrder: '34',
  dlvColl: '35',
  vatR: '36',
  reportItemCode: '37',
  reportItemFactor: '38',
  onHoldCode: '39',
  regularSuppCode: '40',
  created: '41',
  user: '42',
  discExempt: '43',
  maxDisc: '44',
  noDecimal: '45',
  lineColourCode: '46',
  lastStockTake: '47',
  web: '48',
  sellUnderCost: '49',
  sellIntoNegative: '50',
  packSize: '51',
  barcode: '52',
  binL: '53',
  ordLvl: '54',
  genCode: '55',
  excludeSellingValue: '56',
  unitsYear: 'units',
  link: 'link',
  _tags: '_tags',
} as const;

// export type StockItemKey = string & ('stockID' | 'code' | 'desc' | 'suppCode' |
//   'dep' | 'subDep' | 'category' | 'lastMoved' | 'lastSold' | 'lastPurchase' |
//   'prvSellingPri' | 'prvSellingPriDate' | 'sellPriExcl1' | 'sellPriIncl1' |
//   'onHand' | 'avCost' | 'latestCost' | 'gp1' | 'recommendedGP' |
//   'suppUsedLast5' | 'suppUsedLast4' | 'suppUsedLast3' | 'suppUsedLast2' |
//   'suppUsedLast1' | 'suppUsedLastPrice5' | 'suppUsedLastPrice4' |
//   'suppUsedLastPrice3' | 'suppUsedLastPrice2' | 'suppUsedLastPrice1' |
//   'suppUsedLastDate5' | 'suppUsedLastDate4' | 'suppUsedLastDate3' |
//   'suppUsedLastDate2' | 'suppUsedLastDate1' | 'salesOrder' | 'purchaseOrder'
//   | 'dlvColl' | 'vatR' | 'reportItemCode' | 'reportItemFactor' |
//   'onHoldCode' | 'regularSuppCode' | 'created' | 'user' | 'discExempt'
//   | 'maxDisc' | 'noDecimal' | 'lineColourCode' | 'lastStockTake' | 'web'
//   | 'sellUnderCost' | 'sellIntoNegative' | 'packSize' | 'unitsYear' |
//   'barcode' | 'binL' | 'ordLvl');

export const sItemIntToKey: { [k in ValueOf<typeof sItemKeyToInt>]: keyof StockItem } = {};
for (const i of Object.keys(sItemKeyToInt)) {
  sItemIntToKey[sItemKeyToInt[i]] = i as keyof StockItem;
}

/**
 *
 * @param rawItem
 * @param castKeys
 */
export const castStockItem = (rawItem: RawStockItem, castKeys: 'ALL' | (keyof StockItem)[] = 'ALL'): StockItem => {
  const item = {};
  let keys: (keyof RawStockItem)[];

  if (castKeys === 'ALL') {
    keys = Object.keys(rawItem) as (keyof RawStockItem)[];
  } else {
    keys = castKeys.map((k) => sItemKeyToInt[k]);
  }
  keys.forEach((key: string) => {

    if (keyToType[sItemIntToKey[key]] === 'date' && rawItem[key]) {
      item[sItemIntToKey[key]] = (rawItem[key] as Timestamp).toDate();
    } else {
      item[sItemIntToKey[key]] = rawItem[key];
    }
  });
  return item as StockItem;
};

export const castFireStockItem = (item: StockItem): any => {
  const rawItem = {};
  Object.keys(item).forEach((key) => {
    rawItem[sItemKeyToInt[key]] = item[key];
  });
  return rawItem;
};

export const keyToTitle = {
  code: 'Code',
  regularSuppCode: 'Supplier',
  suppCode: 'Supplier Stock Code',
  desc: 'Description',
  dep: 'Department',
  subDep: 'Sub Department',
  sellPriIncl1: 'Price',
  sellPriExcl1: 'Price (excl)',
  gp1: 'GP',
  nominalGP: 'Nominal GP',
  recommendedGP: 'Recommended GP',
  lastSold: 'Date Last Sold',
  lastPurchase: 'Date Last Purchased',
  onHand: 'On Hand',
  latestCost: 'Latest Cost',
  lineColourCode: 'Line Colour',
  unitsYear: 'Units 12 Months',
  packSize: 'Pack Size',
  onHoldCode: 'On Hold Code',
  suppUsedLastDate2: 'Prev GRV Date',
  suppUsedLastDate1: 'GRV Date',
  ordLvl: 'Order Level',
  prvSellingPri: 'Prev Sell Price',
  prvSellingPriDate: 'Prev Sell Price Date',
  purchaseOrder: 'Purchase Orders',
  binL: 'Bin Location',
  barcode: 'Barcode',
  dlvColl: 'DelCol Outstanding',
  genCode: 'General Code',
  excludeSellingValue: 'Exclude Sell Value',
};

export const keyToType: { [k in keyof StockItem]?: 'string' | 'number' | 'date' } = {
  regularSuppCode: 'string',
  suppCode: 'string',
  desc: 'string',
  dep: 'string',
  subDep: 'string',
  sellPriIncl1: 'number',
  sellPriExcl1: 'number',
  gp1: 'number',
  recommendedGP: 'number',
  lastSold: 'date',
  lastPurchase: 'date',
  onHand: 'number',
  latestCost: 'number',
  lineColourCode: 'number',
  unitsYear: 'number',
  packSize: 'number',
  onHoldCode: 'number',
  suppUsedLastDate2: 'date',
  suppUsedLastDate1: 'date',
  ordLvl: 'number',
  prvSellingPri: 'number',
  prvSellingPriDate: 'date',
  purchaseOrder: 'number',
  binL: 'string',
};


// ------------------------------------------------- ON HOLD TYPES -------------------------------------------------- //

export const onHoldTypes = {
  1: 'Invoices and/or Recurring Charges',
  2: 'Credit Notes',
  4: 'Goods Receiving',
  8: 'Returns',
  16: 'Purchase Orders',
  32: 'Sales Orders',
  64: 'Quotes',
  128: 'Job Cards',
  256: 'Point of Sale',
  512: 'Stock Transfers',
  1024: 'Stock Transfer Requests',
  2048: 'Bill of Quantities',
  4194304: 'Agent Transactions',
  134217728: 'Laybyes',
};

export const supplierOnHoldTypes = {
  4: 'GRV',
  8: 'Returns',
  16: 'Purchase Orders',
  4194304: 'Agent Transact',
  8388608: 'Journals',
  33554432: 'Request for quote',
};

// export const onHoldCombine = (codes: number[]) => {
//
// }

export const onHoldDecode = (onHoldCode: number, codes: boolean = false, supplier?: boolean) => {
  if (onHoldCode && onHoldCode >= 0) {
    const binary = onHoldCode.toString(2);
    let types: string[] = [];
    const lookUp = supplier ? supplierOnHoldTypes : onHoldTypes;

    for (let i = 0; i < binary.length; i++) {
      if (binary[binary.length - 1 - i] === '1') {
        const int = 2 ** i;

        if (lookUp.hasOwnProperty(int)) {
          if (!codes) {
            types = types.concat(lookUp[int]);
          } else {
            types = types.concat('' + int);
          }
        } else {
          // TODO: Should I throw an error instead?
          return null;
        }
      }
    }
    return types;
  } else {
    return [];
  }
};

export const onHoldEncode = (types: string[]) => {
  let code = 0;

  if (typeof types === 'string') {
    types = [types];
  }

  for (const type of types) {
    for (const num of Object.keys(onHoldTypes)) {
      if (onHoldTypes[num] === type) {
        code += +num;
        break;
      }
    }
  }
  return code > 0 ? code : undefined;
};

export const bitEncode = (num: number, length: number = 16): (1 | 0)[] => {
  const bits: number[] = [];

  for (let i = length - 1; i >= 0; i--) {
    bits.push(Math.floor((num / Math.pow(2, i)) % 2));
  }
  return bits as (1 | 0)[];
};

export const bitDecode = (bits: boolean[]) => {
  let num = 0;
  bits.forEach((value, index) => {
    num += (value ? Math.pow(2, bits.length - index - 1) : 0);
  });
  return num;
};

/* -------------------------------------------------- TESTING SHIZ -------------------------------------------------- */

export interface FormPost {
  title: string;
  subHeader?: string;
  body: string;
  tags?: string[];
  import?: boolean;
  displayName?: string;
  poll?: FormPostPoll;
  ts: Date;
  imgs?: ImageObj[];
  id?: string; // Optional as is auto assigned on subscription
}

export interface ImageObj {
  name: string;
  thumb: string;
  desc?: string;
  url?: string;
  thumbUrl?: string;
}

export interface FormPostPoll {
  text?: string;
  multi?: boolean;
  options: {
    text: string[];
    value: (string | number)[];
  };
  votes?: { [value: string | number]: number };
  expire?: Date;
  // front end use only
  disabled?: boolean;
  formPostID?: string;
}

export type LinkedDepartmentsLinkType = 'dep' | 'sub';

export interface LinkedDepartments {
  [index: number]: {
    dep: { [depID: string]: { [index: number]: string[] } };
    sub: { [subDepID: string]: { [index: number]: string[] } };
  };

  idxLookUp: { [storeID: string]: number };
  sIDLookUp: { [index: number]: string };
}
