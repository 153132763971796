import {ValidationErrors} from '@angular/forms';

export interface Report {
  stockID?:          string;
  desc:              string;
  suppCode:          any;
  dep:               string;
  regularSuppCode:   string;
  sellPriIncl1:      number;
  gp1:               number;
  suppUsedLastDate1: Date;
  suppUsedLastDate2: Date;
  lineColourCode:    number;
  packSize:          number;
  sellPriExcl1:      number;
  vatR:              number;
  latestCost:        number;
  daysDiff:          number;
  diffGP:            number;
  nominalGP:         number;
  prevGP:            number;
  prevCostPrice:     number;
  unitsYear:         number;
  newlyAdded?:       boolean;
  createdDate?:      Date;
  created?:          boolean;
  cratedHasSupp1?:   boolean; // Todo: Fix the spelling mistake
  manuallyAdded?:    Date;
  costMiss?:         boolean; // latest cost !== last grv price
  lineColour?:       string;
}

/**
 * code:              string;
 *
 * desc:              string;
 *
 * suppCode:          string;
 *
 * dep:               string;
 *
 * regularSuppCode:   string;
 *
 * sellPriIncl1:      string;
 *
 * gp1:               string;
 *
 * suppUsedLastDate2: string;
 *
 * suppUsedLastDate1: string;
 *
 * lineColourCode:    string;
 *
 * packSize:          string;
 *
 * sellPriExcl1:      string;
 *
 * vatR:              string;
 *
 * latestCost:        string;
 *
 * daysDiff:          string;
 *
 * diffGP:            string;
 *
 * nominalGP:         string;
 *
 * prevCostPrice:     string;
 *
 * prevGP:            string;
 *
 * unitsYear:         string;
 *
 */
export interface DisplayReport {
  code:              string;
  desc:              string;
  suppCode:          string;
  dep:               string;
  subDep:            string;
  regularSuppCode:   string;
  sellPriIncl1:      string;
  gp1:               string;
  suppUsedLastDate2: string;
  suppUsedLastDate1: string;
  lineColourCode:    string;
  packSize:          string;
  sellPriExcl1:      string;
  vatR:              string;
  latestCost:        string;
  daysDiff:          string;
  diffGP:            string;
  nominalGP:         string;
  prevCostPrice:     string;
  prevGP:            string;
  unitsYear:         string;
}

export const REPORT_KEYS = (): (keyof Report)[] => [
  'desc', 'suppCode', 'dep', 'regularSuppCode', 'sellPriIncl1', 'gp1', 'suppUsedLastDate1', 'suppUsedLastDate2',
  'lineColourCode', 'packSize', 'sellPriExcl1', 'vatR', 'latestCost', 'daysDiff', 'diffGP', 'nominalGP', 'prevGP',
  'prevCostPrice', 'unitsYear'
];

export const REPORT_DISPLAYABLE_KEYS: (keyof DisplayReport)[] = [
  'desc',  'suppCode',  'dep', 'subDep',  'regularSuppCode',  'sellPriIncl1',  'gp1',  'suppUsedLastDate2',  'suppUsedLastDate1',
  'lineColourCode',  'packSize',  'sellPriExcl1',  'vatR',  'latestCost',  'daysDiff',  'diffGP',  'nominalGP',
  'prevCostPrice',  'prevGP', 'unitsYear'
];

export const DEFAULT_DISPLAY = (): (keyof DisplayReport)[] =>
  ['desc', 'packSize', 'daysDiff', 'unitsYear', 'sellPriIncl1'];

// TODO: Could I get this working??
// const CONST_EDITABLE = ['desc', 'packSize', 'sellPriIncl1', 'gp1'] as const;
// export type NgpEditableKey = ValueOf<typeof CONST_EDITABLE>;
// export const EDITABLE = (): typeof  CONST_EDITABLE => CONST_EDITABLE;

export const EDITABLE = (): (keyof DisplayReport)[] => ['desc', 'packSize', 'sellPriIncl1', 'nominalGP'];

export interface NgpFilter {
  toggles: {
    negative?: boolean; positive: boolean;
  };
  edited?: boolean;
  dep?: { dep?: string[]; subDep?: string[] };
  suppliers?: string[];
  // createdDate?: { start?: Date; end?: Date };
  suppUsedLastDate1?: { start?: Date; end?: Date };
}

export interface NgpReportSettings {
  requestExpDays?: number;
  itemExpDays: number;
}

export const DEFAULT_NG_REP_SETTINGS = {itemExpDays: 30} as const;

export const checkNgpReportSettings = (nrs: NgpReportSettings, key?: keyof NgpReportSettings):
  ValidationErrors | null => {
    const errors: ValidationErrors = {};

    if (!key || ['itemExpDays', 'requestExpDays'].includes(key)) {
      if (!nrs.itemExpDays || nrs.itemExpDays < 7 || nrs.itemExpDays > 60) {
        errors.itemExpDaysInvalid = 'Value is required and must be between 7 and 60';
      }
    }

    if (!key || key === 'requestExpDays') {
      if (nrs.hasOwnProperty('requestExpDays') && nrs.requestExpDays !== null && nrs.requestExpDays !== undefined) {
        if (nrs.requestExpDays < 1 || nrs.requestExpDays > 30) {
          errors.requestExpDaysInvalid = 'Value must be between 1 and 30';
        }
        if (nrs.requestExpDays >= nrs.itemExpDays) {
          errors.requestExpDaysPassItemExpDays = 'Value must be smaller then item expiration days';
        }
      }
    }
    return Object.keys(errors) ? errors : null;
  };
