import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Store} from '@ngrx/store';
import {getImageURL} from '../../store/core.actions';
import {Observable} from 'rxjs';
import {selectImageURL} from '../../store/core.selectors';

@Component({
  selector: 'app-freshness-explanation',
  templateUrl: './freshness-explanation.component.html',
  styleUrls: ['./freshness-explanation.component.scss'],
})
export class FreshnessExplanationComponent implements OnInit {
  isImageLoading = true;
  imageURL$: Observable<string>;

  constructor(
    private modalController: ModalController,
    private readonly store: Store
  ) {
  }

  ngOnInit(): void {
    const image = 'mgt_cloud_flow_chart.svg';
    this.store.dispatch(getImageURL({path: `/site_media/help/data_freshness/${image}`, image}));
    this.imageURL$ = this.store.select(selectImageURL(image));
  }

  onImageLoad(): void {
    this.isImageLoading = false;
  }

  onClose(): void {
    void this.modalController.dismiss();
  }

}
