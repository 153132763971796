import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {
  Filter,
  filterConditions,
  FilterGroup,
  keyToTitle,
  keyToType,
  sItemKeyToInt
} from '../../../../shared-utilities/models-old/datastructures';
import {SaveUserSettingsPage} from '../../modules-old/save-user-settings/save-user-settings.page';

@Component({
  selector: 'app-stock-filters',
  templateUrl: './stock-filters.component.html',
  styleUrls: ['./stock-filters.component.scss'],
})
export class StockFiltersComponent implements OnInit {

  @Input() type: 'search' | 'query' = 'search';
  @Input() filters: FilterGroup;
  @Input() stockPageStyle?: boolean = true;
  @Input() onApply: (filterString: string, filters: FilterGroup) => void;

  @Input() lock = false;

  newFilter: { type: 'tag' | 'values'; filter?: Filter; valueValid?: boolean };
  editing: Filter;
  selectedFilterGroup: FilterGroup;

  dragged: FilterGroup;

  filterable: string[];

  changeMade = false;

  readonly k2t = keyToTitle;
  readonly k2i = sItemKeyToInt;
  readonly key2Type = keyToType;

  constructor(
    private alertControl: AlertController,
    private modalController: ModalController,
  ) {
    const filterable = Object.keys(keyToTitle).sort((a, b) => keyToTitle[a] < keyToTitle[b] ? -1 : 1);
    this.filterable = filterable.filter(v => !['code', 'desc', 'dep', 'supDep', 'regularSuppCode'].includes(v));
  }

  get newTags(): string[] {
    if (this.newFilter.type === 'tag') {
      return this.newFilter.filter.value as string[];
    }
    return null;
  }

  static stringifyFilters(filters: Filter | FilterGroup, type: 'search' | 'query' = 'search'): string {
    const recursiveStep = (node: Filter | FilterGroup, filtersType: 'string' | 'number', first: boolean = false): string => {
      if (node.hasOwnProperty('filters')) {
        node = node as FilterGroup;
        filtersType = node.filtersType;

        if (type === 'search') {
          let s = '';

          for (const filter of node.filters) {
            s += `${s !== '' ? ` ${node.type} ` : ''}${recursiveStep(filter, filtersType)}`;
          }

          if (!first) {
            return `(${s})`;
          }
          return s;
        } else {
          return null;
        }
      } else {
        node = node as Filter;

        if (type === 'search') {
          switch (node.condition) {
            case 'in':
              if (node.itemIntKey === '_tags') {
                return '(' + (node.value as string[]).reduce((acc, cv) => `${acc} OR ${cv}`) + ')';
              } else {
                throw Error('The condition "in" can only be used on the _tags field in algolia search filters.');
              }
            case 'TO':
              const v1 = node.value[0] instanceof Date ? node.value[0].getTime() : node.value[0];
              const v2 = node.value[1] instanceof Date ? node.value[1].getTime() : node.value[1];
              return `${sItemKeyToInt[node.itemIntKey]}: ${v1} TO ${v2}`;
            case '=':
              console.log(node, filtersType);
              if (filtersType === 'string') {
                return sItemKeyToInt[node.itemIntKey] + ':'+
                  (node.value instanceof Date ? node.value.getTime() : node.value);
              }
            // eslint-disable-next-line no-fallthrough
            default:
              const v = node.value instanceof Date ? node.value.getTime() : node.value;
              return `${sItemKeyToInt[node.itemIntKey]} ${node.condition} ${v}`;
          }
        } else {
          return null;
        }
      }
    };

    return recursiveStep(filters, null, true);
  }

  ngOnInit() {
    console.log(this.filters);
  }

  async addSearchFilter(type: 'tag' | 'values') {

    if (this.editing) { return; }

    if (this.newFilter && this.newFilter.type !== type && this.newFilter.filter) {
      const ac = await this.alertControl.create({
        header: 'Change Filter Type?', subHeader: 'The current unsaved filter will be discarded.',
        message: 'Are you sure you want to change filter types?', cssClass: 'custom-alert',
        buttons: ['Cancel', {text: 'Yes', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role !== 'y') {
        return;
      }
    }
    this.newFilter = {type};

    if (type === 'values') {
      this.newFilter.filter = {} as Filter;
    }
  }

  async cancelNew() {
    if (this.newFilter && this.newFilter.filter) {
      const ac = await this.alertControl.create({
        header: 'Discard New Filter?', subHeader: 'Are you sure you want to discard this filter?',
        cssClass: 'custom-alert', buttons: ['Cancel', {text: 'Yes', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role !== 'y') {
        return;
      }
    }
    this.newFilter = null;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  addNewFilter() {
    this.selectedFilterGroup = this.selectedFilterGroup ? this.selectedFilterGroup : this.filters;

    if (this.newFilter && this.newFilter.filter) {

      if (!this.isCompatibleFilterTypes()) {
        return;
      }
      console.log(this.newFilter.filter);

      this.selectedFilterGroup.filters.push(this.newFilter.filter);
      this.setFGFilterType(this.selectedFilterGroup);
      this.newFilter = null;
      this.changeMade = true;
    }
  }

  finishEditing() {

    if (!this.isCompatibleFilterTypes()) {
      return;
    }
    this.editing = null;
    this.newFilter = null;
    this.changeMade = true;
  }

  addTags(event, type: 'typing' | 'backspace' | 'lose-focus' = 'typing') {
    console.log('HIT');
    const target = event.target as HTMLIonInputElement;

    if (['typing', 'lose-focus'].includes(type)) {
      const newTags = (target.value as string).split(',').map(a => a.trim()).filter(a => a.length > 0);

      if (type === 'lose-focus') {
        if ( ! this.newFilter.filter) {
          this.newFilter.filter = {condition: 'in', value: [], itemIntKey: '_tags'};
        }
        this.newFilter.filter.value = (this.newFilter.filter.value as string[]).concat(newTags);
        target.value = '';
      } else if (type === 'typing' && newTags.length > 1) {
        if ( ! this.newFilter.filter) {
          this.newFilter.filter = {condition: 'in', value: [], itemIntKey: '_tags'};
        }
        this.newFilter.filter.value = (this.newFilter.filter.value as string[])
          .concat(newTags.slice(0, newTags.length - 1));
        target.value = newTags[newTags.length - 1];
      }
    } else if (type === 'backspace') {
      if (target.value === '' && this.newFilter.filter.value) {
        const tags = this.newFilter.filter.value as string[];

        if (tags.length >= 1) {
          target.value = tags.splice(tags.length - 1, 1)[0];
        }
      }
    }
    this.newFilter.valueValid = this.newFilter.filter && !!this.newFilter.filter.value;
  }

  setFilterValue(event) {
    const value: string = event.target.value;
    const input = event.target as HTMLIonInputElement;
    let valid = true;
    let parsedValue;

    switch (keyToType[this.newFilter.filter.itemIntKey]) {
      case 'string':
        parsedValue = value;
        break;
      case 'number':
        parsedValue = +value;
        valid = !isNaN(parsedValue);
        break;
      case 'date':
        console.log(value);
        parsedValue = StockFiltersComponent.inputDateStr2Date(value);
        valid = parsedValue !== null;
        break;
      default:
        parsedValue = value;
    }

    console.log(valid, value);

    if (!valid) {
      input.className += ' invalid';
      delete this.newFilter.filter.value;
      this.newFilter.valueValid = false;
      return;
    }

    if (input.className.includes(' invalid')) {
      const classes = input.className.split(' ').filter(v => v !== 'invalid');
      input.className = classes.reduce((pv, cv) => pv + ' ' + cv);
    }

    if (this.type === 'search') {
      console.log('YUS?', parsedValue);
      this.newFilter.filter.value = parsedValue;
      this.newFilter.valueValid = true;
    } else {

    }
  }

  setFilterRange(event, part: 'start' | 'end') {
    const value: string = event.target.value;
    const input = event.target as HTMLIonInputElement;
    let parsedValue;
    let valid = true;

    if (keyToType[this.newFilter.filter.itemIntKey] === 'number') {
      parsedValue = +value;
      valid = !isNaN(parsedValue);
    } else if (keyToType[this.newFilter.filter.itemIntKey] === 'date') {
      parsedValue = StockFiltersComponent.inputDateStr2Date(value);
      valid = parsedValue !== null;
    } else {
      this.newFilter.valueValid = false;
      return;
    }

    if (valid) {
      const idx = part === 'start' ? 0 : 1;

      if (idx === 1 && keyToType[this.newFilter.filter.itemIntKey] === 'date') {
        parsedValue = parsedValue.setDate(parsedValue.getDate() + 1);
      }

      if (this.newFilter.filter.value && typeof this.newFilter.filter.value === 'object' &&
        this.newFilter.filter.value instanceof Array) {
        const oIdx = idx === 1 ? 0 : 1;
        const otherValue = this.newFilter.filter.value[oIdx];
        valid = idx === 0 ? (parsedValue < otherValue) : (parsedValue > otherValue);

        if (valid) {
          this.newFilter.filter.value[idx] = parsedValue;
        }
      } else {
        this.newFilter.filter.value = [null, null];
        this.newFilter.filter.value[idx] = value;
      }
    }

    if (!valid) {
      input.className += ' invalid';
      this.newFilter.valueValid = false;
      return;
    } else {
      this.newFilter.valueValid = true;

      if (input.className.includes(' invalid')) {
        const classes = input.className.split(' ').filter(v => v !== 'invalid');
        input.className = classes.reduce((pv, cv) => pv + ' ' + cv);
      }
    }
  }


  addGroup() {
    const fg: FilterGroup = {type: 'OR', filters: []};
    this.filters.filters.push(fg);
    this.changeMade = true;
  }

  cycleFGType(type: 'AND' | 'OR' | 'NOT'): 'AND' | 'OR' | 'NOT' {
     const idx = ['OR', 'AND', 'NOT'].indexOf(type) + 1;

     if (idx < 3) {
       return ['OR', 'AND', 'NOT'][idx] as 'AND' | 'OR' | 'NOT';
     } else {
       return 'OR';
     }
  }

  async deleteGroup() {
    const ac = await this.alertControl.create({
      header: 'Delete Filter Group?', subHeader: 'All filters and filter groups within the selected filter group will' +
        ' be removed.', message: 'This cannot be undone', cssClass: 'custom-alert',
      buttons: ['Cancel', {text: 'Delete', role: 'y'}]
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      const parent = this.findFGParent(this.selectedFilterGroup);
      parent.filters.splice(parent.filters.indexOf(this.selectedFilterGroup, 1));
      this.setFGFilterType(parent);
      this.changeMade = true;
    }
  }

  deleteFilter(filter: Filter) {

    if (this.lock) { return; }
    const parent = this.findFGParent(filter);
    parent.filters.splice(parent.filters.indexOf(filter), 1);
    this.setFGFilterType(parent);
    this.changeMade = true;
  }

  async clear() {

    if (this.lock) { return; }
    const ac = await this.alertControl.create({header: 'Clear Filters', subHeader: 'Are you sure?',
      cssClass: 'custom-alert', buttons: ['No', {text: 'Yes', role: 'y'}]});
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      this.filters = {type: 'OR', filters: []};
      this.applyFilters();
    }
  }

  editFilter(filter: Filter) {

    if (this.lock || this.newFilter) {
      return;
    }
    this.editing = filter;
    this.newFilter = {filter, type: filter.itemIntKey === '_tags' ? 'tag' : 'values', valueValid: true};
    this.selectedFilterGroup = this.findFGParent(filter);
    // this.editing = {filter, part};
  }

  conditionsForType(type: 'string' | 'number' | 'date'): string[] {
    if (this.type === 'search' && type === 'string') {
      return ['='];
    } else {
      return filterConditions(this.type);
    }
  }

  applyFilters() {
    this.trim();
    const filterString = this.stringify();
    this.onApply(filterString, this.filters);
    this.changeMade = false;
  }

  async saveFilters() {
    const mc = await this.modalController.create({
      component: SaveUserSettingsPage, componentProps: {
        document: 'stock-filters', getSaveObj: this.filters.filters.length > 0 ? (() => this.filters) : null,
        entryPrefix: this.type, what: `Stock Filters (${this.type[0].toUpperCase()}${this.type.substring(1)})`,
      }
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();

    if (data) {
      this.filters = data;
      const makeDates = (node: FilterGroup) => {
        for (const child of node.filters) {
          if (child.hasOwnProperty('filters')) {
            makeDates(child as FilterGroup);
          } else if (keyToType[(child as Filter).itemIntKey] === 'date') {
            (child as Filter).value = (child as any).value.toDate();
          }
        }
      };
      makeDates(this.filters);
      this.applyFilters();
    }
  }

  trim(node: FilterGroup = this.filters, parent: FilterGroup = null) {

    for (const n of node.filters) {
      if (n.hasOwnProperty('filters')) {
        this.trim(n as FilterGroup, node);
      }
    }

    if (node.filters.length === 0 && parent) {
      parent.filters.splice(parent.filters.indexOf(node), 10);
    }
  }

  stringify(root: Filter | FilterGroup = this.filters): string {
    return StockFiltersComponent.stringifyFilters(root, this.type);
  }

  format(value: string | number | Date) {
    if (value instanceof Date) {
      return `${value.getDate()}/${value.getMonth() + 1}/${value.getFullYear()}`;
    }
    return value;
  }

  groupDragStart(event, fg: FilterGroup) {
    if (this.filters !== fg) {
      this.dragged = fg;
      const fgs = document.getElementsByClassName('filter-group');

      for (let i = 0; i < fgs.length; i++) {
        const group = (fgs.item(i) as HTMLDivElement);
        group.className = group.className + ' drag-in-progress';
      }
      const currentFG = event.target.parentElement.parentElement;
      currentFG.className = currentFG.className + ' dragged';
    }
  }

  groupDragEnter(event) {
    event.target.style.background = 'lightyellow';
  }

  groupDragLeave(event) {
    event.target.style.background = '#fafafa';
  }

  groupDragDrop(event, fg: FilterGroup) {

    if (fg === null || this.dragged === null || fg === this.dragged) {
      return;
    }

    console.log(fg);
    console.log(this.dragged);

    event.target.style.background = '#fafafa';
    const fgs = document.getElementsByClassName('filter-group');

    for (let i = 0; i < fgs.length; i++) {
      const group = (fgs.item(i) as HTMLDivElement);
      const classes = group.className.split(' ');
      group.className = classes.reduce((pv, cv) => pv + (!['drag-in-progress', 'dragged'].includes(cv) ? ' '+cv : ''));
    }

    const parent = this.findFGParent(this.dragged);

    if (parent) {
      parent.filters.splice(parent.filters.indexOf(this.dragged), 1);
    }

    fg.filters.push(this.dragged);
    this.dragged = null;
    console.log(this.filters);
    this.changeMade = true;
  }

  typeOKey(key: string): string {
    if (keyToType[key] === 'string'){
      return 'text / string';
    }
    return keyToType[key];
  }

  private isCompatibleFilterTypes(): boolean {
    if (this.type === 'search') {
      const fgType = this.selectedFilterGroup.filtersType;

      if (fgType) {
        const newType = keyToType[this.newFilter.filter.itemIntKey] === 'string' ||
        this.newFilter.filter.itemIntKey === '_tags' ? 'string' : 'number';

        console.log(fgType !== newType, fgType, newType);

        if (fgType !== newType) {
          this.alertControl.create({
            header: 'Incompatible Filter Group Types', subHeader: 'Currently the search engine does not allow ' +
              'combining filters for string (text) with other types of filters (numbers, dates, etc.).',
            message: 'This issue can usually be bypassed by using more sub filter groups. Otherwise try querying ' +
              'the database instead.', cssClass: 'custom-alert', buttons: ['ok']
          }).then(ac => ac.present());
          return false;
        }
      }
    }
    return true;
  }

  private findFGParent(fg: FilterGroup | Filter, currentFG: FilterGroup = this.filters): FilterGroup {

    if (currentFG.filters.includes(fg)) {
      return currentFG;
    }

    for (const otherFG of currentFG.filters) {
      if (otherFG.hasOwnProperty('filters')) {
        const p = this.findFGParent(fg, otherFG as FilterGroup);

        if (p) { return p; }
      }
    }
    return null;
  }

  private setFGFilterType(fg: FilterGroup) {
    if (this.type === 'search') {

      for (const child of fg.filters) {
        if (!child.hasOwnProperty('filters')) {
          const k = (child as Filter).itemIntKey;
          fg.filtersType = keyToType[k] === 'string' || k === '_tags' ? 'string' : 'number';
          return;
        }
      }

      if (fg.hasOwnProperty('filtersType')) {
        delete fg.filtersType;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static inputDateStr2Date(value: string): Date {
    if (/\d{4}-\d{2}-\d{2}/.test(value)) {
      const parts = value.split('-');
      const parsedValue = new Date();
      parsedValue.setFullYear(+parts[0], +parts[1] - 1, +parts[2]);
      parsedValue.setHours(0, 0, 0, 0);
      return parsedValue;
    } else {
      return null;
    }
  }

}
