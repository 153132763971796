import {createReducer, on} from '@ngrx/store';
import * as OptionsActions from './options.actions';
import {NGPReportOptions} from '../models/ngp-report-options';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';

export interface OptionsState extends IInitialState {
  ngpReportOptions: NGPReportOptions,
}

export const initialOptionsState: OptionsState = {
  ngpReportOptions: {},
  errors: null,
};

export const OptionsReducer = createReducer(
  initialOptionsState,
  // ===========================================================================
  // get NGP Report Options
  // ===========================================================================
  on(OptionsActions.getNGPReportOptionsSuccess, (state, {rulesDoc, store, lineColours, navSettings, colPreview}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        rulesDoc,
        colPreview,
        lineColours,
        navSettings,
      },
    },
  })),
  on(OptionsActions.getNGPReportOptionsFailure, (state, {errors, store}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        rulesDoc: null,
        colPreview: null,
        lineColours: null,
        navSettings: null,
      },
    },
    errors: [
      ...state.errors,
      ...errors,
    ],
  })),
  // ===========================================================================
  // get NGP Report Preview Columns
  // ===========================================================================
  on(OptionsActions.setNgpReportOptionsPreviewColumnsSuccess, (state, {columnData, store}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        colPreview: columnData,
      },
    },
  })),
  on(OptionsActions.setNgpReportOptionsPreviewColumnsFailure, (state, {error, store}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        colPreview: {},
      },
    },
    errors: [
      ...state.errors,
      error,
    ],
  })),
  // ===========================================================================
  // get NGP Report Table Navigation Options
  // ===========================================================================
  on(OptionsActions.setNGPOptionsTableNavSettingsFailure, (state, {error, store}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        tableNavSettings: {},
      },
    },
    errors: [
      ...state.errors,
      error,
    ],
  })),
  on(OptionsActions.setNGPOptionsTableNavSettingsSuccess, (state, {settings, store}) => ({
    ...state,
    ngpReportOptions: {
      ...state.ngpReportOptions,
      [store.storeID]: {
        ...state[store.storeID],
        navSettings: settings,
      },
    },
  })),
);
