import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SHARED_ICONS} from './components';

@NgModule({
  declarations: [
    ...SHARED_ICONS
  ],
  exports: [
    ...SHARED_ICONS
  ],
  imports: [
    CommonModule
  ]
})
export class SharedIconsModule {
}
