import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {forkJoin, Observable} from 'rxjs';
import {TitleCasePipe} from '@angular/common';
// noinspection SpellCheckingInspection
import algoliasearch, {SearchIndex} from 'algoliasearch';
import {RequestOptions} from '@algolia/transporter';
import {StockFiltersComponent} from '../stock-filters/stock-filters.component';
import {ProgressComponent} from '../progress/progress.component';
import {
  castStockItem,
  FilterGroup,
  keyToTitle,
  sItemIntToKey,
  sItemKeyToInt,
  StockItem,
  SubDep,
  Supplier
} from '../../../../shared-utilities/models-old/datastructures';
import {StockFunctions} from '../../../../shared-utilities/functions-old/stock-functions';
import {DOCUMENT_ID, FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {intersection, list2oxfordComma} from '../../../../shared-utilities/utils-old/formatting';

interface SearchResult extends StockItem {
  highlight?: {
    fullyHighlighted: boolean;
    matchLevel: string;
    matchedWords: string[];
    value: string;
  };
}

@Component({
  selector: 'app-stock-search',
  templateUrl: './stock-search.component.html',
  styleUrls: ['./stock-search.component.scss'],
})
export class StockSearchComponent implements OnInit {

  @Input() storeID: string;
  @Input() departments?: { dep: { [code: string]: { name: string } }; sub: { [code: string]: SubDep } };
  @Input() suppliers?: { [suppID: string]: Supplier };
  @Input() filters: FilterGroup = {filters: [], type: 'OR'};
  @Input() modal = false;
  @Input() lockFilters = false;

  @Input() multiple = false;

  @Input() disabled?: string[];
  @Input() highlight?: boolean;

  @Input() displayDefault: string[];

  unhidden = {
    tweaks: false,
    search: false, deps: false, subDeps: false, supps: false, filters: false, retrieve: false,
  };
  tweaks = ['filters', 'deps', 'subDeps', 'supps'];

  searchable = StockFunctions.algoliaSearchable;
  searchSelection: { [keys: string]: boolean } = {};
  retrieveSelection: { [keys: string]: boolean } = {code: true, desc: true};

  facets: { dep: { [key: string]: number }; subDep: { [key: string]: number };
    regularSuppCode: { [key: string]: number }; };
  facetChecks: {
    dep: { [key: string]: boolean }; subDep: { [key: string]: boolean }; regularSuppCode: { [key: string]: boolean };
  } = {dep: {}, subDep: {}, regularSuppCode: {}};
  refinementApplied: { searchable?: boolean; dep?: boolean; subDep?: boolean; regularSuppCode?: boolean;
    filters?: boolean; retrieve?: boolean; } = {};
  refinementAppliedStr: string;

  substr: string;
  index: 'desc' | 'unitsYear' = 'desc';
  hits: SearchResult[];


  displayColumns: string[] = ['code', 'desc'];

  searching: boolean;

  selected: { [code: string]: boolean } = {};
  numSelected = 0;

  pagination: { nbHits: number; nbPages: number; page: number } = {nbHits: 0, nbPages: 0, page: 0};

  readonly key2Title = keyToTitle;
  private searchClient = algoliasearch(
    'G7C0VDZPUM', 'efdb7deac9783f2d31b319d227220f42'
  );
  private searchIndices: { desc: SearchIndex; unitsYear?: SearchIndex } = {} as any;
  private params: RequestOptions = {
    attributesToRetrieve: [sItemKeyToInt.code, sItemKeyToInt.desc],
    facets: [sItemKeyToInt.dep, sItemKeyToInt.subDep, sItemKeyToInt.regularSuppCode],
    hitsPerPage: 10,
  };
  private canSearch: boolean;

  constructor(
    private firebase: FirebaseService,
    private fireAuth: FireAuthService,
    private modalController: ModalController,
    private alertControl: AlertController,
  ) {
    this.searchable.map(key => {
      this.searchSelection[key] = true;
    });
  }
  ngOnInit() {
    this.canSearch = this.fireAuth.hasAccess(this.storeID, {ruleID: 'b.'}) === true;

    if (this.canSearch) {
      this.disabled = this.disabled ? this.disabled : [];
      this.searchIndices = {
        desc: this.searchClient.initIndex(`stock_${this.storeID}`),
        unitsYear: this.searchClient.initIndex(`stock_${this.storeID}_units_sold`),
      };

      if (this.displayDefault) {
        if (!this.displayDefault.includes('code')) { this.displayDefault.push('code'); }
        this.displayColumns = intersection([this.searchable, this.displayDefault]).sort((a, b) =>
          this.searchable.indexOf(a) - this.searchable.indexOf(b));
        this.displayColumns.forEach((key) => {
          this.retrieveSelection[key] = true;
        });
        this.setRefinementMarker('retrieve', true);
      }

      if (this.highlight) {
        this.params.attributesToHighlight = this.displayColumns.map(k => sItemKeyToInt[k]);
        this.params.highlightPreTag = '<em class="search-highlight-emphasis">';
        this.params.highlightPostTag = '</em>';
      }

      if (!this.departments) {
        this.departments = {dep: null, sub: null};
        this.firebase.getStoreDataDoc('departments', this.storeID).then(d => {
          const tc = new TitleCasePipe();
          for (const k of Object.keys(d)) {
            d[k].name = tc.transform(d[k].name);
          }
          this.departments.dep = d;
        });
        this.firebase.getStoreDataDoc('sub_departments', this.storeID).then(d => {
          const tc = new TitleCasePipe();
          for (const k of Object.keys(d)) {
            d[k].name = tc.transform(d[k].name);
          }
          this.departments.sub = d;
        });
      }

      if (!this.suppliers) {
        this.firebase.getSuppliers(this.storeID).then(suppliers => {
          this.suppliers = suppliers;
        });
      }

      if (this.filters && this.filters.filters.length > 0) {
        this.params.filters = StockFiltersComponent.stringifyFilters(this.filters);
        // this.refinementApplied.filters = true;
        this.setRefinementMarker('filters', true);
      }
      this.searchIndices[this.index].search('', this.params).then(results => {
        this.facets = {dep: {}, subDep: {}, regularSuppCode: {}};

        for (const key of Object.keys(results.facets)) {
          this.facets[sItemIntToKey[key]] = results.facets[key];
        }
      });
    } else {
      this.alertControl.create({header: 'Permission Denied', subHeader: 'Sorry you do not have permission to search ' +
          'this stores stock', cssClass: ['custom-alert', 'warn'], buttons: ['ok']})
        .then((ac) => ac.present()
          .then(() => ac.onDidDismiss()
            .then(() => this.modalController.dismiss())
          )
        );
    }
  }

  refinementName(key: 'searchable' | 'dep' | 'subDep' | 'regularSuppCode' | 'filters' | 'retrieve') {
    switch (key) {
      case 'searchable':
        return 'Searchable Fields';
      case 'dep':
        return 'Departments';
      case 'subDep':
        return 'Sub Departments';
      case 'regularSuppCode':
        return 'Suppliers';
      case 'filters':
        return 'Filters';
      case 'retrieve':
        return 'Shown Fields';
    }
  }

  clickTweak(tweak: string) {
    this.unhidden[tweak] = !this.unhidden[tweak];

    if (this.unhidden[tweak]) {
      this.tweaks.forEach(t => {
        if (t !== tweak) {
          this.unhidden[t] = false;
        }
      });
    }
  }

  applyFilters = (filterString: string, filters: FilterGroup) => {
    if (this.lockFilters) { return; }
    this.filters = filters;
    this.params.filters = filterString;

    console.log('filterString:', filterString);

    if (filterString === '') {
      if (this.refinementApplied.filters) {
        // delete this.refinementApplied.filters;
        this.setRefinementMarker('filters', false);
      }
    } else {
      // this.refinementApplied.filters = true;
      this.setRefinementMarker('filters', true);
    }
    this.search();
  };

  close() {
    this.modalController.dismiss().then();
  }

  searchEvent(event) {
    const target = event.target as HTMLIonInputElement;
    const substr = target.value as string;
    this.search(substr);
  }

  toggleSearch(key?: string) {

    if (key) {
      this.searchSelection[key] = !this.searchSelection[key];
    }
    let restrict = this.searchable.filter((k) => this.searchSelection[k]);

    if (this.searchable.length === restrict.length) {

      if (!key) {
        this.searchable.forEach((k) => {
          this.searchSelection[k] = false;
        });
        this.setRefinementMarker('searchable', false);
      }
      delete this.params.restrictSearchableAttributes;
      this.setRefinementMarker('searchable', false);

      if (this.highlight) {
        this.params.attributesToHighlight =
          intersection([this.searchable, this.displayColumns]).map(k => sItemKeyToInt[k]);
      }
    } else {

      if (!key) {
        this.searchable.forEach((k) => {
          this.searchSelection[k] = true;
        });
        restrict = this.searchable;
        this.setRefinementMarker('searchable', false);
      } else {
        this.setRefinementMarker('searchable', true);
      }
      this.params.restrictSearchableAttributes = restrict.map(k => sItemKeyToInt[k]);

      if (this.highlight) {
        if (this.params.restrictSearchableAttributes) {
          this.params.attributesToHighlight =
            intersection([this.params.restrictSearchableAttributes, this.displayColumns]);
        } else {
          this.params.attributesToHighlight = this.displayColumns;
        }
      }
    }
    this.search(null, null, true);
  }

  toggleRetrieve(key?: string) {
    console.log('toggleRetrieve', key);
    if (key) {
      this.retrieveSelection[key] = !this.retrieveSelection[key];
    }
    let retrieve = this.searchable.filter((k) => this.retrieveSelection[k]);

    if (this.searchable.length === retrieve.length) {

      if (!key) {
        this.searchable.forEach((k) => {
          this.retrieveSelection[k] = false;
        });
        this.setRefinementMarker('retrieve', false);
      }
      this.params.attributesToRetrieve = [sItemKeyToInt.code, sItemKeyToInt.desc];
      this.setRefinementMarker('retrieve', false);
    } else {

      if (!key) {
        this.searchable.forEach((k) => {
          this.searchSelection[k] = true;
        });
        retrieve = ['code', 'desc'];
        this.setRefinementMarker('retrieve', false);
      } else {
        this.setRefinementMarker('retrieve', true);
      }
      this.params.attributesToRetrieve = retrieve.map(k => sItemKeyToInt[k]);
    }
    this.displayColumns = Object.keys(this.retrieveSelection).filter((k) => this.retrieveSelection[k])
      .sort((a, b) => this.searchable.indexOf(a) - this.searchable.indexOf(b));

    if (this.highlight) {
      this.params.attributesToHighlight =
        intersection([this.searchable, this.displayColumns]).map(k => sItemKeyToInt[k]);
    }
    this.search(null, null, true);
  }

  toggleFacet = (facetID: string, key: string) => {
    this.facetChecks[facetID][key] = !this.facetChecks[facetID][key];
    const facetFilters = [];

    for (const k of Object.keys(this.facetChecks) as
      ('searchable' | 'dep' | 'subDep' | 'regularSuppCode' | 'filters')[]) {
      Object.keys(this.facetChecks[k]).forEach(val => {
        // delete this.refinementApplied[k];
        this.setRefinementMarker(k, false);
        if (this.facetChecks[k][val]) {
          facetFilters.push(`${sItemKeyToInt[k]}:${val}`);
          // this.refinementApplied[k] = true;
          this.setRefinementMarker(k, true);
        } else {
          delete this.facetChecks[k][val];
        }
      });
    }

    if (facetFilters.length > 0) {
      this.params.facetFilters = facetFilters;
    } else {
      delete this.params.facetFilters;
    }
    this.search(null, facetID, true);
  };

  changePage(event) {
    this.pagination = event.value;
    console.log(this.pagination);
    this.search();
  }

  search(substr: string = null, heldFacet?: string, keepTweaks: boolean = false) {

    if (substr !== null) {
      if (this.substr !== undefined && substr.toLowerCase() === this.substr.toLowerCase()) {
        return;
      }
    } else {
      substr = this.substr;
    }

    if (!keepTweaks) {
      for (const key of Object.keys(this.unhidden)) {
        this.unhidden[key] = false;
      }
    }
    this.searching = true;
    this.params.page = this.pagination.page;
    console.log(this.params);
    this.searchIndices[this.index].search(substr, this.params).then(results => {
      this.hits = results.hits.map(hit => {
        const sItem: any = {};

        for (const fbInt of Object.keys(hit)) {
          if (sItemIntToKey[fbInt]) {
            sItem[sItemIntToKey[fbInt]] = hit[fbInt];
          } else if (this.highlight && fbInt === '_highlightResult') {
            // eslint-disable-next-line no-underscore-dangle
            const highlights = hit._highlightResult;
            sItem.highlight = {};
            Object.keys(highlights).forEach((fbInt2) => {
              const value: string = highlights[fbInt2].value;
              const sIdx = value.indexOf(this.params.highlightPreTag);
              const eIdx = value.indexOf(this.params.highlightPostTag);
              const match = value.substring(sIdx + this.params.highlightPreTag.length, eIdx);
              const muc = match.toUpperCase();
              const substrUC = substr.toUpperCase();
              let replacement = '';
              let open = false;

              for (let i = 0; i < muc.length; i++) {
                if (muc[i] === substrUC[i]) {
                  if (!open) {
                    replacement += '<span class="exact">';
                    open = true;
                  }
                } else {
                  if (open) {
                    replacement += '</span>';
                    open = false;
                  }
                }
                replacement += match[i];
              }
              highlights[fbInt2].value = value.substring(0, sIdx + this.params.highlightPreTag.length);
              highlights[fbInt2].value += value.substring(sIdx + this.params.highlightPreTag.length)
                .replace(match, replacement);
              // let i = 0;
              //
              // while (i < muc.length && muc[i] === substrUC[i]) {
              //   i++;
              // }
              //
              // if (match.toUpperCase() === ) {
              //   highlights[fbInt2].value = value.substring(0, sIdx + this.params.highlightPreTag.length);
              //   highlights[fbInt2].value += value.substring(sIdx + this.params.highlightPreTag.length)
              //     .replace(match, `<span class="exact">${match}</span>`);
              // }
              sItem.highlight[sItemIntToKey[fbInt2]] = highlights[fbInt2];
            });
          }
        }
        return sItem as StockItem;
      });
      console.log(this.hits);

      for (const key of Object.keys(results.facets)) {
        if (!heldFacet || sItemIntToKey[key] !== heldFacet) {
          this.facets[sItemIntToKey[key]] = results.facets[key];
          // const facets = Object.keys(results.facets[key]).map(k => ({key: k, count: results.facets[key][k]}));
          // facets.sort((a, b) => b.count - a.count);
          // this.facets[sItemIntToKey[key]] = facets;
        }
      }
      this.pagination.nbHits = results.nbHits;
      this.pagination.nbPages = results.nbPages;
      this.pagination.page = results.page;
      console.log(results);
      this.searching = false;
    });
    this.substr = substr;
  }

  select(stockID: string) {
    if (this.multiple) {
      this.selected[stockID] = !this.selected[stockID];
      this.numSelected += (this.selected[stockID] ? 1 : -1);
    } else {
      if (this.selected[stockID]) {
        this.selected = {};
      } else {
        this.selected = {};
        this.numSelected = 0;
        this.selected[stockID] = true;
        this.numSelected = 1;
      }
    }
  }

  async makeSelection() {
    if (this.numSelected) {
      const codes = Object.keys(this.selected).filter(code => this.selected[code]);
      const promises: Promise<void>[] = [];
      let items: StockItem[] = [];

      const obs = new Observable(observer => {
        observer.next(0);

        while (codes.length) {
          const codes10 = codes.splice(0, 10);
          promises.push(new Promise<void>((resolve, reject) => this.firebase.queryStock(this.storeID, [{
              q: 'where',
              p: [DOCUMENT_ID(), 'in', codes10]
            }]).then(r => {
              const results = r as { ids: string[]; items: any };
              items = items.concat(results.ids.map(stockID => {
                const item = castStockItem(results.items[stockID]);
                item.stockID = stockID;
                return item;
              }));
              observer.next(items.length / this.numSelected);
              resolve();
            }).catch(e => reject('Failed to fetch some items: ' + e))
          ));
        }
        forkJoin(promises).toPromise().then(() => {
          observer.complete();
        }).catch(e => observer.error(e));
      });

      const mc = await this.modalController.create({
        component: ProgressComponent, componentProps: {updates: obs, showPercentage: true}
      });
      await mc.present();
      await mc.onDidDismiss();

      if (this.multiple) {
        this.modalController.dismiss(items).then();
      } else {
        this.modalController.dismiss(items[0]).then();
      }
    }
  }

  private setRefinementMarker(key: 'searchable' | 'dep' | 'subDep' | 'regularSuppCode' | 'filters' | 'retrieve',
                              value: boolean) {
    if (value) {
      this.refinementApplied[key] = true;
    } else {
      delete this.refinementApplied[key];
    }
    const applied = Object.keys(this.refinementApplied) as
      ('searchable' | 'dep' | 'subDep' | 'regularSuppCode' | 'filters')[];

    if (applied.length) {
      this.refinementAppliedStr = list2oxfordComma(applied.map(k => this.refinementName(k)));
    } else {
      this.refinementAppliedStr = null;
    }
  }
}
