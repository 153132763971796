import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';

import {FirebaseService, Timestamp} from './firebase.service';
import {timeBreakDown, TimeBreakDown} from '../functions-old/date-functions';
import {mergeMap} from 'rxjs/operators';

export interface StoreDataFreshness {
  sales?: Date;
  shelfTalkers?: Date;
  stock?: Date;
  suppliers?: Date;
}

export const DATA_FRESHNESS_KEYS = ['sales', 'shelfTalkers', 'stock', 'suppliers'] as const;

export type StoreDataFreshnessAge = { [key in keyof StoreDataFreshness]: TimeBreakDown };

interface StoreDataFreshnessRaw {
  sales?: Timestamp;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  shelf_talkers?: Timestamp;
  stock?: Timestamp;
  suppliers?: Timestamp;
}

/**
 * storeID?: string | 'all';
 *
 * keys?: (keyof StoreDataFreshness)[];
 *
 * frequency?: number;
 */
interface AgeObsOpts {
  storeID?: string | 'all';
  keys?: (keyof StoreDataFreshness)[];
  frequency?: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataFreshnessService {

  private stores: { order: string[]; names: { [storeID: string]: string } } = {order: [], names: {}};
  private storeSubs: { [storeID: string]: Subscription } = {};

  private allFreshnessBS: BehaviorSubject<{ [storeID: string]: StoreDataFreshness }> = new BehaviorSubject({});
  private storeFreshnessBS: { [storeID: string]: BehaviorSubject<StoreDataFreshness> } = {};

  private allFreshnessChangesBS: BehaviorSubject<{ storeID: string; changes: StoreDataFreshness }> = new BehaviorSubject(null);
  private storeFreshnessChangesBS: { [storeID: string]: BehaviorSubject<StoreDataFreshness> } = {};



  constructor(
    private firebase: FirebaseService,
  ) {
    this.firebase.stores.subscribe((stores) => {
      const removed = this.stores.order.filter((storeID) => !stores.stores.hasOwnProperty(storeID));
      const added = stores.order.filter((storeID) => !this.stores.names.hasOwnProperty(storeID));

      removed.forEach((storeID) => {
        if (this.storeSubs[storeID]) {
          this.storeSubs[storeID].unsubscribe();
          delete this.storeSubs[storeID];
          delete this.storeFreshnessBS[storeID];
          delete this.storeFreshnessChangesBS[storeID];
        }
      });

      added.forEach((storeID) => {
        this.storeFreshnessBS[storeID] = new BehaviorSubject<StoreDataFreshness>(null);
        this.storeFreshnessChangesBS[storeID] = new BehaviorSubject<StoreDataFreshness>(null);

        const obs = this.firebase.subStoreDoc('data/singular_documents/data_timestamps', storeID);
        this.storeSubs[storeID] = obs.subscribe((data: StoreDataFreshnessRaw) => {
          const current = this.allFreshnessBS.value;
          const freshness: StoreDataFreshness = {};
          const changes: StoreDataFreshness = {};

          for (const key of (Object.keys(data) as (keyof StoreDataFreshnessRaw)[])) {
            const date = data[key].toDate();
            let saveKey: keyof StoreDataFreshness;

            switch (key) {
              case 'shelf_talkers':
                saveKey = 'shelfTalkers';
                break;
              default:
                saveKey = key;
            }
            freshness[saveKey] = date;

            if (!current[storeID] || !current[storeID][saveKey] || current[storeID][saveKey].getTime() !== date.getTime()) {
              changes[saveKey] = date;
            }
          }

          if (Object.keys(changes).length) {
            current[storeID] = freshness;
            this.allFreshnessBS.next(current);
            this.storeFreshnessBS[storeID].next(freshness);
            this.allFreshnessChangesBS.next({storeID, changes});
            this.storeFreshnessChangesBS[storeID].next(changes);
          }
        });
      });
    });
  }

  get freshness(): Observable<{ [storeID: string]: StoreDataFreshness }> {
    return this.allFreshnessBS.asObservable();
  }

  get freshnessChanges(): Observable<{ storeID: string; changes: StoreDataFreshness }> {
    return this.allFreshnessChangesBS.asObservable();
  }

  storeFreshness(storeID: string): Observable<StoreDataFreshness> {
    return this.storeFreshnessBS[storeID].asObservable();
  }

  storeFreshnessChanges(storeID: string): Observable<StoreDataFreshness> {
    return this.storeFreshnessBS[storeID].asObservable();
  }

  /**
   * - storeID?: string | 'all' = 'all;
   *
   * - keys?: (keyof StoreDataFreshness)[] = DATA_FRESHNESS_KEYS;
   *
   * - frequency?: number = 10000 // 10s;
   *
   * @param opts?: AgeObsOpts
   */
  createAgeObserver(opts?: AgeObsOpts): Observable<{ [storeID: string]: StoreDataFreshnessAge } | StoreDataFreshnessAge> {
    const options: AgeObsOpts = {
      storeID: 'all', keys: DATA_FRESHNESS_KEYS.map((k) => k), frequency: 10000
    };

    if (opts) {
      Object.keys(opts).forEach((key) => {
        if (opts[key]) {
          options[key] = opts[key];
        }
      });
    }

    const processStore = (freshness: StoreDataFreshness, time) => {
      const age: StoreDataFreshnessAge = {};

      for (const key of options.keys) {
        age[key] = timeBreakDown(time - freshness[key].getTime());
      }
      return age;
    };

    return interval(options.frequency).pipe(mergeMap((_) => {
      const freshness = this.allFreshnessBS.value;
      const storeIDs = options.storeID === 'all' ? Object.keys(freshness) : options.storeID;
      const time = (new Date()).getTime();

      if (typeof storeIDs === 'string') {
        return [processStore(freshness[storeIDs], time)];
      } else {
        const ages: { [storeID: string]: StoreDataFreshnessAge } = {};

        for (const storeID of storeIDs) {
          ages[storeID] = processStore(freshness[storeID], time);
        }
        return [ages];
      }
    }));
  }
}
