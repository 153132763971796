import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertController, IonNav, ModalController, PopoverController, ToastController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {AOSettings, bitEncode, Colleague, EmailSettings, Message, StoreInfo,} from '../../../../shared-utilities/models-old/datastructures';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {FuzzySort} from '../../../../shared-utilities/functions-old/fuzzysort';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {ColleaguesService} from '../../../../shared-utilities/services-old/colleagues-service/colleagues.service';
import {hasRule, ruleStructure} from '../../../../shared-utilities/models-old/utils-old/rule-structure';
import {cleanObj} from '../../../../shared-utilities/functions-old/object-functions';

 type AOSettingsSubSet = Omit<AOSettings, 'iqCode' | 'iqTill' | 'repName' | 'repCell' | 'ccEmail' | 'cc' |
   'executionTime' | 'noLogo'>;

@Component({
  selector: 'app-automation-settings-email',
  templateUrl: './automation-settings-email.component.html',
  styleUrls: ['./automation-settings-email.component.scss'],
})
export class AutomationSettingsEmailComponent implements OnInit, OnDestroy {
  @Input() storeID: string;
  @Input() storeInfo?: StoreInfo;
  thisUser: string;
  settings: { [storeID: string]: { [userID: string]: AOSettings } } = {};

  storeName: string;
  saving: boolean;
  testing: string;

  hide: { [something: string]: boolean } = {mailer: true, iqLogin: true};

  settingsForm: FormGroup;
  formCache: { [storeID: string]: { [userID: string]: AOSettings } } = {};
  changed: { [storeID: string]: { [userID: string]: { [path: string]: boolean } } } = {};

  colleagues: { users: { [userID: string]: Colleague }; storesUsers: { [storeID: string]: string[] } } =
    {users: {}, storesUsers: {}};
  canAutoOrder: { [storeID: string]: { [userID: string]: boolean } } = {};
  userIdOpts: string[] = [];

  classes: { [storeID: string]: string } = {};

  userID: string;
  changes: { [document: string]: any };
  disabled: { [storeID: string]: boolean } = {};
  fuzzy: FuzzySort;

  gmail: { [storeID: string]: { [userID: string]: boolean } } = {};
  defaultStatus: { [storeID: string]: { [userID: string]: boolean } } = {};
  copiedValue: AOSettings;
  default: {[storeID: string]: FormGroup} = {};


  readonly help = {
    iqCode: 'Prefix to add to the incremental code generated for purchase orders. Used to identify automatically' +
      'generated orders.',
    iqTill: '',
    repName: 'Name of a contact for the supplier. Placed on generated purchase orders.',
    repCell: 'Phone number of a contact for the supplier. Placed on generated purchase orders.',
    ccEmail: 'Email address to cc orders to. Useful for a staff member to keep track of all outgoing order.',
    cc: '',
    executionTime: '**NOT YET IMPLEMENTED** Time of day at said days orders are generated. Process may take a few ' +
      'minutes. Time is 24h.',
    emailDetails: 'Auto Ordering requires access to a company email address to send orders from.',
    noLogo: null,
    emailBody: {
      self: 'Customise the subject and body of your emails.',
      subject: null,
      body: null
    },
    pdfClause: {
      self: 'Add a delivery clause to the bottom of the purchase order pdf.',
      text: 'This clause will appear on the bottom of your purchase orders.',
    },
    mailer: {
      email: null,
      user: null,
      pwd: null,
      smtp: { port: null, server: null, sslOnly: null, },
      imap: { port: null, server: null, sentBox: null, },
    }
    // iqLogin: {
    //   user: null,
    //   till: null,
    //   pwd: null,
    // },
  };

  readonly emailContentVariables = {
    account: '<{Account #}>',
    poNum: '<{Order #}>',
    company: '<{Store Name}>',
    repName: '<{Rep Name}>',
    tel: '<{Rep Cell #}>',
  };
  readonly emailContentOps = Object.keys(this.emailContentVariables)
    .map((k) => this.emailContentVariables[k])
    .sort();
  readonly emailSubjPH = `${this.emailContentVariables.company} - ${this.emailContentVariables.poNum} - ` +
    `${this.emailContentVariables.account}`;
  readonly emailBodyPH = `Please see attached order as per subject line.\nPlease call or reply to this email if you ` +
    `have any queries.\n${this.emailContentVariables.repName}\n${this.emailContentVariables.tel}`;
  private readonly emailVariablePat = /<{[\w# ]*}>/g;
  private readonly emailVariableIncompletePat = /(<{(?![\w# ]+}>))/g;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private firebase: FirebaseService,
    private fireAuth: FireAuthService,
    private msgService: MessagesService,
    private modalControl: ModalController,
    private nav: IonNav,
    private collService: ColleaguesService,
    private alertControl: AlertController,
    private toastControl: ToastController,
    private popControl: PopoverController,
  ) { }

  private static astrixArise(length: number) {
    let s = '';

    for (let i = 0; i < length; i++) {
      s += '*';
    }
    return s;
  }

  ngOnInit() {
    this.defaultStatus[this.storeID] = {};
    this.settings[this.storeID] = {};
    this.gmail[this.storeID] = {};
    this.subscriptions.push(this.firebase.userObj.subscribe((uo) => this.thisUser = uo.id));
    this.subscriptions.push(this.firebase.colleagues.subscribe((colleagues) => {
      this.colleagues = colleagues;
      this.colleagues.users.default = {name: 'Store Default', stores: {}};
    }));
    this.firebase.subColleaguesAccess(this.storeID).then((p) => {
      this.subscriptions.push(p.subscribe((userAccess) => {
        this.canAutoOrder[this.storeID] = {};
        Object.keys(userAccess).forEach((userID) => {
          const access = bitEncode(userAccess[userID].stores[this.storeID], ruleStructure.index.length);
          this.canAutoOrder[this.storeID][userID] = hasRule('d.iii', access);
        });
        this.userIdOpts = ['default']
          .concat(Object.keys(this.canAutoOrder[this.storeID])
            .filter((userID) => this.canAutoOrder[this.storeID][userID]));
      }));
    });

    // this.firebase.colleagues.subscribe((colleagues) => {
    //   this.colleagues = colleagues;
    //   this.colleagues.users.default = {name: 'Store Default', stores: {}};
    //   this.userIdOpts = ['default'].concat(colleagues.storesUsers[this.storeID]);
    // });

    this.firebase.subAutoOrderingEmailSettings(this.storeID).subscribe((settings) => {
      this.settings[this.storeID] = {};
      this.defaultStatus[this.storeID] = {};
      this.changed[this.storeID] = {};
      this.formCache[this.storeID] = {};

      for (const userID of this.colleagues.storesUsers[this.storeID]) {
        this.changed[this.storeID][userID] = {};
        if (settings[userID]) {
          this.settings[this.storeID][userID] = settings[userID] as AOSettings;

          if (settings[userID].mailer || settings[userID].emailBody || settings[userID].pdfClause) {
            this.defaultStatus[this.storeID][userID] = false;
          } else {
            this.settings[this.storeID][userID].mailer = {} as EmailSettings;
          }
        } else {
          this.settings[this.storeID][userID] = {} as AOSettings;
          this.defaultStatus[this.storeID][userID] = true;
        }
        this.gmail[this.storeID][userID] = this.isGmail(this.settings[this.storeID][userID].mailer);
      }
    });
  }

  ngOnDestroy() {
    console.log('AutomationSettingsComponent Destroyed');
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  close = () => {
    this.modalControl.dismiss().then();
  };

  accordionChange(ev){

    if (this.settingsForm) {
      // TODO: I WAS DOING THIS
      this.formCache[this.storeID][this.userID] = this.settingsForm.value;
    }
    this.userID = ev.detail.value;

    if (!this.userID) { return; }
    const s = this.formCache[this.storeID][this.userID] ? this.formCache[this.storeID][this.userID] :
      (this.settings[this.storeID][this.userID] ? this.settings[this.storeID][this.userID] : {}) as AOSettings;
    const mailer = s.mailer ? s.mailer : {smtp: {}, imap: {}} as EmailSettings;
    const emailBody = s.emailBody ? s.emailBody : {subject: null, body: null};
    const pdfClause = s.pdfClause ? s.pdfClause : {text: null};

    const req = Validators.required;
    this.settingsForm = new FormGroup({
      iqCode: new UntypedFormControl(s.iqCode, [req, Validators.pattern(/^\w{3,10}\s*$/)]),
      iqTill: new UntypedFormControl(s.iqTill, [req]),
      repName: new UntypedFormControl(s.repName, [req, Validators.pattern(/^[\p{L}'][ \p{L}'-]*[\p{L}]$/u)]),
      repCell: new UntypedFormControl(s.repCell,
        [Validators.pattern(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)]),
      ccEmail: new UntypedFormControl(s.ccEmail, [Validators.email]),
      cc: new UntypedFormControl(s.cc, []),
      executionTime: new UntypedFormControl(s.executionTime, []),
      noLogo: new UntypedFormControl(s.noLogo, []),
      emailBody: new UntypedFormGroup({
        subject: new UntypedFormControl(emailBody.subject, [this.validEmailValues()]),
        body: new UntypedFormControl(emailBody.body, [this.validEmailValues()]),
      }),
      pdfClause: new UntypedFormGroup({
        text: new FormControl<string>(pdfClause.text,
          [Validators.minLength(5), Validators.maxLength(300)]),
      }),
      mailer: new UntypedFormGroup({
        email: new UntypedFormControl(mailer.email, [req]),
        user: new UntypedFormControl(mailer.user, Validators.pattern(/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)),
        pwd: new UntypedFormControl(AutomationSettingsEmailComponent.astrixArise(mailer.pwdLength)),
        smtp: new UntypedFormGroup({
          port: new UntypedFormControl(mailer.smtp.port, []),
          server: new UntypedFormControl(mailer.smtp.server, []),
          sslOnly: new UntypedFormControl(mailer.smtp.sslOnly)
        }),
        imap: new UntypedFormGroup({
          port: new UntypedFormControl(mailer.imap ? mailer.imap.port : null),
          server: new UntypedFormControl(mailer.imap ? mailer.imap.server: null),
          sentBox: new UntypedFormControl(mailer.imap ? mailer.imap.sentBox: null)
        }),
      }),
    });
    this.gmail[this.storeID][this.userID] = this.isGmail(mailer);

    if (this.gmail[this.storeID][this.userID] === true){
      this.settingsForm.get('mailer.imap.server').setValue('');
      this.settingsForm.get('mailer.imap.port').setValue('');
      this.settingsForm.get('mailer.imap.sentBox').setValue('');
    }
  }

  async emailBodyVariable(part: 'body' | 'subject') {
    const fc = this.settingsForm.get(`emailBody.${part}`);
    const order = Object.keys(this.emailContentVariables).sort();
    const selection = {};
    order.forEach((key) => { selection[key] = this.emailContentVariables[key]; });

    const pc = await this.popControl.create({
      component: SelectPopoverComponent, componentProps: {
        title: 'Auto Variables', selection, order, search: true, multiple: true
      }
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (!data) { return; }

    if (fc.value) {
      const space = fc.value[fc.value.length - 1] === ' ' ? '' : ' ';
      for (let i = 0; data.length > i; i++){
        fc.setValue(fc.value + space + this.emailContentVariables[data[i]]);
      }

    } else {
      fc.setValue(' ' + this.emailContentVariables[data[0]]);
      const space = fc.value[fc.value.length - 1] === ' ' ? '' : ' ';
      for (let i = 1; data.length > i; i++){
        fc.setValue(fc.value + space + this.emailContentVariables[data[i]]);
      }
    }
  }

  async emailBodyError(part: 'body' | 'subject') {
    const fc = this.settingsForm.get(`emailBody.${part}`);
    let s = '';

    for (const error of Object.keys(fc.errors)) {
      s += `<em>${error}</em>: ${fc.errors[error]}<br>`;
    }
    const ac = await this.alertControl.create({
      header: `Error in email ${part}`, subHeader: 'Please fix the following error(s) before continuing',
      message: s, cssClass: 'custom-alert', buttons: ['ok']
    });
    await ac.present();
  }
  copy(){
    this.copiedValue = this.settingsForm.value;
    this.copiedValue.mailer.pwd = null;
  }

  paste() {
    if (this.copiedValue !== undefined) {
      this.settingsForm.setValue(this.copiedValue);
    }
  }

  savingCB(saving: boolean = true) {
    this.saving = saving;
    const elements = document.getElementsByClassName('saving-disable');
    const opacity = saving ? '50%' : '100%';

    for (let i = 0; i < elements.length; i++) {
      (elements.item(i) as HTMLElement).style.opacity =  opacity;
    }
  }


  changeEvt(event, path: string, userID: string = this.userID, storeID: string = this.storeID) {
    event.stopPropagation();
    // if (this.settingsForm.get('mailer.smtp.server').value != undefined) {
    //   this.gmail[this.storeID][this.userID] = !!this.settingsForm.get('mailer.smtp.server').value.includes('gmail');
    // }
    // if(this.gmail[this.storeID][this.userID] === true){
    //   this.settingsForm.get('mailer.imap.server').setValue('');
    //   this.settingsForm.get('mailer.imap.port').setValue('');
    //   this.settingsForm.get('mailer.imap.sentBox').setValue('');
    // }

    if (path.endsWith('server')) {
      this.gmail[storeID][userID] = this.isGmail(this.settingsForm.value.mailer);

      if (this.gmail[storeID][userID]) {
        this.settingsForm.get('mailer.imap.server').setValue('');
        this.settingsForm.get('mailer.imap.port').setValue('');
        this.settingsForm.get('mailer.imap.sentBox').setValue('');
      }
    }

    const newValue = event.detail.value;
    let v: any = this.settings[this.storeID][this.userID];
    if (!this.changed[storeID][userID]) { this.changed[storeID][userID] = {}; }

    for (const p of path.split('.')) {
      if (v && v[p]) {
        v = v[p];
      } else {
        this.changed[storeID][userID][path] = true;
        return;
      }
    }
    switch (path.substring(path.lastIndexOf('.'))) {
      case 'port':
        break;
      case 'pwd':
        break;
    }
    this.changed[storeID][userID][path] = newValue !== v;
  }

  save(storeID: string = this.storeID, userID = this.userID) {
    if (this.settingsForm.invalid) { return; }
    const updates = this.settingsForm.value as AOSettingsSubSet;
    const imap = updates.mailer.imap;

    if ( ! (imap && imap.server && imap.port)) {
      delete updates.mailer.imap;
    }

    if (updates.pdfClause && updates.pdfClause.text === '') {
      delete updates.pdfClause;
    }

    if (updates.mailer && updates.mailer.pwd) {
      let valid = false;

      for (const ch of updates.mailer.pwd) {
        if (ch !== '*') {
          valid = true;
          break;
        }
      }

      if (!valid) {
        delete updates.mailer.pwd;
      }
      if (updates.mailer.pwdLength) { delete updates.mailer.pwdLength; }
    }
    console.log(cleanObj(updates, 'all'), 'values cleaned');

    if (Object.keys(updates).length) {
      this.savingCB();
      this.saving = true;
      this.firebase.updateAutoOrderingEmailSettings(storeID, userID, updates as AOSettings).then(() => {
        this.savingCB(false);
        this.saving = false;
      });
    }
  }


  async testEmail() {
    let recipientEmail: string;
    console.log(this.gmail);

    while ( ! recipientEmail) {
      const ac = await this.alertControl.create({
        header: 'Test Email Account',
        subHeader: 'Please enter a recipient email below.',
        message: 'The recipient will ' +
          'receive a test email to confirm your account is working.',
        cssClass: 'custom-alert',
        inputs: [{name: 'email', type: 'text', placeholder: 'Test recipient email'}],
        buttons: ['Cancel', {text: 'Test', role: 'y'}]
      });
      await ac.present();
      const response = await ac.onDidDismiss();

      if (response.role === 'y') {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(response.data.values.email)) {
          recipientEmail = response.data.values.email;
        } else {
          const ac2 = await this.alertControl.create({
            header: 'Invalid Email Address', subHeader: 'The following is not a valid email address:',
            message: response.data.values.email !== '' ? response.data.values.email : 'empty string', buttons: ['ok'],
            cssClass: 'custom-alert'
          });
          await ac2.present();
          await ac2.onDidDismiss();
        }
      } else {
        return;
      }
    }
    this.testing = 'EMAIL';
    let failed = false;

    const handleFail = (msg: Message) => {
      failed = true;
      this.alertControl.create({
        header: 'Test Failed', subHeader: 'The server was unable to send the test email using the details you ' +
          'provided.', message: msg.payload.body, cssClass: 'custom-alert', buttons: ['Ok'],
      }).then(hfc => hfc.present().then(() => hfc.onDidDismiss().then(() => this.testing = null)));
    };
    const data = {} as any;
    data.mailer = this.settingsForm.get('mailer').value;

    if ( ! (data.mailer.imap && data.mailer.imap.server && data.mailer.imap.port)) {
      console.log('imap removed');
      delete data.mailer.imap;
    }
    let valid = false;

    for (const ch of data.mailer.pwd) {
      if (ch !== '*') {
        valid = true;
        break;
      }
    }
    console.log(data.mailer);

    if (!valid) {
      const ac = await this.alertControl.create({header: 'Please reenter password for testing', subHeader: 'Your ' +
          'password isn\'t actually here, its encrypted and hidden away.', message: 'This step is temporary',
        cssClass: 'custom-alert', buttons: ['ok']});
      await ac.present();
      return;
    }
    data.recipient = recipientEmail;

    this.msgService.testServer(this.storeID, 'EMAIL', data, handleFail).subscribe(msg => {
      this.toastControl.create({
        header: 'Testing Email', message: msg, cssClass: 'custom-toast', duration: 3000
      }).then(tc => tc.present());
    }, e => {
      this.testing = null;
      this.alertControl.create({header: 'Test Error', subHeader: e.message, message: 'Inform Techodactyl Support', buttons: ['Ok'],
        cssClass: ['custom-alert', 'error-alert']})
        .then(ec => {
          ec.present();
          console.log(e);
        });
    }, () => {
      console.log('COMPLETE');
      this.testing = null;

      if ( ! failed) {
        this.alertControl.create({
          header: 'Email Test Successful', message: 'The server managed to use the set up email account successfully',
          cssClass: 'custom-alert', buttons: ['Ok']
        }).then(ac => ac.present());
      }
    });
  }

  invalid() {
    console.log('saving:', this.saving);
    console.log('valid:', this.settingsForm.valid);

    const dig = (fg: FormGroup) => {
      for (const key of Object.keys(fg.controls)) {
        const control = fg.controls[key];

        if (['mailer', 'smtp', 'imap'].includes(key)) {
          dig(fg.controls[key] as FormGroup);
        } else {
          console.log(key, fg.controls[key].valid);
        }
      }
    };
    dig(this.settingsForm);
  }
  private isGmail(emailInfo: EmailSettings): boolean {
    return emailInfo && (
      (emailInfo.imap && emailInfo.imap.server && emailInfo.imap.server.toLowerCase().includes('gmail.com')) ||
      (emailInfo.smtp && emailInfo.smtp.server && emailInfo.smtp.server.includes('gmail.com'))
    );
  }

  private validEmailValues(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const v: string = control.value;

      if (v) {
        let matches = v.match(this.emailVariablePat);

        if (matches) {
          for (const x of matches) {
            if (!this.emailContentOps.includes(x)) {
              return {invalidVariable: `${x} is not a valid variable`};
            }
          }
        }
        matches = v.match(this.emailVariableIncompletePat);

        if (matches) {
          const x = matches[0];
          const xx = x.length < 8 ? x : x.substring(0, 8) + '...';
          return {invalidVariable: `Incomplete variable "${xx}"`};
        }
      }
      return null;
    };
  }
}

