import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FieldPath} from 'firebase/firestore';
import * as CryptoJS from 'crypto-js';
import {FireAuthService} from '../fire-auth.service';
import {FirebaseService} from '../firebase.service';
import {AlertController} from '@ionic/angular';
import {Colleague, StoreInfo, UserAccess} from '../../models-old/datastructures';
import {superSafeURLEncode} from '../../utils-old/formatting';

@Injectable({
  providedIn: 'root'
})
export class ColleaguesService {

  private userAccess: UserAccess;

  private colleagues: { users: { [userID: string]: Colleague }; storesUsers: { [storeID: string]: string[] } };
  private stores: { stores: { [p: string]: StoreInfo }; order: string[] };
  private trimmedAccess: { [userID: string]: UserAccess };
  private outStandingLinksBS: BehaviorSubject<{ ts: Date; storeID: string; expired: boolean }[]>
    = new BehaviorSubject(null);

  private infoPacketBS: BehaviorSubject<{
    colleagues: { users: { [userID: string]: Colleague }; storesUsers: { [storeID: string]: string[] } };
    stores: { stores: { [p: string]: StoreInfo }; order: string[] };
    userAccess: { [userID: string]: UserAccess };
  }> = new BehaviorSubject({
    colleagues: {users: {}, storesUsers: {}}, stores: {stores: {}, order: []}, userAccess: {}
  });
  private storesSub: Subscription;
  private canEdit: { [storeID: string]: boolean } = {};

  constructor(
    private af: AngularFirestore,
    private fbService: FirebaseService,
    private fireAuth: FireAuthService,
    private alertControl: AlertController,
  ) {
    let sentLinksSub: Subscription;
    this.fireAuth.userAccess.subscribe(ua => {
      this.userAccess = ua;

      if (ua) {
        const canEdit = {};
        ua.storeList.forEach((storeID) => {
          if (this.fireAuth.hasAccess(storeID, {ruleID: 'a.'}) === true) {
            canEdit[storeID] = true;
          }
        });
        const accessChanged = (''+(Object.keys(canEdit).sort())) !== (''+(Object.keys(this.canEdit).sort()));
        this.canEdit = canEdit;
        console.log('.>>>>>');
        console.log(this.canEdit);
        this.setInfoPacket();

        if (accessChanged) {
          if (sentLinksSub) { sentLinksSub.unsubscribe(); }
          sentLinksSub = this.af.collection('/signup_links', (ref) =>
            ref.where('storeID', 'in', Object.keys(this.canEdit))
          ).valueChanges().subscribe((vcs) => {
            const now = new Date();
            this.outStandingLinksBS.next(vcs.map((vc) => {
              const doc = vc as { userID: string; storeID: string; ts: Date; secret: string };
              doc.ts = (doc.ts as any).toDate();
              return { ts: doc.ts, storeID: doc.storeID, expired: (now.getTime() - doc.ts.getTime()) / 3600000 > 12};
            }).sort((a, b) => a.ts.getTime() < b.ts.getTime() ? 1 : -1));
          });
        }
      }
    });
    this.fbService.stores.subscribe((obj) => {
      this.stores = obj;
      this.setInfoPacket();
    });
    this.fbService.colleagues.subscribe((colleagues) => {
      this.colleagues = colleagues;
      this.setInfoPacket();
    });
    // allowedEdit(storeID)
    // this.af.collection('/signup_links', (ref) => {
    //   ref.where('storeID')
    // });
  }

  get packetInfoObs(): Observable<{
    colleagues: {users: { [userID: string]: Colleague }; storesUsers: { [storeID: string]: string[] }};
    stores: { stores: { [p: string]: StoreInfo }; order: string[] };
    userAccess: { [userID: string]: UserAccess };
  }> {
    return this.infoPacketBS.asObservable();
  }

  get access(): UserAccess {
    return this.userAccess;
  }

  get outstandingLinks(): Observable<{ ts: Date; storeID: string; expired: boolean }[]> {
    return this.outStandingLinksBS.asObservable();
  }

  allowedEdit(storeID: string) {
    return !!this.canEdit[storeID];
  }

  updateUserAccess(
    titles: { [storeID: string]: { [userID: string]: string } },
    access: { [userID: string]: { storeID: string; access: number }[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      let batch = this.af.firestore.batch();
      let count = 0;

      for (const storeID of Object.keys(titles)) {
        const doc = this.af.firestore.doc(`/stores/${storeID}/store_users/user_titles`);
        const serialized: any[] = [doc];

        for (const userID of Object.keys(titles[storeID])) {
          serialized.push(new FieldPath(userID, 'title'), titles[storeID][userID]);
        }
        batch.update.apply(batch, serialized);
        count++;

        if (count === 499) {
          await batch.commit();
          batch = this.af.firestore.batch();
          count = 0;
        }
      }
      const ts = new Date();

      for (const userID of Object.keys(access)) {
        for (const acc of access[userID]) {
          const docRef = this.af.collection(`user_access/${userID}/access_alterations`).doc().ref;
          const data: {storeID: string; access: number; ts?: Date} = acc;
          data.ts = ts;
          batch.set(docRef, data);
          count++;

          if (count === 499) {
            await batch.commit();
            batch = this.af.firestore.batch();
            count = 0;
          }
        }
      }

      if (count > 0) {
        await batch.commit();
      }
      resolve();
    });
  }

  async createInviteLink(storeID: string, pwd: string): Promise<string> {
    const {id} = await this.fbService.userObj.pipe(take(1)).toPromise();
    pwd = CryptoJS.SHA256(pwd).toString();
    const secret = CryptoJS.lib.WordArray.random(16).toString();
    const internalSecret = CryptoJS.lib.WordArray.random(16).toString();
    // noinspection TypeScriptValidateJSTypes
    const encrypted = CryptoJS.AES.encrypt(secret, pwd).toString();
    // noinspection TypeScriptValidateJSTypes
    const encryptedIS = CryptoJS.AES.encrypt(internalSecret, pwd).toString();
    const ts = new Date();
    let pos = 0;

    try {
      await this.af.doc(`/signup_links/${secret}`).set({userID: id, storeID, ts, secret: encryptedIS});
      pos++;
      await this.af.doc(`/signup_links/${secret}/link_use/signup`).set({secret: internalSecret, ts});
      return `https://www.manage-it.co.za/signup;secret=${superSafeURLEncode(encrypted)}`;
    } catch (error) {
      error.message = `Unable to create invite link${pos === 0 ? '' : ' signup document'}.\n${error.message}`;
      throw error;
    }
  }

  async resetPwd(userID) {
    const storeID = Object.keys(this.colleagues.users[userID].stores).find((sID) =>
      this.fireAuth.hasAccess(sID, {ruleID: 'a.'}) === true);

    if (!storeID) { return; }
    const ac = await this.alertControl.create({header: 'Send Reset Email?', subHeader: 'A password reset email will ' +
        `be sent to ${userID}.`, message: 'The user can follow the instructions, and link, in the email to reset ' +
        'their password.<br><br>Do you want to continue?', cssClass: 'custom-alert',
      buttons: ['No', {text: 'Yes', role: 'y'}]});
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      await this.fireAuth.resetPwd(userID, storeID);
    }
  }

  private setInfoPacket() {
    if (this.stores && this.stores.order.length) {
      if (this.storesSub) {
        this.storesSub.unsubscribe();
      }
      this.storesSub = this.af.collection('/user_access/', (ref) => ref.where(
        'storeList', 'array-contains-any', this.stores.order
      )).valueChanges({idField: 'userID'}).subscribe((docs) => {
        this.trimmedAccess = {};
        docs.forEach(doc => {
          const userID = doc.userID;
          delete doc.userID;
          const access = doc as any as UserAccess;
          access.storeList.forEach(storeID => {
            if (!this.stores.stores.hasOwnProperty(storeID) || !this.canEdit[storeID]) {
              if (access.stores && access.stores[storeID]) {
                delete access.stores[storeID];
              }
            }
          });
          delete access.storeList;
          this.trimmedAccess[userID] = access;
        });

        if (this.colleagues && this.stores && this.trimmedAccess) {
          this.infoPacketBS.next({
            colleagues: this.colleagues, stores: this.stores, userAccess: this.trimmedAccess
          });
        }
      });
    }
  }
}
