import {createAction, props} from '@ngrx/store';
import {IUser} from '../../../shared-utilities/models-old/user/IUser';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {StoreObject} from '../../../shared-utilities/models-old/store/store-object';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {RouterEvent} from '@angular/router';
import {UserAccess} from '../../../shared-utilities/models-old/datastructures';

// ====================================================================================================
// Get User
// ====================================================================================================
export const getUser = createAction(
  '[Core][User] Get User'
);

export const getUserSuccess = createAction(
  '[Core][User] Get User Success',
  props<{ user: IUser }>()
);

export const getUserFailure = createAction(
  '[Core][User] Get User Failure',
  props<{ error: IError }>()
);

export const setIsUserLoading = createAction(
  '[Core][User] Set Is User Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Get User Access
// ====================================================================================================
export const getUserAccess = createAction(
  '[Core][User] Get User Access'
);

export const getUserAccessSuccess = createAction(
  '[Core][User] Get User Access Success',
  props<{ userAccess: UserAccess }>()
);

export const getUserAccessFailure = createAction(
  '[Core][User] Get User Access Failure',
  props<{ error: IError }>()
);

export const setIsUserAccessLoading = createAction(
  '[Core][User] Set Is User Access Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set ITA (Is Techo Admin)
// ====================================================================================================
export const setITA = createAction(
  '[Core][Admin] Set ITA',
  props<{ ita: boolean }>()
);

// ====================================================================================================
// Get User Stores
// ====================================================================================================
export const getUserStores = createAction(
  '[Core][User Stores] Get User Stores',
  props<{ pageStoreDocument: string }>()
);

export const getUserStoresSuccess = createAction(
  '[Core][User Stores] Get User Stores Success',
  props<{ storeObject: StoreObject }>()
);

export const getUserStoresFailure = createAction(
  '[Core][User Stores] Get User Stores Failure',
  props<{ error: IError }>()
);

export const setIsUserStoresLoading = createAction(
  '[Core][User Stores] Set Is User Stores Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set User Selected Store
// ====================================================================================================
export const setUserSelectedStore = createAction(
  '[Core][User Stores] Set User Selected Store',
  props<{ selectedStore: IStore }>()
);

// ====================================================================================================
// Set User Last Visited Page
// ====================================================================================================
export const setCurrentPageAndTab = createAction(
  '[Core][Navigation] Set User Last Visited Page',
  props<{ currentSelectedPage: INavigationPage }>()
);

// ====================================================================================================
// Set Is Module Loading
// ====================================================================================================
export const setIsModuleLoading = createAction(
  '[Core][Module] Set Is Module Loading',
  props<{ event: RouterEvent; isLoading: boolean; instance: string }>()
);

// ====================================================================================================
// Get Image URL
// ====================================================================================================
export const getImageURL = createAction(
  '[Core][Image] Get Image URL',
  props<{ path: string, image: string }>()
);

export const getImageURLSuccess = createAction(
  '[Core][Image] Get Image URL Success',
  props<{ url: string, image: string }>()
);

export const getImageURLFailure = createAction(
  '[Core][Image] Get Image URL Failure',
  props<{ error: IError, image: string }>()
);

// ====================================================================================================
// User Messages
// ====================================================================================================
export const getUserMessageCount = createAction(
  '[Core][Messages] Get User Message Count'
);

export const getUserMessageCountSuccess = createAction(
  '[Core][Messages] Get User Message Count Success',
  props<{ messageCount: number }>()
);

export const getUserMessageCountFailure = createAction(
  '[Core][Messages] Get User Message Count Failure',
  props<{ error: IError }>()
);

export const setIsUserMessageCountLoading = createAction(
  '[Core][Messages] Set Is User Message Count Loading',
  props<{ isLoading: boolean }>()
);
