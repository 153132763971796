import {Component, Input, OnInit} from '@angular/core';
import {
    CellFocusedEvent,
    CellKeyDownEvent,
    ColDef,
    Column,
    GridApi,
    GridOptions,
    GridReadyEvent,
} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
    gridColumnTypes,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/column-types/column-types';
import {MenuUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/menu-utils';
import {HeaderMenuColumnData} from '../../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {
    keyboardBindings,
    KeyboardBindingsAvailable,
} from '../../../../shared-utilities/utils-old/shared-utils-old/key-codes';
import {GridNavigationUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-navigation-utils';
import {GridPreviousEditedCell} from '../../../../shared-utilities/models-old/ngp-report-grid/grid';
import {Store} from '@ngrx/store';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {selectSharedGridVisibleFieldsByCurrentPage, selectTableNavSettings} from '../../store/shared-grid.selectors';
import {Observable} from 'rxjs';
import {TableNavSettings} from '../../../../shared-utilities/models-old/datastructures';
import {IAgGridExportSettings} from '../../models/ag-grid-export-settings';
import {selectNgpReportAtStockID} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {
    updateSingleNGPReportWithStoreId,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {
    selectCurrentPageAndTab,
    selectSelectedUserStores,
} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {INavigationPage} from '../../../../shared-utilities/models-old/page/page';

@Component({
    selector: 'app-shared-grid',
    templateUrl: './shared-grid.component.html',
    styleUrls: ['./shared-grid.component.scss'],
})
export class SharedGridComponent implements OnInit {

    @Input() isLoading: boolean;
    gridApi: GridApi;
    rowData: NGPReport[] = [];
    allData: NGPReport[] = [];
    colDefs: ColDef[] = [];
    colDefsDefaults: ColDef = {};
    gridOptions: GridOptions = {};
    columnTypes = gridColumnTypes;
    tableNavSettings: TableNavSettings = {tabEnd: null, enterEnd: null};
    currentStore: IStore;
    currentPage: INavigationPage;
    private onGridReadyCallback: (gridReadyEvent: GridReadyEvent) => void;
    private previousEditedCell: GridPreviousEditedCell;
    private visibleFields$: Observable<{ [p: string]: boolean }>;
    private tableNavSettings$: Observable<TableNavSettings>;
    private currentStore$: Observable<IStore>;
    private currentPage$: Observable<{ currentSelectedPage: INavigationPage }>;

    constructor(
        private readonly store: Store,
    ) {
    }

    get overlayTemplate(): string {
        let template = '';
        if (this.isLoading) {
            template = '<span class="ag-overlay-no-rows-center"><ion-spinner name="dots"></ion-spinner></span>';
        } else {
            template = '<span class="ag-overlay-no-rows-center">No Items To Display</span>';
        }
        return template;
    }

    @Input() set setColDefs(columns: ColDef[]) {
        this.colDefs = GridUtils.verifyArrayDataExists<ColDef>(columns);
        if (this.gridApi) {
            this.gridApi.setGridOption('columnDefs', [...this.colDefs]);
            this.gridApi.refreshHeader();
        }
    }

    @Input() set setColDefDefaults(columns: ColDef) {
        this.colDefsDefaults = columns;
    }

    @Input() set setRowData(data: NGPReport[]) {
        this.rowData = [...GridUtils.verifyArrayDataExists(data)];
        this.allData = [...GridUtils.verifyArrayDataExists(data)];
    }

    @Input() set setGridOptions(options: GridOptions) {
        this.gridOptions = {
            ...options,
            onCellKeyDown: (event: CellKeyDownEvent): void => {
                this.onCellKeyDownCustom(event);
            },
            onCellFocused: (event: CellFocusedEvent): void => {
                this.onCellFocusedCustom(event);
            },
        };
    }

    @Input() set setOnGridReady(callback: () => void) {
        this.onGridReadyCallback = callback;
    }

    @Input() set setUpdateColumns(headerMenuData: HeaderMenuColumnData) {
        if (this.colDefs?.length > 0 && this.gridApi && headerMenuData) {
            this.switchMenuAction(headerMenuData);
        }
    }

    @Input() set setExportToCSVSettings(exportSettings: IAgGridExportSettings) {
        if (exportSettings && this.gridApi) {
            this.gridApi.exportDataAsCsv(exportSettings);
        }
    }

    /**
     * A function that is triggered by AG Grid when the grid is ready and loaded.
     *
     * @member {GridReadyEvent} gridReadyEvent The data object the grid returns when it is ready and loaded.
     */
    onGridReady(gridReadyEvent: GridReadyEvent): void {
        this.gridApi = gridReadyEvent.api;
        if (this.onGridReadyCallback) {
            this.onGridReadyCallback(gridReadyEvent);
        }
        gridReadyEvent.api.showLoadingOverlay();
    }

    ngOnInit(): void {
        this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
        this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
            this.setVisibleFields(visibleFields);
        });
        this.tableNavSettings$ = this.store.select(selectTableNavSettings);
        this.tableNavSettings$.subscribe((tableNavSettings: TableNavSettings) => {
            this.tableNavSettings = tableNavSettings;
        });
        this.currentStore$ = this.store.select(selectSelectedUserStores);
        this.currentStore$.subscribe((selectedUserStores: IStore) => {
            this.currentStore = selectedUserStores;
        });

        this.currentPage$ = this.store.select(selectCurrentPageAndTab);
        this.currentPage$.subscribe((currentPage: { currentSelectedPage: INavigationPage }) => {
            this.currentPage = currentPage.currentSelectedPage;
        });
    }

    setVisibleFields(visibleFields: { [p: string]: boolean }): void {
        let actionOccurred = false;
        Object.keys(visibleFields).forEach((key: string) => {
            const hideIndex = this.colDefs.findIndex((c: ColDef) => c.field === key);
            if (hideIndex > -1) {
                this.colDefs[hideIndex] = {...this.colDefs[hideIndex]};
                this.colDefs[hideIndex].hide = !visibleFields[key];
                actionOccurred = true;
            }
        });
        if (actionOccurred) {
            this.gridApi.setGridOption('columnDefs', this.colDefs);
        }
    }

    private onCellKeyDownCustom(cellKeyDownEvent: CellKeyDownEvent): void {
        const gridApi: GridApi = cellKeyDownEvent.api;
        switch ((cellKeyDownEvent.event as KeyboardEvent).key) {
            case keyboardBindings[KeyboardBindingsAvailable.enter].code:
                this.handleEnterKeyDown(gridApi, cellKeyDownEvent);
                break;
            case keyboardBindings[KeyboardBindingsAvailable.tab].code:
                this.handleTabKeyDown(gridApi, cellKeyDownEvent);
                break;
            default:
                break;
        }
    }

    private navigateToNextEnabledCell(
        cellKeyDownEvent: CellKeyDownEvent,
        gridApi: GridApi,
        navigationFn: Function | null,
        defaultNavigationFn: () => void,
    ): void {
        const column: Column | string = cellKeyDownEvent.column;
        let rowIndex = cellKeyDownEvent.rowIndex;

        const maxIterations = 50; // Prevent infinite loops
        let iterations = 0;
        let foundNextEnabledCell = false;

        while (iterations < maxIterations) {
            const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);

            if (rowNode && rowIndex !== cellKeyDownEvent.rowIndex && !rowNode.data.disabled) {
                foundNextEnabledCell = true;
                break;
            }
            rowIndex++;
            iterations++;
        }
        const isLastRowInPage = rowIndex > (gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1)) - 1;
        if (foundNextEnabledCell && !isLastRowInPage) {
            gridApi.startEditingCell({
                rowIndex,
                colKey: column.getColId(),
            });


        } else {
            const isLastCellInColumn = GridNavigationUtils.isLastVisibleCellInColumn(gridApi, cellKeyDownEvent);

            if (isLastCellInColumn || isLastRowInPage) {
                if (navigationFn) {
                    this.previousEditedCell = navigationFn(gridApi, cellKeyDownEvent, this.previousEditedCell);

                } else {
                    // Jump to the first field on the current page if no navigation function provided
                    this.previousEditedCell = GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage(
                        gridApi,
                        cellKeyDownEvent,
                        this.previousEditedCell,
                    );
                }
            } else {
                defaultNavigationFn();
            }
        }

        if (rowIndex - 1 === (gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1)) - 1) {
            this.previousEditedCell.rowIndex--;
            this.previousEditedCell = navigationFn(gridApi, cellKeyDownEvent, this.previousEditedCell);
            gridApi.startEditingCell({
                rowIndex,
                colKey: column.getColId(),
            });
        }

        if (iterations >= maxIterations) {
            console.warn('Reached maximum iterations while navigating to the next enabled cell.');
        }
    }

    private handleEnterKeyDown(gridApi: GridApi, cellKeyDownEvent: CellKeyDownEvent): void {


        const enterEndWithRegex = this.tableNavSettings.enterEnd.replace(/-/g, '_');
        // Define navigation rules based on the enter key behavior
        const navigationHandlers: { [key: string]: Function } = {
            restart_page: GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage,
            restart_column: GridNavigationUtils.restartAtTheTopOfTheColumn,
            next_column: GridNavigationUtils.startTheNextColumn,
            next_page: GridNavigationUtils.goToFirstFieldOnNextPage,
            next_page_same_column: GridNavigationUtils.continueTheColumnOnNextPage,
        };

        if (this.currentPage.currentPage === 'ngp-report') {
            let mostRecentNgpItem: NGPReport;
            this.store.select(selectNgpReportAtStockID(cellKeyDownEvent.node.data.stockID as string))
              .subscribe((ngp: NGPReport) => {
                mostRecentNgpItem = ngp;
            });
            const updatedNgpItem: NGPReport = {
                ...mostRecentNgpItem, isSelected: true,
            };
            this.store.dispatch(updateSingleNGPReportWithStoreId({
                ngpReport: updatedNgpItem,
                storeID: this.currentStore.storeID,
            }));
        }

        setTimeout(() => {
            const navigationFn = navigationHandlers[enterEndWithRegex] || null;
            this.navigateToNextEnabledCell(
                cellKeyDownEvent,
                gridApi,
                navigationFn,
                () => {
                    this.previousEditedCell = GridNavigationUtils.gridNavigateToNextRowCell(
                        gridApi,
                        cellKeyDownEvent,
                        this.previousEditedCell,
                    );
                },
            );
        }, 0);
    }

    private handleTabKeyDown(gridApi: GridApi, cellKeyDownEvent: CellKeyDownEvent): void {
        const tabEndWithRegex = this.tableNavSettings.tabEnd.replace(/-/g, '_');
        const navigationHandlers: { [key: string]: Function } = {
            restart_page: GridNavigationUtils.jumpBackToFirstFieldOnCurrentPage,
            next_page: GridNavigationUtils.goToFirstFieldOnNextPage,
        };
        const navigationFn = navigationHandlers[tabEndWithRegex] || null;
        this.navigateToNextEnabledCell(
            cellKeyDownEvent,
            gridApi,
            navigationFn,
            () => {
                this.previousEditedCell = GridNavigationUtils.gridNavigateToNextRowCell(
                    gridApi,
                    cellKeyDownEvent,
                    this.previousEditedCell,
                );
            },
        );
    }

    /**
     * A method to handle custom on cell focused events for the grid.
     *
     * @param cellFocusedEvent An on cell focused triggered event.
     */
    private onCellFocusedCustom(cellFocusedEvent: CellFocusedEvent): void {
        const column: Column | string = cellFocusedEvent.column;
        let rowIndex = cellFocusedEvent.rowIndex;
        // Check if the previous edited cell matches the current one to skip re-editing
        if (this.previousEditedCell) {
            const rowIndexMatch = this.previousEditedCell.rowIndex === rowIndex;
            const colIndexMatch = this.previousEditedCell.columnIndex === (column as Column).getColId();
            if (rowIndexMatch && colIndexMatch) {
                // This cell was just edited, skip re-editing
                return;
            }
        }
    }

    /**
     * A method that is used to perform actions on the custom header menu within the grid.
     *
     * @param menuAction A menu action that is triggered on the column header menu.
     */
    private switchMenuAction(menuAction: HeaderMenuColumnData): void {
        let actionOccurred = false;
        Object.keys(menuAction).forEach((key: string) => {
            const columnData = menuAction[key] as HeaderMenuColumnData;
            switch (columnData?.isEditing) {
                case true:
                    const editStartIndex = this.colDefs.findIndex((c: ColDef) => c.field === key);
                    if (editStartIndex > -1) {
                        this.colDefs[editStartIndex] = MenuUtils.onMenuActionEdit(this.colDefs[editStartIndex], true);
                        actionOccurred = true;
                    }
                    break;
                case false:
                    const editStopIndex = this.colDefs.findIndex((c) => c.field === key);
                    if (editStopIndex > -1) {
                        this.colDefs[editStopIndex] = MenuUtils.onMenuActionEdit(this.colDefs[editStopIndex], false);
                        actionOccurred = true;
                    }
                    break;
                default:
                    break;
            }

            switch (columnData?.sortType) {
                case 'sort-asc':
                case 'sort-desc':
                case 'sort-unsort':
                    const sortType = columnData?.sortType.substring(5);
                    const sort = sortType === 'asc'
                        ? 'asc'
                        : sortType === 'desc' ? 'desc' : null;
                    this.gridApi.resetColumnState();
                    this.gridApi.applyColumnState({
                        state: [{colId: key, sort}],
                        applyOrder: false, // This prevents columns moving due to sorting
                    });
                    break;
                default:
                    break;
            }
        });

        if (actionOccurred) {
            this.gridApi.setGridOption('columnDefs', this.colDefs);
            // this.gridApi.refreshHeader(); // Switched off to make button states last without state management
        }
    }
}
