import {SharedLoadingIndicatorComponent} from './shared-loading-indicator/shared-loading-indicator.component';
import {SharedModalBasicComponent} from './shared-modal-basic/shared-modal-basic.component';

export const SHARED_LOADING_INDICATOR = SharedLoadingIndicatorComponent;
export const SHARED_MODAL_BASIC = SharedModalBasicComponent;

export const SHARED_COMPONENTS = [
  SHARED_LOADING_INDICATOR,
  SHARED_MODAL_BASIC
]
