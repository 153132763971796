import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { FireAuthService } from '../../services-old/fire-auth.service';
import { AlertController } from '@ionic/angular';
import {pageRules, RuleHumanID, RuleInfo} from '../../models-old/utils-old/rule-structure';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {

  private storeList: string[] = null;

  constructor(
    private router: Router,
    private auth: FireAuthService,
    private alertControl: AlertController,
  ) {
    this.auth.userAccess.subscribe((ua => this.storeList = ua ? ua.storeList : null));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const page = state.url.substr(1);
    return this.validate(page);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('segment', segments);
    const page = segments ? segments[0].path : null;
    console.log(page);
    return this.validate(page);
  }

  private async validate(page: string): Promise<boolean | UrlTree> {
    // TODO: -------------------------------------------------------------------
    //  **                    Surly there is a better way?                    **
    //  ------------------------------------------------------------------------
    const checks = pageRules(page);

    if (checks !== false && checks.length === 0) {
      return true;
    }
    await this.waitForInitialisation();

    if (this.storeList === null) {
      return false;
    }

    if (checks === false) {
      return false;
    }
    const failed: RuleInfo[] = [];

    for (const ruleID of checks) {
      let allowed: true | RuleInfo;

      for (const storeID of this.storeList) {
        allowed = this.auth.hasAccess(storeID, {ruleID});

        if (allowed === true) {
          break;
        }
      }

      if (allowed !== true) {
        failed.push(allowed);
      }
    }

    if (failed.length === 0) {
      return true;
    }
    let failures = '';
    failed.forEach((rule) => failures += `${rule.humanID} ${rule.text}<br>`);
    const ac = await this.alertControl.create({
      header: 'Insufficient Access', subHeader: 'You do not have permission to view this page.', message: 'You require ' +
        'the following user access permissions:<br><br>' + failures, cssClass: ['custom-alert', 'error'],
      buttons: ['ok'], backdropDismiss: false
    });
    await ac.present();
    return new UrlTree();
  }

  private async waitForInitialisation() {
    if (this.storeList === null) {
      const ac = await this.alertControl.create({
        header: 'Validating User Authorisation', subHeader: 'Please wait...', cssClass: 'custom-alert',
        backdropDismiss: false,
      });
      await ac.present();

      for (let i = 0; i < 4; i++) {
        await new Promise<void>((resolve) => setTimeout(resolve, 500));

        if (this.storeList !== null) {
          break;
        }
      }
      ac.dismiss().then();
    }
  }
}
