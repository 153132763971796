import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  CollectionReference,
  DocumentData, DocumentSnapshot,
  Query
} from '@angular/fire/compat/firestore';
import {FieldPath} from 'firebase/firestore';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {FireAuthService} from './fire-auth.service';
import {map, mergeMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';

import {arrayRemove, arrayUnion, deleteField, documentId, FieldValue, serverTimestamp} from '@angular/fire/firestore';

import * as firebase from 'firebase/compat';

import {
  AOScheduleElement,
  AOSettings,
  ApiLogObj,
  ApiLogObj2,
  castStockItem,
  Colleague,
  DepSales,
  DepSales2,
  DepSalesHistory,
  Feature,
  FormPost,
  FormPostPoll,
  GenAOData,
  Message,
  MsgType,
  PriceBand,
  RawStockItem,
  sItemKeyToInt,
  StockItem,
  StockItemUpdateObj,
  StockValChangeCheckConfig,
  StockValuesChangeCheckResult,
  StoreInfo,
  Supplier,
  UserAccess
} from '../models-old/datastructures';
import {
  PageAlertComponent
} from '../../features-as-modules/feature-admin-internal/components/page-alert/page-alert.component';
import {ForceReloadService} from './force-reload.service';
import {pageRules, RuleHumanID} from '../models-old/utils-old/rule-structure';
import {dateDelta} from '../functions-old/date-functions';
import {
  AOPartSubmissions,
  AutoOrder,
  AutoOrderCollection,
  UnspecifiedAutoOrderCollection
} from '../models-old/auto-ordering/ao-datastructures';
import {StockFunctions} from '../functions-old/stock-functions';
import {ObjectUtils} from '../utils-old/shared-utils-old/object-utils';
import {IUser} from '../models-old/user/IUser';
import {
  StockValChangeFlagsComponent
} from '../../shared-modules/shared-module/components/stock-val-change-flags/stock-val-change-flags.component';
import {IStore} from '../models-old/store/IStore';
import {StoreObject} from '../models-old/store/store-object';
import {IPriceBand, IPriceBandPerStore} from '../../shared/shared-components/models/price-banding/price-band';
import {IFirebaseQuery} from '../../shared/shared-components/models/firebase/firebase-queries';
import {castObjectToType} from '../../shared/shared-components/utils/object/object.utils';
import {IDepartment, ISubDepartment} from '../../shared/shared-components/models/stock/departments';

export const DOCUMENT_ID = documentId;
export const DELETE_FILED = deleteField;
export const SERVER_TIMESTAMP = serverTimestamp;
export const ARRAY_UNION = arrayUnion;
export const ARRAY_REMOVE = arrayRemove;
// TODO: Gross. Find a better way
export const FIELD_PATH = (path: string, toString: boolean = true): string | FieldPath =>
  toString ? `\`${path}\`` : new FieldPath(path);

export type Timestamp = firebase.default.firestore.Timestamp;

export interface FbQuery {
  q: 'where' | 'orderBy' | 'limit' | 'startAt' | 'startAfter' | 'endAt' | 'endBefore';
  p: any[];
}

export interface AOPartReady {
  userID: string;
  supp: string;
  executed: Date;
  scheduled?: boolean;
  items: { [code: string]: GenAOData };
  notMine?: boolean;
  new?: true | 'PERIOD-2';
  aoSettingsElement?: Omit<AOScheduleElement, 'rank' | 'userID'>;
}

export interface AOPartPrepared {
  userID: string;
  ts: Date;
  status?: 'SUBMITTED' | 'FAILED';
  originalUserID?: string;
  quantities: { [code: string]: { qty: number; price?: number } };
  comment?: string;
  total?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private userID: string;
  private userAccess: UserAccess = {} as UserAccess;
  private lastMsgTS: Date = null;

  private sVChangeCheckConfigs: { [storeID: string]: StockValChangeCheckConfig } = {};

  private lastSeenRefreshTask;

  private readonly userObjSubject: BehaviorSubject<IUser>;

  // Todo: Convert this to IStore
  private readonly storesMapObs: BehaviorSubject<{ stores: { [storeID: string]: StoreInfo }; order: string[] }>;

  // TODO: Dont forget about this
  private readonly keyValSettingsObjSubject: BehaviorSubject<{ linkDep: boolean }>;

  private readonly storeDataSubs: { [storeID: string]: { [doc: string]: Observable<any> } } = {};

  private readonly colleaguesBS: BehaviorSubject<{
    users: { [userID: string]: Colleague };
    storesUsers: { [storeID: string]: string[] };
  }>;

  // Manage_IT
  private salesDepSumObs: {
    [storeID: string]: Observable<{ store: string; data: { [department: string]: DepSales }; time: Date }>;
  } = {};

  // Auto Ordering

  constructor(
    private fireAuth: FireAuthService,
    private forceReload: ForceReloadService,
    private af: AngularFirestore,
    private storage: AngularFireStorage,
    private router: Router,
    private modalController: ModalController,
    private alertControl: AlertController,
  ) {
    let userAccessSub: Subscription;
    this.storesMapObs = new BehaviorSubject<
      { stores: { [storeID: string]: StoreInfo }; order: string[] }
    >({stores: {}, order: []});
    this.colleaguesBS = new BehaviorSubject({users: {}, storesUsers: {}});

    this.userObjSubject = new BehaviorSubject<IUser>(null);
    const colleagues: { [userID: string]: Colleague } = {};

    this.fireAuth.userIDSub.subscribe(userID => {
      console.log('USER: ' + userID);
      if (userID) {

        if (this.userID && this.userID !== userID) {
          userAccessSub.unsubscribe();
        }

        if (this.userID !== userID) {

          if (this.userID) {
            this.router.navigate(['home']).then();
          }
          this.userID = userID;

          userAccessSub = this.af.doc(`user_access/${userID}`).valueChanges().subscribe(data => {
            this.userAccess = data as UserAccess;

            if (this.userAccess.storeList && this.userAccess.storeList.length > 0) {
              this.af.collection('stores', (ref) => ref.where(DOCUMENT_ID(), 'in', this.userAccess.storeList))
                .get().toPromise().then(qs => {
                  const storesMap = {};
                  const storesOrder: string[] = qs.docs.map(doc => {
                    const storeID = doc.id;
                    storesMap[storeID] = doc.data() as StoreInfo;

                    // TODO: TEMPORARY DEFAULT
                    this.getStoreEditFlagConfig(storeID).then();

                    return storeID;
                  });
                  storesOrder.sort((a, b) => storesMap[a].name < storesMap[b].name ? -1 : 1);
                  this.storesMapObs.next({stores: storesMap, order: storesOrder});
                }
              );

              // // Get store info
              // const storesMap = {};
              // let storesOrder: string[] = [];
              //
              // for (const storeID of Object.keys(this.userAccess.stores)) {
              //   this.af.doc(`stores/${storeID}`).get().toPromise().then(doc => {
              //     storesMap[storeID] = doc.data() as StoreInfo;
              //     storesOrder = storesOrder.concat(storeID);
              //     storesOrder.sort((a, b) => storesMap[a].name<storesMap[b].name?-1:1);
              //     // TODO: Is this really the best way to do it? Stores trickle through one by one?
              //     this.storesMapObs.next({stores: storesMap, order: storesOrder});
              //   });
              // }
              // Get colleagues
              // ---------------------------
              if (Object.keys(this.userAccess.stores).length) {
                const cg = this.af.collectionGroup('store_users', ref =>
                  ref.where('storeID', 'in', this.userAccess.storeList)).valueChanges().subscribe(snap => {
                  const storesUsers: { [storeID: string]: string[] } = {};
                  snap.map((doc: { [userID: string]: { userName: string; title?: string }; storeID: string | any }) => {
                    const storeID = doc.storeID;
                    delete doc.storeID;
                    delete doc[userID];
                    storesUsers[storeID] = Object.keys(doc).sort();

                    storesUsers[storeID].forEach(uID => {
                      if (uID !== this.userID) {
                        if (!colleagues.hasOwnProperty(uID)) {
                          colleagues[uID] = {name: doc[uID].userName, stores: {}};
                        }
                        colleagues[uID].stores[storeID] = doc[uID].title;
                      }
                    });
                  });
                  // Remnant of older queries, leaving for now. Current store user queries isn't great either
                  const needsName: string[] = [];

                  // for (const colleagueID of Object.keys(colleagues)) {
                  //   if (colleagues[colleagueID].name === undefined) {
                  //     needsName.push(colleagueID);
                  //   }
                  // }

                  if (needsName.length) {
                    const promises: Promise<void>[] = [];

                    for (const colleagueID of needsName) {
                      promises.push(this.af.doc(`users/${colleagueID}`).get().toPromise()
                        .then(ds => colleagues[colleagueID].name = (ds.data() as any).userName));
                    }
                    forkJoin(promises).toPromise()
                      .then(() => this.colleaguesBS.next({users: colleagues, storesUsers}))
                      .catch(error => {
                        error.message = `Colleagues subscription error.\n${error.message}`;
                        this.colleaguesBS.error(error);
                      });
                  } else {
                    this.colleaguesBS.next({users: colleagues, storesUsers});
                  }
                }, error => {
                  console.log('Colleagues sub error');
                  throw error;
                });
                this.fireAuth.setLogoutCallback('colleagues', () => {
                  cg.unsubscribe();
                });
              }
              // ---------------------------

              // start online indication
              this.updateLastSeen();

              if (this.lastSeenRefreshTask) {
                clearInterval(this.lastSeenRefreshTask);
              }
              this.lastSeenRefreshTask = setInterval(() => {
                this.updateLastSeen();
              }, 120000);
            }
          }, error => {
            console.log(`\nPoo error\n${error}`);
          });

          const userDocSub = this.af.doc(`users/${userID}`).valueChanges().subscribe(data => {
            const d = data as any;
            this.checkForForcedReload(d);
            this.userObjSubject.next({
              id: this.userID, userName: d.hasOwnProperty('userName') ? d.userName : userID, pp: d.pp
            });
          }, e => {
            console.log(`user document fetch failed ${userID}`);
            throw e;
          });
          this.fireAuth.setLogoutCallback('user-document', () => {
            userDocSub.unsubscribe();
          });

          // TODO: Should really update last seen according to this? Can I? DO IT NOW!
          const visited: string[] = [];

          const pageAlert = (url: string) => {
            url = url.includes('/') ? url.substr(0, url.indexOf('/')) : url;
            visited.push(url);
            this.af.doc(`users/${userID}/page_alerts/${url}`).ref.get().then(d => {
              if (d.exists) {
                const data = d.data() as any;
                this.userObj.pipe(take(1)).toPromise().then((uo: IUser) => {
                  const userName = uo.userName;

                  if (!data.cssClass) {
                    data.cssClass = [];
                  }

                  if (typeof data.cssClass === 'string') {
                    data.cssClass = [data.cssClass];
                  }

                  if (!data.cssClass.includes('page-alert-modal')) {
                    data.cssClass.push('page-alert-modal');
                  }
                  data.cssClass.push('custom-alert');

                  if (!data.backdropDismiss) {
                    data.backdropDismiss = true;
                  }
                  const options: any = {
                    component: PageAlertComponent, componentProps: {options: data, userName},
                    cssClass: data.cssClass, backdropDismiss: data.backdropDismiss,
                  };
                  delete data.cssClass;
                  delete data.backdropDismiss;
                  this.modalController.create(options)
                    .then(mc => mc.present().then(() => mc.onDidDismiss().then(response => {
                      console.log(response);

                      if (response.role === 'DELETE') {
                        this.af.doc(`users/${userID}/page_alerts/${url}`).delete().then();
                      }
                    })));
                });
              }
            });
          };
          router.events.subscribe(obs => {
            if (obs.hasOwnProperty('urlAfterRedirects') && !obs.hasOwnProperty('state')) {
              const url = ((obs as any).urlAfterRedirects as string).replace('/', '--');

              if (!visited.includes(url)) {
                pageAlert(url);
              }
            }
          });

          setTimeout(() => {
            const url = this.router.url.replace('/', '--');
            pageAlert(url);
          }, 1500);
        }
      } else if (userAccessSub) {
        this.router.navigate(['login']).then();
        userAccessSub.unsubscribe();
      }
    }, error => console.log('user timing error\n' + error));
  }

  get userObj(): Observable<IUser> {
    return this.userObjSubject.asObservable();
  }

  get stores(): Observable<{ stores: { [id: string]: StoreInfo }; order: string[] }> {
    return this.storesMapObs.asObservable();
  }

  get colleagues(): Observable<{
    users: { [userID: string]: Colleague }; storesUsers: { [storeID: string]: string[] };
  }> {
    return this.colleaguesBS.asObservable();
  }

  //TODO check here and look at the service for images in community form update branch
  getImageUrl(path: string): Observable<string> {
    const ref = this.storage.ref(path);
    return ref.getDownloadURL();
  }

  * sliceArrayForFBQueries<T>(values: T[]): Generator<T[]> {
    const MAX_FB_ARRAY_QUERY_SIZE = 30;
    let idx = 0;

    while (idx < values.length) {
      yield values.slice(idx, idx + MAX_FB_ARRAY_QUERY_SIZE);
      idx += MAX_FB_ARRAY_QUERY_SIZE;
    }
  }

  async subColleaguesAccess(stores: string | string[]): Promise<Observable<{ [userID: string]: UserAccess }>> {
    const storeList = typeof stores === 'string' ? [stores] : stores;
    return this.af.collection('/user_access/', (ref) =>
      ref.where('storeList', 'array-contains-any', storeList)
    ).valueChanges({idField: 'userID'}).pipe(mergeMap((data) => {
      const ua: { [userID: string]: UserAccess } = {};
      data.forEach((access) => {
        // TODO: Very dangerous that the server user is found here
        // @ts-ignore
        if (access.userID === this.userID || access.isServer) {
          return;
        }
        ua[access.userID] = access as any as UserAccess;
        delete access.userID;
      });
      return [ua];
    }));
  }

  pageStores(page: string): Observable<StoreObject> {
    const rules = pageRules(page);
    return this.stores.pipe(mergeMap((stores) => {
      const filtered: { order: string[]; stores: { [storeID: string]: StoreInfo } } = {order: [], stores: {}};
      filtered.order = stores.order.filter((storeID) => {
        if (rules !== false) {

          for (const rule of rules as RuleHumanID[]) {
            if (this.fireAuth.hasAccess(storeID, {ruleID: rule}) !== true) {
              return false;
            }
          }
          filtered.stores[storeID] = stores.stores[storeID];
          return true;
        }
        return false;
      });
      return [filtered];
    }));
  }

  updateLastSeen() {
    if (!this.userID) {
      return;
    }

    const pathList = this.router.url.split('/');
    let i = 0;

    while (i < pathList.length) {
      if (pathList[i] === '') {
        pathList.splice(i, 1);
      } else {
        i++;
      }
    }
    let feature: string;

    if (pathList.length >= 2) {
      feature = pathList[0] === 'manage-it' ? 'observation' : pathList[0] === 'ao' ? 'operational' : null;
      pathList[0] = feature;
    } else if (pathList[0] === 'home') {
      return;
    } else {
      feature = 'shared';
    }

    // if (pathList.length && pathList[0].includes('ngp')) {
    //   // pathList[0] = 'ngp-report';
    //   //
    //   // TODO, fix the damn paths!!
    //   //
    //   pathList[0] = 'stock';
    // }

    console.log('  >>', pathList);

    if (feature) {
      const batch = this.af.firestore.batch();
      const update = {time: SERVER_TIMESTAMP(), path: pathList};
      const fieldPath = new FieldPath(this.userID);

      for (const store of Object.keys(this.userAccess.stores)) {
        const doc = `${feature}/stores_data/${store}/messages/from_app/last_seen/`;
        batch.update(this.af.doc(doc).ref, fieldPath, update);
      }
      batch.commit().then(() => {
      }).catch(e => {
        console.log('Update Last Seen Error:\n' + e);
        // batch = this.af.firestore.batch();
        //
        // for (const store of Object.keys(this.userAccess.stores)) {
        //   const doc = `${feature}/stores_data/${store}/messages/from_app/last_seen/`;
        //   batch.set(this.af.doc(doc).ref, fieldPath, update);
        // }
        // for (const store of Object.keys(this.userAccess.stores)) {
        //   const doc = `${feature}/stores_data/${store}/messages/from_app/last_seen/`;
        //   batch.update(this.af.doc(doc).ref, fieldPath, update);
        // }
        // batch.commit().then();
      });
    }
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                                    USER PATHS                                                    //
  /* ---------------------------------------------------------------------------------------------------------------- */

  getUSERDepSalesSumSettings(): Promise<{ growthTargets: { [key: string]: number } }> {
    return new Promise<{ growthTargets: { [key: string]: number } }>((resolve, reject) => {
      this.getUserDocument('dep-sales-summary-settings').then(
        doc => resolve(doc.data() as { growthTargets: { [key: string]: number } })
      ).catch(e => {
        e.message = `Get user dep sales summary error.\n${e.message}`;
        reject(e);
      });
    });
  }

  getUserDocument(document?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.userID) {
        if (document) {
          this.af.doc(`users/${this.userID}/singular_documents/${document}`)
            .get().toPromise().then(d => resolve(d.data())).catch(e => {
            e.message = `Error getting user document: ${document}.\n${e.message}`;
            reject(e);
          });
        } else {
          this.af.doc(`users/${this.userID}`).get().toPromise().then(d => resolve(d.data())).catch(e => {
            e.message = `Error getting user document.\n${e.message}`;
            reject(e);
          });
        }
      } else {
        reject(Error('userID not initialised'));
      }
    });
  }

  updateUSERDepSalesSumSettings(updates: { [key: string]: any }): Promise<void> {
    return this.updateUserDocument('dep-sales-summary-settings', updates);
  }

  async getUserPreferences<T>(document: string, defaultFactory?: () => T): Promise<T> {
    try {
      const doc = await this.af.doc(`users/${this.userID}/saved_preferences/${document}`).get().toPromise();
      if (doc.exists) {
        return doc.data() as T | any;
      } else if (defaultFactory) {
        return defaultFactory();
      }
      return undefined;
    } catch (e) {
      e.message = `Error fetching user Preference Document: ${document}\n${e.message}`;
      throw e;
    }
  }

  async updateUserPreferences(document: string, updates: any): Promise<void> {
    try {
      await this.af.doc(`users/${this.userID}/saved_preferences/${document}`).set(updates, {merge: true});
    } catch (e) {
      e.message = `Error updating user Preference Document: ${document}\n${e.message}`;
      throw e;
    }
  }

  updateUserDocument(document: string, updates: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.af.doc(`users/${this.userID}/singular_documents/${document}`).update(updates)
        .then(() => resolve()).catch(_ => {
        //  TODO: Check the document isn't accidentally overwritten
        this.af.doc(`users/${this.userID}/singular_documents/${document}`).set(updates)
          .then(() => resolve())
          .catch(e => {
            e.message = `Error updating user document: ${document}\n${e.message}`;
            reject(e);
          });
      });
    });
  }

  setUserDocument(document: string, updates: any, merge: boolean = false): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.af.doc(`users/${this.userID}/singular_documents/${document}`)
        .set(updates, {merge}).then(() => resolve()).catch(e => {
        e.message = `Error setting user document: ${document}\n${e.message}`;
        reject(e);
      });
    });
  }

  deleteUserDocument(document: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.af.doc(`users/${this.userID}/singular_documents/${document}`)
        .delete().then(() => resolve()).catch(e => {
        e.message = `Error deleting user document: ${document}\n${e.message}`;
        reject(e);
      });
    });
  }

  subMessagesQuery(types: MsgType[], options: {
    from?: Date; to?: Date; success?: boolean; sender?: string; pathValues?: [string, string][];
  } = {}, desc: boolean = true) {
    return new Observable<{ order: string[]; messages: { [id: string]: Message } }>(observer => {
      this.af.collection(`users/${this.userID}/messages`, ref => {
        let qr: firebase.default.firestore.Query = ref;

        if (types.length === 1) {
          qr = qr.where('type', '==', types[0]);
        } else if (types.length > 1) {
          types = [...new Set(types)];

          if (types.length <= 10) {
            qr = qr.where('type', 'in', types);
          } else {
            observer.error(Error('List of types must be at most 10 strings.'));
          }
        }

        if (options.from) {
          qr = qr.where('timestamp', '>=', options.from);
        }
        if (options.to) {
          qr = qr.where('timestamp', '<=', options.to);
        }
        if (options.success !== undefined) {
          qr = qr.where('payload.success', '==', options.success);
        }
        if (options.sender) {
          qr = qr.where('sender', '==', options.sender);
        }

        if (options.pathValues && options.pathValues.length > 0) {
          for (const pv of options.pathValues) {
            qr = qr.where(pv[0], '==', pv[1]);
          }
        }
        qr.orderBy('timestamp', desc ? 'desc' : 'asc');
        return qr;
      }).snapshotChanges().subscribe(docChanges => {
        const messages: { [id: string]: Message } = {};
        const order = docChanges.map(dc => {
          messages[dc.payload.doc.id] = dc.payload.doc.data() as Message;
          messages[dc.payload.doc.id].timestamp = (messages[dc.payload.doc.id].timestamp as any).toDate();
          return dc.payload.doc.id;
        });
        observer.next({order, messages});
      }, error => {
        error.message = `Error in message subscription query\n${error.message}`;
        observer.error(error);
      });
    });
  }

  deleteMessage(id: string): Promise<void> {
    return this.af.doc(`/users/${this.userID}/messages/${id}`).delete();
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                               Single Docs PATHS                                                  //
  \* ---------------------------------------------------------------------------------------------------------------- */

  async getPriceBandingForStores(): Promise<IPriceBandPerStore> {
    const storeIDs: string[] = this.storesMapObs.value.order
      .filter((storeID: string) => this.fireAuth.hasAccess(storeID, {ruleID: 'b.i'}));
    const results: IPriceBandPerStore = {};
    const documentPromises: Promise<void>[] = [];
    storeIDs.forEach((storeID: string): void => {
      documentPromises.push(
        this.getStoreDataDoc('price_bands', storeID, 'shared')
          .then((data: { [index: number]: IPriceBand }): void => {
            if (!data) {
              results[storeID] = null;
            } else {
              results[storeID] = [];
              Object.keys(data).forEach((index: string): void => {
                results[storeID].push(data[index] as IPriceBand);
              });
            }
          })
      );
    });
    await Promise.all(documentPromises);
    return results;
  }

  // Old get price banding - deprecated
  async getPriceBands(): Promise<{ personal?: PriceBand[]; stores: { [storeID: string]: PriceBand[] } }> {
    const storeIDs = this.storesMapObs.value.order;
    storeIDs.filter((sID) => this.fireAuth.hasAccess(sID, {ruleID: 'b.i'}));
    const result: { personal: PriceBand[]; stores: { [storeID: string]: PriceBand[] } } = {personal: null, stores: {}};
    const promises: Promise<void>[] = [];

    // promises.push(new Promise<void>((resolve, reject) =>
    //   this.getUserDocument('price_bands').then((data) => {
    //     if (data) {
    //       result.personal = [];
    //       for (let i = 0; i < Object.keys(data).length; i++) {
    //         result.personal.push(data[i]);
    //       }
    //     }
    //     resolve();
    //   })
    // ));
    storeIDs.forEach((sID) => promises.push(new Promise<void>((resolve, reject) =>
      this.getStoreDataDoc('price_bands', sID, 'shared').then((data) => {
        if (data) {
          result.stores[sID] = [];
          for (let i = 0; i < Object.keys(data).length; i++) {
            result.stores[sID].push(data[i]);
          }
        } else {
          result.stores[sID] = null;
        }
        resolve();
      })
    )));
    await forkJoin(promises).toPromise();
    return result;
  }

  getStoreDataDoc(document: string, storeID: string, feature: Feature = 'shared') {
    return new Promise<any>((resolve, reject) => {
      this.af.doc(`${feature}/stores_data/${storeID}/data/singular_documents/${document}`)
        .get().toPromise().then(doc => resolve(doc.data())).catch(e => {
        e.message = `Error getting store data doc: ${feature} -> ${document}\n${e.message}`;
        reject(e);
      });
    });
  }

  getStoreDataDocTyped<DocType>(document: string, store: IStore, feature: Feature = 'shared'): Promise<DocType> {
    return new Promise<DocType>((resolve, reject): void => {
      this.af.doc(`${feature}/stores_data/${store.storeID}/data/singular_documents/${document}`)
        .get()
        .toPromise()
        .then((doc: DocumentSnapshot<DocType>) => {
          resolve(doc.data())
        }).catch((error): void => {
        error.message = `Error getting store data doc: ${feature} -> ${document}\n${error.message}`;
        reject(error);
      });
    });
  }

  getDepartmentsByStore(store: IStore): Observable<IDepartment[]> {
    const departments$ = this.getStoreDataDocTyped<{ [depCode: string]: IDepartment}>('departments', store);
    const subDepartments$ = this.getStoreDataDocTyped<{ [depCode: string]: ISubDepartment}>('sub_departments', store);
    return forkJoin([departments$, subDepartments$]).pipe(
      map(([departments, subDepartments]) => {
        const deps: IDepartment[] = [];
        const subDepsAll: ISubDepartment[] = [];
        Object.keys(subDepartments).forEach((key: string): void => {
          subDepsAll.push({
            subDep: key,
            dep: subDepartments[key].dep,
            name: subDepartments[key].name,
            targetGP: subDepartments[key].targetGP,
          } as ISubDepartment);
        });
        Object.keys(departments).forEach((key: string) => {
          deps.push({
            dep: key,
            name: departments[key].name,
            subDeps: subDepsAll.filter((dep: ISubDepartment): boolean => dep.dep === key)
          } as IDepartment);
        });
        return deps;
      })
    );
  }

  async getStoreEditFlagConfig(storeID: string): Promise<StockValChangeCheckConfig> {
    const config = await this.getStoreDataDoc('stock_edit_thresholds', storeID);

    if (config) {
      this.sVChangeCheckConfigs[storeID] = config;
    } else {
      this.sVChangeCheckConfigs[storeID] = {
        sellPriIncl1: {
          pct: 0.2, negPct: 0.1, noNeg: false, noZero: false,
        }
      };
    }
    return this.sVChangeCheckConfigs[storeID];
  }

  countStoreCollection(col: string, storeID: string, feature: Feature = 'shared') {
    // this.af.firestore.collection(`${feature}/stores_data/${storeID}/data/${col}`).count
  }

  updateStoreDataDoc(document: string, storeID: string, data: any, feature: Feature = 'shared',
                     set: boolean = false, safeFieldPath: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      const path = `${feature}/stores_data/${storeID}/data/singular_documents/${document}`;
      const doc = !safeFieldPath ? this.af.doc(path) : this.af.firestore.doc(path);
      const update: any[] = [];

      if (safeFieldPath) {
        Object.keys(data).forEach(key => update.push(new FieldPath(key), data[key]));
      }

      if (set) {
        if (!safeFieldPath) {
          doc.set(data).then(() => resolve()).catch(e => {
            e.message = `Error setting store data doc: ${feature} -> ${document}\n${e.message}`;
            reject(e);
          });
        } else {
          doc.set.apply(doc, update).then(() => resolve()).catch(e => {
            e.message = `Error setting store data doc (with FieldPath): ${feature} -> ${document}\n${e.message}`;
            reject(e);
          });
        }
      } else {
        let p = !safeFieldPath ? doc.update(data) : doc.update.apply(doc, update);
        p.then(() => resolve()).catch(e => {
          if (e.name === 'FirebaseError' && e.code === 'not-found') {
            p = !safeFieldPath ? doc.set(data) : doc.set.apply(doc, update);
            p.then(() => resolve()).catch(_ => {
              e.message = `Error updating store data doc after doc was not found ` +
                `${safeFieldPath ? ' (with FieldPath)' : ''}: ${feature} -> ${document}\n${e.message}`;
              reject(e);
            });
          } else {
            e.message = `Error updating store data doc${safeFieldPath ? ' (with FieldPath)' : ''}: ` +
              `${feature} -> ${document}\n${e.message}`;
            reject(e);
          }
        });
      }
    });
  }

  subStoreDataDoc(document: string, storeID: string, feature: Feature = 'shared'): Observable<any> {
    if (!this.storeDataSubs[storeID]) {
      this.storeDataSubs[storeID] = {};
    }

    if (!this.storeDataSubs[storeID][document]) {
      this.storeDataSubs[storeID][document] = new Observable<any>(observer => {
        this.af.doc(`${feature}/stores_data/${storeID}/data/singular_documents/${document}`)
          .valueChanges().subscribe(doc => observer.next(doc), error => {
          error.message = `Error on store data doc subscription: ${feature} -> ${document}\n${error.message}`;
          observer.error(error);
        });
      });
    }
    return this.storeDataSubs[storeID][document];
  }

  async getStoreDoc(docPath: string, storeID: string, feature: Feature = 'shared'): Promise<object> {
    try {
      const doc = await this.af.doc(`${feature}/stores_data/${storeID}/${docPath}`).get().toPromise();
      return doc.data() as object;
    } catch (error) {
      error.message = `Error in getStoreDoc. Error getting store data doc: ${feature} -> ${document}`;
      throw error;
    }
  }

  subStoreDoc(docPath: string, storeID: string, feature: Feature = 'shared'): Observable<unknown> {
    return this.af.doc(`${feature}/stores_data/${storeID}/${docPath}`).valueChanges().pipe(mergeMap((data) => [data]));

    // { observer: Observable<any>; unsub: () => void } {
    // // TODO: Gross, must redo with map
    // let sub: Subscription;
    // const observer = new Observable<any>(obs => {
    //   sub = this.af.doc(`${feature}/stores_data/${storeID}/${docPath}`)
    //     .snapshotChanges().subscribe(
    //       doc => {
    //         obs.next(doc.payload.data());
    //       },
    //       error => {
    //         error.message = `subStoreDoc failed: ${feature} -> ${docPath}\n${error.message}`;
    //         obs.error(error);
    //       });
    // });
    // const unsub = () => {
    //   if (sub) {
    //     sub.unsubscribe();
    //     sub = null;
    //   }
    // };
    // return {observer, unsub};
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                             TEMP SALES STUFF                                                     //
  \* ---------------------------------------------------------------------------------------------------------------- */

  /**
   * Query firebase for sales history of provided store. History can be filtered to fall between two dates and/or
   * belong to 1 to 10 departments. The object returned by the promise (DepSalesHistory) will have redundant copies of
   * the data, byYear and byDep, as nested Map objects. These objects are the exact same data but byYear is prioritizes
   * groupings by date and byDep priorities groupings by department key.
   * E.g. to select the history for department x on date DD-MM-YYYY one can index the data in one of the 2 following
   * ways.
   *    data.byYear.get(YYYY).get(MM).get(DD)[x] or
   *    data.byDep[x].get(YYYY).get(MM).get(DD)
   *
   * If param dayTotals is true there will be a third Map in the data containing the totals of all departments
   *
   * @param storeID - Firebase ID of the store to be queried.
   * @param startDate - Provide a start date for history. History would only go back a max of 5 years.
   * @param endDate - Provide an end date for history.
   * @param departments - 1 to 10 department filters. Department key strings.
   * @param dayTotals - Add a map that provides the totals of all departments for each day.
   * @param fullMonth Ignore the days of the startDate or endDate parameters and instead provide full months. useful
   *  to get a full months' history without having to worry about how many days are in the month. For example the
   *  full history for June 2022 can be retrieved by providing startDate and endDate both being any date in June 2022.
   * @returns Promise<DepSalesHistory>
   */
  salesHist(storeID: string, startDate?: Date, endDate?: Date, departments?: string[] | string,
            dayTotals: boolean = true, fullMonth: boolean = false):
    Promise<DepSalesHistory> {

    if (departments && typeof departments !== 'string' && departments.length > 10) {
      throw new Error('Sales History Query Error. 0 to 10 department keys can be queried. ' +
        'No more then 10 departments allowed.');
    }
    let qStartDate: Date;
    let qEndDate: Date;

    if (startDate) {
      qStartDate = new Date(`${startDate.getFullYear()}-${startDate.getMonth() + 1}-01`);
      qStartDate.setHours(0, 0, 0, 0);
    }

    if (endDate) {
      qEndDate = new Date(`${endDate.getFullYear()}-${endDate.getMonth() + 1}-01`);
      qEndDate.setDate(34);
      qEndDate.setDate(2);
      qEndDate.setHours(0, 0, 0, -1);
    }

    return new Promise<DepSalesHistory>((resolve, reject) => {
      this.af.collection(`observation/stores_data/${storeID}/data/sales_hist`, ref => {
        let reference: any = ref;
        reference = qStartDate ? reference.where('ts', '>=', qStartDate) : reference;
        reference = qEndDate ? reference.where('ts', '<=', qEndDate) : reference;
        reference = departments ?
          reference.where('dep', (typeof departments === 'string' ? '==' : 'in'), departments) : reference;
        return reference;
      }).get().toPromise().then(results => {
        const byYear: Map<number, Map<number, Map<number, { [dep: string]: DepSales2 }>>> = new Map();
        const byDep: { [dep: string]: Map<number, Map<number, Map<number, DepSales2>>> } = {};
        const totals: Map<number, Map<number, Map<number, DepSales2>>> = dayTotals ? new Map() : null;
        results.docs.map(ds => {
          const data = ds.data() as { days: { [day: number]: number[] }; dep: string; ts: Date };
          data.ts = (data.ts as any).toDate();
          const year = data.ts.getFullYear();
          const month = data.ts.getMonth() + 1;

          if (byYear.has(year)) {
            if (!byYear.get(year).has(month)) {
              byYear.get(year).set(month, new Map<number, { [dep: string]: DepSales2 }>());

              if (totals) {
                totals.get(year).set(month, new Map<number, DepSales2>());
              }
            }
          } else {
            byYear.set(year, new Map<number, Map<number, { [dep: string]: DepSales2 }>>());
            byYear.get(year).set(month, new Map<number, { [dep: string]: DepSales2 }>());

            if (totals) {
              totals.set(year, new Map<number, Map<number, DepSales2>>());
              totals.get(year).set(month, new Map<number, DepSales2>());
            }
          }

          for (const day of Object.keys(data.days)) {
            let addDay = fullMonth;

            if (!addDay) {
              const date = new Date(`${year}-${month}-${day}`);
              addDay = (!startDate || date >= startDate) && (!endDate || date <= endDate);
            }

            if (addDay) {
              if (!byYear.get(year).get(month).has(+day)) {
                byYear.get(year).get(month).set(+day, {});

                if (totals) {
                  totals.get(year).get(month).set(+day, {netCost: 0, netSales: 0, customerCount: 0});
                }
              }
              const depSales: DepSales2 = {
                netSales: data.days[day][0], netCost: data.days[day][1],
                customerCount: data.days[day][2]
              };
              byYear.get(year).get(month).get(+day)[data.dep] = depSales;

              if (totals) {
                const t = totals.get(year).get(month).get(+day);
                t.netSales += depSales.netSales;
                t.netCost += depSales.netCost;
                t.customerCount += depSales.customerCount;
              }

              if (byDep.hasOwnProperty(data.dep)) {
                if (byDep[data.dep].has(year)) {
                  if (!byDep[data.dep].get(year).has(month)) {
                    byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
                  }
                } else {
                  byDep[data.dep].set(year, new Map<number, Map<number, DepSales2>>());
                  byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
                }
              } else {
                byDep[data.dep] = new Map<number, Map<number, Map<number, DepSales2>>>();
                byDep[data.dep].set(year, new Map<number, Map<number, DepSales2>>());
                byDep[data.dep].get(year).set(month, new Map<number, DepSales2>());
              }
              byDep[data.dep].get(year).get(month).set(+day, depSales);
            }
          }
        });
        const result: DepSalesHistory = {byYear, byDep};

        if (totals) {
          result.totals = totals;
        }
        resolve(result);
      }).catch(error => {
        error.message = `Failed to get Sales History\n${error.message}`;
        reject(error);
      });
    });
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                               SHARED PATHS                                                       //
  \* ---------------------------------------------------------------------------------------------------------------- */

  queryStock(storeID: string, queries: FbQuery[], asArray: boolean = false):
    Promise<{ ids: string[]; items: { [id: string]: any } } | any[]> {
    return new Promise<{ ids: string[]; items: { [id: string]: any } } | any[]>((resolve, reject) => {
      this.stockQueryRef(storeID, queries).get().pipe(take(1)).toPromise().then(result => {
        if (!asArray) {
          const items = {};
          const ids = result.docs.map(d => {
            items[d.id] = d.data();
            return d.id;
          });
          resolve({ids, items});
        } else {
          const items = result.docs.map(d => {
            const item = d.data();
            item[sItemKeyToInt.code] = d.id;
            return item;
          });
          resolve(items);
        }
      }).catch(e => {
        e.message = `Error on stock query\n${e.message}`;
        reject(e);
      });
    });
  }

  queryStock2(storeID: string, queries: FbQuery[], cast?: boolean, objectify?: boolean): Promise<
    StockItem[] | any[] | { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } }
  > {
    // TODO (AO Data restructure branch has many helpfull types and functions for this

    return new Promise<
      StockItem[] | any[] | { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } }
    >((resolve, reject) => {
      // TODO: ---------------------------------------------------------------------------------------------------------
      //                                                    finish
      //  --------------------------------------------------------------------------------------------------------------
      this.stockQueryRef(storeID, queries).get().toPromise().then((result) => {
        if (objectify) {
          const pack = {items: {}} as
            { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } };
          pack.ids = result.docs.map((doc) => {
            pack.items[doc.id] = cast ? castStockItem(doc.data()) : doc.data();
            return doc.id;
          });
          resolve(pack);
        } else {
          resolve(result.docs.map((doc) => {
            const data = doc.data();
            data.objectID = doc.id;
            const item: StockItem | any = cast ? castStockItem(data) : data;
            return item;
          }));
        }
      });
    });
  }

  subQueryStock(storeID: string, queries: FbQuery[], asArray: boolean = false):
    { obs: Observable<{ ids: string[]; items: { [id: string]: any } } | any[]>; fireUnsub: () => void } {
    let fireSubscription: Subscription;
    const obs = new Observable<{ ids: string[]; items: { [id: string]: any } } | any[]>(observer => {
      // Using objectID such that if all keys are automatically with user-friendly keys objectID -> code.
      // Aligning with Algolia results.
      fireSubscription = this.stockQueryRef(storeID, queries).valueChanges({idField: 'objectID'}).subscribe(result => {
        if (!asArray) {
          const items = {};
          const ids = result.map(d => {
            items[d.objectID] = d;
            return d.objectID;
          });
          observer.next({ids, items});
        } else {
          // const items = result.map(d => d);
          observer.next(result);
        }
      }, e => {
        e.message = 'Stock query subscription error\n' + e.message;
        observer.error(e);
      });
    });
    return {obs, fireUnsub: () => fireSubscription.unsubscribe()};
  }

  subQueryStock2(storeID: string, queries: FbQuery[], cast?: boolean | (keyof StockItem)[], objectify?: boolean
  ): Observable<StockItem[] | any[] | {
    ids: string[];
    items: { [objectID: string]: any } | { [objectID: string]: StockItem }
  }> {
    return this.stockQueryRef(storeID, queries).valueChanges({idField: 'objectID'}).pipe(mergeMap((next) => {
      if (objectify) {
        const pack = {items: {}} as
          { ids: string[]; items: { [objectID: string]: any } | { [objectID: string]: StockItem } };

        if (cast) {
          pack.ids = next.map((rawItem) => {
            pack.items[rawItem.objectID] = castStockItem(rawItem as RawStockItem, cast === true ? 'ALL' : cast);
            return rawItem.objectID;
          });
        } else {
          pack.ids = next.map((rawItem) => {
            pack.items[rawItem.objectID] = rawItem;
            return rawItem.objectID;
          });
        }
        return [pack];
      }

      if (cast) {
        return [next.map((rawItem) => castStockItem(rawItem as RawStockItem, cast === true ? 'ALL' : cast))];
      }
      return [next] as any[];
    }));
  }

  getStockItems(
    store: IStore,
    firebaseQueries: IFirebaseQuery[],
    idField: string
  ): Observable<StockItem[]> {
    return this.stockQueryRef(store.storeID, firebaseQueries)
      .valueChanges({ idField })
      .pipe(
        map((documents: DocumentData[]) => {
          return documents.map((doc: DocumentData) => castObjectToType<StockItem, DocumentData>(doc));
        })
      );
  }

  dataQueryStock(
    store: IStore,
    firebaseQueries: FbQuery[],
    options: {
      idField: string;
      cast?: boolean;
      objectify?: boolean;
    },
  ): Observable<StockItem[] | StockItem[][] | any[]> {
    return this.stockQueryRef(store.storeID, firebaseQueries)
      .valueChanges({idField: options.idField})
      .pipe(
        mergeMap((next: DocumentData[]): DocumentData[][] => {
          if (options.objectify) {
            return ObjectUtils.objectify<StockItem>(options.cast || false, next, options.idField, store);
          }
          if (options.cast) {
            return [next.map((rawItem: DocumentData) => ObjectUtils.castObject<StockItem>(rawItem, store))];
          }
          return [next] as any[];
        })
      );
  }

  stockQueryRef(storeID: string, queries: FbQuery[]): AngularFirestoreCollection {
    return this.af.collection(`shared/stores_data/${storeID}/data/stock`, ref => {
      let currentRef = ref;

      for (const q of queries) {
        switch (q.q) {
          case 'where':
            currentRef = currentRef.where.apply(currentRef, q.p);
            break;
          case 'orderBy':
            currentRef = currentRef.orderBy.apply(currentRef, q.p);
            break;
          case 'limit':
            currentRef = currentRef.limit.apply(currentRef, q.p);
            break;
          case 'startAt':
            currentRef = currentRef.startAt.apply(currentRef, q.p);
            break;
          case 'startAfter':
            currentRef = currentRef.startAfter.apply(currentRef, q.p);
            break;
          case 'endAt':
            currentRef = currentRef.endAt.apply(currentRef, q.p);
            break;
          case 'endBefore':
            currentRef = currentRef.endBefore.apply(currentRef, q.p);
            break;
        }
      }
      return currentRef;
    });
  }

  getStockItem(storeID: string, code: string) {
    console.log(`shared/stores_data/${storeID}/data/stock/${code}`);
    return new Promise<any>(resolve =>
      this.af.doc(`shared/stores_data/${storeID}/data/stock/${code}`)
        .get().toPromise().then(data => resolve(data.data()))
    );
  }

  updateStockTags(storeID, stockIDs: string | string[], tag: string, remove: boolean = false) {
    // return new Promise<void>((resolve, reject) => {
    //   // const update: { [stockID: string]: { [tag: string]: { userID: string; ts: Date } } } = {};
    //   const update = {};
    //   update[tag] = remove ? DELETE_FILED() : {userID: this.userID, ts: new Date()};
    //
    //   if (typeof stockIDs === 'string') {
    //     this.af.doc(`shared/stores_data/${storeID}/data/stock_tags/${stockIDs}`).set(update, {merge: true})
    //       .then(() => resolve())
    //       .catch(e => reject(`Failed to update tag "${tag}" for stockID=${stockIDs}\n${e}`));
    //   } else {
    //     const promises: Promise<void>[] = [];
    //     let idx = 0;
    //
    //     while (idx < stockIDs.length) {
    //       const batch = this.af.firestore.batch();
    //       const endIdx = Math.min(idx + 3, stockIDs.length);
    //
    //       for (let i = idx; i < endIdx; i++) {
    //         batch.set(this.af.doc(`shared/stores_data/${storeID}/data/stock_tags/${stockIDs[i]}`).ref,
    //           update, {merge: true});
    //       }
    //       promises.push(batch.commit());
    //       idx = endIdx;
    //     }
    //     forkJoin(promises).toPromise().then(() => resolve())
    //       .catch(e => reject(`Failed to update tags "${tag}" to stockIDs ${stockIDs}\n${e}`));
    //   }
    // });
    return new Promise<void>((resolve, reject) => {
      const update = {_tags: remove ? ARRAY_REMOVE(tag) : ARRAY_UNION(tag)};
      console.log(remove, update);

      if (typeof stockIDs === 'string') {
        this.af.doc(`shared/stores_data/${storeID}/data/stock/${stockIDs}`)
          .update(update).then(resolve)
          .catch(e => {
            e.message = `Failed to union tags ${tag} to code ${stockIDs} on store ${storeID}.\n${e.message}`;
            reject(e);
          });
      } else {
        const promises: Promise<void>[] = [];

        while (stockIDs.length > 0) {
          const batch = this.af.firestore.batch();

          for (const code of stockIDs.splice(Math.max(0, stockIDs.length - 500), 500)) {
            batch.update(this.af.doc(`shared/stores_data/${storeID}/data/stock/${code}`).ref, update);
          }
          promises.push(batch.commit());
        }

        if (promises.length === 1) {
          promises[0].then(() => resolve())
            .catch(e => {
              e.message = `Failed to union tags ${tag} to codes ${stockIDs} on store ${storeID}.\n${e.message}`;
              reject(e);
            });
        } else {
          forkJoin(promises).toPromise().then(() => resolve())
            .catch(e => {
              e.message = `Failed to union tags ${tag} to codes ${stockIDs} on store ${storeID}.\n${e.message}`;
              reject(e);
            });
        }
      }
    });
  }

  // getAllStockItems(storeID: string) {
  //   return new Promise<{ [code: string]: any }>(resolve =>
  //     this.af.collection(`shared/stores_data/${storeID}/data/stock`)
  //       .snapshotChanges().pipe(take(1)).toPromise().then(stock => {
  //         const stockItems: { [code: string]: any } = {};
  //         stock.map(d => stockItems[d.payload.doc.id] = d.payload.doc.data());
  //         resolve(stockItems);
  //     })
  //   );
  // }
  //
  // async fixStockStringDates(storeID: string) {
  //   console.clear();
  //   const all = await this.getAllStockItems(storeID);
  //   const dateKeys = ['6', '7', '8', '10', '28', '29', '30', '31', '32', '41', '47'];
  //   const updates = {};
  //
  //   for (const code of Object.keys(all)) {
  //     for (const key of dateKeys) {
  //       if (all[code][key] && all[code][key] !== '' && typeof all[code][key] === 'string') {
  //         const date = strToDate(all[code][key]);
  //
  //         if (date) {
  //           if (!updates.hasOwnProperty(code)) { updates[code] = {}; }
  //           updates[code][key] = date;
  //         }
  //       }
  //     }
  //   }
  //   const codes = Object.keys(updates);
  //   console.log('Store:', storeID);
  //   console.log(`Updating ${codes.length} items`);
  //
  //   while (codes.length > 0) {
  //     const batch = this.af.firestore.batch();
  //     const splice = codes.splice(0, Math.min(codes.length, 499));
  //
  //     for (const code of splice) {
  //       const dr = this.af.doc(`shared/stores_data/${storeID}/data/stock/${code}`).ref;
  //       batch.update(dr, updates[code]);
  //     }
  //     await batch.commit();
  //   }
  //   console.log('Done');
  // }

  subSuppliersOnceOff(storeID: string): { 'obs': Observable<{ [account: string]: Supplier }>; 'sub': Subscription } {
    let sub: Subscription;
    const obs = new Observable<{ [account: string]: Supplier }>(observer => {
      sub = this.af.collection(`shared/stores_data/${storeID}/data/suppliers`).snapshotChanges()
        .subscribe(snaps => {
          const suppliers = {};

          for (const snap of snaps) {
            suppliers[snap.payload.doc.id] = snap.payload.doc.data();
          }
          observer.next(suppliers);
        });
    });
    return {obs, sub};
  }

  getSuppliers(storeID: string): Promise<{ [account: string]: Supplier }> {
    return new Promise<{ [account: string]: Supplier }>((resolve, reject) =>
      this.af.collection(`shared/stores_data/${storeID}/data/suppliers`).ref.get().then(d => {
        const suppliers = {};
        d.docs.forEach(value => suppliers[value.id] = value.data());
        resolve(suppliers);
      }).catch(error => {
        error.message = `Failed to get suppliers ${storeID}.\n${error.message}`;
        reject(error);
      })
    );
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                                      DANGER                                                      //
  \* ---------------------------------------------------------------------------------------------------------------- */

  async setLinkedStockItems(links: { [linkHash: string]: Map<string, string> }) {
    let batch = this.af.firestore.batch();
    let count = 0;

    for (const hash of Object.keys(links)) {

      if (Object.keys(links[hash]).length + count > 500) {
        await batch.commit();
        batch = this.af.firestore.batch();
        count = 0;
      }

      for (const storeID of Object.keys(links[hash])) {
        batch.update(
          this.af.doc(`shared/stores_data/${storeID}/data/stock/${links[hash][storeID]}`).ref,
          {link: hash, _tags: ARRAY_UNION('linked_private')}
        );
      }
      count++;
    }

    if (count > 0) {
      await batch.commit();
    }
  }

  async stockItemsDeleteLink(items: { [storeID: string]: string[] }) {
    const batch = this.af.firestore.batch();

    for (const storeID of Object.keys(items)) {
      for (const code of items[storeID]) {
        batch.update(
          this.af.doc(`shared/stores_data/${storeID}/data/stock/${code}`).ref,
          {
            link: DELETE_FILED(),
            _tags: ARRAY_REMOVE('linked_private')
          }
        );
      }
    }
    return batch.commit();
  }


  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                             OBSERVATION PATHS                                                    //
  \* ---------------------------------------------------------------------------------------------------------------- */

  subDepSalesSummary(storeID: string):
    Observable<{ store: string; data: { [department: string]: DepSales }; time: Date }> {

    return null;

    // if ( ! this.salesDepSumObs[storeID]) {
    //   this.salesDepSumObs[storeID] = new Observable(
    //     observer => {
    //     this.af.doc(`observation/stores_data/${storeID}/data/singular_documents/dep-sales-summary` )
    //       .snapshotChanges().subscribe(ds => {
    //       observer.next({
    //         store: storeID,
    //         data: ds.payload.data() as { [department: string]: DepSales },
    //         time: ds.payload._delegate._document.version.timestamp.toDate(),
    //       });
    //     });
    //   });
    // }
    // return this.salesDepSumObs[storeID];
  }

  getDepSalesSumSettings(storeID: string): Promise<{ growthTargets: { [key: string]: number } }> {
    return new Promise<{ growthTargets: { [key: string]: number } }>((resolve, reject) => {
      this.af.doc(`observation/stores_data/${storeID}/data/singular_documents/dep-sales-summary-settings`)
        .get().toPromise().then(doc => resolve(doc.data() as { growthTargets: { [key: string]: number } })).catch(e => {
        reject(e);
      });
    });
  }

  updateDepSalesSumSettings(storeID: string, updates: { [key: string]: any }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.af.doc(`observation/stores_data/${storeID}/data/singular_documents/dep-sales-summary-settings`)
        .update(updates).then(() => resolve());
    });
  }

  /* ---------------------------------------------------------------------------------------------------------------- *\
  //                                              OPERATIONAL PATHS                                                   //
  \* ---------------------------------------------------------------------------------------------------------------- */

  getApiLog(storeID: string, apiLogID: string): Promise<ApiLogObj> {
    return new Promise<ApiLogObj>((resolve, reject) => {
      this.af.doc(`/operational/stores_data/${storeID}/data/api_events/${apiLogID}`).get().toPromise().then((doc) => {
        const obj = doc.data();
        ['creationDate', 'scheduledDate', 'executedDate'].forEach((dk) => {
          if (obj[dk]) {
            obj[dk] = obj[dk].toDate();
          }
        });

        resolve(obj as ApiLogObj);
      }).catch(e => {
        e.message = `Error getting API log.\n${e.message}`;
        reject(e);
      });
    });
  }

  subAutoOrderingSchedule(storeID: string): Observable<
    { schedule: { [day: string]: string[] }; accounts: { [acc: string]: any } }
  > {
    if (!this.storeDataSubs[storeID].oaSchedule) {
      this.storeDataSubs[storeID].oaSchedule = new Observable<any>(observer => {
        this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/schedule/accounts`)
          .snapshotChanges()
          .subscribe(docChanges => {
            const schedule: { [day: string]: string[] } = {};
            const accounts = {};

            for (const dc of docChanges) {
              const acc = dc.payload.doc.id;
              accounts[acc] = dc.payload.doc.data();

              for (const day of Object.keys(accounts[acc])) {
                schedule[day] = schedule[day] ? schedule[day].concat(acc) : [acc];
              }
            }

            for (const day of Object.keys(schedule)) {
              schedule[day].sort((a, b) => accounts[a][day].rank - accounts[b][day].rank);
            }
            observer.next({schedule, accounts});
          });
      });
    }
    return this.storeDataSubs[storeID].oaSchedule;
  }

  updateAutoOrderingSchedules(storeID, accounts: { [acc: string]: any }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const params: { doc: firebase.default.firestore.DocumentReference; data: AOScheduleElement }[] = [];

      for (const acc of Object.keys(accounts)) {
        for (const day of Object.keys(accounts[acc])) {
          const doc = this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/schedule/${day}/${acc}`)
            .ref;
          const data = accounts[acc][day];

          if (data) {
            data.userID = this.userID;
          }
          params.push({doc, data});
        }
      }

      const upload = () => {
        if (params.length) {
          const batch = this.af.firestore.batch();

          for (let i = 0; i < Math.min(500, params.length); i++) {
            const p = params.pop();

            if (p.data) {
              batch.set(p.doc, p.data);
            } else {
              batch.delete(p.doc);
            }
          }
          batch.commit().then(() => upload()).catch(error => {
            error.message = `Failed to update schedule.\n${error.message}`;
            reject(error);
          });
        } else {
          resolve();
        }
      };
      upload();
    });
  }

  getAutoOrderingScheduleSubs(storeID: string): { [day: string]: Observable<{ [acc: string]: AOScheduleElement }> } {
    const subs: { [day: string]: Observable<{ [acc: string]: AOScheduleElement }> } = {};
    const dayArr = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    const path = `/operational/stores_data/${storeID}/data/auto_ordering/schedule`;

    for (const day of dayArr) {
      subs[day] = new Observable<{ [p: string]: AOScheduleElement }>(observer => {
        this.af.collection(`${path}/${day}`).snapshotChanges().subscribe(dcs => {
          const accounts: { [acc: string]: AOScheduleElement } = {};
          dcs.map(dc => accounts[dc.payload.doc.id] = dc.payload.doc.data() as AOScheduleElement);
          observer.next(accounts);
        });
      });
    }
    return subs;
  }

  getAutoOrderingScheduleDay(storeID: string, day: string): Promise<{ [sup: string]: AOScheduleElement }> {
    return new Promise<{ [sup: string]: AOScheduleElement }>((resolve, reject) => {
      this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/schedule/${day}`)
        .get().toPromise().then(docs => {
        const orders = {};
        docs.docs.map(doc => orders[doc.id] = doc.data());
        resolve(orders);
      }).catch(e => {
        e.message = `Error getting AO Schedule for day ${day}.\n${e.message}`;
        reject(e);
      });
    });
  }

  getAutoOrderingSettings(storeID: string): Promise<AOSettings> {
    return new Promise<AOSettings>((resolve, reject) => {
      this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/user-settings/settings/${this.userID}`)
        .get().toPromise().then(doc => {
        resolve(doc.data() as AOSettings);
      }).catch(e => {
        e.message = `Error getting Auto Ordering Settings.\n${e.message}`;
        reject(e);
      });
    });
  }

  getAutoOrderingEmailSettings(storeID: string, userID: string): Promise<AOSettings> {
    return new Promise<AOSettings>((resolve, reject) => {
      this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/user-settings/settings/${userID}`)
        .get().toPromise().then(doc => {
        resolve(doc.data() as AOSettings);
      }).catch(e => {
        e.message = `Error getting Auto Ordering Settings.\n${e.message}`;
        reject(e);
      });
    });
  }

  subAutoOrderingEmailSettings(storeID: string): Observable<{ [userID: string]: AOSettings }> {
    return this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/user-settings/settings`)
      .valueChanges({idField: 'objectID'}).pipe(mergeMap((next) => {
        const settings: { [userID: string]: AOSettings } = {};
        next.forEach((doc) => {
          settings[doc.objectID] = doc as any as AOSettings;
          delete doc.objectID;
        });
        return [settings];
      }));
  }

  updateAutoOrderingSettings(storeID: string, settings: AOSettings): Promise<void> {
    return this.af.doc(
      `/operational/stores_data/${storeID}/data/auto_ordering/user-settings-changes/settings/${this.userID}`
    ).set(settings, {merge: true});
  }

  updateAutoOrderingEmailSettings(storeID: string, userID: string, settings: AOSettings): Promise<void> {
    return this.af.doc(
      `/operational/stores_data/${storeID}/data/auto_ordering/user-settings-changes/settings/${userID}`
    ).set(settings);
  }

  // TODO: This function currently checks for items data in the document as a map or if the items are in a collection
  //  (which it should be). Both are checked because the back end has been updated but not on all stores and there might
  //  be old order data that still stores the info in a map.
  subAutoOrderingReady(storeID: string): Observable<{
    [orderID: string]: {
      userID: string; supp: string; executed: Date; scheduled?: boolean; items: { [code: string]: GenAOData };
      notMine?: boolean; new?: true | 'PERIOD-2'; aoSettingsElement?: Omit<AOScheduleElement, 'rank' | 'userID'>;
    };
  }> {

    return new Observable(observer => {
      const process = async (dc) => {
        const orders = {};

        // Process each document in parallel
        await Promise.all(dc.map(async (d) => {
          const obj = d.data() as any;
          obj.executed = obj.executed.toDate();

          // Initialize the order object in the orders map
          orders[d.id] = {
            ...obj,
            items: {}  // Initialize items as an empty object
          } as {
            supp: string; executed: Date; scheduled?: boolean; items: { [code: string]: GenAOData };
            aoSettingsElement?: Omit<AOScheduleElement, 'rank' | 'userID'>;
          };

          if (obj.userID !== this.userID) {
            orders[d.id].notMine = true;
          }

          // Check if the items field is a collection
          const itemsSnapshot = await this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders/${d.id}/items`).get().toPromise();

          if (itemsSnapshot.size > 0) {
            // If items exist as a collection, subscribe to the items collection
            const items = {};
            itemsSnapshot.docs.map(itemDoc => {
              items[itemDoc.id] = itemDoc.data() as GenAOData;
            });
            orders[d.id].items = items;  // Update the items for this order
          } else {
            // Otherwise, handle items as a map (old way)
            orders[d.id].items = obj.items;
          }
        }));

        // Emit the fully processed orders object after all documents are handled
        observer.next(orders);
      };

      console.log('Can see colleagues', this.fireAuth.hasAccess(storeID, {ruleID: 'd.i'}));

      if (this.userAccess.stores[storeID] && this.fireAuth.hasAccess(storeID, {ruleID: 'd.i'}) === true) {
        this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders`)
          .snapshotChanges().subscribe(
          docChanges => process(docChanges.map(d => d.payload.doc)),
          error => observer.error(Error(`Error on subscription to ready auto orders (colleagues).\n${error}`))
        );
      } else {
        this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders`)
          .ref.where('userID', '==', this.userID)
          .onSnapshot(
            qs => process(qs.docs),
            error => observer.error(Error(`Error on subscription to ready auto orders.\n${error}`))
          );
      }
    });
  }

  getAutoOrderParts(storeID: string, orderID: string): Promise<null | {
    ready: AOPartReady;
    prepared?: AOPartPrepared;
  }> {
    // TODO: I could obscure this to fetch any number > 1 of documents ✪ ω ✪
    return new Promise((resolve, reject) => {
      const result: { ready: AOPartReady; prepared?: AOPartPrepared } =
        {} as { ready: AOPartReady; prepared?: AOPartPrepared };
      const promises: Promise<void>[] = [];

      promises.push(new Promise(async (resolveInner, rejectInner) => {
        const doc = await this.af.doc(
          `/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders/${orderID}`
        ).get().toPromise();
        const data = doc.data();

        if (data) {
          result.ready = data as AOPartReady;
        }
        resolveInner();
      }));
      promises.push(new Promise(async (resolveInner, rejectInner) => {
        const doc = await this.af.doc(
          `/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/${orderID}`
        ).get().toPromise();
        const data = doc.data();

        if (data) {
          result.prepared = data as AOPartPrepared;
        }
        resolveInner();
      }));

      forkJoin(promises).toPromise().then(() => resolve(Object.keys(result).length ? result : null));
    });
  }

  async getAutoOrder(storeID: string, orderID: string): Promise<AutoOrder | null> {
    // TODO: I could obscure this to fetch any number > 1 of documents ✪ ω ✪
    try {
      const doc = await this.af.doc(`${this.autoOrderColPath(storeID)}/${orderID}`).get().toPromise();
      return doc.data() as AutoOrder | null;
    } catch (error) {
      error.message = `Error fetching Auto Order "${orderID}"\n${error.message}`;
      throw error;
    }
  }

  addEmptyAutoOrder(storeID: string, suppID: string, codes: string[]): Promise<void> {
    const d = new Date();
    const data: AutoOrder = {
      executed: d, suppID, userID: this.userID, owned: this.userID,
      genInfo: {items: {}, aoSettingsElement: null, scheduled: 'CREATED'},
      archived: false
    };
    codes.forEach(code => data.genInfo.items[code] = {new: 'ADDED'});
    const orderID = `${this.userID.replace(/\./g, '-')}_${suppID}_${d.getDate()}-${d.getMonth() + 1}`;
    console.log(data);
    console.log(`${this.autoOrderColPath(storeID)}/${orderID}`);
    return this.af.doc(`${this.autoOrderColPath(storeID)}/${orderID}`).set(data);
  }

  makeAutoOrderReadyDirty(storeID: string, orderID: string, codes: string[]): Promise<void> {
    const value = {
      outOfStock: 0, maxSold: 0, netSold: 0, qtyDiff: 0, maxReturned: 0, negDays: 0, zeroDays: 0, new: 'ADDED'
    };
    const update: any[] = [];
    // codes.forEach(code => update.push(new FieldPath('items', code), value));
    codes.forEach((code) => update.push(new FieldPath('genInfo', 'items', code), value));
    const doc = this.af.firestore.doc(`${this.autoOrderColPath(storeID)}/${orderID}`);
    // this.af.firestore.doc(`/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders/${orderID}`);
    return doc.update.apply(doc, update);
  };


  async removeItemsFromOrder(storeID: string, orderID: string, codes: string[]): Promise<void> {
    const deletions: any[] = [];
    // codes.forEach(code => update.push(new FieldPath('items', code), DELETE_FILED()));
    // const pDoc = this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/${orderID}`);
    // const rDoc = this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/ready_ao/orders/${orderID}`);
    // const prepUpdates = [];
    //
    // return this.af.firestore.runTransaction(async (transaction) => {
    //   let trans = transaction;
    //   try {
    //     const prep = (await trans.get(pDoc.ref)).data();
    //     if (prep) {
    //       codes.forEach(code => prepUpdates.push(new FieldPath('quantities', code), DELETE_FILED()));
    //     }
    //   } catch (e) {
    //     console.log(`No fetch of Prepared${e}`);
    //   }
    //
    //   if (prepUpdates.length) {
    //     trans = await trans.update.apply(trans, [pDoc.ref].concat(prepUpdates));
    //   }
    //   await trans.update.apply(trans, [rDoc.ref].concat(update));
    // });

    // TODO: This might need to be done in the transaction, like the old way did it. In case the filed path does not
    //  exist?
    codes.forEach(code => {
      deletions.push(new FieldPath('genInfo', 'items', code), DELETE_FILED());
      deletions.push(new FieldPath('preparedInfo', 'quantities', code), DELETE_FILED());
    });
    const doc = this.af.doc(`${this.autoOrderColPath(storeID)}/${orderID}`);
    try {
      await doc.update.apply(doc, deletions);
    } catch (error) {
      error.message = `Error removing items from order "${orderID}"\n${error.message}`;
      throw error;
    }
  }

  async setAOQuantities(
    storeID: string, order: AutoOrder, quantities: { [code: string]: { qty: number; price?: number } }, total: number,
    comment?: string, dirtyCodes?: string[]
  ): Promise<void> {
    // suppAccount: string, orderID: string,
    // quantities: { [code: string]: { qty: number; price?: number } }, dirtyCodes: string[],
    // ogUserID: string, total: number, comment?: string): Promise<void> {

    interface AOPartSubmissionUpdate extends Omit<AOPartSubmissions, 'ts'> {
      ts: FieldValue;
    }

    const payload: AOPartSubmissionUpdate = {ts: SERVER_TIMESTAMP(), quantities, total};

    if (comment) {
      payload.comment = comment;
    } else if (order?.preparedInfo?.comment) {
      payload.comment = order.preparedInfo.comment;
    }

    // TODO: Determine if dirty codes are still needed at all.
    if (dirtyCodes && dirtyCodes.length) {
      payload.dirty = {};
      dirtyCodes.forEach((code) => (payload.dirty[code] = true));
    }

    try {
      await this.af.doc(`${this.autoOrderColPath(storeID)}/${order.orderID}`)
        .update({userID: this.userID, preparedInfo: payload});
    } catch (error) {
      throw Error(`Error in setAOQuantities. Changes not updated.\n${error}`);
    }
    // return new Promise<void>((resolve, reject) => {
    //   const payload: any = {supp: suppAccount, userID: this.userID, quantities, ts: new Date(), originalUserID: ogUserID, total};
    //
    //   if (comment && comment !== '') {
    //     payload.comment = comment;
    //   }
    //   if (dirtyCodes && dirtyCodes.length) {
    //     payload.dirty = {};
    //     dirtyCodes.forEach((code) => (payload.dirty[code] = true));
    //   }
    //   this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/${orderID}`).set(payload)
    //     .then(() => resolve())
    //     .catch(e => {
    //       e.message = `Error setting Auto Order Prepared.\n${e.message}`;
    //       reject(e);
    //     });
    // });
  }

  async deleteAutoOrder(storeID: string, orderID: string | string[], part?: 'genInfo' | 'preparedInfo'): Promise<void> {
    const toDelete = typeof orderID === 'string' ? [orderID] : orderID;
    const batch = this.af.firestore.batch();

    for (const oID of toDelete) {
      const orderDocRef = this.af.doc(`${this.autoOrderColPath(storeID)}/${oID}`).ref;

      if (part) {
        batch.update(orderDocRef, {part: DELETE_FILED()});
      } else {
        batch.delete(orderDocRef);
      }
    }
    try {
      await batch.commit();
    } catch (e) {
      e.message = `Error deleting Ready AO${part ? ' Part = \'' + part + '\'' : ''}\n${e.message}`;
      throw e;
    }
  }

  async submitAutoOrder(storeID: string, orderIDs: string[], ordersSupp: { [orderID: string]: string },
                        recipients: { [orderID: string]: string[] }, ignoreHist?: string[]) {
    const batch = this.af.firestore.batch();

    for (const orderID of orderIDs) {
      const docPath = `${this.autoOrderColPath(storeID)}/${orderID}`;
      batch.update(this.af.doc(docPath).ref, {status: 'SUBMITTED', recipients: recipients[orderID]});
    }
    const msgRef = this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`).doc().ref;
    const msg: Message = {
      type: 'AUTO_ORDERS',
      payload: {
        data: orderIDs
      },
      sender: this.userID, timestamp: new Date()
    };
    batch.set(msgRef, msg);

    if (ignoreHist && ignoreHist.length) {
      const updates = {};

      for (const supp of ignoreHist) {
        updates[`${supp}.ignore`] = true;
      }
      batch.update(
        this.af.doc(`/operational/stores_data/${storeID}/data/auto_ordering/basic_history`).ref, updates
      );
    }

    if (Object.keys(recipients).length) {
      const update: { [supp: string]: string[] } = {};
      Object.keys(recipients).forEach((orderID) => (update[ordersSupp[orderID]] = recipients[orderID]));
      const ref = this.af.doc(`/users/${this.userID}/singular_documents/ao_supp_emails`).ref;
      batch.set(ref, update, {merge: true});
    }
    try {
      await batch.commit();
    } catch (e) {
      e.message = 'Submitting AO batch failed.\n' + e.message;
      throw e;
    }
  }

  reattemptAutoOrder(msgID: string, storeID: string, reattemptData: { [orderID: string]: any } | string[]):
    Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const now = new Date();
      const msg: Message = {
        type: 'AUTO_ORDERS',
        payload: {
          data: reattemptData
        },
        sender: this.userID, timestamp: now
      };
      const reattemptFieldsUpdates = {};
      const batch = this.af.firestore.batch();

      for (const orderID of Object.keys(reattemptData)) {
        reattemptFieldsUpdates[`payload.data.${orderID}.reattempted`] = SERVER_TIMESTAMP();
        const update: any = {status: 'SUBMITTED'};

        if (reattemptData[orderID].pos !== 'AO_EMAIL_FAIL') {
          if (reattemptData[orderID].itemsToIgnore) {
            for (const code of reattemptData[orderID].itemsToIgnore) {
              update[`preparedInfo.quantities.${code}`] = DELETE_FILED();
            }
          }
        }
        batch.update(
          this.af.doc(`${this.autoOrderColPath(storeID)}/${orderID}`).ref,
          update
        );
      }
      batch.update(this.af.doc(`users/${this.userID}/messages/${msgID}`).ref, reattemptFieldsUpdates);
      batch.commit()
        .then(() => this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`).add(msg)
          .then((dr) => resolve(dr.id))
          .catch(error => {
            error.message = `AO reattempt to add message to store.\n${error.message}`;
            reject(error);
          })
        ).catch(error => {
        error.message = `AO reattempt failed on batch updates.\n${error.message}`;
        reject(error);
      });
    });
  }

  requestAutoOrderUnscheduled(storeID: string, orderData: { [supp: string]: AOScheduleElement }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msg: Message = {
        type: 'AUTO_ORDERS_U_UPDATE', timestamp: new Date(), sender: this.userID, payload: {data: orderData}
      };
      this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`).add(msg)
        .then(doc => {
          console.log(doc.id);
          resolve();
        })
        .catch(e => {
          e.message = `Error sending unscheduled order to server ${storeID}. ${e.message}`;
          reject(e);
        });
    });
  }

  // subAutoOrderingPrepared(storeID: string): Observable<{
  //   [supp: string]: { userID: string; ts: Date; status?: 'SUBMITTED' | 'FAILED'; originalUserID?: string;
  //     quantities: { [code: string]: { qty: number; price?: number } }; comment?: string; total?: number; }; }> {
  //   // TODO: This could be updated so that a manager can pass a flag, and see all users orders.
  //   return new Observable(observer => {
  //     const process = (docs) => {
  //       const objs = {};
  //
  //       for (const doc of docs) {
  //         objs[doc.id] = doc.data() as { userID: string; quantities; ts: Date; pdf?: boolean; originalUserID?: string };
  //       }
  //       observer.next(objs);
  //     };
  //
  //     if (this.userAccess.stores[storeID] && this.fireAuth.hasAccess(storeID, {ruleID: 'd.i'}) === true) {
  //       this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/`)
  //         .snapshotChanges().subscribe(sc => {
  //          process(sc.map(snapshot => snapshot.payload.doc));
  //       });
  //     } else {
  //       this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/`).ref
  //         .where('originalUserID', '==', this.userID)
  //         .onSnapshot(qs => process(qs.docs));
  //     }
  //   });
  // }

  autoOrderColPath(storeID: string): string {
    return `/operational/stores_data/${storeID}/data/auto_ordering/auto_orders/orders`;
  }

  /**
   * Subscribe to a stores auto orders and return the collection of {@link AutoOrder}s. Optionally parameter `archived`
   * can be used to change the subscription to return archived auto orders {@link ArchivedAutoOrder} instead, or even
   * both Archived and normal "active" auto orders.
   *
   * @member {string} storeID The ID of the store to subscribe to.
   * @member {boolean | 'BOTH'} archived Specify if archived orders should be returned (`true` or `false`) or providing
   * `'BOTH'` will return all, regardless of state.
   * @return A collection of auto orders: {@link UnspecifiedAutoOrderCollection}, depending on the use of `archived`.
   */
  subAutoOrders(storeID: string, archived?: boolean | 'BOTH'): Observable<UnspecifiedAutoOrderCollection> {
    const queries: ((ref: Query | CollectionReference<DocumentData>) => Query)[] = [];

    if (this.fireAuth.hasAccess(storeID, {ruleID: 'd.i'}) !== true) {
      queries.push(
        (ref) => ref.where('owned', 'in', [this.userID, 'public'])
      );
    }

    if (archived) {
      if (archived === true) {
        queries.push((ref) => ref.where('archived', '==', true));
      } else if (archived !== 'BOTH') {
        throw Error(`Unsupported parameter in subAutoOrders. archived cannot be ${archived}`);
      }
    } else {
      queries.push((ref) => ref.where('archived', '!=', true));
    }
    const colRef = this.af.collection(this.autoOrderColPath(storeID), (reference) => {
      let ref: Query | CollectionReference = reference;
      queries.forEach((q) => (ref = q(ref)));
      return ref;
    });

    return colRef.valueChanges({idField: 'orderID'}).pipe(mergeMap((values) => {
      console.log('---------------------------------');
      console.log(values);
      const orders: AutoOrderCollection = {};
      // values.forEach((value) => {
      //   this.castTS2Date(value, [['executed'], ['preparedInfo', 'ts']]);
      //   orders[value.orderID] = value as AutoOrder;
      // });
      return [orders];
    }));
  }

  serverConfigMessage(
    storeID: string, configs: { [config: string]: any }, feature: Feature = 'operational'
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const batch = this.af.firestore.batch();
      Object.keys(configs).forEach(config => {
        batch.set(this.af.doc(`/stores/${storeID}/configs/${config}`).ref, configs[config], {merge: true});
        console.log(`/stores/${storeID}/configs/${config}`);
      });

      const payload = {data: Object.keys(configs)};
      const message: Message = {payload, sender: this.userID, timestamp: new Date(), type: 'SERVER_CONFIG'};

      batch.commit().then(() =>
        this.af.collection(`/${feature}/stores_data/${storeID}/messages/from_app/`).add(message).then(r => {
          console.log(r);
          resolve(r.id);
        }).catch(e => {
          e.message = `Could not send server configuration message: ${e.message}`;
          reject(e);
        }).catch(e => {
          e.message = `Could not update server configurations: ${e.message}`;
          reject(e);
        })
      );
    });
  }


  /* ________________________________________________ STOCK UPDATES _________________________________________________ */

  reattemptUpdateStock(storeID: string, apiLogID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const msg: Message = {
        sender: this.userID, timestamp: new Date(), type: 'STOCK_UPDATE', payload: {data: {logID: apiLogID}}
      };
      this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`)
        .add(msg).then(() => resolve()).catch(e => {
        e.message = `Failed to send logID msg to server.\nLogID: ${apiLogID}\n${e.message}`;
        reject(e);
      });
    });
  }

  async queryStockUpdates(
    storeID: string,
    query: { code?: string; minDate?: Date; maxDate?: Date; userID?: string }
  ): Promise<ApiLogObj2[]> {
    const reference = this.af.collection(`/operational/stores_data/${storeID}/data/api_events/`, (r) => {
      let ref: CollectionReference<DocumentData> = r;

      console.log(query);

      if (query.minDate) {
        ref = ref.where('executedDate', '>=', query.minDate) as CollectionReference<DocumentData>;
      }
      if (query.maxDate) {
        ref = ref.where('executedDate', '<=', query.maxDate) as CollectionReference<DocumentData>;
      }
      if (query.userID) {
        ref = ref.where('userID', '==', query.userID) as CollectionReference<DocumentData>;
      }
      if (query.code) {
        ref = ref.orderBy(new FieldPath('data', query.code)) as CollectionReference<DocumentData>;
        console.log(new FieldPath('data', query.code));
      }

      // TODO: NBNB this needs to apply on code queries as well but for now cant as an index is required. Me (Storm)
      //  needs to figure out the damn index thingy on the firebase-functions repo. To do this auto magically
      // ref = ref.orderBy('executedDate', 'desc') as CollectionReference<DocumentData>;
      // return ref.limit(15);
      return ref;
    });

    const results: ApiLogObj2[] = [];
    (await reference.get().toPromise()).forEach((sh) => {
      const data = sh.data() as ApiLogObj2;

      if (data.hasOwnProperty('executedDate')) {
        data.id = sh.id;
        ['creationDate', 'scheduleDate', 'executedDate', 'lastAttempt'].forEach((dateKey) => {
          if (data[dateKey]) {
            data[dateKey] = (data[dateKey] as Timestamp).toDate();
          }
        });
        results.push(data);
      }
    });
    return results;
  }


  stockUpdate(storeID: string, updates: { [code: string]: { o: StockItem; n: StockItem } }, scheduledDate?: Date) {
    return new Promise<string>(async (resolve, reject) => {
      const data: { [code: string]: StockItemUpdateObj } = {};

      const newItems: { [code: string]: StockItem } = {};
      const oldItems: { [code: string]: StockItem } = {};

      Object.keys(updates).forEach(code => {
        data[code] = {o: {}, n: {}};
        // Object.keys(updates[code].o).forEach(k => data[code].o[sItemKeyToInt[k]] = updates[code].o[k]);
        Object.keys(updates[code].n).forEach((k) => {
          if (!updates[code].o.hasOwnProperty(k) || updates[code].n[k] !== updates[code].o[k]) {
            data[code].n[sItemKeyToInt[k]] = updates[code].n[k];
            data[code].o[sItemKeyToInt[k]] = updates[code].o[k];

            if (!newItems[code]) {
              newItems[code] = {} as StockItem;
              oldItems[code] = {} as StockItem;
            }
            newItems[code][k] = updates[code].n[k];
            oldItems[code][k] = updates[code].o[k];

          } else {
            this.alertControl.create({
              header: 'Whoops', subHeader: 'The updates object passed to the firebase service isn\'t as it should be.',
              message: 'Please inform Techodactyl Support this happened as well as whether you were on the NGP page or the Stock ' +
                `page.<br>Your update will still go through but this key, of this item, is excluded:<br><br>${code}: ` +
                `${!updates[code].o.hasOwnProperty(k) ? 'key ' + k + ' not in original object.' : k + ': update == ' +
                  'original value "' + updates[code].o[k] + '"'}`, cssClass: ['custom-alert', 'error'], buttons: ['ok']
            }).then(ac => ac.present());
          }
        });
      });

      const changeChecks = this.sVChangeCheckConfigs[storeID];
      if (changeChecks) {
        const itemFlags: { [code: string]: StockValuesChangeCheckResult } = {};
        const flaggedCodes = Object.keys(newItems).filter((code) => {
          const flags = StockFunctions.checkStockValues(newItems[code], this.sVChangeCheckConfigs[storeID],
            oldItems[code]);
          if (flags) {
            itemFlags[code] = flags;
            return true;
          }
          return false;
        });

        if (flaggedCodes.length) {
          const ac = await this.modalController.create({
            component: StockValChangeFlagsComponent, componentProps: {codes: flaggedCodes, itemFlags},
          });
          await ac.present();
          const result = await ac.onDidDismiss();
          if (result.role === 'change') {
            for (const code of Object.keys(result.data)) {
              for (const key of Object.keys(result.data[code])) {
                newItems[code][key] = result.data[code][key];
                data[code].n[sItemKeyToInt[key]] = result.data[code][key];
              }
            }
          }
        }
      }

      const apiLogObj: ApiLogObj2 = {
        creationDate: new Date(), data, type: 'STOCK_UPDATE',
        userID: this.userID
      };
      const msg: Message = {
        sender: this.userID, timestamp: new Date(), type: 'STOCK_UPDATE', payload: {data: {}}
      };

      if (scheduledDate && scheduledDate > new Date()) {
        msg.payload.data.scheduledDate = scheduledDate;
      }

      this.af.firestore.runTransaction(async trans => {
        const docRef = this.af.collection(`/operational/stores_data/${storeID}/data/api_events/`).doc().ref;
        msg.payload.data.logID = docRef.id;
        trans.set(docRef, apiLogObj);
        trans.set(this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`).doc().ref, msg);
        return docRef.id;
      }).then((docID: string): void => {
        resolve(docID);
      })
        .catch(error => {
          error.message = 'Failed to run stock update transaction\n' + error.message;
          console.error(error.message);
          reject(error);
        });

      // this.af.collection(`/operational/stores_data/${storeID}/data/api_events/`)
      //   .add(apiLogObj).then(docRef => {
      //     msg.payload.data.logID = docRef.id;
      //     this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`)
      //       .add(msg).then(doc => resolve(doc.id)).catch(e =>
      //       reject(`Failed to send logID msg to server.\nLogID: ${docRef.id}\n${e}`));})
      //   .catch(e => reject('Filed to create stock update api log.\n' + e));
    });
  }

  /* ---------------------------------------------------------------------------------------------------------------- */
  /* .                                                TESTING STUFF                                                 . */

  /* ---------------------------------------------------------------------------------------------------------------- */

  testingHider(onChange: (show: boolean) => void, additionalUIDs?: string[]): Subscription {
    let uids = ['claydenburger@gmail.com', 'baileyhassall@gmail.com'];
    uids = additionalUIDs ? uids.concat(additionalUIDs) : uids;
    uids = [...new Set(uids)];
    // TODO: shouldn't this be a merge map or something?
    return this.userObj.subscribe((uo) =>
      onChange(uo && uids.includes(uo.id))
    );
  }

  news(indexDate: Date = null, limit: number = 5, direction: 'startAfter' | 'endBefore' = 'startAfter'):
    Observable<FormPost[]> {
    interface FormPostPollRaw extends Omit<FormPostPoll, 'expire'> {
      expire?: Timestamp;
    }

    interface FormPostRaw extends Omit<FormPost, 'ts' | 'poll'> {
      ts: Timestamp;
      poll?: FormPostPollRaw;
    }

    console.log(indexDate, limit, direction);

    return this.af.collection(
      '/community_form',
      (ref) => {
        const r = ref.orderBy('ts', 'desc');

        if (direction === 'startAfter') {
          return indexDate ? r.startAfter(indexDate).limit(limit) : r.limit(limit);
        }
        return indexDate ? r.endBefore(indexDate).limit(limit) : r.limit(limit);
      }
    ).valueChanges({idField: 'id'}).pipe(mergeMap((vc) => {
      const data = vc as FormPostRaw[];
      return [data.map((rfp) => {
        const fp = (rfp as any) as FormPost;
        fp.ts = rfp.ts.toDate();

        if (fp.poll) {
          if (fp.poll.expire) {
            fp.poll.expire = (rfp.poll.expire).toDate();
          } else {
            fp.poll.expire = dateDelta(fp.ts, {days: 1});
          }
        }
        return fp;
      })];
    }));
  }

  oldestFormPost(): Observable<Date> {
    return this.af.collection('/community_form', (ref) => ref.orderBy('ts').limit(1)).valueChanges({idField: 'id'})
      .pipe(mergeMap((vc): [Date | null] => {
        if (vc.length) {
          return [(vc[0] as { id: string; ts: Timestamp }).ts.toDate()];
          // return [[vc[0]] as FormPost[]];
        }
        return [null];
      }));
  }

  formPost(fp: FormPost): Promise<string> {
    return new Promise<string>((resolve, reject) =>
      this.af.collection('/community_form').add(fp)
        .then((dr) => resolve(dr.id))
        .catch((e) => {
          e.message = 'Failed to post to Community From' + e.message;
          reject(e);
        })
    );
  }

  async getFormPollVote(formPostID: string): Promise<(string | number)[]> {
    let doc;

    try {
      doc = await this.af.doc(`/community_form/${formPostID}/form_poll_votes/${this.userID}`).get().toPromise();
    } catch (e) {
      e.message = 'getFormPollVote Failed: ' + e.message;
      throw e;
    }
    const data = doc ? doc.data() : null;
    return data ? (data as { vote: (string | number)[] }).vote : null;
  }

  async formPollVote(vote: (string | number)[], formPostID: string): Promise<void> {

    console.log(vote, formPostID, this.userID);

    try {
      if (vote.length > 0) {
        await this.af.doc(`/community_form/${formPostID}/form_poll_votes/${this.userID}`).set({vote});
      } else {
        await this.af.doc(`/community_form/${formPostID}/form_poll_votes/${this.userID}`).delete();
      }
    } catch (e) {
      e.message = 'formPollVote Failed: ' + e.message;
      throw e;
    }
  }

  async getCommunityFormImg(imgName: string, postID: string) {
    try {
      return await this.storage.ref(`community_form/${postID}/${imgName}`).getDownloadURL().toPromise();
    } catch (error) {
      error.message = `Error fetching image for community form, postID="${postID}", imgName="${imgName}"` +
        error.message;
      throw error;
    }
  }

  async test(storeID_: string) {

    // TODO Set seen of all FormPosts to True.
    // const col = await this.af.collection('users').get().toPromise();
    // const users = col.docs.map((document) => document.id)
    //   .filter((user) => !user.endsWith('.internal@techodactyl.com '));
    // const batch = this.af.firestore.batch();
    // const update = {
    //   '8ZvRSXvfAhtu0ECO110T': true,
    //   'UiXecAoI5UHS70QNE9Vw': true,
    //   'evf9ZYMMEGXhatwDkfc1': true,
    //   'gg21ygBSIhs1agHav4Oo': true,
    //   'h1u6p3sD0lDw0I30CVqE': true,
    //   'jy0WT8070Kmke9MG2hgj': true,
    //   'ux6rILO7RZrzWbu59rQD': true,
    // };
    // users.forEach((userID) => {
    //   console.log(`USER: ${userID}`);
    //   batch.set(this.af.doc(`users/${userID}/singular_documents/last_seen_form_post`).ref, update);
    // });
    // await batch.commit();
    // console.log('DOPE');

    // this.queryStockUpdates('JuimjSgn2R4VnFhvA2aY', {code: '16040650'}).then((data) => {
    //   console.log(data);
    // });
    // console.log('------------------------------------');

    // // users/baileyhassall@gmail.com/singular_documents/last_msg_timestamp
    // const col = await this.af.collection('users').get().toPromise();
    // const users = col.docs.map((document) => document.id)
    //   .filter((user) => !user.endsWith('.internal@techodactyl.com '));
    // console.log(users);
    // const batch = this.af.firestore.batch();
    //
    // users.forEach((user) => {
    //   batch.set(
    //     this.af.doc(`users/${user}/singular_documents/last_msg_timestamp`).ref,
    //     {ts: 1714514400000}
    //   );
    // });
    // batch.commit().then(() => console.log('DOPE'));

    // TODO: This does not work. Promises and arrow functions lose their names

    // const f1 = () => {
    //   f2(0);
    // };
    // const f2 = (i: number) => {
    //   if (i === 0) {
    //     f3();
    //   } else {
    //     f4();
    //   }
    // };
    // const f3 = () => {
    //   f2(1);
    // };
    // const f4 = () => {
    //   callerCrawler();
    // };
    //
    // const callerCrawler = () => {
    //   console.clear();
    //   let e = (new Error()).stack;
    //   const funcChain = [];
    //   let idx = e.search(/\w+@\w+/);
    //   while (idx >= 0) {
    //     const edx = e.indexOf('@', idx);
    //     funcChain.push(e.substring(idx, edx));
    //     e = e.substring(edx +1);
    //     idx = e.search(/\w+@\w+/);
    //   }
    //   console.log(funcChain);
    //   // let caller = callerCrawler.caller;
    //   // let i = 5;
    //   // const funcChain = [];
    //   //
    //   // while (i) {
    //   //   funcChain.push(caller);
    //   //   i--;
    //   //
    //   //   try {
    //   //     caller = caller.caller;
    //   //   } catch (e) { console.log(e); }
    //   // }
    //   // let s = '';
    //   // while (funcChain.length) { s = s + ' -> ' + funcChain.pop().name; }
    //   // return s;
    // };
    //
    // f1();

    // this.queryStock('JuimjSgn2R4VnFhvA2aY', [{q: 'limit', p: [120]}]).then((stock) => {
    //   const batch = this.af.firestore.batch();
    //   (stock as {ids: string[]}).ids.forEach((stockID) => batch.update(
    //     this.af.doc(`/shared/stores_data/JuimjSgn2R4VnFhvA2aY/data/stock/${stockID}`).ref,
    //     {_tags: ARRAY_UNION('in_ngp')}
    //   ));
    //   batch.commit().then(() => alert('o(*°▽°*)o'));
    // });

    // const queries: FbQuery[] = [{q: 'where', p: ['_tags', 'array-contains', 'in_ngp']}];
    // const stores = ['GHQd7S9ae4kcr1u5qfVR', 'KDOT6Zvx5VYesN91IhvT', 'RYGP1hxeZsMy4QI0GdZ3',
    // 'X1PtGozg1ztJ2NqOa22g',
    // 'tdf3wa3gKzGHOSuZF0V9'];
    //
    // for (const storeID of stores) {
    //   const items = (await this.queryStock(storeID, queries)) as {ids: string[]; items: { [id: string]: any } };
    //   const stockIDs = items.ids.map((id) => id);
    //   console.log(storeID, stockIDs.length, 'items');
    //
    //   while (stockIDs.length) {
    //     const batch = this.af.firestore.batch();
    //     const batchSize = Math.min(stockIDs.length, 499);
    //
    //     for (let i = 0; i < batchSize; i++) {
    //       const stockID = stockIDs.pop();
    //       batch.update(
    //         this.af.doc(`shared/stores_data/${storeID}/data/stock/${stockID}`).ref,
    //         {_tags: ARRAY_REMOVE('in_ngp')}
    //       );
    //     }
    //     await batch.commit();
    //     console.log('  ', batchSize, 'done');
    //   }
    //   console.log('  done');
    // }

    // CHANGE AO SCHEDULE USER-IDS
    // console.clear();
    // const batch = this.af.firestore.batch();
    //
    // for (const day of ['Mo', 'Tu', 'We', 'Th', 'Fr']) {
    //   const coll = await this.af.collection(
    //     `operational/stores_data/1HAbtynze57UiSHiVO5q/data/auto_ordering/schedule/${day}`
    //   ).get().toPromise();
    //   console.log(day);
    //
    //   for (const doc of coll.docs) {
    //     const data = doc.data() as any;
    //
    //     if (data.userID === 'ruan@buildit.africa') {
    //       console.log(' ', doc.id);
    //       batch.update(
    //         this.af.doc(`operational/stores_data/1HAbtynze57UiSHiVO5q/data/auto_ordering/schedule/${day}/${doc.id}`).ref,
    //         {userID: 'groblersdal@buildit.africa'}
    //       );
    //     }
    //   }
    // }
    // await batch.commit();
    // console.log('dope');
    // this.queryStock('JuimjSgn2R4VnFhvA2aY', [{q: 'limit', p: [1000]}]).then(stock => {
    //   const update = {};
    //   // let time = 0;
    //   // let d = 23;
    //   // let count = 0;
    //   let d = new Date();
    //
    //   (stock as {ids: string[]}).ids.forEach((sID) => {
    //     update[sID] = d;
    //     d = dateDelta(d, {minutes: -2});
    //   });
    //   this.af.firestore.doc('/shared/stores_data/JuimjSgn2R4VnFhvA2aY/data/singular_documents/shelf_talkers')
    //     .update(update).then(() => console.log('DONE'));
    // });
    // const updates = {
    //   '17194948': new Date(),
    //   '16153067': new Date(),
    //   '16161306': new Date(),
    //   'HBSTDFSO': new Date(),
    //   '16165494': new Date(),
    // };
    // this.af.doc('/shared/stores_data/EOsNAgceflnj4paRD8v7/data/singular_documents/shelf_talkers')
    //   .update(updates).then(() => console.log('dope'));
    // this.af.doc('test/testing').get().toPromise().then(doc => {
    //   console.clear();
    //   console.log(doc.data());
    // });
    // this.af.collection('user_access').get().toPromise().then(ss => {
    //   const stores: { [storeID: string]: { [email: string]: string } } = {};
    //   ss.docs.forEach(doc => {
    //     const userID = doc.id;
    //     const access = doc.data() as { features: string[]; storeList: string[];
    //       stores: { [storeID: string]: 'admin' | 'manager' | 'shelf-talkers' }; };
    //
    //     for (const storeID of access.storeList) {
    //       if (!stores[storeID]) { stores[storeID] = {}; }
    //       stores[storeID][userID] = access.stores[storeID];
    //     }
    //   });
    //   const batch = this.af.firestore.batch();
    //
    //   for (const storeID of Object.keys(stores)) {
    //     const doc = this.af.firestore.doc(`stores/${storeID}/store_users/user_titles`);
    //     const update: any[] = [doc];
    //     Object.keys(stores[storeID]).forEach(email => {
    //       update.push(new FieldPath(email), stores[storeID][email]);
    //     });
    //     batch.set(this.af.firestore.doc(`stores/${storeID}/store_users/user_titles`), {});
    //     batch.update.apply(batch, update);
    //   }
    //   batch.commit().then(() => alert('DOPE'));
    // });
  }

  bulkPermissionTest(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'X',
        'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      const update = {};

      for (let i = 0; i < 25; i++) {
        for (let j = 0; j < 25; j++) {
          update[alpha[i] + alpha[j]] = {v1: +(Math.random().toFixed(2)), v2: +(Math.random().toFixed(2))};

          if (Object.keys(update).length === 500) {
            break;
          }
        }
        if (Object.keys(update).length === 500) {
          break;
        }
      }
      const batch = this.af.firestore.batch();
      Object.keys(update).forEach(key => batch.set(
        this.af.doc(`/test/bulk_rules_test/bulk_rules_test/${key}`).ref, update[key]
      ));

      batch.commit().then(() => resolve()).catch(e => reject(e));
    });
  }

  /* TODO: -------------------------------------------------------------------------------------------------------------
      * <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> *
      * ******************************************* BAD BAD REMOVE AND MOVE ****************************************** *
      * <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> <> *
      --------------------------------------------------------------------------------------------------------------- */
  // superTempHasAccess(storeID: string, ruleNum: number, access: number = this.userAccess.stores[storeID]): boolean {
  //   return Math.floor((access / Math.pow(2, ruleNum)) % 2) === 1;
  // }

  /* ----------------------------------------------- FIREBASE ALERTS ------------------------------------------------ */

  private checkForForcedReload(d: any) {
    if (this.userObjSubject.getValue() !== null && d.hasOwnProperty('forceReload')) {
      this.forceReload.register(d.forceReload);
      this.af.doc(`users/${this.userID}`).update({forceReload: DELETE_FILED()}).then(() => {
        console.log('Force Update Variable Cleared');
      });
    }
  }

  // private newMessagesToast(messages: Message[]) {
  //
  //   if (messages.length === 0) {
  //     return;
  //   }
  //   this.stores.pipe(take(1)).toPromise().then(storeInfo => {
  //     const notify = () => {
  //       const msg = messages.pop();
  //
  //       if ( ! msg) { return; }
  //
  //       this.toastControl.create({
  //         header: `New Message from ${storeInfo.stores[msg.sender] ? storeInfo.stores[msg.sender].name : msg.sender}`,
  //         message: msg.payload.body ? msg.payload.body : (msg.payload.hasOwnProperty('success') ?
  //           `Request was ${msg.payload.success?'Successful':'Unsuccessful'}` : 'No body available.'),
  //         cssClass: ['custom-toast', 'new-msg-toast'], duration: 3000, position: 'top'
  //       }).then(tc =>
  //         tc.present().then(() =>
  //           tc.onDidDismiss().then(() => notify())
  //         )
  //       );
  //     };
  //     notify();
  //   });
  // }
}
