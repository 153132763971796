import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AlertController, IonAccordionGroup, ModalController, PopoverController, ToastController} from '@ionic/angular';
import {
  DisabledRules,
  LineColour,
  StoreInfo,
  TableNavSettings,
} from '../../../../shared-utilities/models-old/datastructures';
import {FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {
  checkNgpReportSettings,
  DEFAULT_NG_REP_SETTINGS,
  NgpReportSettings,
} from '../../../../shared-utilities/models-old/ngp-report/ngp-data-types';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {StandardAlertsService} from '../../../../shared-utilities/services-old/standard-alerts.service';
import {selectSelectedUserStores} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {Store} from '@ngrx/store';
import {SelectPopoverComponent} from '../select-popover/select-popover.component';
import {ColDef} from 'ag-grid-community';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  ngpReportGridColDefs,
} from '../../../../shared-utilities/models-old/ngp-report-grid/defaults/ngp-report/ngp-report-grid-col-defs';
import {
  getNGPReportOptions,
  setNGPOptionsTableNavSettings,
  setNgpReportOptionsPreviewColumns,
} from '../../../../features-as-modules/feature-options/store/options.actions';
import {selectNGPReportOptions} from '../../../../features-as-modules/feature-options/store/options.selectors';

@Component({
  selector: 'app-ngp-report.ts-setting',
  templateUrl: './ngp-report-setting.component.html',
  styleUrls: ['./ngp-report-setting.component.scss'],
})
export class NgpReportSettingComponent implements OnInit {

  @Input() storeID: string;
  @Input() storeInfo: StoreInfo = {name: ''};
  @Input() openAccordion?: string;

  selectPopover: {
    order: string[]; selection: { [key: string]: string }; disabledKeys: string[]; value: string[];
  } = {
    order: [], selection: {}, disabledKeys: [], value: [],
  };
  ngpSettings: NgpReportSettings;
  changed: { [key in keyof NgpReportSettings]: true };
  itemDisablingRules: DisabledRules;
  currentStore: IStore | null;
  lineColours: LineColour;
  previewColumns: { [key: number]: string };

  ngpSettingsFG: UntypedFormGroup;
  navSettings: TableNavSettings;
  @ViewChild('accordionGroup', {static: true}) accordionGroup: IonAccordionGroup;

  constructor(
    private firebase: FirebaseService,
    private stdAlerts: StandardAlertsService,
    private alertControl: AlertController,
    private modalController: ModalController,
    private toastControl: ToastController,
    private popoverController: PopoverController,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getNGPReportOptions());
    this.store.select(selectSelectedUserStores).subscribe((store: IStore) => {
      this.currentStore = store;
    });

    this.store.select(selectNGPReportOptions).subscribe((
      result: {
        rulesDoc: DisabledRules;
        lineColours: LineColour;
        navSettings: TableNavSettings;
        colPreview: { [key: number]: string };
      }) => {
      this.lineColours = result?.['lineColours'];
      this.itemDisablingRules = result?.['rulesDoc'];
      this.navSettings = result?.['navSettings'];
      this.previewColumns = result?.['colPreview'];
    });

    this.stdAlerts.indeterminateProgress({}).then((finished) => {
      this.firebase.getStoreDataDoc('general_ngp_settings', this.storeID, 'operational').then((data) => {
        console.log('>', data);
        this.ngpSettings = data ? data : DEFAULT_NG_REP_SETTINGS;
        this.ngpSettingsFG = new UntypedFormGroup({});
        this.ngpSettingsFG.setControl('itemExpDays', new FormControl<number>(this.ngpSettings.itemExpDays,
          [this.validateNgpReportSetting('itemExpDays')]));
        this.ngpSettingsFG.setControl('requestExpDays', new FormControl<number>(this.ngpSettings.requestExpDays,
          [this.validateNgpReportSetting('requestExpDays')]));

        Object.keys(this.ngpSettingsFG.controls).forEach((key) => {
          this.ngpSettingsFG.controls[key].setParent(this.ngpSettingsFG);
        });
        finished();
      });
    });
    if (this.openAccordion) {
      this.accordionGroup.value = this.openAccordion;
    }
  }


  close = () => {
    this.modalController.dismiss().then();
  };

  change(event, key: keyof NgpReportSettings) {
    console.log('' + event.target.value, '' + this.ngpSettings[key], '' + event.target.value !== '' + this.ngpSettings[key]);

    if ('' + event.target.value !== '' + this.ngpSettings[key] &&
      !(event.target.value === '' && [undefined, null].includes(this.ngpSettings[key]))) {
      if (!this.changed) {
        this.changed = {[key]: true} as any;
      } else {
        // @ts-ignore
        this.changed[key] = true;
      }
    } else if (this.changed) {
      // @ts-ignore
      delete this.changed[key];

      if (Object.keys(this.changed).length === 0) {
        this.changed = null;
      }
    }
  }

  async onOpenPreviewColumnsClick(fieldEvent: Event) {
    this.convertColumnDefinitionsToSelectPopover(ngpReportGridColDefs);
    const popover: HTMLIonPopoverElement = await this.popoverController.create({
      component: SelectPopoverComponent, componentProps: {
        cancelText: 'Cancel',
        disabled: this.selectPopover.disabledKeys,
        maxListHeight: 250,
        multiple: true,
        okText: 'Apply',
        order: this.selectPopover.order,
        title: 'Set Preview Columns',
        search: true,
        selectAll: true,
        selection: this.selectPopover.selection,
        value: this.selectPopover.value,
      }, event: fieldEvent,
    });
    await popover.present();
    const data = await popover.onDidDismiss();
    if (data.data) {
      console.log(data);
      this.store.dispatch(setNgpReportOptionsPreviewColumns({columnData: {...data.data}}));
    }
  }

  checkRemoveValue(key: keyof NgpReportSettings, valueOnWhichToRemove: string | number) {
    // @ts-ignore
    const v = this.ngpSettingsFG.get(key).value;

    if (v === valueOnWhichToRemove) {
      // @ts-ignore
      this.ngpSettingsFG.get(key).setValue(null);
    }
  }

  getErrors(key: keyof NgpReportSettings) {
    const errors: string[] = [];
    // @ts-ignore
    const e = this.ngpSettingsFG.get(key).errors;
    Object.keys(e).forEach((k) => {
      errors.push(e[k]);
    });
    return errors;
  }

  async save() {
    if (this.ngpSettingsFG.invalid) {
      return;
    }

    const value = this.ngpSettingsFG.value;

    const ac = await this.alertControl.create({
      header: 'Update General NGP Settings', subHeader: 'You are about to update the NGP Report settings for ' +
        this.storeInfo.name + '.', message: 'This will affect all users with access to Report for ' +
        this.storeInfo.name + '<br><br>Are you sure you want to continue?', cssClass: ['custom-alert', 'warn'],
      buttons: ['Cancel', {text: 'Yes', role: 'y'}],
    });
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role !== 'y') {
      return;
    }

    this.stdAlerts.indeterminateProgress({}).then((loaded) => {
      this.firebase.updateStoreDataDoc('general_ngp_settings', this.storeID, value, 'operational')
        .then(async () => {
          loaded();
          const tc = await this.toastControl.create({
            header: 'Settings Saved', cssClass: 'custom-toast', duration: 1500, position: 'top',
          });
          await tc.present();
          this.changed = null;
        })
        .catch((error) => {
          loaded();
          this.stdAlerts.errorAlert({
            message: error.message,
            type: (error.message.includes('Missing or insufficient permissions') ? 'PERMISSION' : null),
          });
        });
    });
  }

  async setTableNave(data: TableNavSettings): Promise<void> {
    if (data) {
      this.navSettings = data;
      this.store.dispatch(setNGPOptionsTableNavSettings({settings: data}));
    }
  }

  private convertColumnDefinitionsToSelectPopover(colDefs: ColDef[]): void {
    let isVisible = this.previewColumns;
    this.selectPopover.selection = {};
    const order = [];
    const disabled = [];
    const selected = [];
    colDefs = [...colDefs];
    colDefs.forEach((col: ColDef) => {
      const description: string = GridUtils.getHumanReadableColumnIdentifier(col);
      if (col.lockPinned && col.field !== 'disabled') {
        disabled.push(col.field);
      }
      if (Object.values(isVisible).length > 0) {
        if (isVisible[col.field] || (col.lockPinned && col.field !== 'disabled')) {
          selected.push(col.field);
        }
      } else {
        if (!col.hide) {
          selected.push(col.field);
        }
      }

      order.push(col.field);
      this.selectPopover = {
        order,
        selection: {...this.selectPopover.selection, [col.field]: description},
        disabledKeys: disabled,
        value: selected,
      };
      const previewColumnsValues = Object.values(this.previewColumns);
      const combinedSet = new Set([...disabled, ...previewColumnsValues]);

      this.selectPopover.value = Array.from(combinedSet);
    });
  }

  private validateNgpReportSetting(key: keyof NgpReportSettings): ValidatorFn {
    return (control): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const nrs = control.parent.value;
      nrs[key] = control.value;
      return checkNgpReportSettings(nrs, key);
    };
  }
}
