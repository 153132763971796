import {FreshnessExplanationComponent} from './freshness-explanation/freshness-explanation.component';
import {MessagesModalPage} from '../../../shared-modules/shared-module/modules-old/messages-modal/messages-modal.page';
import {MushroomEasterEggComponent} from '../../feature-easter-egg/mushroom-easter-egg/mushroom-easter-egg.component';
import {PageHeaderComponent} from './page-header/page-header.component';
import {SideNavComponent} from './side-nav/side-nav.component';

export const FRESHNESS_EXPLANATION = FreshnessExplanationComponent;
export const MUSHROOM_EASTER_EGG = MushroomEasterEggComponent;
export const MESSAGES_MODAL_COMPONENT = MessagesModalPage
export const PAGE_HEADER_COMPONENT = PageHeaderComponent;
export const SIDE_NAV_COMPONENT = SideNavComponent;

export const CORE_COMPONENTS = [
  FRESHNESS_EXPLANATION,
  MUSHROOM_EASTER_EGG,
  MESSAGES_MODAL_COMPONENT,
  PAGE_HEADER_COMPONENT,
  SIDE_NAV_COMPONENT
];

