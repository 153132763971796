import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {IndicatorStatus} from '../data-freshness-indicator/data-freshness-indicator.component';
import {ModalController} from '@ionic/angular';
import {StoreDataFreshnessAge} from '../../../../shared-utilities/services-old/data-freshness.service';
import {timeBreakDown2String} from '../../../../shared-utilities/functions-old/date-functions';
import {
  FreshnessExplanationComponent
} from '../../../../features-as-modules/feature-core/components/freshness-explanation/freshness-explanation.component';

@Component({
  selector: 'app-freshness-popup',
  templateUrl: './freshness-popup.component.html',
  styleUrls: ['./freshness-popup.component.scss'],
})
export class FreshnessPopupComponent implements OnInit, OnDestroy {

  @Input() dataObs: Observable<{
    age: StoreDataFreshnessAge;
    currentStatuses: { [key in keyof StoreDataFreshnessAge]:  IndicatorStatus};
    currentStatus: IndicatorStatus;
  }>;

  age: StoreDataFreshnessAge;
  currentStatuses: { [key in keyof StoreDataFreshnessAge]:  IndicatorStatus};
  currentStatus: IndicatorStatus;

  keyOrder: string[] = [];
  ageStrings: { [key in keyof StoreDataFreshnessAge]: string } = {};

  private subscription: Subscription;

  constructor(
    private modalController: ModalController,
  ) {
  }

  ngOnInit() {
    this.subscription = this.dataObs.subscribe((data) => {
      if (data.currentStatus) {
        this.age = data.age;
        this.currentStatuses = data.currentStatuses;
        this.currentStatus = data.currentStatus;
        this.keyOrder = Object.keys(this.currentStatuses);
        this.keyOrder.sort();

        this.keyOrder.forEach((key) => {

          if (this.age[key].msTotal < 0) {

            console.log(this.age[key].msTotal);
            // TODO: Need to use server time. Then this wont be necessary anymore
            this.age[key] = {d: 0, h: 0, m: 0, s: 0, ms: 0, msTotal: 0};
          }
          this.ageStrings[key] = timeBreakDown2String(this.age[key]);
        });
      }
    });

    setInterval(() => this.tick(), 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async onFreshnessExpClick() {
    const mc = await this.modalController.create({
      component: FreshnessExplanationComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

  private tick() {
    this.keyOrder.forEach((key) => {
      if (this.age[key].s < 58) {
        this.age[key].s += 1;
      } else {
        this.age[key].s = 0;
        this.age[key].m += 1;
      }
      this.ageStrings[key] = timeBreakDown2String(this.age[key]);
    });
  }

}
