import {MatTooltipModule} from '@angular/material/tooltip';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResizableModule} from './modules-old/resizable/resizable.module';
import {SaveUserSettingsPageModule} from './modules-old/save-user-settings/save-user-settings.module';
import {SHARED_MODULE_COMPONENTS} from './components';
import {SHARED_MODULE_DIRECTIVE} from './directives';
import {IonicModule} from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedIconsModule} from '../shared-icons/shared-icons.module';
import {AgGridAngular} from 'ag-grid-angular';
import {MatButtonModule} from '@angular/material/button';
import {SharedGridModule} from '../shared-grid/shared-grid.module';
import {StoreModule} from '@ngrx/store';
import {ngpReportReducer} from '../../features-as-modules/feature-ngp-report/store/ngp-report.reducer';
import {PushModule} from '@ngrx/component';

@NgModule({
  declarations: [
    ...SHARED_MODULE_COMPONENTS,
    ...SHARED_MODULE_DIRECTIVE
  ],
  imports: [
    // AgGridAngular?
    CommonModule,
    FormsModule,
    IonicModule,
    MatTooltipModule,
    ResizableModule,
    SaveUserSettingsPageModule,
    ReactiveFormsModule,
    SharedIconsModule,
    AgGridAngular,
    MatButtonModule,
    PushModule,
  ],
  exports: [
    ResizableModule,
    SaveUserSettingsPageModule, // Is this necessary?
    ...SHARED_MODULE_COMPONENTS,
    ...SHARED_MODULE_DIRECTIVE
  ]
})
export class SharedModule {
}
