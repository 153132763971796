import {IRange} from '../../models/range/range';

/**
 * Checks if a given value falls within the specified range.
 *
 * This function verifies if a value lies between the start and end values of the range. It considers whether the range is inclusive or exclusive
 * based on the `inc` (inclusive) property. If the value is outside the range or the range limits are not defined, the function returns `false`.
 *
 * @param {number} value - The value to check.
 * @param {IRange} range - The range object, which contains the start and end values, along with optional inclusivity flags.
 * @param {Object} range.start - The starting point of the range.
 * @param {number} range.start.v - The starting value of the range.
 * @param {boolean} [range.start.inc] - Whether the start value is inclusive (`true`) or exclusive (`false`).
 * @param {Object} range.end - The ending point of the range.
 * @param {number} range.end.v - The ending value of the range.
 * @param {boolean} [range.end.inc] - Whether the end value is inclusive (`true`) or exclusive (`false`).
 * @returns {boolean} - Returns `true` if the value falls within the range (considering inclusivity/exclusivity), otherwise `false`.
 *
 * @example
 * const range = { start: { v: 10, inc: true }, end: { v: 20, inc: false } };
 * const result = isValueBetweenRanges(15, range);
 * console.log(result); // Output: true
 *
 * const result2 = isValueBetweenRanges(20, range);
 * console.log(result2); // Output: false (20 is exclusive)
 */
export function isValueBetweenRanges(value: number, range: IRange): boolean {
  if (range?.start && range.start?.v !== null && range.start.v !== undefined) {
    if (range.start.v >= value && !(range.start.inc && range.start.v === value)) {
      return false;
    }
    if (range?.end && range.end?.v !== null && range.end.v !== undefined) {
      if (range.end.v <= value && !(range.end.inc && range.end.v === value)) {
        return false
      }
    }
  }
  return true;
}

/**
 * Rounds a number to the specified number of decimal places.
 *
 * This function rounds the provided number down to the specified number of decimal places. It works by multiplying
 * the number by a factor of 10 raised to the power of `decimalDigits`, flooring the result, and then dividing it back.
 *
 * @param {number} value - The number to be rounded.
 * @param {number} decimalDigits - The number of decimal places to round to.
 * @returns {number} - The value rounded down to the specified decimal places.
 *
 * @example
 * const result = convertToDecimal(12.34567, 2);
 * console.log(result); // Output: 12.34
 */
export function convertToDecimal(value: number, decimalDigits: number): number {
  const factor = Math.pow(10, decimalDigits);
  return Math.floor(value * factor) / factor;
}

/**
 * Splits a number into two parts: the first part with all digits except the last `n`, and the second part being the last `n` digits.
 *
 * This function floors the input value to ensure it's an integer, then splits it into two parts:
 * the first part contains the digits before the last `n` digits, and the second part contains the last `n` digits.
 *
 * @param {number} value - The number to split. It is first floored to remove any decimal places.
 * @param {number} n - The number of digits from the end to split off as the second part.
 * @returns {number[]} - An array with two elements:
 * - The first element is the integer part with the last `n` digits set to zero.
 * - The second element is the last `n` digits of the number.
 *
 * @example
 * const result = splitValueAtN(123456, 3);
 * console.log(result); // Output: [123000, 456]
 */
export function splitValueAtN(value: number, n: number): number[] {
  const flooredValue = Math.floor(value);
  const tens = Math.pow(10, n);
  const integerPart = Math.floor(flooredValue / tens);
  const removedPart = flooredValue % tens;

  return [integerPart * tens, removedPart];
}
