import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {StoreDataFreshnessAge} from '../../../../shared-utilities/services-old/data-freshness.service';

@Component({
    selector: 'app-shared-store-tabs',
    templateUrl: './shared-store-tabs.component.html',
    styleUrls: ['./shared-store-tabs.component.scss'],
})
export class SharedStoreTabsComponent {

    @Input() showFreshnessIndicator?: boolean;
    @Input() dataKey?: string[] = [];
    @Input() isModal?: boolean = false;
    @Input() isMessagesModal = false;
    @Output() readonly emitStoreChange: EventEmitter<IStore> = new EventEmitter<IStore>();
    stores: IStore[] = [];

    get getDataKey(): (keyof StoreDataFreshnessAge)[] {
        return [this.dataKey[0] as keyof StoreDataFreshnessAge];
    }

    @Input() set setStores(stores: IStore[]) {
        this.stores = stores;
        if (this.stores.length > 0) {
            this.emitStoreChange.emit(this.stores[0]);
        }
    }

    onStoreChange(customEvent: any): void {
        if (customEvent?.detail?.value) {
            this.emitStoreChange.emit(customEvent.detail.value as IStore);
        }
    }

    getTabMaxWidth(): string {
        const tabCount = this.stores.length;
        if (tabCount > 6) {
            return '100px';
        }
        return `calc(600px / ${tabCount})`;
    }

}
