import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PushModule} from '@ngrx/component';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module'
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {SharedGridModule} from '../../shared-modules/shared-grid/shared-grid.module';
import {OptionsEffects} from './store/options.effects';
import {OptionsReducer} from './store/options.reducer';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    IonicModule,
    MatTooltipModule,
    PushModule,
    FormsModule,
    SharedGridModule,
    SharedIconsModule,
    SharedModule,
    StoreModule.forFeature('options', OptionsReducer),
    EffectsModule.forFeature([OptionsEffects]),
  ]
})
export class OptionsModule {
}
