import {sItemIntToKey} from '../../../../shared-utilities/models-old/datastructures';

/**
 * Creates a deep copy of the provided object.
 *
 * This function takes an object of any type and returns a deep copy of it.
 * The deep copy is created by converting the object to a JSON string and then parsing it back into an object.
 * This method ensures that all nested objects and arrays are fully cloned, but it does not preserve functions, undefined values, or non-serializable properties.
 *
 * @template ObjectType - The type of the object to be copied.
 * @param {ObjectType} obj - The object to be deep-copied.
 * @returns {ObjectType} - A deep copy of the provided object.
 *
 * @example
 * const original = { name: 'John', details: { age: 30 } };
 * const copy = getDeepCopyOfObject(original);
 * console.log(copy); // Output: { name: 'John', details: { age: 30 } }
 * console.log(copy === original); // Output: false (different references)
 */
export function getDeepCopyOfObject<ObjectType>(obj: ObjectType): ObjectType {
  return JSON.parse(JSON.stringify(obj)) as ObjectType;
}

export function castObjectToType<CastType, ObjectType>(object: ObjectType): CastType {
  const newObject: CastType = {} as CastType;
  Object.keys(object).forEach((key: string): void => {
    newObject[sItemIntToKey[key]] = object[key];
  })
  return newObject;
}
