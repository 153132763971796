import {Component, Input, OnInit} from '@angular/core';
import {ModalController,} from '@ionic/angular';
import {LinkedDepartmentsLinkType} from '../../../../shared-utilities/models-old/datastructures';

export interface DepInfo {
  name: string;
  depID: string;
}

export interface SuggestedLink {
  storeA: DepInfo;
  storeB: DepInfo;
}

@Component({
  selector: 'app-unlinked-departments-modal',
  templateUrl: './unlinked-departments-modal.component.html',
  styleUrls: ['./unlinked-departments-modal.component.scss']
})
export class UnlinkedDepartmentsModalComponent implements OnInit {
  @Input() type: LinkedDepartmentsLinkType;
  @Input() suggestLinks: SuggestedLink[];
  @Input() storeABIDs: { storeA: string; storeB: string };
  @Input() noLinks?: string[];

  // @Output() updateDepartments = new EventEmitter<{ [storeID: string]: DepInfo }[]>();

  confirmedLinks: { [storeID: string]: DepInfo }[];

  typeText: string;


  constructor(
    private modalControl: ModalController,
  ) {
  }

  ngOnInit() {
    if (this.type === 'dep') {
      this.typeText = 'Department';
    } else if (this.type === 'sub') {
      this.typeText = 'Sub-Department';
    }
  }

  addDepartment(i: number) {
    const suggestion = this.suggestLinks[i];
    this.confirmedLinks.push({
      [this.storeABIDs.storeA]: suggestion.storeA,
      [this.storeABIDs.storeB]: suggestion.storeB,
    });
    this.removeDepartmentFromView(i);
  }

  removeDepartmentFromView(i: number) {
    this.suggestLinks.splice(i, 1);

    if (this.suggestLinks.length === 0) {
      // this.updateDepartments.emit(this.confirmedLinks);
      this.submit();
    }
  }

  submit() {
    this.modalControl.dismiss(this.confirmedLinks).then();
  }

  closeModal() {
    this.modalControl.dismiss().then();
  }
}
