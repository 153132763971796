import {leadingZero} from '../utils-old/formatting';


export interface Period {
  start: Date; end: Date;
  contains?(PeriodI): 'FULLY' | 'START' | 'END' | number;
  copy?(): PeriodC;
}
// contains?(PeriodI): 'FULLY' | 'START' | 'END' | number

export class PeriodC implements Period {
  end: Date;
  start: Date;

  constructor(start: Date | Period, end?: Date) {
    if (end) {
      this.start = start as Date;
      this.end = end;
    } else {
      start = start as Period;
      this.start = start.start;
      this.end = start.end;
    }
  }

  contains(period: PeriodC): 'FULLY' | 'START' | 'END' | number {
    const containsStart = this.start <= period.start && this.end >= period.start;
    const containsEnd = this.start <= period.end && this.end >= period.end;
    const contains = containsStart ? (containsEnd ? 'FULLY' : 'START') : (containsEnd ? 'END' : false);

    if (contains) { return contains; }
    return period.end < this.start ? period.end.valueOf() - this.start.valueOf() :
      period.end.valueOf() - this.end.valueOf();
  };

  copy(): PeriodC {
    return new PeriodC(this.start, this.end);
  };
}

// Period.prototype.contains = function(period: PeriodI): 'FULLY' | 'START' | 'END' | number {
//   const containsStart = this.start <= period.start && this.end >= period.start;
//   const containsEnd = this.start <= period.end && this.end >= period.end;
//   const contains = containsStart ? (containsEnd ? 'FULLY' : 'START') : (containsEnd ? 'END' : false);
//
//   if (contains) { return contains; }
//   return period.end < this.start ? period.end.valueOf() - this.start.valueOf() :
//     period.end.valueOf() - this.end.valueOf();
// };

// Period.prototype.copy = function(): Period {
//   return {start: this.start, end: this.end};
// };

export type Interval = 'Daily' | 'Weakly' | 'BiWeakly' | 'Monthly' | number;

/**
 *
 * @param start     - first date in the list
 * @param interval  - interval at which to space the dates
 * @param end       - last possible date allowed (by default it will be the last day of the month of the start date)
 * @param zeroTimes - default true: set the times of the dates to 00:00:00.0, else the start dates time is used
 */
export const futureDatesTill = (start: Date, interval: Interval, end?: Date, zeroTimes: boolean = true): Date[] => {
  const endDate = end ? end : lastDayOMonth(start);

  if (zeroTimes) {
    start.setHours(0, 0, 0, 0);
    start.setHours(0, 0, 0, 0);
  }
  const fdt: Date[] = [start];
  let newDateF: () => Date;
  switch (interval) {
    case 'Daily':
      newDateF = () => dateDelta(fdt[fdt.length -1], {days: 1});
      break;
    case 'Weakly':
      newDateF = () => dateDelta(fdt[fdt.length -1], {days: 7});
      break;
    case 'BiWeakly':
      newDateF = () => dateDelta(fdt[fdt.length -1], {days: 14});
      break;
    case 'Monthly':
      newDateF = () => {
        let d = new Date(fdt[fdt.length - 1].getTime());
        d.setMonth(fdt[fdt.length - 1].getMonth() + 1);

        if (d.getDate() !== fdt[fdt.length - 1].getDate()) {
          d.setMonth(d.getMonth() - 1);
          d = lastDayOMonth(d);
        }
        return d;
      };
      break;
    default:
      if (typeof interval !== 'number') { throw Error('interval is not of type Interval'); }
      if (interval < 1 || interval > 365 || Math.floor(interval) !== interval) {
        throw Error('interval must be recognised string literal or an integer between 1 and 365.');
      }
      newDateF = () => dateDelta(fdt[fdt.length - 1], {days: interval});
      break;
  }
  let next = newDateF();

  while (next.getTime() <= endDate.getTime()) {
    fdt.push(next);
    next = newDateF();
  }
  return fdt;
};

export const dateDelta = (date: Date, delta: {
  years?: number; months?: number; days?: number; hours?: number;
  minutes?: number; secs?: number; mss?: number;
}): Date => {
  const Y = delta.years !== undefined ? delta.years : 0;
  const M = delta.months !== undefined ? delta.months : 0;
  const D = delta.days !== undefined ? delta.days : 0;
  const h = delta.hours !== undefined ? delta.hours : 0;
  const m = delta.minutes !== undefined ? delta.minutes : 0;
  const s = delta.secs !== undefined ? delta.secs : 0;
  const ms = delta.mss !== undefined ? delta.mss : 0;
  return new Date(
    date.getFullYear() + Y, date.getMonth() + M, date.getDate() + D, date.getHours() + h,
    date.getMinutes() + m, date.getSeconds() + s, date.getMilliseconds() + ms
  );
};

export const lastDayOMonth = (date: Date): Date => {
  const d = new Date(date.getTime());
  d.setMonth(d.getMonth() + 1);
  d.setDate(0);
  return d;
};

export const firstDayOMonth = (date: Date): Date => {
  const d = new Date(date.getTime());
  d.setDate(1);
  return d;
};

export const firstDayOWeek = (date: Date, firstDay: 'sunday' | 'monday' = 'sunday'): Date => {
  const target = firstDay.toLowerCase() === 'sunday' ? 0 : (firstDay.toLowerCase() === 'monday' ? 1 : null);

  if (target === null) {
    throw Error(`Parameter 'firstDay' must be "sunday" or "monday". ${firstDay} not a valid value.`);
  }
  return dateDelta(date, {days: target - date.getDay()});
};

export const lastDayOfWeek = (date: Date, lastDay: 'saturday' | 'sunday' = 'saturday'): Date => {
  const target = lastDay.toLowerCase() === 'saturday' ? 6 : (lastDay.toLowerCase() === 'sunday' ? 7 : null);

  if (target === null) {
    throw Error(`Parameter 'firstDay' must be "saturday" or "sunday". ${lastDay} not a valid value.`);
  }
  return dateDelta(date, {days: target - date.getDay()});
};

export const strToDate = (dateStr: string, dropTime: boolean = false): Date => {
  const p1 = new RegExp(/^(?<D>\d\d?)(?<d1>[/\-])(?<M>\d\d?)\k<d1>(?<Y>\d{4})/.source +
    /(?:\s+(?<h>\d\d?):(?<m>\d\d?)(:(?<s>\d\d?)(:?\.(?<ms>\d{1,3}))?)?)?$/.source);
  const p2 = new RegExp(/^(?<Y>\d{4})(?<d1>[/\-])(?<M>\d\d?)\k<d1>(?<D>\d\d?)/.source +
    /(?:(?:\s+|T)(?<h>\d\d?):(?<m>\d\d?)(?::(?<s>\d\d?)(:?\.(?<ms>\d{1,3})(?<tz>[a-zA-Z]{1,2})?)?)?)?$/.source);

  let match = p1.exec(dateStr);

  if (!match) {
    match = p2.exec(dateStr);
  }

  if (match) {
    let s = `${match.groups.Y}-${match.groups.M}-${match.groups.D}`;

    if (match.groups.h && !dropTime) {
      s += `T${match.groups.h}:${match.groups.m}`;
      if (match.groups.s) {
        s += match.groups.s;
        if (match.groups.ms) {
          s += match.groups.ms;
          if (match.groups.tz) {
            s += match.groups.tz;
          }
        }
      }
    }
    const d = new Date(s);

    if (!isNaN(d.valueOf())) {
      return d;
    }
  }
  return null;
};

export const sortDates = (dates: Date[], asc: boolean = true): Date[] =>
  dates.sort((a, b) => asc ? a.getTime() - b.getTime() : b.getTime() - a.getTime());

// TODO: sort by date

export interface TimeBreakDown {
  d: number;
  h: number;
  m: number;
  s: number;
  ms: number;
  msTotal: number;
}

export const timeBreakDown2String = (tbd: TimeBreakDown, ms?: boolean, zeroStr = '0'): string => {
  let str = '';
  if (tbd.d) { str = `${tbd.d} days`; }
  if (tbd.h) { str += (str !== '' ? ' ' : '') + `${tbd.h}h`; }
  if (tbd.m) { str += (str !== '' ? ' ' : '') + `${tbd.m}m`; }
  if (tbd.s) { str += (str !== '' ? ' ' : '') + `${tbd.s}s`; }
  if (ms) { str += (str !== '' ? ' ' : '') + `${tbd.ms}ms`; }
  return str ? str : zeroStr;
};

export const timeDiffBreakDown = (d1: Date, d2: Date, abs?: boolean): TimeBreakDown => {
  if (abs && d1.getTime() < d2.getTime()) {
    return timeBreakDown(d2.getTime() - d1.getTime());
  }
  return timeBreakDown(d1.getTime() - d2.getTime());
};

export const daysDiff = (d1: Date, d2: Date, abs?: boolean): number => {
  const days = (d1.getTime() - d2.getTime()) / (3600000 * 24);
  return abs ? Math.abs(days) : days;
};

/**
 * Check if two dates occur on the same Day. Time is ignored.
 * TODO Storm. Would it be more efficient to do math with getTime?
 *
 * @member {Date} dateOne
 * @member {Date} dateTwo
 */
export const isSameDay = (dateOne: Date, dateTwo: Date): boolean =>
  dateOne.getMonth()    === dateTwo.getMonth() &&
  dateOne.getDay()      === dateTwo.getDay() &&
  dateOne.getFullYear() === dateTwo.getFullYear();

export const timeBreakDown = (time: number): TimeBreakDown => {
  const d = Math.floor(time / (3600000 * 24));
  const h = Math.floor((time % (3600000 * 24)) / 3600000);
  const m = Math.floor((time % 3600000) / 60000);
  const s = Math.floor((time % 60000) / 1000);
  const ms = time % 1000;
  return {d, h, m, s, ms, msTotal: time};
};

export const sortPeriods = (periods: Period[], asc: boolean = true): Period[] => {
  const m = asc ? -1 : 1;
  return periods.sort((p1, p2) =>
    p1.start < p2.start ? m : (p1.start > p2.start ? -m : (p1.end < p2.end ? m : -1)));
};

export const isoDateIgnoreTimeZone = (d: Date): string =>
  `${d.getFullYear()}-${leadingZero(d.getMonth() +1)}-${leadingZero(d.getDate())}`;

export const isoTimeIgnoreTimeZone = (d: Date): string =>
  `${d.getFullYear()}-${leadingZero(d.getMonth() +1)}-${leadingZero(d.getDate())}` +
  `T${leadingZero(d.getHours())}:${leadingZero(d.getMinutes())}:${leadingZero(d.getSeconds())}.${d.getMilliseconds()}`;

// export const contains()

export const minEnclosingPeriods = (periods?: Period[], dates?: Date[], maxNumPeriods: number = 1):
  PeriodC[] => {
  periods = periods ? periods : [];
  periods = periods.map(p => new PeriodC(p.start, p.end));
  dates = dates ? dates : [];
  maxNumPeriods = maxNumPeriods >= 1 ? maxNumPeriods : 1;

  if (periods.length <= 0 && dates.length <= 0) { return null; }

  if (dates.length >= 1) { throw Error('Dates not supported yet NB NB'); }

  // interface Gap {
  //   start: Date; end: Date; size: number;
  // }
  interface PeriodNode {
    period: PeriodC;
    parent: PeriodNode;
    next?: PeriodNode;
  }

  periods = sortPeriods(periods);
  // dates = sortDates(dates);
  const root: PeriodNode = {period: periods[0].copy(), parent: null};
  let curr: PeriodNode = root;
  let numNodes = periods.length;

  for (let i = 1; i < periods.length; i++) {
    curr.next = {period: periods[i].copy(), parent: curr};
    curr = curr.next;
  }

  while (numNodes > maxNumPeriods) {
    let minGap: { parent: PeriodNode; gap: number } = null;
    curr = root;
    const length = numNodes;

    while (curr !== undefined && curr.next !== undefined) {
      const contain = curr.period.contains(curr.next.period);

      if (typeof contain === 'number') {
        if (minGap === null || Math.abs(contain) > Math.abs(minGap.gap)) {
          minGap = {parent: curr, gap: contain};
        }
      } else {
        switch (contain) {
          case 'FULLY':
            break;
          case 'START':
            curr.period.end = curr.next.period.end;
            break;
          case 'END':
            curr.period.start = curr.next.period.start;
            break;
        }
        curr.next = curr.next.next;
        numNodes--;
      }
      curr = curr.next;
    }

    if (length === numNodes) {
      curr = minGap.parent;

      if (minGap.gap > 0) {
        curr.period.end = curr.next.period.end;
      } else {
        curr.period.start = curr.next.period.start;
      }
      curr.next = curr.next.next;
      numNodes--;
    }
  }

  const result: PeriodC[] = [];
  curr = root;

  while (curr) {
    result.push(curr.period);
    curr = curr.next;
  }
  return result;
};
