import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SHARED_COMPONENTS} from './components';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module';
import {IonicModule} from '@ionic/angular';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS
  ],
  imports: [
    CommonModule,
    SharedModule,
    IonicModule,
    SharedIconsModule
  ],
  exports: [
    ...SHARED_COMPONENTS
  ]
})
export class SharedComponentsModule {
}
