import { NgModule } from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';

import { MessagesModalPage } from './messages-modal.page';
import {MsgApiResponseComponent, PopoverComponent} from './msg-api-response/msg-api-response.component';
import {MsgAoResponseComponent} from './msg-ao-response/msg-ao-response.component';

const routes: Routes = [
  {
    path: 'messages-modal',
    component: MessagesModalPage
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    IonicModule,
    CommonModule,
    MatTooltipModule,
    FormsModule,
    MatButtonModule
  ],
  providers: [
    { provide: MsgApiResponseComponent, useValue: {timestamp: 0, message: {}} },
    { provide: PopoverComponent, useValue: {timestamp: 0, message: {}} },
    { provide: MsgAoResponseComponent, useValue: {timestamp: 0, message: {}} },
  ]
})
export class MessagesModalPageRoutingModule {

}
