interface RuleInfoRaw {
  text: string;
  humanID: RuleHumanID;
  toolTip?: string;
  desc?: string;
  rules?: RuleInfoRaw[];
  depend?: string[];
}

export interface RuleInfo {
  text: string;
  humanID: RuleHumanID;
  index: number;
  rules: RuleInfo[];
  depend: RuleInfo[];
  toolTip?: string;
  desc?: string;
}

// interface AccessFlags {
//   text: string;
//   flag: string;
// }

export type RuleHumanID = 'a.' | 'b.' | 'b.i' | 'b.ii' | 'c.' | 'd.' | 'd.i' | 'd.ii' | 'd.iii' | 'd.iv' | 'd.v' |
  'd.vi' | 'e.' | 'f.';

const rawRuleStructure:  RuleInfoRaw[] = [
  {text: 'Edit Staff Access', humanID: 'a.'},                         // 0  ~ a.
  {text: 'View / Search Stock Items', rules: [                        // 1  ~ b.
      {text: 'Access Stock Edit Interface', humanID: 'b.i'},          // 2  ~ b.i
      {text: 'Submit Stock Edits to IQ', humanID: 'b.ii'},            // 3  ~ b.ii
    ], humanID: 'b.'},
  {text: 'View NGP Report', depend: ['b.i', 'b.ii'], humanID: 'c.'},  // 4  ~ c.
  {text: 'Access Auto Ordering Tool', rules: [                        // 5  ~ d.
      {text: 'See Colleagues Orders', humanID: 'd.i'},                // 6  ~ d.i
      {text: 'Edit Colleagues Orders', humanID: 'd.ii'},              // 7  ~ d.ii
      {text: 'Submit Auto Orders', humanID: 'd.iii'},                 // 8  ~ d.iii
      {text: 'Edit Schedule', humanID: 'd.iv'},                       // 9  ~ d.iv
      {text: 'Run Unscheduled Orders', humanID: 'd.v'},               // 10 ~ d.v
      {text: 'View & Edit Settings', humanID: 'd.vi'},                // 11 ~ d.vi
    ], humanID: 'd.'},
  {text: 'View Dashboard', humanID: 'e.'},                            // 12 ~ e.
  {text: 'View Shelf Talkers', humanID: 'f.', depend: ['b.']},        // 13 ~ f.
];

// const rawAccessFlags: AccessFlags[] = [
//   {flag: 'temp-admin-viewer', text: 'Temporary Admin View Access'},
// ];

export enum AccessFlags {
  isTechoAdmin = 'isTechoAdmin',
}

// const compileFlags = (): { [flag: string]:  }

const compile = (): { structured: RuleInfo[]; index: RuleInfo[]; dictionary: { [humanID: string]: RuleInfo } } => {
  const index: RuleInfo[] = [];
  const dictionary: { [humanID: string]: RuleInfo } = {};
  const incompleteRequiredBy: { [humanID: string]: RuleInfo[] } = {};

  const recursiveStep = (rules: RuleInfoRaw[] = rawRuleStructure, parent: RuleInfo = null) => {
    for (const raw of rules) {
      const rule: RuleInfo = {humanID: raw.humanID, index: index.length, text: raw.text, depend: [], rules: []};

      if (AccessFlags[rule.humanID]) {
        alert('A rule\'s `humanID` is equal to an AccessFlag enum');
        throw Error('A rule\'s `humanID` is equal to an AccessFlag enum');
      }
      index.push(rule);
      dictionary[rule.humanID] = rule;

      if (parent) {
        rule.depend.push(parent);
        parent.rules.push(rule);
      }

      if (incompleteRequiredBy[rule.humanID]) {

        for (const dependable of incompleteRequiredBy[rule.humanID]) {
          dependable.depend.push(rule);
        }
        delete incompleteRequiredBy[rule.humanID];
      }

      for (const humanID of raw.depend ? raw.depend : []) {
        if (dictionary[humanID]) {
          rule.depend.push(dictionary[humanID]);
        } else if (incompleteRequiredBy[humanID]) {
          incompleteRequiredBy[humanID].push(rule);
        } else {
          incompleteRequiredBy[humanID] = [rule];
        }
      }

      if (raw.rules) {
        recursiveStep(raw.rules, rule);
      }
    }
  };
  recursiveStep();

  if (Object.keys(incompleteRequiredBy).length) {
    throw Error(`One ore more rules dependent on humanIDs that dont exist?. Dingus\n` +
      JSON.stringify(incompleteRequiredBy));
  }
  const structured = rawRuleStructure.map((raw) => dictionary[raw.humanID]);
  return {structured, index, dictionary};
};

export const ruleStructure = compile();

export const hasRule = (humanID: string, bitsAccess: (0 | 1)[]): boolean =>
  bitsAccess[ruleStructure.dictionary[humanID].index] === 1;

export const pageRules = (page: string): (RuleHumanID | AccessFlags)[] | false => {
  switch (page) {
    case 'admin-info':
      return [AccessFlags.isTechoAdmin];
    case 'admin-internal':
      return [];
    case 'home':
      return [];
    case 'manage-it/dep-sales-summary':
      return false;
    case 'dss-modal':
      return false;
    case 'login':
      return [];
    case 'messages-modal':
      // TODO: Was this because I used a page instead of a component?
      return [];
    case 'shelf-talkers':
      return ['f.'];
    case 'ngp-report.ts-new':
    case 'ngp-report.ts':
    case 'ngp-report':
      return ['c.']; // ['c.', 'b.i', 'b.ii']
    case 'save-user-settings':
      // TODO: Was this because I used a page instead of a component?
      return [];
    case 'barcode-scanner':
      return ['c.'];
    case 'auto-ordering':
      return ['d.'];
    case 'testing':
      return [];
      // Give access to AccessFlags.isTechoAdmin for testing
    case 'stock-match':
      // return ['b.', 'b.i', 'b.ii'];
      return [];
    case 'stock':
    case 'stock-manager':
    case 'stock-updates':
      return ['b.i'];
    case 'dashboard':
      return ['e.'];
    case 'user-access':
      return ['a.'];
    case 'signup':
      return [];
    default:
      return false;
  }
};

export const validateRule = (index: number, access: number): boolean => {
  const validated: { [i: number]: boolean } = {};

  const recursiveStep = (i: number): boolean => {
    if (!validated.hasOwnProperty(i)) {
      if (Math.floor((access / Math.pow(2, i)) % 2) === 1) {
        validated[i] = true;

        for (const rule of ruleStructure.index[i].depend) {
          if (!recursiveStep(i) && validated[i]) {
            validated[i] = false;
          }
        }
        return validated[i];
      } else {
        validated[i] = false;
      }
    }
    return validated[i];
  };

  if (!recursiveStep(index)) {
    delete validated[index];

    if (Object.keys(validated).length > 0) {
      return validated[index];
    } else {
      return false;
    }
  }
  return true;
};
