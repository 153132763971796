import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {IonNav, IonRouterOutlet, ModalController} from '@ionic/angular';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NavigableModalComponent} from '../navigable-modal/navigable-modal.component';
import {NgpReportSettingComponent} from '../ngp-report-setting/ngp-report-setting.component';
import {DisableSupplierSettingsComponent} from '../disable-supplier-dettings/disable-supplier-settings.component';
import {AutomationSettingsEmailComponent} from '../automation-settings-email/automation-settings-email.component';
import {StoreInfoComponent} from '../store-info/store-info.component';
import {FireAuthService} from '../../../../shared-utilities/services-old/fire-auth.service';
import {StoreInfo} from '../../../../shared-utilities/models-old/datastructures';
import {RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {Store} from '@ngrx/store';
import {getUserStores, setUserSelectedStore} from '../../../../features-as-modules/feature-core/store/core.actions';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {selectUserStores} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {StockFunctions} from '../../../../shared-utilities/functions-old/stock-functions';
import {
  getLineColours, getStockItem,
  getStoreDepartments,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {subQueryStock2} from '../../../../shared-utilities/queries-old/sub-query-stock-2';
import {getNGPReportOptions} from '../../../../features-as-modules/feature-options/store/options.actions';

@Component({
  selector: 'app-store-config',
  templateUrl: './store-config.component.html',
  styleUrls: ['./store-config.component.scss'],
})
export class StoreConfigComponent implements OnInit, OnDestroy {

  stores: { order: string[]; stores: { [storeID: string]: StoreInfo } };
  selectedStoreBS: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  changes: { [document: string]: any };
  disabled: { [storeID: string]: boolean } = {};

  userStores$ : Observable<IStore[]>;
  selectedStore: IStore;

  private modalButtons: { [storeID: string]: { text: string; component: any; rules: (RuleHumanID)[] }[] } = {};
  private readonly modalButtonsAll: { text: string; component: any; rules: (RuleHumanID)[] }[] = [
    {text: 'Store Information (Temporary)', component: StoreInfoComponent, rules: ['d.', 'd.iv', 'd.vi']},
    {text: 'Define suppliers disabling rules', component: DisableSupplierSettingsComponent, rules: ['d.', 'd.iv', 'd.vi']},
    {text: 'Configure general NGP Report settings', component: NgpReportSettingComponent, rules: ['c.', 'a.']},
    {text: 'Edit Auto Ordering Email Settings', component: AutomationSettingsEmailComponent, rules: ['d.', 'd.iv', 'd.vi']},
  ];

  private subs: Subscription[] = [];

  constructor(
    private firebase: FirebaseService,
    private fireAuth: FireAuthService,
    private modalController: ModalController,
    private nav: IonNav,
    private store: Store,
    @Optional() private routerOutlet: IonRouterOutlet,
  ) { }

  get buttons(): { text: string; component: any; rules: (RuleHumanID)[] }[] {
    return this.modalButtons[this.selectedStoreBS.value];
  }

  ngOnInit() {
    this.subs.push(this.firebase.stores.subscribe((stores) => {
      this.stores = stores;
      this.selectedStoreBS.next(stores.order[0]);
      // TODO: Eww in general
      // stores.order.forEach((storeID) => {
      //   if (!this.disabled.hasOwnProperty(storeID)) {
      //     this.disabled[storeID] = !this.ok(storeID);
      //   }
      // });
    }));
    this.store.dispatch(getUserStores({pageStoreDocument: 'messages-modal'}));
    this.subs.push(this.fireAuth.userAccess.subscribe((ua) => {
      this.modalButtons = {};
      ua.storeList.forEach((storeID) => {
        this.modalButtons[storeID] = [];

        for (const mb of this.modalButtonsAll) {
          let access = true;

          for (const hID of mb.rules) {
            if (this.fireAuth.hasAccess(storeID, {ruleID: hID}) !== true) {
              access = false;
              break;
            }
          }

          if (access) {
            this.modalButtons[storeID].push(mb);
          }
        }
      });
    }));
    this.userStores$ = this.store.select(selectUserStores);
    // setTimeout(() => this.open('app-automation-settings-email').then(), 1000);
  }

  ngOnDestroy() {
    for (const subscription of this.subs) {
      subscription.unsubscribe();
    }
  }

  onStoreChange(selectedStore: IStore): void {
    this.selectedStore = selectedStore;
    // Todo: Move this to an effect.
    this.store.dispatch(setUserSelectedStore({selectedStore}));
  }


  // ok(storeID: string): boolean {
  //   return this.fireAuth.hasAccess(storeID, {ruleID: 'b.ii'}) === true &&
  //     this.fireAuth.hasAccess(storeID, {ruleID: 'b.'}) === true;
  // }

  close = () => {
    this.nav.pop().then();
  };

  async open(component: any) {
    const storeID = this.selectedStoreBS.value;
    const storeInfo = this.stores.stores[storeID];

    console.log('rootParams:', storeID, storeInfo);

    const mc = await this.modalController.create({
      presentingElement: this.routerOutlet == null ? await this.modalController.getTop() : this.routerOutlet.nativeEl,
      component: NavigableModalComponent,
      componentProps: {root: component, rootParams: {storeID, storeInfo, cssClass: 'child-modal'}},
      cssClass: 'larger-modal',
      backdropDismiss: false
      // component, componentProps: {storeID, storeInfo}, cssClass: 'child-modal'
    });
    await mc.present();
    const {data} = await mc.onDidDismiss();
  }

  storeSelect = (storeID: string): boolean => true;

}
