import { NGPReport } from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import { FiltersAndTools } from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import { IStore } from '../../../shared-utilities/models-old/store/IStore';
import { HeaderMenuColumnData }from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';

export function getSelectNGPReportsFiltered(
    ngpReports: NGPReport[],
    filtersAndTools: FiltersAndTools
): NGPReport[] {
  if (!filtersAndTools) {
    return ngpReports || [];
  }
  let copyNGPReports: NGPReport[] = [...ngpReports];


  /** Negative Values Only ------------------------------------------------------------------ */
  if (filtersAndTools.isGpDifferenceNegativeOn && !filtersAndTools.isGpDifferencePositiveOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP < 0);
  }
  /** Positive Values Only ------------------------------------------------------------------ */
  else if (filtersAndTools.isGpDifferencePositiveOn && !filtersAndTools.isGpDifferenceNegativeOn) {
    copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.diffGP >= 0);
  }
  /** Show Preview ----------------------------------------------------------------------------- */
  else if (filtersAndTools.isGpDifferencePositiveOn && filtersAndTools.isGpDifferenceNegativeOn) {
    if (filtersAndTools.showSubmissionPreview) {
      const copyEditedNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
      const copySelectedNGPReports = ngpReports.filter(
          (row: NGPReport): boolean => row.isSelected && !row.isEdited
      );
      copyNGPReports = copyEditedNGPReports.concat(copySelectedNGPReports);
    } else if (filtersAndTools.isEditedItemsOn) {
      copyNGPReports = ngpReports.filter((row: NGPReport): boolean => row.isEdited);
    } else {
      copyNGPReports = ngpReports;
    }
  }
  /** No Values ----------------------------------------------------------------------------- */
  else {
    copyNGPReports = [];
  }

  return copyNGPReports;
}


export function selectGetSelectNGPReportsFiltered(
    ngpReports: NGPReport[],
    storeHeaderMenuData: { [key: string]: HeaderMenuColumnData; }
): NGPReport[] {
  let found = '';
  let sortType = '';

  // Iterate over the columns in the store's header menu data
  Object.keys(storeHeaderMenuData).forEach((key: string): void => {
    if (storeHeaderMenuData[key]?.sortType !== null && storeHeaderMenuData[key]?.sortType !== undefined) {
      found = key;
      sortType = storeHeaderMenuData[key]?.sortType;
    }
  });

  if (found !== '' && storeHeaderMenuData) {
    switch (sortType) {
      case 'sort-asc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => (a[found] > b[found] ? 1 : -1));
        break;
      case 'sort-desc':
        ngpReports.sort((a: NGPReport, b: NGPReport): number => (a[found] < b[found] ? 1 : -1));
        break;
    }
  }
  return ngpReports;
}
