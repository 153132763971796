import {createFeatureSelector, createSelector} from '@ngrx/store';
import {
  selectCurrentPageAndTab,
  selectSelectedUserStores
} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  selectFiltersAndToolsProperty,
  selectNGPReportsFiltered,
  selectNGPReportsMenuDataByStoreId,
  selectNGPVisibleFields,
  selectVatRateBySelectedStore
} from '../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {
  HeaderMenuColumnData,
  StoreHeaderMenuData
} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {SharedGridState} from './shared-grid.reducer';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {IPriceBand, IPriceBandPerStore} from '../../../shared/shared-components/models/price-banding/price-band';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {
  applyPriceBanding, PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS
} from '../../../shared/shared-components/utils/price/price-banding.utils';
import {VatRates} from '../../../shared-utilities/models-old/datastructures';

export const selectSharedGridState = createFeatureSelector<SharedGridState>('shared-grid');

//===============================================================================================================
// Export to CSV
//===============================================================================================================
export const selectExportToCSVSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.exportToCSVSettings
);

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================

export const selectGridHeaderMenuDataByCurrentPage = createSelector(
  selectCurrentPageAndTab,
  selectNGPReportsMenuDataByStoreId,
  (
    navigation: { currentSelectedPage: INavigationPage },
    headerMenuData: StoreHeaderMenuData | {
      [p: string]: HeaderMenuColumnData;
    }) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return headerMenuData;
      default:
        return {};
    }
  }
);

export const selectSharedGridVisibleFieldsByCurrentPage = createSelector(
  selectCurrentPageAndTab,
  (navigation: { currentSelectedPage: INavigationPage }) => {
    const currentPage = navigation.currentSelectedPage.currentPage;
    switch (currentPage) {
      case 'ngp-report':
        return selectNGPVisibleFields;
      case 'stock-manager':
        return {};
      default:
        return {};
    }
  }
);

export const selectTableNavSettings = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.tableNavSettings
);

//===============================================================================================================
// Price Banding
//===============================================================================================================
export const selectIsGetPriceBandingPerStoreLoading = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.isPriceBandingPerStoreLoading
);

export const selectPriceBandingForAllStores = createSelector(
  selectSharedGridState,
  (state: SharedGridState) => state.priceBandsPerStore
);

export const selectPriceBandingForUserSelectedStore = createSelector(
  selectPriceBandingForAllStores,
  selectSelectedUserStores,
  (priceBands: IPriceBandPerStore, userStore: IStore): IPriceBand[] => {
    if (!userStore?.storeID) {
      return [] as IPriceBand[];
    } else {
      return priceBands[userStore.storeID];
    }
  }
);

export const selectNGPReportsPriceBanded = createSelector(
  selectPriceBandingForUserSelectedStore,
  selectNGPReportsFiltered,
  selectFiltersAndToolsProperty<boolean>('isApplyPriceBandingOn'),
  selectSelectedUserStores,
  selectVatRateBySelectedStore,
  (priceBands: IPriceBand[], ngpReports: NGPReport[], isPriceBanding, userStore, vatRates: VatRates): NGPReport[] => {
    if (isPriceBanding) {
      return applyPriceBanding<NGPReport[]>(ngpReports, priceBands, PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS, userStore, vatRates);
    } else {
      return ngpReports;
    }
  }
)
