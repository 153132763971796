import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {SelectPopoverComponent} from '../../../components/select-popover/select-popover.component';
import {DateRangeSelectorComponent} from '../../../components/date-range-selector/date-range-selector.component';
import {ApiLogObj2} from '../../../../../shared-utilities/models-old/datastructures';
import {IUser} from '../../../../../shared-utilities/models-old/user/IUser';
import {FirebaseService} from '../../../../../shared-utilities/services-old/firebase.service';
import {firstDayOWeek} from '../../../../../shared-utilities/functions-old/date-functions';
import {ColleaguesObject} from '../../../../../shared-utilities/models-old/colleagues/colleagues';
import {StoreObject} from '../../../../../shared-utilities/models-old/store/store-object';

const QUERY_SELECTION = {
  code: 'Stock Code',
  email: 'Email',
  dates: 'Between Dates'
} as const;

export type SockHisQueryType = keyof typeof QUERY_SELECTION;

@Component({
  selector: 'app-stock-history-log',
  templateUrl: './stock-history-log.component.html',
  styleUrls: ['./stock-history-log.component.scss'],
})
export class StockHistoryLogComponent implements OnInit {
  @Input() storeID: string;
  @Input() codeHistoryList?: string = '';
  @Input() queryType?: string;

  alreadySearched = false;
  selectedDates: { start?: Date; end?: Date } = {}; // Initialize as needed
  minDate: Date = new Date(2020, 0, 1);
  maxDate: Date = new Date();
  apiEvent: ApiLogObj2[];
  expandedRowID: number;
  storeName: string;
  searchedDate: string;

  userID: IUser;
  colleagues: ColleaguesObject = {users: {}, storesUsers: {}};
  loading: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly Object = Object;

  constructor(
    private firebaseService: FirebaseService,
    private modalControl: ModalController,
    private popoverControl: PopoverController,
  ) {}

  ngOnInit() {
    this.apiEvent = [];
    // this.searchedDate = 'No Dates Searched, Click Select Dates';
    this.firebaseService.colleagues.subscribe((colleagues: ColleaguesObject) => {
      this.colleagues = colleagues;
    });
    this.firebaseService.stores.subscribe((stores: StoreObject) => {
      this.storeName = stores.stores[this.storeID].name;
    });
    this.firebaseService.userObj.subscribe((uo: IUser) => this.userID = uo);
    if(this.codeHistoryList !== ''){
      this.onSubmit().then();
    }
    const today = new Date();
    const start = firstDayOWeek(new Date());

    this.selectedDates = {
      start,
      end: today
    };
  }

  handleDateChange(event: { start?: Date; end?: Date }): void {
    this.selectedDates = event; // Update your selectedDates based on the emitted event
  }

  async onSubmit(){
    this.loading = true;
    let apiEvent: ApiLogObj2[] = [];
    this.alreadySearched = true;
    const regexCode = this.codeHistoryList.replace(/ /g, '');

    if (this.codeHistoryList !== '') {
      if (this.queryType === 'code') {
        apiEvent = await this.firebaseService.queryStockUpdates(this.storeID, {code: regexCode});
      } else if (this.queryType === 'email') {
        apiEvent = await this.firebaseService.queryStockUpdates(this.storeID, {userID: regexCode});
      }
    } else if (this.queryType === 'dates') {
      apiEvent = await this.firebaseService.queryStockUpdates(this.storeID,
        {minDate: this.selectedDates.start, maxDate: this.selectedDates.end});
    }
    // TODO: We wont need to sort or slice once the firebase Index issue is fixed.
    apiEvent.sort((a, b) => b.executedDate.getTime() - a.executedDate.getTime());
    this.apiEvent = apiEvent.slice(0, 15);
    this.loading = false;
  }

  onClose(): void {
    this.modalControl.dismiss().then();
  }

  getFormatedDate(dateString){
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };

    // Format the date parts
    const formattedDate = date.toLocaleTimeString('en-EN', options);
    return `${formattedDate}`;
  }

  onUpdateCodeHistoryList(codeInfo: any): void {
    this.codeHistoryList = (codeInfo);
  }

  onUpdateQueryType(query: any) {
    this.queryType = (query);
    this.onSubmit().then();
  }
  async onSelectQueryType(event){
    const pc = await this.popoverControl.create({
      component: SelectPopoverComponent, event, componentProps: {
        title: 'Select Query Type',
        selection: QUERY_SELECTION,
        value: this.queryType,
        multiple: false,
      }
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data) {
      console.log(QUERY_SELECTION[data]);
      this.queryType = data;
      if (data === 'dates'){
        this.codeHistoryList = '';
      }
    }
  }

  async onOpenDatePicker(event: any){
    const pc = await this.popoverControl.create({
      component: DateRangeSelectorComponent, event, componentProps: {
        selectedDates: this.selectedDates,
        minDate: this.minDate,
        maxDate: this.maxDate,
        visibleButtons: {ok: true, reset: false, cancel: true},
        isPopOver: true,
      }
    });
    await pc.present();
    const {data} = await pc.onDidDismiss();

    if (data) {
      this.codeHistoryList = '';
      this.queryType = 'dates';
      if (data.start === undefined || data.start === null){
        this.selectedDates.start = new Date(2018, 0, 1);
      } else {
        this.selectedDates.start = data.start;
      }

      if (data.end === undefined || data.end === null){
        this.selectedDates.end = new Date();
      } else {
        this.selectedDates.end = data.end;
      }
      this.searchedDate = `From ${this.selectedDates.start
        .toLocaleDateString(
          'en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })} To ${this.selectedDates.end
        .toLocaleDateString(
          'en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}`;
    }
  }

  onSearchUser(userID: string): void{
    this.queryType = 'email';
    this.codeHistoryList = (userID);
    this.onSubmit().then();
  }

  onExpandedRowID(i: number){
    this.expandedRowID = this.expandedRowID === i ? null : i;
  }
}
