import {createFeatureSelector, createSelector, Selector} from '@ngrx/store';
import {NGPReportState} from './ngp-report.reducer';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {StoreHeaderMenuData} from '../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {selectSelectedUserStores} from '../../feature-core/store/core.selectors';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {getSelectNGPReportsFiltered, selectGetSelectNGPReportsFiltered} from './ngp-report.selectors.utils';
import {LineColour} from '../../../shared-utilities/models-old/datastructures';
import {IDepartment} from '../../../shared/shared-components/models/stock/departments';

export const selectNGPState = createFeatureSelector<NGPReportState>('ngp-report');

// ====================================================================================================
// Set Filters and Tools
// ====================================================================================================
export const selectFiltersAndTools = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.filtersAndTools
);

export const selectFiltersAndToolsIsApplyPriceBandingOn = createSelector(
  selectFiltersAndTools,
  (filtersAndTools: FiltersAndTools) => filtersAndTools.isApplyPriceBandingOn
);

export const selectFiltersAndToolsProperty = <PropertyReturnType>(property: string): Selector<object, PropertyReturnType> => createSelector(
  selectFiltersAndTools,
  (filtersAndTools: FiltersAndTools): PropertyReturnType => filtersAndTools[property] as PropertyReturnType
);

// ====================================================================================================
// Get NGP Reports
// ===============================X=====================================================================
export const selectNGPReports = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReports
);

export const selectIsStockItemsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isStockItemsLoading
);

export const selectNGPReportsFiltered = createSelector(
  selectNGPReports,
  selectFiltersAndTools,
  selectSelectedUserStores,
  (
    allNGPReports: { [storeID: string]: NGPReport[] },
    filtersAndTools: FiltersAndTools,
    store: IStore
  ): NGPReport[] => {
    return getSelectNGPReportsFiltered(allNGPReports, filtersAndTools, store);
  }
);

// ====================================================================================================
// Set Grid Column Definitions
// ====================================================================================================
export const selectNGPReportGridColDefs = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.ngpReportGridColDefs
);

// ====================================================================================================
// Get Grid Menu Data
// ====================================================================================================
export const selectNGPReportsMenuData = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.headerMenuData
);

export const selectNGPReportsMenuDataByStoreId = createSelector(
  selectNGPReportsMenuData,
  selectSelectedUserStores,
  (headerMenuData: StoreHeaderMenuData, selectedStore: IStore) => {
    if (!selectedStore?.storeID || !headerMenuData[selectedStore.storeID]) {
      return {} as StoreHeaderMenuData;
    } else {
      return headerMenuData[selectedStore.storeID];
    }
  }
);

// ====================================================================================================
// Get Sorted and Filtered NGPReport
// ====================================================================================================
export const selectNGPReportsFilteredWithSort = createSelector(
  selectNGPReportsFiltered,
  selectNGPReportsMenuData,
  (ngpReports: NGPReport[], headerMenuData: StoreHeaderMenuData): NGPReport[] => {
    return selectGetSelectNGPReportsFiltered(ngpReports, headerMenuData);
  }
);
// ====================================================================================================
// Get Is Ngp Reports Loading
// ====================================================================================================
export const selectIsNGPReportsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isNGPReportsLoading
);

// ====================================================================================================
// Get Visible Fields For NGP Reports
// ====================================================================================================
export const selectNGPVisibleFields = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.visibleFields
);

// ====================================================================================================
// Get Line Colours For Selected Store
// ====================================================================================================
export const selectLineColours = createSelector(
  selectNGPState,
  selectSelectedUserStores,
  (ngpState: NGPReportState, selectedStore: IStore): LineColour =>
    ngpState.lineColours ? ngpState.lineColours[selectedStore.storeID] : null
);

//===============================================================================================================
// Get Store Departments
//===============================================================================================================
export const selectIsStoreDepartmentsLoading = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.isDepartmentsLoading
);

export const selectAllStoreDepartments = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.departments
);

export const selectDepartmentsForSelectedStore = createSelector(
  selectAllStoreDepartments,
  selectSelectedUserStores,
  (departments:  { [key: string]: IDepartment[] }, selectedUserStore: IStore) => {
    if (!selectedUserStore || !departments) {
      return [];
    }
    return departments[selectedUserStore.storeID] || [] as IDepartment[];
  }
);

//===============================================================================================================
// Get Store Suppliers
//===============================================================================================================
export const selectAllStoresSuppliers = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.storeSuppliers
);

export const selectSuppliersByUserSelectedStore = createSelector(
  selectNGPState,
  selectSelectedUserStores,
  (state: NGPReportState, selectedUserStore: IStore) => state.storeSuppliers[selectedUserStore.storeID] || {}
);

//===============================================================================================================
// Get Vat Rate For StoreID
//===============================================================================================================

export const selectAllVatRates = createSelector(
  selectNGPState,
  (state: NGPReportState) => state.vatRates
);

export const selectVatRateBySelectedStore = createSelector(
  selectNGPState,
  selectSelectedUserStores,
  (state: NGPReportState, selectedUserStore: IStore) => state.vatRates[selectedUserStore.storeID] || {}
);
