export enum Icons {
  arrowDown = 'arrowDown',
  arrowLeft = 'arrowLeft',
  arrowRight = 'arrowRight',
  arrowUp = 'arrowUp',
  backSpaceOutline = 'backSpaceOutline',
  bandage = 'bandage',
  barcode = 'barcode',
  bellOutline = 'bellOutline',
  bugOutline = 'bugOutline',
  calendar = 'calendar',
  calendarClear = 'calendarClear',
  cart = 'cart',
  chevronDown = 'chevronDown',
  chevronLeft = 'chevronLeft',
  chevronRight = 'chevronRight',
  chevronUp = 'chevronUp',
  clipboard = 'clipboard',
  close = 'close',
  closeOutline = 'closeOutline',
  closeBox = 'closeBox',
  closeBoxOutline = 'closeBoxOutline',
  closeCircle = 'closeCircle',
  closeCircleOutline = 'closeCircleOutline',
  closeOctagon = 'closeOctagon',
  closeOctagonOutline = 'closeOctagonOutline',
  closeThick = 'closeThick',
  create = 'create',
  download = 'download',
  downloadOutline = 'downloadOutline',
  fileTrayStacked = 'fileTrayStacked',
  eyeOffOutline = 'eyeOffOutline',
  eyeOutline = 'eyeOutline',
  googleDinosaur = 'googleDinosaur',
  helpCircleOutline = 'helpCircleOutline',
  infoSlabCircleOutline = 'infoSlabCircleOutline',
  home = 'home',
  key = 'key',
  logIn = 'logIn',
  mail = 'mail',
  menu = 'menu',
  newBox = 'newBox',
  notEqualVariant = 'notEqualVariant',
  pencil = 'pencil',
  pencilOff = 'pencilOff',
  person = 'person',
  pieChart = 'pieChart',
  pint = 'pint',
  planet = 'planet',
  priceTags = 'priceTags',
  settings = 'settings',
  sort = 'sort',
  tableCog = 'tableCog',
  terminal = 'terminal',
  today = 'today',
}
