import {createFeatureSelector, createSelector} from '@ngrx/store';
import {OptionsState} from './options.reducer';
import {selectSelectedUserStores} from '../../feature-core/store/core.selectors';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';

const selectOptionsState = createFeatureSelector<OptionsState>('options');

export const selectNGPReportOptions = createSelector(
  selectOptionsState,
  selectSelectedUserStores,
  (state: OptionsState, selectedStore: IStore) => state.ngpReportOptions[selectedStore.storeID],
);
export const selectRulesDoc = createSelector(
  selectOptionsState,
  selectSelectedUserStores,
  (state: OptionsState, selectedStore: IStore) => state.ngpReportOptions[selectedStore.storeID].rulesDoc,
);


export const selectLineColour = createSelector(
  selectOptionsState,
  selectSelectedUserStores,
  (state: OptionsState, selectedStore: IStore) => state.ngpReportOptions[selectedStore.storeID].lineColours,
);

export const selectNavSettings = createSelector(
  selectOptionsState,
  selectSelectedUserStores,
  (state: OptionsState, selectedStore: IStore) => state.ngpReportOptions[selectedStore.storeID].navSettings,
);
