import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NumberUtils} from '../../../../shared-utilities/utils-old/shared-utils-old/number-utils';
import {Store} from '@ngrx/store';
import {updateSingleVisibleField} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {selectSharedGridVisibleFieldsByCurrentPage} from '../../store/shared-grid.selectors';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-cell-editor-general',
  templateUrl: './cell-editor-general.component.html',
  styleUrls: ['./cell-editor-general.component.scss'],
})
export class CellEditorGeneralComponent implements ICellEditorAngularComp, OnInit {

  @ViewChild('input') input: ElementRef;

  value: any;
  params: ICellEditorParams;
  width: string;
  colDefField: string;
  colDefFieldType: any;
  currentVisibleFields: { [key: string]: boolean } = {};

  visibleFields$: Observable<{ [key: string]: boolean }>;

  readonly icons: typeof Icons = Icons;

  constructor(private readonly store: Store) {
  }

  agInit(params: ICellEditorParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = `${this.params.colDef.width}px`;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });
  }

  ngOnInit(): void {
    this.width = `${this.params.colDef.width}px`;
    this.colDefFieldType = typeof this.params.value;
    this.value = this.value as typeof this.params.value;
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagFromNgpItem(this.params.data, this.colDefField, this.store);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  getValue(): string | number {
    this.params.value = this.value;
    const type = this.params.colDef.cellDataType;
    GridUtils.updateIsEditedAndForceRefresh(this.params);

    let newValue: string | number;
    if (type === 'text') {
      newValue = this.value.toString();
    } else if (type === 'number') {
      newValue = +NumberUtils.formatNumberToDecimals(this.value, 2);
      if (this.colDefField === 'sellPriIncl1') {
        this.changeNgpOnPriceChange(newValue);
      }
    }
    GridUtils.updateSingleNgpItem(this.params.data, this.colDefField, newValue, this.store)
    return newValue;
  }

  changeNgpOnPriceChange(newValue: number): void {
    if (!this.currentVisibleFields.nominalGP) {
      this.store.dispatch(updateSingleVisibleField({colId: 'nominalGP', value: true}));
    }
    this.params.data.nominalGP = newValue;

  }

  onKeyUp(event: KeyboardEvent): void {
      this.params.value = this.value;
      this.params.data[this.colDefField] = this.value;
      GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  afterGuiAttached(): void {
      this.input.nativeElement.focus();
  }

}
