import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ModalController} from '@ionic/angular';

/**
 * updates: Observable<number>;
 *
 * header?: string - 'Loading';
 *
 * subHeader?: string - 'Please wait.';
 *
 * message?: string
 *
 * autoClose?: boolean - true;
 *
 * completion?: string - 'Complete';
 *
 * okButton?: string - 'ok';
 *
 * indeterminate?: boolean - false;
 *
 * showPercentage?: void - false;
 *
 * retry?: void - false;
 */
export interface ProgressOptions {
  updates: Observable<number>;
  header?: string; // 'Loading';
  subHeader?: string; // 'Please wait.';
  message?: string;
  autoClose?: boolean; // true;
  completion?: string; // 'Complete';
  okButton?: string; // 'ok';
  indeterminate?: boolean; // false;
  showPercentage?: void; // false;
  retry?: void; // false;
}

/**
 * header?: string - 'Loading';
 *
 * subHeader?: string - 'Please wait.';
 *
 * message?: string
 *
 * autoClose?: boolean - true;
 *
 * completion?: string - 'Complete';
 *
 * okButton?: string - 'ok';
 *
 * indeterminate?: boolean - false;
 *
 * showPercentage?: void - false;
 *
 * retry?: void - false;
 */
export type PartialProgressOptions = Omit<ProgressOptions, 'updates'>;

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {

  @Input() updates: Observable<number>;
  @Input() header = 'Loading';
  @Input() subHeader = 'Please wait.';
  @Input() message?: string;

  @Input() autoClose = true;
  @Input() completion = 'Complete';
  @Input() okButton = 'ok';

  @Input() indeterminate = false;
  @Input() showPercentage = false;

  @Input() retry = false;

  showCompletion: 'success' | 'failure';
  progress = 0;

  constructor(
    private modalControl: ModalController,
  ) { }

  ngOnInit() {
    this.updates.subscribe(
        progress => this.progress = progress,
        error => {
          if (this.autoClose) {
            this.modalControl.dismiss().then();
          } else {
            this.completion = ''+error;
            this.showCompletion = 'failure';
            console.log(error);
          }
        }, () => {
          if (this.autoClose) {
            this.modalControl.dismiss().then();
          } else {
            this.showCompletion = 'success';
          }
      });
  }

  ok() {
    this.modalControl.dismiss().then();
  }
}
