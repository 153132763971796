import {Report} from '../../models-old/ngp-report/ngp-data-types';
import {LineColour} from '../../models-old/datastructures';
import {
  CellClickedEvent,
  CellKeyDownEvent,
  ColDef,
  GridApi,
  ICellEditorParams,
  ICellRendererParams,
  RowNode
} from 'ag-grid-community';
import {
  GridColumnHeaderComponent
} from '../../../shared-modules/shared-grid/components/grid-column-header/grid-column-header.component';
import {DataAdditionalHeaderMenuOption} from '../../models-old/ngp-report-grid/data-additional-header-menu-option';
import {StoreObject} from '../../models-old/store/store-object';
import {IStore} from '../../models-old/store/IStore';
import {NGPReport} from '../../models-old/ngp-reports/ngp-report';
import {
  updateAllNGPReports,
  updateIsSelectedForSingleNGPReport,
  updateSingleNGPReport
} from '../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {Store} from '@ngrx/store';
import {IDepartment, ISubDepartment} from '../../../shared/shared-components/models/stock/departments';
/**
 * @class GridUtils
 * Utilities for manipulating and displaying the grid and its data.
 * This includes cell renderer functions.
 *
 * @member verifyArrayDataExists Verify if there is data provided in the array or return an empty array.
 * @member mapStoreObjectToStore Map store objects to an array of stores.
 * @member mapReportsToNGPReportsArray Map NGP Reports to an Array from a Reports Object
 * @member applyLineColoursToNgpReports Apply the line colours to the NGP Report comparing a colour code and line colour hash code.
 * @member setColumnTypeToEditable Set column definitions types to edit as a style if they are editable.
 * @member addColumnTypeCellEdit Add the column type of `cellEdit` to a column definition.
 * @member setColumnHeaderMenu Set the column header menu component and additional parameters for column definitions.
 * @member getHumanReadableColumnIdentifier Get a human-readable identifier for a column definition field.
 */
export class GridUtils {

  /**
   * Verify if there is data provided in the array.
   * If there is no data it will return an empty array,
   *
   * @param data An array of data that needs to be verified.
   * @returns An array of the same data or an empty array if no data was provided.
   */
  static verifyArrayDataExists<T>(data: T[]): T[] {
    return data?.length > 0 ? data : [];
  }

  /**
   * Map store objects to an array of stores.
   *
   * @member {StoreObject} storeObject A store object that needs to be converted to an array.
   * @returns Returns an array of stores. The testing store will always be returned first.
   */
  static mapStoreObjectToStore(storeObject: StoreObject): IStore[] {
    let stores: IStore[] = [];
    if (storeObject?.order?.length < 1) {
      return [];
    }
    storeObject.order.forEach((storeID: string, index: number): void => {
      stores.push({sequence: index, storeID, name: storeObject.stores[storeID].name} as IStore);
      if (storeID === 'MANAGE_IT') {
        stores = stores.filter((store: IStore): boolean => store.storeID !== 'MANAGE_IT');
      }
    });
    stores.sort((a: IStore, b: IStore): number => a.sequence < b.sequence ? -1 : 1);
    return stores;
  }

  /**
   * Map NGP Reports to an Array from a Reports Object
   *
   * @param reportObject An object containing reports as properties.
   * @returns An array of NGP Report objects.
   */
  static mapReportsToNGPReportsArray(reportObject: { [reportId: string]: Report }): NGPReport[] {
    const reportReturn: NGPReport[] = [];
    Object.keys(reportObject).forEach((reportId: string): void => {
      const report: Report = reportObject[reportId];
      reportReturn.push({
        ...report
      } as NGPReport);
    });
    return [...reportReturn];
  }

  /**
   * Apply the line colours to the NGP Report comparing a colour code and line colour hash code.
   * The following properties are added: `lineColourDescription` and `lineColourValue`.
   *
   * @param lineColours Line colours with their code and has code assigned.
   * @param ngpReports The NGP Report to add the properties for line colours to.
   * @returns An array of NGP Report objects.
   */
  static applyLineColoursToNgpReports(lineColours: LineColour, ngpReports: NGPReport[]): NGPReport[] {
    const reports: NGPReport[] = [];
    ngpReports.forEach((r: NGPReport): void => {
      const newR = { ...r };
      if (newR.lineColourCode && newR.lineColourCode > 0) {
        const color = lineColours[newR.lineColourCode];
        if (color) {
          newR.lineColourDescription = color.description;
          newR.lineColourValue = color.value;
        }
      }
      reports.push(newR);
    });
    return reports;
  }

  /**
   * Set column definitions types to edit as a style if they are editable.
   *
   * @param colDefs Grid column definitions.
   * @returns An array of Column Definitions objects.
   */
  static setColumnTypeToEditable(colDefs: ColDef[]): ColDef[] {
    if (colDefs?.length > 0) {
      colDefs.forEach((col: ColDef): void => {
        if (col.editable) {
          col.type = this.changeColumnDefinitionType(col, '', 'cellEdit');
        }
      });
    }
    return colDefs || [];
  }

  /**
   * Add the column type of `cellEdit` to a column definition.
   *
   * @param colDef The column definition the type `cellEdit` is being added to.
   * @returns An array of `type` objects.
   */
  static addColumnTypeCellEdit(colDef: ColDef): string[] {
    if (!colDef.type) {
      colDef = {...colDef, type: []};
    }
    const cellEditTypeIndex: number = [...colDef.type]?.findIndex((type: string): boolean => type === 'cellEdit') || -1;
    if (cellEditTypeIndex < 0) {
      return [...colDef.type || [], 'cellEdit'];
    }
    return [...colDef.type] || [];
  }

  /**
   * Add and/or remove column types from column definitions.
   *
   * @param colDef The column definition to amend types for.
   * @param deleteType The type that needs to be removed from the column definition.
   * @param addType ? The type that needs to be added to the column definition.
   * @returns An array of `type` objects.
   */
  static changeColumnDefinitionType(colDef: ColDef, deleteType: string, addType?: string): string[] {
    if (!colDef.type) {
      return;
    }
    const deleteIndex: number = [...colDef.type]?.findIndex((type: string): boolean => type === deleteType) || -1;
    if (deleteIndex > -1) {
      colDef.type = [...colDef.type].splice(deleteIndex - 1, 1);
    }
    if (addType) {
      return [...colDef.type, addType];
    }
    return [...colDef.type];
  }

  /**
   * Set the column header menu component and additional parameters for column definitions.
   *
   * @param colDefs The column definitions to have the header menu component added to.
   * @param headerComponentParamData The data to be sent through to the header menu component.
   * @returns An array of column definitions with Header Menu's added.
   */
  static setColumnHeaderMenu<ActionReturnType>(colDefs: ColDef[], headerComponentParamData: DataAdditionalHeaderMenuOption<ActionReturnType>[]): ColDef[] {
    colDefs.forEach((col: ColDef): void => {
      col.headerComponent = GridColumnHeaderComponent;
      col.headerComponentParams = [...headerComponentParamData];
    });
    return colDefs;
  }

  /**
   * Get a human-readable identifier for a column definition field.
   *
   * @Example
   * If (col headerName) return col headerName;
   * else convert col field from `propertyName` to `Property Name`
   *
   * @param col The column definition.
   * @returns A human-readable identifier for the column definition.
   */
  static getHumanReadableColumnIdentifier(col: ColDef): string {
    if (col.headerName) {
      return col.headerName;
    } else {
      const splitWords: string = col.field.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      return splitWords.replace(/\b\w/g, char => char.toUpperCase());
    }
  }

  static updateIsEditedAndForceRefresh(params: ICellEditorParams | ICellRendererParams): void {
    // Validate that originalValue exists and is properly structured
    if (!params.data.originalValue || typeof params.data.originalValue !== 'object') {
      console.error(`Invalid originalValue structure with value ${params.data.originalValue}`);
      return;
    }
    const isAnyEdited = Object.keys(params.data.originalValue).some((key: string) => {
      if (key !== 'isEdited' && key !== 'isSelected' && key !== 'error' && key !== 'newlyAdded') {
        const originalValue = params.data.originalValue[key].value;
        const currentValue = params.data[key];
        return originalValue !== currentValue;
      }
      return false;
    });
    const rowNode = params.api.getRowNode(params.node.id);
    if (rowNode) {
      const updatedData = {...rowNode.data, isEdited: isAnyEdited};
      rowNode.setData(updatedData);
      params.api.refreshCells({rowNodes: [rowNode], force: true});
    }
  }


  static updateErrorAndForceRefresh(params: ICellEditorParams | ICellRendererParams, departments: IDepartment[]): void {
    const isError = this.checkSubDepCode(params, departments);

    const rowNode = params.api.getRowNode(params.node.id);
    if (rowNode) {
      let updatedData = {};
      if (isError) {
        updatedData = {...rowNode.data, error: isError, isSelected: false};
      } else {
        updatedData = {...rowNode.data, error: isError};
      }

      rowNode.setData(updatedData);
      params.api.refreshCells({rowNodes: [rowNode], force: true});
    }
  }

  static checkSubDepCode(params: ICellEditorParams | ICellRendererParams, departments: IDepartment[]): boolean {
    if (params.colDef.field === 'dep') {
      if (params.data.dep === '0') {
        return true;
      } else if (params.data.dep === params.data.originalValue.dep.value) {
        const department = departments.find((dep: IDepartment): boolean => dep.dep === params.data.dep);
        const subDepartments = department?.subDeps;
        if (subDepartments) {
          return !(subDepartments.filter((subDep: ISubDepartment): boolean => subDep.subDep === params.data.subDep).length > 0);
        }
        return false;
      }
    }
    if (params.data.dep !== '0') {
      const department = departments.find((dep: IDepartment): boolean => dep.dep === params.data.dep);
      const subDepartments = department?.subDeps;
      if (subDepartments) {
        return !(subDepartments.filter((subDep: ISubDepartment): boolean => subDep.subDep === params.data.subDep).length > 0);
      }
    }
    return false; // No error for other fields
  }

  static removeIsEditingFlagFromNgpItem(params: NGPReport, field: string, store: Store): void {
    const updatedNgpItem: NGPReport = {
      ...params, [field]: params.originalValue[field].value, isEdited: false, isSelected: false,
    };
    store.dispatch(updateSingleNGPReport({ngpReport: updatedNgpItem}));
  }

  static updateSingleNgpItem(params: NGPReport, field: string, newValue: string | number | boolean, store: Store): void {
    const updatedNgpItem: NGPReport = {
      ...params, [field]: newValue,
    };
    if (updatedNgpItem[field] !== params.originalValue[field].value) {
      const updatedNgpItem: NGPReport = {
        ...params, isEdited: true,
      };
      store.dispatch(updateSingleNGPReport({ngpReport: updatedNgpItem}));
    }
  }

  static updateIsSelectedForSingleNgpItem(cellKeyDownEvent: CellKeyDownEvent | CellClickedEvent, selectedValue: boolean, store: Store): void {
    let rowNode;

    if (cellKeyDownEvent.type) {
      rowNode = cellKeyDownEvent.node.data;
    } else {
      rowNode = cellKeyDownEvent
    }
    const updatedNgpItem: NGPReport = {
      ...rowNode, isSelected: selectedValue
    };

    store.dispatch(updateIsSelectedForSingleNGPReport({ngpReport: updatedNgpItem}));
  }

  static updateIsSelectedForSingleNgpItemOnEnter(ngpReport: NGPReport, selectedValue: boolean, store: Store): void {
    const updatedNgpItem: NGPReport = {
      ...ngpReport, isSelected: selectedValue
    };

    store.dispatch(updateSingleNGPReport({ngpReport: updatedNgpItem}));
  }

  static updateIsSelectedForAllNgpItem(gridApi: GridApi, selectedValue: boolean | string | number, store: Store): void {
    const updatedNgpReports: NGPReport[] = [];
    gridApi.forEachNode((rowNode: RowNode) => {
      updatedNgpReports.push({
        ...rowNode.data,
        isSelected: selectedValue,
      } as NGPReport);
    });
    store.dispatch(updateAllNGPReports({ngpReports: updatedNgpReports}));
  }

  static disableItem(params: NGPReport, store: Store): void{
    store.dispatch(updateSingleNGPReport({ngpReport: params}));
  }

}
