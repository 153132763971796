import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  EnvironmentInjector,
  Injectable
} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, Subscription, TimeoutError} from 'rxjs';

import {FirebaseService, Timestamp} from '../firebase.service';
import {InboxMessage, INCOMING_MSG_TYPES, Message, MsgType} from '../../models-old/datastructures';
import {FireAuthService} from '../fire-auth.service';
import {StandardAlertsService} from '../standard-alerts.service';
import {take} from 'rxjs/operators';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {
  SuccessfulEventFabComponent
} from '../../../shared-modules/shared-module/components/successful-event-fab/successful-event-fab.component';
import {
  FailedOrdersAlertComponent
} from '../../../shared-modules/shared-module/components/failed-orders-alert/failed-orders-alert.component';

type MatchableMsgTypes = MsgType | '<ALL>';

interface RawMessage extends Omit<Message, 'timestamp'> {
  timestamp: Timestamp;
  msgID: string;
}

interface DataItem {
  msg: string;
  // other properties if needed
}

interface AoErrorObj {
  error: number;
  desc: string;
  itemErrors?: { [code: string]: { error: number; desc: string } };
}

// TODO Move
export interface AoResponse {
  success: boolean;
  supplier: string;
  po?: string; // IQ purchase order number
  sent?: Date; // if succeeded
  pos?: 'AO_SUBMISSION_FAIL' | 'AO_PDF_FAIL' | 'AO_EMAIL_FAIL' | 'AO_REPEAT_SUPPLIER'; // if failed
  msg?: string; // error message, if API submission failed
  errorObj?: AoErrorObj; // error information, if API submission failed and msg was successfully parsed
  // if email failed
  fileName?: string;
  recipients?: string[];
  // if reattempted
  reattempted?: Date; // If reattempted, timestamp of last reattempt
}

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  /* TODO: Note: To add reactions or alerts to new messages of certain types, do so at the bottom in reactToType */
  private userID: string;
  private subs: { [what: string]: Subscription } = {};
  private liveMsgs: BehaviorSubject<{ order: string[]; messages: { [mID: string]: InboxMessage }}>;
  private numLiveMsgs: BehaviorSubject<number> = new BehaviorSubject(0);
  private lastMsgTS: number;
  private fabComponentRefs: ComponentRef<any>[] = [];

  private readonly limit = 25;

  constructor(
    private firebase: FirebaseService,
    private af: AngularFirestore,
    private fireAuth: FireAuthService,
    private stdAlerts: StandardAlertsService,
    private toastControl: ToastController,
    private alertControl: AlertController,
    private modalController: ModalController,
    private environmentInjector: EnvironmentInjector,
    private appRef: ApplicationRef,
  ) {
    this.liveMsgs = new BehaviorSubject({order: [], messages: {}});

    this.firebase.userObj.subscribe((uo) => {
      if (!uo || this.userID === uo.id) { return; }
      this.userID = uo.id;

      if (this.subs.messagesSub) {
        this.subs.messagesSub.unsubscribe();
        this.liveMsgs.next({order: [], messages: {}});
        this.numLiveMsgs.next(0);
      }
      this.getLastMessageTimestamp().then();
      this.subs.messagesSub = this.af.collection(`users/${this.userID}/messages`, (ref) =>
        ref.orderBy('timestamp', 'desc') /* .limit(this.limit) */
      ).valueChanges({idField: 'msgID'}).subscribe(async (data) => {
        const messages: { [mID: string]: InboxMessage } = {};
        const order: string[] = data.map((d) => {
          const raw = d as RawMessage;
          messages[d.msgID] = raw as any;
          messages[d.msgID].timestamp = raw.timestamp.toDate();
          return d.msgID;
        });
        this.liveMsgs.next({order, messages});
        this.numLiveMsgs.next(order.length);

        if (order.length > 0) {
          await this.doAlerts(order, messages);
          await this.setLastMessageTimestamp(messages[order[0]].timestamp.valueOf());
        }
      });
    });
  }

  get limitSize(): number {
    return this.limit;
  }

  get numLiveMsgsSub(): Observable<number> {
    return this.numLiveMsgs.asObservable();
  }

  get userMsgsSub(): Observable<{ order: string[]; messages: { [mID: string]: InboxMessage }}> {
    // let i = 0;
    // setInterval(() => {
    //   let { order, messages } = this.liveMsgs.value;
    //   const idx = leadingZero(i, 20);
    //   order = [idx].concat(order);
    //   messages[idx] = {
    //     payload: {body: `Test Message: ${idx}`}, sender: 'JuimjSgn2R4VnFhvA2aY', timestamp: new Date(), type: undefined
    //   };
    //   i++;
    //   console.log(`\nNEW MESSAGE ${idx}\n`);
    //   this.liveMsgs.next({order, messages});
    // }, 5000);

    return this.liveMsgs.asObservable();
  }

  testServer(storeID: string, testType: string, data?: any, failHandle: (Message) => void = null,
             timeout: number = 60, successMsg: string ='Test completed successfully'): Observable<string> {
    // `/operational/stores_data/${storeID}/messages/from_app/`
    return new Observable<string>(observer => {
      observer.next('Sending test request');
      const msg: Message = {
        type: 'SERVER_TEST', sender: this.userID, payload: {data: data ? data : {}}, timestamp: new Date()
      };
      msg.payload.data.type = testType;
      this.af.collection(`/operational/stores_data/${storeID}/messages/from_app/`).add(msg).then(dr => {
        observer.next('Test successfully sent');
        let timeoutTO = null;

        const msgSub = this.userMsgsSub.subscribe(messages => {
          const msgID = 'SERVER_TEST_' + dr.id;

          if (messages.messages.hasOwnProperty(msgID)) {

            if (timeoutTO !== null) {
              console.log('Clearing timeout');
              clearTimeout(timeoutTO);
            }
            observer.next('Response received.');
            const response: Message = messages.messages[msgID];

            if (response.payload.success) {
              observer.next(successMsg);
            } else {
              observer.next('Test Failed');
              if (failHandle) { failHandle(response); }
            }
            msgSub.unsubscribe();
            observer.complete();
          }
        });

        timeoutTO = timeout ? setTimeout(() => {
          msgSub.unsubscribe();
          const e  = new TimeoutError();
          e.message = `Server did not respond within timeout interval (${timeout}s).`;
          observer.error(e);
          observer.complete();
        }, timeout * 1000) : null;
      }).catch(e => {
        e.message = 'Failed sending test request: ' + e.message;
        observer.error(e);
      });
    });
  }

  private async getLastMessageTimestamp() {
    const d = await this.firebase.getUserDocument('last_msg_timestamp');
    this.lastMsgTS = d?.ts ? d.ts : new Date(0).getTime();
    // this.lastMsgTS = new Date(0).getTime();
  }

  private async setLastMessageTimestamp(ts: number) {
    this.lastMsgTS = ts;
    await this.firebase.setUserDocument('last_msg_timestamp', {ts: this.lastMsgTS});
  }

  private async doAlerts(order: string[], messages: { [mID: string]: Message }) {
    if (this.lastMsgTS !== null) {
      const reactions = this.reactToTypes(order, messages);
      reactions.forEach((r) => r.func(r.applyTo));
    }
  }

  private reactToTypes(order: string[], messages: {[msgID: string]: Message }): {
    func: (applyTo: Message[]) => Promise<void>; applyTo: Message[];
  }[] {

    const onType: { type: MatchableMsgTypes; func: (applyTo: Message[]) => Promise<void> }[] = [
      // ---------------------------------------------- ADD REACTIONS HERE ---------------------------------------------
      { type: 'ACCESS_CHANGE', func: this.handleAccessAlerts },
      { type: 'AUTO_ORDER_RESULT', func: this.handleAutoOrders },
      { type: 'API-RESPONSE', func: this.handleAPIResponse},
      { type: '<ALL>', func: this.newMessagesToast },
      // ---------------------------------------------------------------------------------------------------------------
    ];

    const lookUp: { [type in typeof INCOMING_MSG_TYPES[number] | '<ALL>']?: number[] } = {};
    const lists: Message[][] = onType.map((ot, i) => {
      if (lookUp[ot.type]) {
        lookUp[ot.type].push(i);
      } else {
        lookUp[ot.type] = [i];
      }
      return [];
    });
    let idx = 0;
    let msg = messages[order[idx]];

    while (msg && msg.timestamp.valueOf() > this.lastMsgTS) {
      if (lookUp[msg.type]) {
        msg.id = order[idx];

        for (const onTypeIdx of lookUp[msg.type]) {
          lists[onTypeIdx].push(msg);
        }
      }

      for (const onTypeIdx of lookUp['<ALL>']) { lists[onTypeIdx].push(msg); }

      idx++;
      msg = messages[order[idx]];
    }
    const result: { func: (applyTo: Message[]) => Promise<void>; applyTo: Message[] }[] = lists.map((l, i) => ({
      func: onType[i].func, applyTo: l
    }));
    return result.filter((r) => r.applyTo.length > 0);
  }

  private handleAccessAlerts = async (applyTo: Message[]) =>{
    const accessAlerts: string[] = applyTo.map((m) => m.id);

    // for (const msgID of order) {
    //   if (messages[msgID].timestamp.valueOf() > lastTs) {
    //     if (messages[msgID].type === 'ACCESS_CHANGE') {
    //       if (accessAlerts.length === 0) {
    //         await this.fireAuth.accessChange(messages[msgID]);
    //       }
    //       accessAlerts.push(msgID);
    //     } else {
    //       newMessages = newMessages.concat(messages[msgID]);
    //     }
    //   } else {
    //     break;
    //   }
    // }

    if (accessAlerts.length > 0) {
      // remove all access change alert messages
      const batch = this.af.firestore.batch();
      accessAlerts.forEach((msgID) =>
        batch.delete(this.af.doc(`users/${this.userID}/messages/${msgID}`).ref)
      );
      batch.commit().then(() => console.log('Access Change Alerts Removed')).catch((e) => {
        this.stdAlerts.errorAlert({
          header: 'Error Clearing Access Alerts', subHeader: 'Something went wrong when clearing AA ' + 'messages.',
          message: e.message
        });
      });
    }
  };

  private createDynamicFab(componentRef:  ComponentRef<any>, count: number, type: 'AO' | 'API') {
    // TODO - Evaluate fully. Is there a better way? Is this useful elsewhere (hope so).
    componentRef.setInput('count', count);
    componentRef.setInput('type', type);
    this.appRef.attachView(componentRef.hostView);
    const domElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElement);
    const verticalPosition = 50 * this.fabComponentRefs.length;
    domElement.style.position = 'absolute';
    domElement.style.zIndex = ''+(1000000 + verticalPosition);
    domElement.style.top = verticalPosition + 'px';
    domElement.style.right = '5px';
    componentRef.instance.onFabClick.subscribe(() => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
      this.fabComponentRefs.splice(this.fabComponentRefs.indexOf(componentRef), 1);
    });
    this.fabComponentRefs.push(componentRef);
  }

  private handleAPIResponse = async (msgs: Message[]) => {
    const failedMsgs = msgs.filter((msg) => !msg.payload.success);
    const successCount = msgs.length - failedMsgs.length;

    if (successCount) {
      const component = createComponent(SuccessfulEventFabComponent, {environmentInjector : this.environmentInjector});
      this.createDynamicFab(component, successCount, 'API');
    }
  };

  private handleAutoOrders = async (msgs: Message[]) => {

    // Get existing orders in order to filter out messages where all failed orders have already been deleted
    const storeIDs = [...new Set(msgs.map((m) => m.sender))];
    const storesOrders: { [storeID: string]: string[] } = {};
    for (const storeID of storeIDs) {
      const coll = await this.af.collection(`/operational/stores_data/${storeID}/data/auto_ordering/prepared/orders/`)
        .get().toPromise();
      storesOrders[storeID] = coll.docs.map((doc) => doc.id);
    }
    let successCount = 0;
    // Filter out messages where all the orders have already been reattempted or deleted.
    const failedMsgs = msgs.filter((msg) => {
      if (msg.payload.success === false) {
        const data = msg.payload.data as { [orderID: string]: AoResponse };

        for (const orderID of Object.keys(data)) {
          if (storesOrders[msg.sender].includes(orderID) && !data[orderID].success && !data[orderID].reattempted) {
            return true;
          }
        }
      } else {
        successCount++;
      }
      return false;
    });
    const failedMessages = [];

    console.log(failedMsgs);
    for (const message of failedMsgs) {
      const allStartWithIMAPIssueErrorIMAP = Object.values(message.payload.data as Record<string, DataItem>)
        .every((dataItem) => dataItem.msg && dataItem.msg.startsWith('IMAPIssueError')
      );
      if (!allStartWithIMAPIssueErrorIMAP) {
        failedMessages.push(message);
      }
    }
    if (failedMessages.length > 0) {
      const mc = await this.modalController.create({
        component: FailedOrdersAlertComponent,
        componentProps: { failedAOMsgs: failedMessages },
        cssClass: 'settings-modal',
        backdropDismiss: false,
      });
      await mc.present();
      await mc.onDidDismiss();
    }

    if (successCount) {
      const component = createComponent(SuccessfulEventFabComponent, {environmentInjector : this.environmentInjector});
      this.createDynamicFab(component, successCount, 'AO');
    }
  };

  private newMessagesToast = async (messages: Message[]) => {
    // TODO FUCK
    return;
    console.log(' NEW MESSAGES TOASTS ', messages);

    if (messages.length === 0) { return; }

    const storeInfo = await this.firebase.stores.pipe(take(1)).toPromise();
    const notify = async () => {
      const msg = messages.pop();

      if ( ! msg) { return; }
      const tc = await this.toastControl.create({
        header: `New Message from ${storeInfo.stores[msg.sender] ? storeInfo.stores[msg.sender].name : msg.sender}`,
        message: msg.payload.body ? msg.payload.body : (msg.payload.hasOwnProperty('success') ?
          `Request was ${msg.payload.success?'Successful':'Unsuccessful'}` : 'No body available.'),
        cssClass: ['custom-toast', 'new-msg-toast'], duration: 3000, position: 'top'
      });
      await tc.present();
      await tc.onDidDismiss();
      notify().then();
    };
    notify().then();
  };

  // Observable<{ order: string[]; messages: { [timestamp: number]: Message }}
}
