import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../shared-utilities/services-old/fire-auth.service';
import {MessagesService} from '../../../shared-utilities/services-old/message-service/messages.service';
import {select, Store} from '@ngrx/store';
import {forkJoin, from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStores} from '../../feature-core/store/core.selectors';
import * as OptionsActions from './options.actions';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {DisabledRules, LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';

@Injectable()
export class OptionsEffects {

  // ===========================================================================
  // get NGP Report Options
  // ===========================================================================
  getNGPReportOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionsActions.getNGPReportOptions),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([action, selectedStore]) => {
        const rulesDoc$ = this.firebaseService.subStoreDataDoc('stock_item_deletion_rules', selectedStore.storeID, 'operational')
          .pipe(
            take(1),
            map((data: DisabledRules) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        const lineColour$ = from(this.firebaseService.getStoreDataDoc('line_colours', selectedStore.storeID))
          .pipe(
            take(1),
            map((data: LineColour) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        const navSettings$ = from(this.firebaseService.getUserDocument('ngp_table_nav'))
          .pipe(
            take(1),
            map((data: TableNavSettings) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        const colPreviews$ = from(this.firebaseService.getUserDocument('ngp_edits_preview'))
          .pipe(
            take(1),
            map((data: { [key: number]: string }) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        return forkJoin({
          rulesDoc: rulesDoc$,
          lineColours: lineColour$,
          navSettings: navSettings$,
          colPreview: colPreviews$,
        }).pipe(
          map(({rulesDoc, lineColours, navSettings, colPreview}) => {
            const errors: IError[] = [];

            if (rulesDoc.error) {
              errors.push(rulesDoc.error);
            }
            if (lineColours.error) {
              errors.push(lineColours.error);
            }
            if (navSettings.error) {
              errors.push(navSettings.error);
            }
            if (colPreview.error) {
              errors.push(colPreview.error);
            }
            console.log(rulesDoc, lineColours, navSettings, colPreview);

            if (errors.length > 0) {
              return OptionsActions.getNGPReportOptionsFailure({
                errors,
                store: selectedStore,
              });
            }

            return OptionsActions.getNGPReportOptionsSuccess({
              rulesDoc: rulesDoc.data,
              store: selectedStore,
              lineColours: lineColours.data,
              navSettings: navSettings.data,
              colPreview: colPreview.data,
            });
          }),
          catchError((error: IError) => {
            return of(OptionsActions.getNGPReportOptionsFailure({
              errors: [error],
              store: selectedStore,
            }));
          }),
        );
      }),
    ),
  );


  // ===========================================================================
  // set NGP Report Preview Columns
  // ===========================================================================
  setNgpReportOptionsPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionsActions.setNgpReportOptionsPreviewColumns),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      switchMap(([action, userSelectedStore]) => {
        return from(this.firebaseService.setUserDocument('ngp_edits_preview', action.columnData))
          .pipe(
            map(() => OptionsActions.setNgpReportOptionsPreviewColumnsSuccess({
              columnData: action.columnData,
              store: userSelectedStore,
            })),
            catchError((error: IError) => of(OptionsActions.setNgpReportOptionsPreviewColumnsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );

  // ===========================================================================
  // set NGP Report Table Navigation Settings
  // ===========================================================================
  setTableNavSettingsInFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OptionsActions.setNGPOptionsTableNavSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([settings, userSelectedStore]) => {
        return from(this.firebaseService.setUserDocument('ngp_table_nav', settings.settings))
          .pipe(
            map(() => OptionsActions.setNGPOptionsTableNavSettingsSuccess({
                settings: settings.settings,
                store: userSelectedStore,
              }),
            ),
            catchError((error: IError) => of(OptionsActions.setNGPOptionsTableNavSettingsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private fireAuthService: FireAuthService,
    private messagesService: MessagesService,
    private store: Store,
  ) {
  }
}
