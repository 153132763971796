import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {Message, stockCodeDecode, StoreInfo} from '../../../../../shared-utilities/models-old/datastructures';
import {AoReattemptModalComponent, ReattemptInput} from '../ao-reattempt-modal/ao-reattempt-modal.component';
import {AoResponse} from '../../../../../shared-utilities/services-old/message-service/messages.service';
import {FirebaseService} from '../../../../../shared-utilities/services-old/firebase.service';
import {copyObj, objLen, updateObj} from '../../../../../shared-utilities/functions-old/object-functions';

interface UseValue {
  msgID: string;
  message: Message;
  getStoreInfo: (storeID: string) => StoreInfo;
  timeStamp: string;
  restoreState?: { input?: { [orderID: string]: ReattemptInput }; checked?: { [orderID: string]: boolean } };
}

@Component({
  selector: 'app-msg-ao-response',
  templateUrl: './msg-ao-response.component.html',
  styleUrls: ['./msg-ao-response.component.scss'],
})
export class MsgAoResponseComponent implements OnInit {

  // This value will only be provided when component is embedded
  @Input() embeddedUseValue?: UseValue;
  @Output() onCheck: EventEmitter<{ orderID: string; value: { [orderID: string]: boolean } }> = new EventEmitter();

  ordersOrder: string[] = [];
  responses: { [orderID: string]: AoResponse };
  suppliersClean: { [orderID: string]: string } = {};
  succeeded: { [orderID: string]: boolean } = {};
  failures = 0;
  checked: { [orderID: string]: boolean } = {};
  numChecked = 0;
  isDeleted: { [orderID: string]: boolean } = {};
  imapErrors: { [orderID: string]: boolean } = {};
  // data: { [orderID: string]: any } = {};
  reattemptInput: { [orderID: string]: ReattemptInput };
  creatingModal: boolean;
  storeName: string;

  private compIsEmbedded: boolean;

  constructor(
    @Optional() @Inject('INPUT') protected useValue: UseValue,
    private firebase: FirebaseService,
    private alertControl: AlertController,
    private modalControl: ModalController,
  ) { }

  get isEmbedded(): boolean { return this.compIsEmbedded; }

  async ngOnInit() {
    this.compIsEmbedded = !!this.embeddedUseValue;
    if (this.isEmbedded) {
      this.useValue = this.embeddedUseValue;
    }
    this.storeName = this.useValue.getStoreInfo(this.useValue.message.sender).name;

    if (this.useValue?.restoreState?.checked) {
      for (const orderID of Object.keys(this.useValue.restoreState.checked)) {
        this.checked[orderID] = this.useValue.restoreState.checked[orderID];
      }
    }
    if (this.useValue?.restoreState?.input) {
      this.reattemptInput = {};

      for (const orderID of Object.keys(this.useValue.restoreState.input)) {
        this.reattemptInput[orderID] = this.useValue.restoreState.input[orderID];
      }
    }

    this.ordersOrder = Object.keys(this.useValue.message.payload.data);
    this.responses = {};
    const storeID = this.useValue.message.sender;

    for (const orderID of this.ordersOrder) {
      this.firebase.getAutoOrder(storeID, orderID).then((order) => (this.isDeleted[orderID] = order === null));
      this.responses[orderID] = this.useValue.message.payload.data[orderID] as AoResponse;
      this.suppliersClean[orderID] = stockCodeDecode(this.responses[orderID].supplier);

      if (this.responses[orderID].reattempted) {
        this.responses[orderID].reattempted = (this.responses[orderID].reattempted as any).toDate();
      }

      if (!this.responses[orderID].success && this.responses[orderID].msg && !this.responses[orderID].errorObj) {
        try {
          this.responses[orderID].errorObj = JSON.parse(this.responses[orderID].msg);
        } catch (error) {}
      }
      this.succeeded[orderID] = this.responses[orderID].success;

      if (!this.succeeded[orderID]) {
        this.failures++;

        if (
          this.responses[orderID].pos === 'AO_EMAIL_FAIL' &&
          this.responses[orderID].msg &&
          this.responses[orderID].msg.toLowerCase().startsWith('imapissueerrorimap error')
        )  {
          this.imapErrors[orderID] = true;
        }
      }
    }
    this.ordersOrder.sort((a, b) => this.responses[a].supplier < this.responses[b].supplier ? 1 : -1);
  }

  checkClicked(orderID: string) {
    this.numChecked += this.checked[orderID] ? 1 : -1;

    if (this.isEmbedded) {
      this.onCheck.emit({orderID, value: this.checked});
    }
  }

  async errorMsg(orderID) {
    let message: string;

    const buttons: any[] = [];

    if (this.responses[orderID].errorObj) {
      const eo = this.responses[orderID].errorObj;
      message = `Error code ${eo.error}: '${eo.desc}'. Number of item errors: ` +
        (eo.itemErrors ? Object.keys(eo.itemErrors).length : 0);
      buttons.push({text: 'Print XML to Console', handler: () => console.log(this.responses[orderID].msg)});
    } else {
      message = this.responses[orderID].msg ? this.responses[orderID].msg : 'No error message given';
    }
    buttons.push('Ok');
    const ac = await this.alertControl.create({
      header: `Supplier ${stockCodeDecode(this.responses[orderID].supplier)} Error`, cssClass: 'custom-alert', message,
      backdropDismiss: true, buttons,
    });
    await ac.present();
  }

  async imapError(){
    const ac = await this.alertControl.create({
      header: `IMAP Error`, subHeader: 'Don`t worry. The email was delivered', message: 'The email sent successfully ' +
        'and has been received by the supplier. Unfortunately the email failed to copy to your sent box, so you wont ' +
        'see it there. This could have been a one time issue. If the issue happens again please contact us, your I' +
        'MAP settings may be incorrect.',
      cssClass: 'custom-alert', backdropDismiss: true, buttons: ['ok'],
    });
    await ac.present();
  }

  async reattempt() {
    if (this.isEmbedded) { return; }
    this.creatingModal = true;
    const filteredOrders: { [i: number]: { [orderID: string]: AoResponse } } = { [0]: {} };

    for (const orderID of Object.keys(this.checked)) {
      if (this.checked[orderID]) {
        filteredOrders[0][orderID] = copyObj(this.responses[orderID]);
      }
    }

    if (!objLen(filteredOrders[0])) {
      this.creatingModal = false;
      return;}

    const storeNames = {0: this.storeName};
    const timeStamps = {0: {d: this.useValue.timeStamp, t: ''}};

    const modal = await this.modalControl.create({
      component: AoReattemptModalComponent, componentProps: {responses: filteredOrders, storeNames,
        timeStamps}, cssClass: ['child-modal']
    });
    await modal.present();
    const { data: modalData } = await modal.onDidDismiss();
    this.creatingModal = false;
    if (!modalData) { return; }

    if (!this.reattemptInput) { this.reattemptInput = {}; }
    updateObj(this.reattemptInput, modalData[0], true);
  }

  async submit() {
    if (this.isEmbedded) { return; }

    if (Object.keys(this.reattemptInput).length > 0) {
      const ac = await this.alertControl.create({
        header: 'Reattempt Auto Order?',
        subHeader: 'Are you sure you would like to reattempt the checked suppliers?',
        message: `This order is from ${this.useValue.message.timestamp.toLocaleDateString()}.`,
        cssClass: 'custom-alert',
        buttons: ['Cancel', {text: 'Reattempt', role: 'y'}],
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();
      if (role === 'y') {
        // for (const orderID of Object.keys(data)) {
        // }
        this.firebase.reattemptAutoOrder(this.useValue.msgID, this.useValue.message.sender, this.reattemptInput)
          .then(docId => {
            for (const orderID of Object.keys(this.reattemptInput)) {
              this.responses[orderID].reattempted = new Date();
            }
          });
      }
    }
  }
}
