import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {GetInputAlertOptions} from '../../../../shared-utilities/services-old/standard-alerts.service';

@Component({
  selector: 'app-basic-multiple-input',
  templateUrl: './basic-multiple-input.component.html',
  styleUrls: ['./basic-multiple-input.component.scss'],
})
export class BasicMultipleInputComponent implements OnInit {

  @Input() options: GetInputAlertOptions;
  emailForm: UntypedFormGroup;

  formControl: UntypedFormControl;

  constructor(
    private modalControl: ModalController,
  ) {}

  get emailFormArray(): FormArray {
    return this.emailForm.get('emails') as FormArray;
  }
  ngOnInit() {
    const validators: ValidatorFn[] = [Validators.required];

    if (this.options.pattern) {
      validators.push(Validators.pattern(this.options.pattern));
    }
    if (this.options.validators) {
      validators.push.apply(validators, this.options.validators);
    }

    if (this.options.type === 'number') {
      this.formControl = new FormControl<number>(null, validators);
    } else {
      this.formControl = new FormControl<string>(null, validators);
    }
    this.emailForm = new FormGroup({
      emails: new FormArray([])
    });
    this.addEmail(); // Add initial email input
  }

  submit() {
    const emails = this.emailForm.value.emails;
    this.modalControl.dismiss(emails).then();
  }

  cancel() {
    this.modalControl.dismiss(null).then();
  }

  getEmailFormControl(index: number): FormControl {
    return this.emailFormArray.at(index) as FormControl;
  }

  addEmail() {
    const validators = [Validators.required, Validators.email]; // Add email validator
    this.emailFormArray.push(new FormControl('', validators));
  }

  removeEmail() {

    const index = this.emailFormArray.length - 1;
    if (index > 0) {
      this.emailFormArray.removeAt(index);
    }
  }
}
