import {createAction, props} from '@ngrx/store';
import {TableNavSettings} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IAgGridExportSettings} from '../models/ag-grid-export-settings';
import {IPriceBandPerStore} from '../../../shared/shared-components/models/price-banding/price-band';

//===============================================================================================================
// Export to CSV
//===============================================================================================================
export const onExportDataToCSV = createAction(
  '[Shared Grid][Export Data to CSV] On Export Data to CSV',
  props<{ settings: IAgGridExportSettings }>()
);

//===============================================================================================================
// Get Table Nav Settings
//===============================================================================================================
export const getTableNavSettings = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings'
);

export const getTableNavSettingsSuccess = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings Success',
  props<{ settings: TableNavSettings }>()
);

export const getTableNavSettingsFailure = createAction(
  '[Shared Grid][Table Nav] Get Table Nav Settings Failure',
  props<{ error: IError }>()
);

//===============================================================================================================
// Price Banding
//===============================================================================================================
export const getPriceBandingForStores = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores'
);

export const getPriceBandingForStoresSuccess = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores Success',
  props<{ priceBandsPerStore: IPriceBandPerStore }>()
);

export const getPriceBandingForStoresFailure = createAction(
  '[Shared Grid][Price Banding] Get Price Banding for Stores Failure',
  props<{ error: IError }>()
);

export const setIsGetPriceBandingForStoresLoading = createAction(
  '[Shared Grid][Price Banding] Set Is Get Price Banding for Stores Loading',
  props<{ isLoading: boolean }>()
);
