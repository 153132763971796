import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {PopoverController} from '@ionic/angular';
import {FreshnessPopupComponent} from '../freshness-popup/freshness-popup.component';
import {
  DataFreshnessService,
  StoreDataFreshnessAge
} from '../../../../shared-utilities/services-old/data-freshness.service';
import {IRange, relativeToRange} from '../../../../shared-utilities/functions-old/ranges';

export type IndicatorStatus = 'good' | 'weak' | 'dead';

@Component({
  selector: 'app-data-freshness-indicator',
  templateUrl: './data-freshness-indicator.component.html',
  styleUrls: ['./data-freshness-indicator.component.scss'],
})
export class DataFreshnessIndicatorComponent implements OnInit, OnDestroy {

  @Input() storeID: string;
  @Input() timestamp: string | string[];
  @Input() lights?: boolean = true;
  @Input() dataKeys?: (keyof StoreDataFreshnessAge)[];
  @Input() frequency?: number;
  // Things below week range will be 'good', above will be 'dead'
  @Input() weekRange?: IRange = {start: {v: 180000}, end: {v: 300000}} as unknown as IRange;

  private age: StoreDataFreshnessAge;
  private currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus } = {};
  private currentStatus: IndicatorStatus;
  private ageSub: Subscription;

  private dataBS: BehaviorSubject<{
    age: StoreDataFreshnessAge;
    currentStatuses: { [key in keyof StoreDataFreshnessAge]: IndicatorStatus };
    currentStatus: IndicatorStatus;
  }>;

  constructor(
    private freshService: DataFreshnessService,
    private popControl: PopoverController,
  ) {
  }

  get status(): 'good' | 'weak' | 'dead' {
    return this.currentStatus;
  }

  ngOnInit() {
    const obs = this.freshService.createAgeObserver({
      storeID: this.storeID, keys: this.dataKeys, frequency: this.frequency
    });
    this.ageSub = obs.subscribe((age: StoreDataFreshnessAge) => {

      if (!age) {
        this.currentStatuses = {};
        this.currentStatus = null;
        return;
      }

      let inRange = false;
      let aboveRange = false;

      for (const key of this.dataKeys) {
        const relative = relativeToRange(age[key].msTotal, this.weekRange as IRange);
        switch (relative) {
          case 'below':
            this.currentStatuses[key] = 'good';
            break;
          case 'in':
            this.currentStatuses[key] = 'weak';
            inRange = true;
            break;
          case 'above':
            this.currentStatuses[key] = 'dead';
            aboveRange = true;
            break;
        }
      }
      this.currentStatus = aboveRange ? 'dead' : (inRange ? 'weak' : 'good');
      this.age = age;

      if (this.dataBS) {
        this.dataBS.next({age: this.age, currentStatus: this.currentStatus, currentStatuses: this.currentStatuses});
      }
    });
  }

  ngOnDestroy() {
    this.ageSub.unsubscribe();
  }

  async displayPopOver(event) {
    this.dataBS = new BehaviorSubject({
      age: this.age, currentStatus: this.currentStatus, currentStatuses: this.currentStatuses
    });

    const pc = await this.popControl.create({
      component: FreshnessPopupComponent, componentProps: {dataObs: this.dataBS.asObservable()}, event
    });
    await pc.present();
    await pc.onDidDismiss();
    this.dataBS.complete();
    this.dataBS = null;
  }

}
