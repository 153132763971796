import {forkJoin} from 'rxjs';

import {FirebaseService} from '../services-old/firebase.service';
import {DepSales2, DepSalesHistory} from '../models-old/datastructures';
import {dateDelta, lastDayOMonth, Period} from './date-functions';

let firebase: FirebaseService;

export const initDepartmentFunctions = (fb: FirebaseService) => {
  firebase = fb;
};

export const calcForecasts = (storeID: string, forecastType: 'mtd' | 'ytd', departments?: string[] | string): Promise<{
  total: { x: DepSales2; y: DepSales2; growth: DepSales2 };
  byDep: { [dep: string]: { x: DepSales2; y: DepSales2; growth: DepSales2 } };
}> => {
  console.log(forecastType);
  const d = new Date();
  const ct = new Date();
  // ct.setDate(ct.getDate() - 1);
  ct.setHours(23, 59, 59, 999);
  console.log(ct);
  let period1: Period;
  let period2: Period;
  let periodX: Period;
  let periodY: Period;
  let data: DepSalesHistory[] = [];
  const promises: Promise<void>[] = [];
  const inPeriod = (date: Date, period: Period) =>
    period.start.valueOf() <= date.valueOf() && date.valueOf() <= period.end.valueOf();

  if (forecastType === 'mtd') {
    const beginningMonth = new Date(ct.getTime());
    beginningMonth.setDate(1);
    const ctMinus30 = dateDelta(ct, {days: -30});
    ctMinus30.setHours(0, 0, 0, 0);
    const ctLastYear = dateDelta(ct, {years: -1});
    const ctMinus30LastYear = dateDelta(ctMinus30, {years: -1});
    const endOfMonthLastYear = lastDayOMonth(ctLastYear);
    period1 = {start: ctMinus30LastYear, end: endOfMonthLastYear};
    period2 = {start: ctMinus30, end: ct};
    periodX = {start: ctLastYear, end: endOfMonthLastYear};
    periodY = {start: beginningMonth, end: ct};

    promises.push(new Promise<void>(resolve =>
      firebase.salesHist(storeID, ctMinus30LastYear, endOfMonthLastYear, departments).then(r => {
        data.push(r);
        resolve();
      })
    ));
    promises.push(new Promise<void>(resolve =>
      firebase.salesHist(storeID, ctMinus30, ct).then(r => {
        data.push(r);
        resolve();
      })
    ));
  } else {
    const bFinYear = new Date(ct.getTime());
    bFinYear.setMonth(0, 1);
    const ctMinus12 = dateDelta(ct, {years: -1});
    const ctMinus24 = dateDelta(ct, {years: -2});
    period1 = {start: ctMinus24, end: ctMinus12};
    period2 = {start: ctMinus12, end: ct};
    periodX = {start: ctMinus12, end: bFinYear};
    periodY = {start: bFinYear, end: ct};
    promises.push(new Promise<void>(resolve =>
      firebase.salesHist(storeID, ctMinus24, ct, departments).then(r => {
        data.push(r);
        resolve();
      })
    ));
  }
  return new Promise(resolve =>
    forkJoin(promises).toPromise().then(() => {
      const x: { [dep: string]: DepSales2 } = {};
      const y: { [dep: string]: DepSales2 } = {};
      let iPart1: { [dep: string]: DepSales2 } = {};
      let iPart2: { [dep: string]: DepSales2 } = {};

      for (const results of data) {
        Object.keys(results.byDep).forEach(dep => {
          for (const p of [x, y, iPart1, iPart2]) {
            if (!p[dep]) { p[dep] = {netSales: 0, netCost: 0, customerCount: 0}; }
          }
        });

        for (const year of results.byYear.keys()) {
          for (const month of results.byYear.get(year).keys()) {
            for (const day of results.byYear.get(year).get(month).keys()) {
              const date = new Date(`${year}-${month}-${day}`);
              const additions: { [dep: string]: DepSales2 }[] = [];
              if (inPeriod(date, period1)) { additions.push(iPart1); }
              if (inPeriod(date, period2)) { additions.push(iPart2); }
              if (inPeriod(date, periodX)) { additions.push(x); }
              if (inPeriod(date, periodY)) { additions.push(y); }

              additions.forEach(p => {
                for (const dep of Object.keys(results.byYear.get(year).get(month).get(day))) {
                  const depSales = results.byYear.get(year).get(month).get(day)[dep];
                  p[dep].netSales += depSales.netSales;
                  p[dep].netCost += depSales.netCost;
                  p[dep].customerCount += depSales.customerCount;
                }
              });
            }
          }
        }
      }
      data = null;
      const keys = ['netSales', 'netCost', 'customerCount'];
      const i: { [dep: string]: DepSales2 } = {};
      let iTotals: { p1: DepSales2; p2: DepSales2 } =
        {p1: {netSales: 0, netCost: 0, customerCount: 0}, p2: {netSales: 0, netCost: 0, customerCount: 0}};
      const totals: { x: DepSales2; y: DepSales2; growth: DepSales2 } = {
        x: {netSales: 0, netCost: 0, customerCount: 0}, y: {netSales: 0, netCost: 0, customerCount: 0},
        growth: {netSales: 0, netCost: 0, customerCount: 0}
      };

      for (const dep of Object.keys(y)) {
        i[dep] = {} as DepSales2;

        for (const key of keys) {
          i[dep][key] = (iPart2[dep][key] / (iPart1[dep][key] + iPart2[dep][key])) * 100;
          iTotals.p1[key] += iPart1[dep][key]; iTotals.p2[key] += iPart2[dep][key];
          totals.x[key] += x[dep][key]; totals.y[key] += y[dep][key];
        }
      }
      iPart1 = null; iPart2 = null;

      for (const key of keys) {
        totals.growth[key] = (iTotals.p2[key] / (iTotals.p1[key] + iTotals.p2[key])) * 100;
      }
      iTotals = null;
      const byDep: { [dep: string]: { x: DepSales2; y: DepSales2; growth: DepSales2 } } = {};
      Object.keys(y).forEach(dep => {
        byDep[dep] = {x: {}, y: {}, growth: {}} as { x: DepSales2; y: DepSales2; growth: DepSales2 };
        keys.forEach(key => {
          byDep[dep].x[key] = x[dep][key];
          byDep[dep].y[key] = y[dep][key];
          byDep[dep].growth[key] = i[dep][key];
        });
      });
      const t = (new Date()).getTime() - d.getTime();
      console.log(`${t}ms`);
      resolve({total: totals, byDep});
    })
  );
};
