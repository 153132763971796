import {Component} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {ColDef, Column, GridApi, IHeaderParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {
  DataAdditionalHeaderMenuOption
} from '../../../../shared-utilities/models-old/ngp-report-grid/data-additional-header-menu-option';
import {Store} from '@ngrx/store';
import {
  selectCurrentPageAndTab,
  selectSelectedUserStores
} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {INavigationPage} from '../../../../shared-utilities/models-old/page/page';
import {IStore} from '../../../../shared-utilities/models-old/store/IStore';
import {selectGridHeaderMenuDataByCurrentPage} from '../../store/shared-grid.selectors';
import {
  HeaderMenuColumnData,
  StoreHeaderMenuData
} from '../../../../shared-utilities/models-old/ngp-report-grid/header-menu-data';
import {
  GridHeaderMenuActionTypes
} from '../../../../shared/shared-components/models/grid-menu/header-menu-action-types';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  selectNGPReports,
  selectNGPReportsFiltered
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {
  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS
} from '../../../../shared/shared-components/utils/price/price-banding.utils';

@Component({
  selector: 'app-grid-column-header',
  templateUrl: './grid-column-header.component.html',
  styleUrls: ['./grid-column-header.component.scss'],
})
export class GridColumnHeaderComponent implements IHeaderAngularComp {

  icons: typeof Icons = Icons;
  sortIcon = {};
  editingColumns = {};
  sortingColumnField: string | null = null;
  storeId: string | null = null;
  currentPage: string;
  indeterminate: boolean;

  params: IHeaderParams & {
    additionalData: DataAdditionalHeaderMenuOption<any>[];
  };

  constructor(
    private readonly store: Store
  ) {
  }

  get getButtonUniqueID(): string {
    return `popover-button-${this.params?.column?.getColId()}`;
  }

  agInit(params: IHeaderParams & { dataAdditional: DataAdditionalHeaderMenuOption<any>[] }): void {
    this.assignComponentProperties(params);
    this.store.select(selectCurrentPageAndTab).subscribe((currentPageObj: {
      currentSelectedPage: INavigationPage
    }): void => {
      this.currentPage = currentPageObj.currentSelectedPage.currentPage;
    });
    this.store.select(selectSelectedUserStores).subscribe(((store: IStore): void => {
      this.storeId = store.storeID;
    }));
    this.store.select(selectGridHeaderMenuDataByCurrentPage).subscribe((menuData: StoreHeaderMenuData): void => {
      this.handleHeaderMenuData(menuData, params);
    });
    this.store.select(selectGridHeaderMenuDataByCurrentPage).subscribe((menuData: StoreHeaderMenuData): void => {
      this.handleHeaderMenuData(menuData, params);
    });

    this.store.select(selectNGPReportsFiltered).subscribe((ngpReport: NGPReport[]): void => {
      const selected = [...ngpReport.filter((report: NGPReport) => report.isSelected)];
      this.indeterminate = selected.length > 0 && selected.length < ngpReport.length;
    });

  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }

  handleHeaderMenuData(
    menuData: StoreHeaderMenuData,
    params: IHeaderParams &
      { dataAdditional: DataAdditionalHeaderMenuOption<any>[] }
  ): void {
    if (menuData && Object.keys(menuData).length !== 0) {
      if (!this.sortIcon) {
        this.sortIcon = {};
      }
      if (!this.sortIcon[this.storeId]) {
        this.sortIcon[this.storeId] = {};
      }
      this.sortIcon[this.storeId][params.column.getColDef().field] = null;
      this.editingColumns = {};
      this.setHeaderIcons(menuData);
    } else {
      const key: string = params.column.getColDef().field;
      const insideOwnColumnHeader = this.params.column.getColDef().field === key;
      this.setSortButtonsColour(insideOwnColumnHeader, GridHeaderMenuActionTypes.sortUnSort);
      if (insideOwnColumnHeader) {
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStart, true, 'visible');
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStop, false, 'visible');
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStart, true, 'visible');
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStop, false, 'visible');
      }
      if (!this.sortIcon) {
        this.sortIcon = {};
      }
      if (!this.sortIcon[this.storeId]) {
        this.sortIcon[this.storeId] = {};
      }
      if (!this.editingColumns) {
        this.editingColumns = {};
      }
      if (!this.editingColumns[this.storeId]) {
        this.editingColumns[this.storeId] = {};
      }
      this.sortIcon[this.storeId][key] = null;
      this.editingColumns[this.storeId][key] = false;
    }
  }

  isButtonVisible(menuItem: DataAdditionalHeaderMenuOption<any>): boolean {
    const colDef: ColDef = this.params.column.getColDef();
    const priceBandingFields: string[] = PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS;
    if (colDef?.lockPinned && menuItem.icon === Icons.eyeOffOutline) {
      return false;
    }
    if (colDef?.cellEditor === false && menuItem.icon === Icons.pencil) {
      return false;
    }
    if (!(priceBandingFields.includes(colDef?.field)) && menuItem.icon === Icons.bandage) {
      return false;
    }
    return menuItem.visible || false;
  }

  onHeaderCheckboxClick(event: Event): void {
    const checkbox = event.target as HTMLIonCheckboxElement;
    const isChecked = !checkbox.checked;
    const gridApi: GridApi = this.params.api;
    GridUtils.updateIsSelectedForAllNgpItem(gridApi, isChecked, this.store)
    gridApi.refreshCells({force: true});
  }

  private setHeaderIcons(menuData: StoreHeaderMenuData | { [colId: string]: HeaderMenuColumnData }): void {
    Object.keys(menuData).forEach((key: string) => {
      const insideOwnColumnHeader = this.params.column.getColDef().field === key;
      switch (menuData[key]?.sortType) {
        case 'sort-asc':
          this.setSortButtonsColour(insideOwnColumnHeader, GridHeaderMenuActionTypes.sortAsc);
          this.sortingColumnField = key;
          if (!this.sortIcon[this.storeId]) {
            this.sortIcon[this.storeId] = {};
          }
          this.sortIcon[this.storeId][key] = this.icons.arrowDown;
          break;
        case 'sort-desc':
          this.setSortButtonsColour(insideOwnColumnHeader, GridHeaderMenuActionTypes.sortDesc);
          this.sortingColumnField = key;
          if (!this.sortIcon[this.storeId]) {
            this.sortIcon[this.storeId] = {};
          }
          this.sortIcon[this.storeId][key] = this.icons.arrowUp;
          break;
        case 'sort-unsort':
          this.setSortButtonsColour(insideOwnColumnHeader, GridHeaderMenuActionTypes.sortUnSort);
          if (!this.sortIcon[this.storeId]) {
            this.sortIcon[this.storeId] = {};
          }
          this.sortIcon[this.storeId][key] = null;
          break;
      }

      switch (menuData[key]?.isEditing) {
        case true:
          if (!this.editingColumns[this.storeId]) {
            this.editingColumns[this.storeId] = {};
          }
          this.editingColumns[this.storeId][key] = true;
          if (insideOwnColumnHeader) {
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStart, false, 'visible');
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStop, true, 'visible');
          }
          break;
        case false:
          if (insideOwnColumnHeader) {
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStart, true, 'visible');
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.editStop, false, 'visible');
          }
          if (!this.editingColumns[this.storeId]) {
            this.editingColumns[this.storeId] = {};
          }
          this.editingColumns[this.storeId][key] = false;
          break;
        default:
          break;
      }

      switch (menuData[key]?.isPriceBanding) {
        case true:
          if (insideOwnColumnHeader) {
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStart, false, 'visible');
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStop, true, 'visible');
          }
          break;
        case false:
          if (insideOwnColumnHeader) {
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStart, true, 'visible');
            this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.priceBandingStop, false, 'visible');
          }
          break;
        default:
          break;
      }
    });
  }

  private setSortButtonsColour(insideOwnColumnHeader: boolean, sortType: GridHeaderMenuActionTypes): void {
    if (sortType !== GridHeaderMenuActionTypes.sortUnSort) {
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour');
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour', true, GridHeaderMenuActionTypes.sortAsc);
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour', true, GridHeaderMenuActionTypes.sortDesc);
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour', true, GridHeaderMenuActionTypes.sortUnSort);
      if (insideOwnColumnHeader) {
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'yellow', 'colour');
        this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'yellow', 'colour', true, sortType);
      }
    } else {
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour');
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour', true, GridHeaderMenuActionTypes.sortAsc);
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'white', 'colour', true, GridHeaderMenuActionTypes.sortDesc);
      this.changeAdditionalDataParameter(GridHeaderMenuActionTypes.sortButton, 'yellow', 'colour', true, GridHeaderMenuActionTypes.sortUnSort);
    }
  }

  private changeAdditionalDataParameter(
    actionToChange: GridHeaderMenuActionTypes,
    propertyNewValue: string | number | boolean | null,
    propertyToChange: string,
    isOptionChanging?: boolean,
    optionAction?: GridHeaderMenuActionTypes
  ): void {
    if (isOptionChanging === undefined) {
      isOptionChanging = false
    }
    if (isOptionChanging) {
      this.params.additionalData
        .find((ad: DataAdditionalHeaderMenuOption<any>): boolean => ad.callbackParams.action === actionToChange)
        .options.find((opt: DataAdditionalHeaderMenuOption<any>): boolean => opt.callbackParams.action === optionAction)
        [propertyToChange] = propertyNewValue;
    } else {
      this.params.additionalData
        .find((ad: DataAdditionalHeaderMenuOption<any>): boolean => ad.callbackParams.action === actionToChange)
        [propertyToChange] = propertyNewValue;
    }
  }

  private assignComponentProperties(
    params: IHeaderParams<any, any> & { dataAdditional: DataAdditionalHeaderMenuOption<any>[] }
  ): void {
    const column = params.column as Column;
    const headerOptions = column.getColDef().headerComponentParams;

    const options = [];

    headerOptions.forEach((option: DataAdditionalHeaderMenuOption<any>): void => {
      const optionOptions = option?.options?.length > 0 ? option.options : [];
      let optCopy = [];

      const newOption: DataAdditionalHeaderMenuOption<any> = {
        ...option,
        callbackParams: {
          ...option.callbackParams,
          colId: params.column.getColId(),
        },
      };

      if (optionOptions.length > 0) {
        optCopy = [];
        optionOptions.forEach((oo: DataAdditionalHeaderMenuOption<any>) => {
          optCopy.push({
            ...oo,
            callbackParams: {
              ...oo.callbackParams,
              colId: params.column.getColId(),
            },
          });
        });
        newOption.options = optCopy;
      }
      options.push(newOption);
    });

    this.params = {
      ...params,
      additionalData: [...options],
    };
  }
}
