import {Component, Inject, Input, OnInit} from '@angular/core';
import {
  ApiLogObj,
  keyToTitle,
  Message,
  sItemIntToKey,
  StoreInfo
} from '../../../../../shared-utilities/models-old/datastructures';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {FirebaseService} from '../../../../../shared-utilities/services-old/firebase.service';
import {StockUpdatesViewerComponent} from '../stock-updates-viewer/stock-updates-viewer.component';

@Component({
  template: `
    <div class='displayed' style='font-size: 0.8em;'>
      <span>{{ oldValue }}</span>
      <ion-icon name='arrow-forward-outline'></ion-icon>
      <span>{{ newValue }}</span>
    </div>
  `
})
export class PopoverComponent {
  @Input() key: string;
  @Input() oldValue: string;
  @Input() newValue: string;
}

@Component({
  selector: 'app-msg-api-response',
  templateUrl: './msg-api-response.component.html',
  styleUrls: ['./msg-api-response.component.scss'],
})
export class MsgApiResponseComponent implements OnInit {
  apiEvent: ApiLogObj;

  changing: boolean;
  redone: boolean;

  readonly keyToTitle = keyToTitle;
  readonly i2k = sItemIntToKey;

  readonly storeName: string;

  private timestamp: number;
  private message: Message;

  constructor(
    @Inject('INPUT') private useValue: {
      id: number | string; message: Message; getStoreInfo: (storeID: string) => StoreInfo;
    },
    private firebase: FirebaseService,
    private popoverControl: PopoverController,
    private alertControl: AlertController,
    private modalControl: ModalController,
  ) {
    this.storeName = this.useValue.getStoreInfo(this.useValue.message.sender).name;
  }

  get messageSuccess(): boolean {
    return this.message.payload.success;
  }

  get msgTS(): number {
    return this.timestamp;
  }

  ngOnInit() {
    this.timestamp = +this.useValue.id;
    this.message = this.useValue.message;
    this.firebase.getApiLog(this.message.sender, this.message.payload.event).then(apiLogObj => {
      this.apiEvent = apiLogObj;

      if (this.apiEvent.lastAttempt) {
        // this.apiEvent.lastAttempt = this.apiEvent.lastAttempt.toDate();
        if (this.msgTS === this.apiEvent.lastAttempt.valueOf()) {
          this.redone = true;
        }
      }
    });
  }

  keys(obj: any): string[] {
    return Object.keys(obj);
  }

  async redo() {
    const confirm = await this.alertControl.create({
      header: 'Api Call Reattempt', subHeader: 'Would you like to retry the failed server action which ' +
        'resulted in this message?', message: 'If the server responds with another failure, or no message at all, ' +
        'please inform Techodactyl Support.', cssClass: 'custom-alert',
      buttons: ['cancel', {text: 'retry', role: 'y'}],
    });
    await confirm.present();
    const response = await confirm.onDidDismiss();

    if (response.role === 'y') {
      this.changing = true;
      this.firebase.reattemptUpdateStock(this.message.sender, this.message.payload.event)
        .then(() => {
          this.changing = false;
          this.redone = true;
        });
    }
  }

  filterDataByKey(apiEvent: any, filterKey: string): any {
    const filteredApiEvent = {...apiEvent}; // Create a shallow copy of apiEvent

    if (filteredApiEvent.data) {
      for (const code in filteredApiEvent.data) {
        if (filteredApiEvent.data.hasOwnProperty(code)) {
          const item = filteredApiEvent.data[code];

          if (item.n && !(filterKey in item.n)) {
            delete filteredApiEvent.data[code];
          }
        }
      }
    }

    return filteredApiEvent;
  }

  async fieldChangePopover(ev: any, timestamp: number, code: string) {
    const copiedApiEvent = JSON.parse(JSON.stringify(this.apiEvent));

    if (copiedApiEvent.hasOwnProperty('data')) {
      const data = copiedApiEvent.data;

      for (const key in data) {
        if (data.hasOwnProperty(key) && key !== code) {
          delete data[key];
        }
      }
    }

    const popover = await this.modalControl.create({
      component: StockUpdatesViewerComponent,
      componentProps: {apiEvent: copiedApiEvent},
    });
    await popover.present();

    const {role} = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  async viewAll() {
    const modal = await this.modalControl.create({
      component: StockUpdatesViewerComponent,
      componentProps: {apiEvent: this.apiEvent, storeID: this.useValue.message.sender},
      cssClass: ['larger-modal'],
    });
    await modal.present();

    const {role} = await modal.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }
}
