import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appImgLoaded]'
})
export class ImgLoadedDirective {

  @Output() imgLoaded = new EventEmitter();
  constructor(private elRef: ElementRef<HTMLImageElement>) {
    console.log('COMPLETE', this.elRef.nativeElement.complete);
    if (this.elRef.nativeElement.complete) {
      this.imgLoaded.emit();
    }
  }

  @HostListener('load')
  onLoad() {
    console.log('COMPLETE');
    this.imgLoaded.emit();
  }

}
