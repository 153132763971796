import {createAction, props} from '@ngrx/store';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {LineColour, StockItem, Supplier, VatRates} from '../../../shared-utilities/models-old/datastructures';
import {FbQuery} from '../../../shared-utilities/services-old/firebase.service';
import {ColDef} from 'ag-grid-community';
import {IStore} from '../../../shared-utilities/models-old/store/IStore';
import {FiltersAndTools} from '../../../shared-utilities/models-old/ngp-reports/filters-tools-ngp';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {IDepartment} from '../../../shared/shared-components/models/stock/departments';
import {IGridHeaderMenu} from '../../../shared/shared-components/models/grid-menu/header-menu';

// ====================================================================================================
// Get Line Colours
// ====================================================================================================
export const getLineColours = createAction(
  '[NGP Report][Line Colour] Get Line Colours',
  props<{ pageStoreDocument: string }>()
);

export const getLineColoursSuccess = createAction(
  '[NGP Report][Line Colour] Get Line Colours Success',
  props<{ lineColours: LineColour; selectedStore: IStore }>()
);

export const getLineColoursFailure = createAction(
  '[NGP Report][Line Colour] Get Line Colours Failure',
  props<{ error: IError }>()
);

export const setIsLineColoursLoading = createAction(
  '[NGP Report][Line Colour] Set Is Line Colours Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Get Stock Items
// ====================================================================================================
export const getStockItem = createAction(
  '[NGP Report][Stock Item] Get Stock Item',
  props<{ store: IStore; firebaseQueries: FbQuery[]; idField: string; }>()
);

export const getStockItemWithExistingSuppliers = createAction(
  '[NGP Report][Stock Item] Get Stock Item With Existing Suppliers',
  props<{ store: IStore; firebaseQueries: FbQuery[]; idField: string; suppliers: { [key: string]: Supplier } }>()
);

export const getStockItemWithoutExistingSuppliers = createAction(
  '[NGP Report][Stock Item] Get Stock Item Without Existing Suppliers',
  props<{ store: IStore; firebaseQueries: FbQuery[]; idField: string; }>()
);

export const getStockItemSuccess = createAction(
  '[NGP Report][Stock Item] Get Stock Item Success',
  props<{ stockItems: StockItem[]; store: IStore }>()
);

export const getStockItemFailure = createAction(
  '[NGP Report][Stock Item] Get Stock Item Failure',
  props<{ error: IError }>()
);

export const setIsStockItemLoading = createAction(
  '[NGP Report][Stock Item] Set Stock Item Loading',
  props<{ isLoading: boolean }>()
);

// ====================================================================================================
// Set Filters and Tools
// ====================================================================================================
export const setNGPReportFiltersAndTools = createAction(
  '[NGP Report][Filters and Tools] Set NGP Report Filters and Tools',
  props<{ filtersAndTools: FiltersAndTools }>()
);

export const setNGPReportFiltersAndToolsWithStore = createAction(
  '[NGP Report][Filters and Tools] Set NGP Report Filters and Tools With Store',
  props<{ filtersAndTools: FiltersAndTools, store: IStore }>()
);

export const setNewlyAddedItemsVisibility = createAction(
  '[NGP Report][Filters and Tools] Set Newly Added Items Visibility'
);

export const setNewlyAddedItemsVisibilityWithStoreID = createAction(
  '[NGP Report][Filters and Tools] Set Newly Added Items Visibility with Store ID',
  props<{ storeID: string }>()
);


// ====================================================================================================
// Update NGP Report Item
// ====================================================================================================
export const updateSingleNGPReport = createAction(
  '[NGP Report][Update] Update Single NGP Report',
  props<{ ngpReport: NGPReport }>()
);

export const updateSingleNGPReportWithStoreId = createAction(
  '[NGP Report][Update] Update Single NGP Report At StoreID',
  props<{ ngpReport: NGPReport, storeID: string }>()
);

export const updateIsSelectedForSingleNGPReport = createAction(
  '[NGP Report][Update] Update Is Selected Single NGP Report',
  props<{ ngpReport: NGPReport }>()
);

export const updateIsSelectedForSingleNGPReportWithStoreId = createAction(
  '[NGP Report][Update] Update Is Selected Single NGP Report At StoreID',
  props<{ ngpReport: NGPReport, storeID: string }>()
);


export const updateAllNGPReports = createAction(
  '[NGP Report][Update] Update Multiple NGP Reports',
  props<{ ngpReports: NGPReport[] }>()
);

export const updateAllNGPReportsWithStoreID = createAction(
  '[NGP Report][Update] Update Multiple NGP Reports With Store ID',
  props<{ ngpReports: NGPReport[], storeID: string }>()
);

// ====================================================================================================
// Set Grid Column Definitions
// ====================================================================================================
export const setNGPReportGridColDefs = createAction(
  '[NGP Report][Col Defs] Set NGP Report Grid Column Definition',
  props<{ colDefs: ColDef[] }>()
);

export const setNGPReportGridColDefsWithStoreID = createAction(
  '[NGP Report][Col Defs] Set NGP Report Grid Column Definitions with Store ID',
  props<{ colDefs: ColDef[]; storeID: string }>()
);


// ====================================================================================================
// Set Grid Menu Action
// ====================================================================================================
export const setNGPReportMenuActions = createAction(
  '[NGP Report][Menu Actions] Set NGP Report Menu Actions per Column',
  props<{ gridHeaderMenu: IGridHeaderMenu }>()
);


export const setNGPReportMenuActionsWithStore = createAction(
  '[NGP Report][Menu Actions] Set NGP Report Menu Actions per Column with Store Details',
  props<{ store: IStore; gridHeaderMenu: IGridHeaderMenu }>()
);

//===============================================================================================================
// Set Visible Fields
//===============================================================================================================
export const setNGPReportVisibility = createAction(
  '[NGP Report][Visible Fields] Set NGP Report Visible Fields',
  props<{ colDef: ColDef[]; menuData: string[] }>()
);

export const setNGPReportVisibilityWithStoreID = createAction(
  '[NGP Report][Visible Fields] Set NGP Report Visibility with Store ID',
  props<{ colDef: ColDef[]; menuData: string[]; storeID: string }>()
);

export const updateSingleVisibleField = createAction(
  '[NGP Report][Visible Fields] Update Single Visible Field',
  props<{ colId: string; value: boolean }>()
);

//===============================================================================================================
// Get Store Departments
//===============================================================================================================
export const getStoreDepartments = createAction(
  '[NGP Report][Departments] Get Store Departments',
  props<{ store: IStore }>()
);

export const getStoreDepartmentsSuccess = createAction(
  '[NGP Report][Departments] Get Store Departments Success',
  props<{ store: IStore; departments: IDepartment[] }>()
);

export const getStoreDepartmentsFailure = createAction(
  '[NGP Report][Departments] Get Store Departments Failure',
  props<{ error: IError }>()
);

export const setIsStoreDepartmentsLoading = createAction(
  '[NGP Report][Departments] Set Is Store Departments Loading',
  props<{ isLoading: boolean }>()
);

//===============================================================================================================
// Get Store Suppliers
//===============================================================================================================
export const getStoreSuppliersByStoreId = createAction(
  '[NGPReport][Suppliers] Get Store Suppliers By Store Id',
  props<{ storeID: string }>()
);

export const getStoreSuppliersByStoreIdSuccess = createAction(
  '[NGPReport][Suppliers] Get Store Suppliers By Store Id Success',
  props<{ suppliers: { [key: string]: Supplier }; storeID: string }>()
);

export const getStoreSuppliersByStoreIdFailure = createAction(
  '[NGPReport][Suppliers] Get Store Suppliers By Store Id Failure',
  props<{ error: IError }>()
);

export const setIsStoreSuppliersByStoreIdLoading = createAction(
  '[NGPReport][Suppliers] Set Is Store Suppliers By Store Id Loading',
  props<{ isLoading: boolean }>()
);

//===============================================================================================================
// Remove Selected Items From NGP Report
//===============================================================================================================
export const removeSelectedNgpReports = createAction(
  '[NGP Report][Remove] Remove Selected NGP Reports',
  props<{ selectedReports: NGPReport[], itemsToUpdate: { [code: string]: { o: StockItem; n: StockItem } } }>()
);

export const removeSelectedNgpReportsWithStoreID = createAction(
  '[NGP Report][Remove] Remove Selected NGP Reports with Store ID',
  props<{ selectedReports: NGPReport[]; storeID: string }>()
);

//===============================================================================================================
// make changes to Price incl || NPG || Gp Diff when one of them is changed
//===============================================================================================================

export const updatePriceGpForNgpReport = createAction(
  '[NGP Report][Price] Update The Price NGP and GpDiff for NGP Reports',
  props<{ ngpReport: NGPReport, field: string }>()
);

export const updatePriceGpForNgpReportWithStoreID = createAction(
  '[NGP Report][Price] Update The Price NGP and GpDiff for NGP Reports for Store ID',
  props<{ ngpReport: NGPReport; field: string; storeID: string }>()
);

//===============================================================================================================
// Get the vat Rate conversions for each store
//===============================================================================================================

export const getVatRateConversion = createAction(
  '[NGP Report][VAT Rate] Get Vat Rate Conversion'
);

export const getVatRateConversionWithExistingVatRates = createAction(
  '[NGP Report][VAT Rate] Get Vat Rate Conversion With Existing Vat Rates',
  props<{ userStores: IStore[], vatRates: { [storeID: string]: VatRates } }>()
);

export const getVatRateConversionWithoutExistingVatRates = createAction(
  '[NGP Report][VAT Rate] Get Vat Rate Conversion Without Existing Vat Rates',
  props<{ userStores: IStore[] }>()
);

export const getVatRateConversionSuccess = createAction(
  '[NGP Report][VAT Rate] Get Vat Rate Conversion Success',
  props<{ vatRates: { [storeID: string]: VatRates } }>()
);

export const getVatRateConversionFailure = createAction(
  '[NGP Report][VAT Rate] Get Vat Rate Conversion Failure',
  props<{ error: IError }>()
);
