import {Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Icons} from '../../../shared-icons/icons';
import {selectSharedGridVisibleFieldsByCurrentPage} from '../../store/shared-grid.selectors';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NumberUtils} from '../../../../shared-utilities/utils-old/shared-utils-old/number-utils';
import {
  updatePriceGpForNgpReport,
  updateSingleVisibleField
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';

@Component({
  selector: 'app-cell-editor-price-ngp',
  templateUrl: './cell-editor-price-ngp.component.html',
  styleUrls: ['./cell-editor-price-ngp.component.scss'],
})
export class CellEditorPriceNgpComponent implements ICellEditorAngularComp {

  @ViewChild('input', {static: true}) input: ElementRef;

  value: number | string;
  params: ICellEditorParams;
  width: string;
  colDefField: string;
  currentVisibleFields: { [key: string]: boolean } = {};

  visibleFields$: Observable<{ [key: string]: boolean }>;

  readonly icons: typeof Icons = Icons;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = +this.params.data[this.colDefField];
    this.width = `${this.params.colDef.width}px`;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });
  }

  getValue(): number {
    if (this.params.value !== this.params.data.originalValue[this.colDefField].value) {
      this.params.data.isEdited = true;
    } else if (this.params.value === this.params.data.originalValue[this.colDefField].value) {
      this.params.data.isEdited = false;
    }
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    const newValue = +NumberUtils.formatNumberToDecimals(+this.value, 2);
    switch (this.colDefField) {
      case 'sellPriIncl1':
        this.params.data[this.colDefField] = +newValue;
        this.updateVisibleField('sellPriIncl1');
        break;
      case 'nominalGP':
        this.updateVisibleField('nominalGP');
        break;
      default:
        break;
    }
    this.store.dispatch(updatePriceGpForNgpReport({ngpReport: this.params.data, field: this.colDefField}))
    return +NumberUtils.formatNumberToDecimals(+this.value, 2);
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

  onKeyUp(event: KeyboardEvent): void {
    this.params.value = this.value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagFromNgpItem(this.params.data, this.colDefField, this.store);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    this.getValue();
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  updateVisibleField(field: string): void {
    switch (field) {
      case 'sellPriIncl1':
        if (!this.currentVisibleFields.nominalGP) {
          this.store.dispatch(updateSingleVisibleField({
            colId: 'nominalGP',
            value: true
          }));
        }
        break;
      case 'nominalGP':
        if (!this.currentVisibleFields.sellPriIncl1) {
          this.store.dispatch(updateSingleVisibleField({
            colId: 'sellPriIncl1',
            value: true
          }));
        }
        break;
      default:
        break;
    }
  }

}
