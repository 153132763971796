import {Component, ElementRef, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {
  selectSuppliersByUserSelectedStore
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';

@Component({
  selector: 'app-cell-editor-supplier',
  templateUrl: './cell-editor-supplier.component.html',
  styleUrls: ['./cell-editor-supplier.component.scss'],
})
export class CellEditorSupplierComponent implements ICellEditorAngularComp {

  @ViewChild('input', {static: true}) input: ElementRef;

  value: any;
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  options: any[] = [];
  icons = Icons;
  departments = {};
  unSortedSuppliers: any;
  unSortedSuppliers$: Observable<any>;
  searchControl = new FormControl('');
  filteredOptions: any[] = [];

  constructor(
    private readonly store: Store
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.unSortedSuppliers$ = this.store.select(selectSuppliersByUserSelectedStore);
    this.unSortedSuppliers$.subscribe((suppliers): void => {
      this.unSortedSuppliers = this.objectToArray(suppliers);
      this.filteredOptions = this.unSortedSuppliers; // Initially set the filtered options to all suppliers
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(200)
    ).subscribe((searchTerm: string): void => {
      this.filterOptions(searchTerm);
    });

    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.params.api.setColumnWidths([{key: this.colDefField, newWidth: 400}]);

  }

  objectToArray(obj: any): any[] {
    return Object.keys(obj).map((key: string) => ({key, description: obj[key].name}));
  }

  filterOptions(searchTerm: string): void {
    if (!searchTerm) {
      this.filteredOptions = this.unSortedSuppliers;
      return;
    }
    const lowerSearchTerm = searchTerm.toLowerCase();
    this.filteredOptions = this.unSortedSuppliers.filter((option: { key: string; description: string }) => {
      const keyMatches = option.key.toLowerCase().includes(lowerSearchTerm);
      const descriptionMatches = option.description?.toLowerCase().includes(lowerSearchTerm);
      return keyMatches || descriptionMatches;
    });
  }

  onSelectionChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
    this.value = value;
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
  }

  selectBestMatch(): void {
    if (this.filteredOptions.length > 0) {
      this.value = this.filteredOptions[0].key;
      this.params.data[this.colDefField] = this.value;
      GridUtils.updateIsEditedAndForceRefresh(this.params);
    }
  }

  getValue(): string | number {
    const type = this.params.colDef.cellDataType;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleNgpItem(this.params.data, this.colDefField, this.value, this.store)
    if (type === 'text') {
      return this.value.toString();
    } else if (type === 'number') {
      return +this.value;
    }
  }

  refresh(params: ICellEditorParams<any>): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

}
