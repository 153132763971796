import {Component, Input, OnInit} from '@angular/core';
import {ModalController, PopoverController} from '@ionic/angular';
import {
  UserProfileConfigComponent
} from '../user-profile-config/user-profile-config.component';
import {IUser} from '../../../../shared-utilities/models-old/user/IUser';
import {MessagesService} from '../../../../shared-utilities/services-old/message-service/messages.service';
import {
  FreshnessExplanationComponent
} from '../../../../features-as-modules/feature-core/components/freshness-explanation/freshness-explanation.component';
import {Store} from '@ngrx/store';
import {setCurrentPageAndTab} from '../../../../features-as-modules/feature-core/store/core.actions';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.html',
  styleUrls: ['./menu-popup.component.scss'],
})
export class MenuPopupComponent implements OnInit {

  @Input() user: IUser;
  @Input() pp: string[];

  private messagesCount: number;

  constructor(
    private msgService: MessagesService,
    private modalControl: ModalController,
    private popoverController: PopoverController,
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.msgService.numLiveMsgsSub.subscribe((num: number) => (this.messagesCount = num));
  }

  async onUserProfileClick(): Promise<void> {
    await this.popoverController.dismiss();
    const mc: HTMLIonModalElement = await this.modalControl.create({
      component: UserProfileConfigComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

  async onLoginLogoutClick(): Promise<void> {
    await this.popoverController.dismiss();
    this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'login', currentTab: null}}));
  }

  async onExplainManageItClick(): Promise<void> {
    await this.popoverController.dismiss();
    const mc: HTMLIonModalElement = await this.modalControl.create({
      component: FreshnessExplanationComponent,
      cssClass: 'larger-modal',
    });
    await mc.present();
  }

}
