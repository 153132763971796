import {Component, OnDestroy} from '@angular/core';
import {AlertController, IonNav, ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {StoreConfigComponent} from '../store-config/store-config.component';
import {EditorsConfigComponent} from '../editors-config/editors-config.component';
import {FirebaseService} from '../../../../shared-utilities/services-old/firebase.service';
import {RuleHumanID} from '../../../../shared-utilities/models-old/utils-old/rule-structure';

interface SettingsOptions {
  text: string;
  disabled?: boolean;
  component?: any;
  rules?: RuleHumanID[];
}

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss'],
})
export class ApplicationSettingsComponent implements OnDestroy {

  canNavigate = true;

  private subscriptions: Subscription[] = [];

  private readonly options: SettingsOptions[] = [
    // {text: 'User Profile', component: UserProfileConfigComponent},
    {text: 'Store Configuration', component: StoreConfigComponent},
    {text: 'Configure Editors', component: EditorsConfigComponent},
    //{text: 'Store Linkages', component: DepartmentLinkingComponent},
  ];

  constructor(
    private firebase: FirebaseService,
    private alertControl: AlertController,
    private modalControl: ModalController,
    private nav: IonNav,
  ) { }

  get menuOptions() {
    return this.options;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  close = async () => {
    if (!this.canNavigate) {
      const ac = await this.alertControl.create({
        header: 'Save before you go', subHeader: 'Closing this window now will discard your unsaved changes.',
        message: 'Would you like to save first?', cssClass: ['custom-alert'],
        buttons: ['Cancel', {text: 'Save', role: 'y'}]
      });
      await ac.present();
      const {role} = await ac.onDidDismiss();

      if (role !== 'y') {
        return;
      }
    }
    this.modalControl.dismiss().then();
  };

  async selectSettings(option: { text: string; disabled?: boolean; component?: string }) {
    if (option.disabled || !option.component) { return; }
    await this.nav.push(option.component, {});
  }

}
