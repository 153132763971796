import {CellEditorSelectDropdownComponent} from './cell-editor-select-dropdown/cell-editor-select-dropdown.component';
import {CellEditorSupplierComponent} from './cell-editor-supplier/cell-editor-supplier.component';
import {CellEditorPriceNgpComponent} from './cell-editor-price-ngp/cell-editor-price-ngp.component';
import {CellEditorGeneralComponent} from './cell-editor-general/cell-editor-general.component';

export const CELL_EDITOR_GENERAL = CellEditorGeneralComponent;
export const CELL_EDITOR_PRICE_NGP = CellEditorPriceNgpComponent;
export const CELL_EDITOR_SELECT_DROPDOWN = CellEditorSelectDropdownComponent;
export const CELL_EDITOR_SUPPLIER = CellEditorSupplierComponent;

export const CELL_EDITOR_COMPONENTS = [
  CELL_EDITOR_GENERAL,
  CELL_EDITOR_PRICE_NGP,
  CELL_EDITOR_SELECT_DROPDOWN,
  CELL_EDITOR_SUPPLIER,
];

