import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './features-as-modules/feature-core/core.module';
import {AngularFireModule} from '@angular/fire/compat';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AgGridModule} from 'ag-grid-angular';
import 'ag-grid-angular';
import {SharedModule} from './shared-modules/shared-module/shared-module.module';
import {SharedIconsModule} from './shared-modules/shared-icons/shared-icons.module';
import {SharedComponentsModule} from './shared/shared-components/shared-components.module';
import {PushModule} from '@ngrx/component';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserAnimationsModule,
    MatTooltipModule,
    NgxPaginationModule,
    SharedIconsModule,
    SharedModule,
    SharedComponentsModule,
    CoreModule,
    AgGridModule,
    PushModule
  ],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, BarcodeScanner],
  bootstrap: [AppComponent]
})
export class AppModule {
}
